import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  Space,
  Button,
  message,
  Switch,
  Tag,
  Modal,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import { header } from '../../../../api/rest';
import { history } from '../../../../redux/store';
import {
  getSurveys as getSurveysApi,
  createSurveyApi,
  deleteSurveyApi,
  editSurveyApi,
} from '../../../../redux/slices/adminSlice';
import { listCommunities } from '../../../../redux/slices/registerSlice';
import { Loader } from '../../../shared/Loader';
import { getAdminUserList } from '../../../../redux/slices/adminUserSlice';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Option } = Select;

export default function Surveys() {
  const storageData = localStorage.getItem('userData');
  const token = storageData ? JSON.parse(storageData).token : '';
  const [surveys, setSurveys] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [sposors, setSponsors] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createSurveyModal, setCreateSurveyModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (text) =>
        text ? (
          <Tag color='green'>Active</Tag>
        ) : (
          <Tag color='red'>Disabled</Tag>
        ),
    },
    {
      title: 'Completed',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      render: (text) =>
        text ? (
          <Tag color='green'>Completed</Tag>
        ) : (
          <Tag color='orange'>In Progress</Tag>
        ),
    },
    {
      title: '% Completed',
      dataIndex: 'completedCount',
      key: 'completedCount',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            type='primary'
            disabled={deleteLoading}
            onClick={() => {
              handleEdit(record);
            }}
          >
            Edit
          </Button>
          <Button
            type='primary'
            disabled={deleteLoading}
            onClick={() => {
              history.push(`/admin/surveys/${record.id}/questions`);
            }}
          >
            Questions
          </Button>
          <Button
            type='primary'
            disabled={deleteLoading}
            onClick={() => {
              history.push(`/admin/surveys/${record.id}/users`);
            }}
          >
            Results
          </Button>
          <Button
            type='primary'
            danger
            disabled={deleteLoading}
            onClick={() => {
              deleteSurvey(record.id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getSurveys();
    getSponsors();
    header.Authorization = `Bearer ${token}`;
    listCommunities(header)
      .then((res) => {
        setCommunities(res.data);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : 'error');
      });
  }, []);

  const getSponsors = async () => {
    getAdminUserList({role:'sponsor'}).then(res => {
      setSponsors(res.data);
    }).catch((err) => {
          message.error(err?.message ? err.message : 'something went wrong');
        })
  }

  const getSurveys = useCallback(async () => {
    if (token) {
      header.Authorization = `Bearer ${token}`;
      getSurveysApi(header)
        .then((res) => {
          setSurveys(res);
          setShowPageLoader(false);
        })
        .catch((err) => {
          message.error(err?.message ? err.message : 'something went wrong');
        });
    } else {
      history.push('/');
    }
  }, [token]);

  const createSurvey = async (values) => {
    setLoading(true);
    try {
      await createSurveyApi(values, { Authorization: `Bearer ${token}` });
      message.success('Survey created');
      setCreateSurveyModal(false);
      getSurveys();
      form.resetFields();
    } catch (err) {
      message.error(err?.message ? err.message : 'something went wrong');
    }
    setLoading(false);
  };

  const deleteSurvey = async (id) => {
    setDeleteLoading(true);
    try {
      await deleteSurveyApi(id, { Authorization: `Bearer ${token}` });
      message.success('Survey Deleted');
      getSurveys();
    } catch (err) {
      message.error(err?.message ? err.message : 'something went wrong');
    }
    setDeleteLoading(false);
  };

  const handleEdit = async (record) => {
    form.setFieldsValue({
      name: record.name,
      description: record.description,
      isActive: record.isActive,
      communities: record.communities,
    });
    setIsEdit(record.id);
    setCreateSurveyModal(true);
  };

  const handleEditSurvey = async (values) => {
    try {
      await editSurveyApi(isEdit, values, { Authorization: `Bearer ${token}` });
      await getSurveys();
      message.success('Survey Updated');
      setIsEdit(false);
      setCreateSurveyModal(false);
      form.resetFields();
    } catch (err) {
      message.error(err?.message ? err.message : 'something went wrong');
    }
  };

  return (
    <>
      {showPageLoader ? <Loader /> : ''}
      <div className='row mt-4 mb-2'>
        <div className='col-12 text-right'>
          <Button onClick={() => setCreateSurveyModal(true)}>
            Create Survey
          </Button>
        </div>
      </div>
      <Table
        dataSource={surveys}
        columns={columns}
        rowKey={(record) => record.id}
      />

      <Modal
        title={isEdit ? 'Edit Survey' : 'Create Survey'}
        centered
        visible={createSurveyModal}
        okButtonProps={{ title: 'Submit' }}
        onCancel={() => {
          setCreateSurveyModal(false);
          setIsEdit(false);
        }}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          onFinish={isEdit ? handleEditSurvey : createSurvey}
        >
          <Form.Item
            label={'Name'}
            name='name'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={'Description'} name='description'>
            <Input />
          </Form.Item>

          <Form.Item label={'Select Communities'} name='communities'>
            <Select
              mode='multiple'
              allowClear
              onChange={(val) => {
                form.setFieldsValue({ communities: val });
              }}
            >
              {communities.map((community) => (
                <Option value={community._id} key={community._id}>
                  {community.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'Select Sponsor'} name='sponsors'>
            <Select
              mode='multiple'
              allowClear
              onChange={(val) => {
                form.setFieldsValue({ sponsors: val });
              }}
            >
              {sposors.map((sponsor) => (
                <Option value={sponsor._id} key={sponsor._id}>
                  {sponsor.firstName} {sponsor.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'Active'} name='isActive'>
            <Switch defaultChecked />
          </Form.Item>

          <Row justify={'center'}>
            <Button type='primary' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
