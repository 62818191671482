import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { CONSTANTS } from '../../shared/constants';
import { header } from '../../../api/rest';
import {
  getUsersGoalStatus,
  toggleUsersGoalStatus,
} from '../../../redux/slices/journeySlice';
import Dart from '../../../assets/images/dart.png';
import { message } from 'antd';
import './goalsChecklistModal.scss';
const { ERROR_MESSAGE } = CONSTANTS;

export function GoalsChecklistModal(props) {
  let { title } = props;
  const [userGoal, setUserGoal] = useState('');
  const storageData = localStorage.getItem('userData');
  //   const [isLoading, showIsLoading] = useState(true);
  const { token, payload } = storageData ? JSON.parse(storageData) : '';
  const { id } = payload;

  function userGoalsChecklistToggle(goal) {
    setUserGoal((prev) => ({ ...prev, [goal]: !userGoal[goal] }));
    header.Authorization = token;
    toggleUsersGoalStatus(goal, header);
  }

  function getGoalStatusFromApi() {
    header.Authorization = token;
    getUsersGoalStatus(id, header)
      .then((res) => {
        setUserGoal(res.updatedUser);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  useEffect(() => {
    getGoalStatusFromApi();
  }, []);
  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      okButtonProps={{ title: "Submit" }}
      footer={[
        <Button key="back" onClick={() => props.onCancel(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={(event) => props.onOk(event)}
        >
          Done
        </Button>,
      ]}
    >
      <div className="goal-checklist">
        <p>
          <img src={Dart} alt="Dart" style={{ float: "unset" }} /> You're making
          progress! Keep up with your goals.
        </p>
        <ul>
          <li onClick={() => userGoalsChecklistToggle("isVisionBoardCreated")}>
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.isVisionBoardCreated
                      ? userGoal.isVisionBoardCreated
                      : false
                    : false
                }
                readOnly
              />
              <span />
            </label>
            <span className="list-content">1. Create a "Vision Board"</span>{" "}
            (update your profile/cover page with inspiration for your business
            and make/upload a video explaining what problem you will solve (ex:
            business idea, wealth, job, etc.)
          </li>
          <li
            onClick={() =>
              userGoalsChecklistToggle("isBusinessEducationModuleCompleted")
            }
          >
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.isBusinessEducationModuleCompleted
                      ? userGoal.isBusinessEducationModuleCompleted
                      : false
                    : false
                }
                readOnly
              />
              <span />
            </label>
            <span className="list-content">
              2. Complete "Business Education" modules
            </span>
          </li>
          <li
            onClick={() => userGoalsChecklistToggle("isUserRegisteredBusiness")}
          >
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.isUserRegisteredBusiness
                      ? userGoal.isUserRegisteredBusiness
                      : false
                    : false
                }
                readOnly
              />
              <span />
            </label>
            <span className="list-content">3. "Register Your Business"</span>
          </li>
          <li
            onClick={() => userGoalsChecklistToggle("isUserRequestEmployeeId")}
          >
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.isUserRequestEmployeeId
                      ? userGoal.isUserRequestEmployeeId
                      : false
                    : false
                }
              />
              <span />
            </label>
            <span className="list-content">
              4. Request an "Employee Identification Number"
            </span>
          </li>
          <li onClick={() => userGoalsChecklistToggle("isUserOpenBankAccount")}>
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.isUserOpenBankAccount
                      ? userGoal.isUserOpenBankAccount
                      : false
                    : false
                }
              />
              <span />
            </label>
            <span className="list-content">5. Open a "Bank Account"</span>
          </li>
          <li onClick={() => userGoalsChecklistToggle("marketResearch")}>
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.marketResearch
                      ? userGoal.marketResearch
                      : false
                    : false
                }
              />
              <span />
            </label>
            <span className="list-content">6. Market Research</span>
          </li>
          <li onClick={() => userGoalsChecklistToggle("businessModel")}>
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.businessModel
                      ? userGoal.businessModel
                      : false
                    : false
                }
              />
              <span />
            </label>
            <span className="list-content">7. Business Model</span>
          </li>
          <li onClick={() => userGoalsChecklistToggle("designProduct")}>
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.designProduct
                      ? userGoal.designProduct
                      : false
                    : false
                }
              />
              <span />
            </label>
            <span className="list-content">8. Design Product </span>
          </li>
          <li
            onClick={() =>
              userGoalsChecklistToggle("developMarketingPlanStrategy")
            }
          >
            <label>
              <input
                type="checkbox"
                name=""
                checked={
                  userGoal
                    ? userGoal.developMarketingPlanStrategy
                      ? userGoal.developMarketingPlanStrategy
                      : false
                    : false
                }
              />
              <span />
            </label>
            <span className="list-content">
              9. Develop "Marketing Plan Strategy"{" "}
            </span>
          </li>
        </ul>
      </div>
    </Modal>
  );
}
