import React, { useEffect, useState } from 'react';
import './about.scss';
// import AboutImg from '../../../assets/images/profile-detail-img.jpg';
import { getVideoHome } from '../../../redux/slices/adminSlice';
import { header } from '../../../api/rest';
import { CONSTANTS, REGEX_CONSTANTS } from '../../shared/constants';
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
import { message } from 'antd';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

const { ERROR_MESSAGE } = CONSTANTS;
var {
  // ALL_PHOTO_FORMAT_REGEX,
  // ALL_VIDEO_FORMAT_REGEX,
  YOUTUBE_URL_REGEX,
  YOUTUBE_EMBED_URL_REGEX,
} = REGEX_CONSTANTS;

function About() {
  React.useEffect(() => {
    document.title = 'About Us';
  });

  // const dispatch = useDispatch();
  // const loginUserData = useSelector(state => state.user);
  // const storageData = localStorage.getItem("userData");
  // const payload = storageData ? JSON.parse(storageData).payload : '';
  // const role = payload ? payload.role : '';
  const [videoList, setVideoList] = useState([]);
    const [playVid, setPlayVid] = React.useState(false);
  const videoRef = React.useRef();
  function playVidfunc() {
    let status = !playVid;
    setPlayVid(status);
    if (status) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }

  useEffect(() => {
    getHomePageVideoList();
  }, []);

  function getHomePageVideoList() {
    getVideoHome(header)
      .then((res) => {
        setVideoList(res.data);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }
  function getFileLink(data) {
    let arr = '';
    if (data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].homeImagesLink) {
          arr = data[i].homeImagesLink;
          return arr;
        } else {
        }
      }
    } else {
      return arr;
    }
  }
  function getId(url) {
    const regExp = YOUTUBE_EMBED_URL_REGEX;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <React.Fragment>
      <section className='about-banner-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='banner-content'>
                <h1>about us</h1>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='text-right'>
                      <h3>Mission</h3>
                      <p>
                        To liberate the skillsets, talents, and ideas of
                        creatives and makers to grow sustaining, abundant, and
                        impactful businesses that create wealth for their
                        families and communities.
                      </p>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className=''>
                      <h3>Vision</h3>
                      <p>
                        To end poverty bottom-up by showing communities of
                        creators and makers how to use their existing resources
                        and talents to grow into thriving businesses, create
                        jobs, develop strong local economies and contribute to
                        global prosperity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {videoList.length ? (
        videoList.map((value, index) => {
          return value.aboutusImagesLink.length
            ? value.aboutusImagesLink.map((data, id) => {
                console.log(data);
                let isYoutubeUrl = data ? YOUTUBE_URL_REGEX.test(data) : false;
                const videoId = isYoutubeUrl ? getId(data) : false;
                if (id % 2 === 0) {
                  return (
                    <section
                      className='about-main-section seocnd-section'
                      key={`${data}-${id}-${index}`}
                    >
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-6 second-column'>
                            <div className='about-content'>
                              {/* <img src={AboutImg}></img> */}
                              {!isYoutubeUrl && !data && (
                                <iframe
                                  width='100%'
                                  height='400px'
                                  title={videoId}
                                  src={`https://www.youtube.com/embed/EEfqvomw0X0`}
                                  frameBorder='0'
                                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                  allowFullScreen
                                />
                              )}
                              {isYoutubeUrl && (
                                <iframe
                                  width='100%'
                                  height='400px'
                                  title={videoId}
                                  src={`https://www.youtube.com/embed/${videoId}`}
                                  frameBorder='0'
                                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                  allowFullScreen
                                />
                              )}
                              {!isYoutubeUrl && data && (
                                <div className='position-relative'>
                                  <video
                                    className='video_block'
                                    ref={videoRef}
                                    src={data}
                                    width='100%'
                                    id=''
                                  />
                                  <button
                                    className='play-btn'
                                    onClick={playVidfunc}
                                  >
                                    {playVid ? (
                                      <PauseCircleOutlined
                                        style={{ fontSize: '48px' }}
                                      />
                                    ) : (
                                      <PlayCircleOutlined
                                        style={{ fontSize: '48px' }}
                                      />
                                    )}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='about-content'>
                              <h2>our story/message from the founder</h2>
                              <p>
                                Equiliberty is a technology company designed to
                                help build diversity in property ownership,
                                support minority businesses and neighborhoods by
                                linking people to information, capital and
                                markets, with a mission to transition the wealth
                                of talent and genius in underserved communities
                                into a treasure of communal wealth, anchored by
                                communal ownership.{' '}
                              </p>
                              <p>
                                The Founders have a goal to expand financial
                                inclusion in the U.S. through access to credit
                                and capital markets. Many of us had to figure
                                out these things on our own, we did it the hard
                                way. We hope that by making business more
                                accessible, we are better able to build a future
                                for growth and economic prosperity for all.{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  );
                } else {
                  return (
                    <section
                      className='about-main-section'
                      key={`${data}-${id}-${index}`}
                    >
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='about-content'>
                              <h2>why we're different</h2>
                              <p>
                                Existing programs focus on venture capital as a
                                funding model, even as studies continue to show
                                Black Founders receive less than 3%, Latino
                                Founders less than 1%, and Women Founders less
                                than 1% of VC funding. The key to strong
                                business ownership, as well as raising
                                communities from poverty is, creating and
                                leveraging capital. Equiliberty does not focus
                                on extractive resources or unicorn deals. We
                                teach people how to leverage what they currently
                                have to grow more. We lower the barrier of entry
                                into business ownership by meeting people where
                                they're at, with the goal of creating local
                                economies that are vibrant, inclusive, and
                                representative of the country's diversity.
                                Equiliberty accomplishes this by serving as a
                                social network for the business owner, but with
                                tools.
                              </p>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='about-content'>
                              {/* <img src={AboutImg}></img> */}
                              <video
                                style={{ width: '100%' }}
                                src={data}
                                controls
                                key={`${data}-${id}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  );
                }
              })
            : '';
        })
      ) : (
        <StaticPage />
      )}
    </React.Fragment>
  );
}

function StaticPage() {
  return (
    <React.Fragment>
      <section className='about-main-section seocnd-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 second-column'>
              <div className='about-content'>
                {/* <img src={AboutImg}></img> */}
                <iframe
                  width='100%'
                  height='400px'
                  src={`https://www.youtube.com/embed/EEfqvomw0X0`}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-content'>
                <h2>our story/message from the founder</h2>
                <p>
                  Equiliberty is a technology company designed to help build
                  diversity in property ownership, support minority businesses
                  and neighborhoods by linking people to information, capital
                  and markets, with a mission to transition the wealth of talent
                  and genius in underserved communities into a treasure of
                  communal wealth, anchored by communal ownership. The Founders
                  have a goal to expand financial inclusion in the U.S. through
                  access to credit and capital markets. Many of us had to figure
                  out these things on our own, we did it the hard way. We hope
                  that by making business more accessible, we are better able to
                  build a future for growth and economic prosperity for all.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='about-main-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='about-content'>
                <h2>why we're different</h2>
                <p>
                  Existing programs focus on venture capital as a funding model,
                  even as studies continue to show Black Founders receive less
                  than 3%, Latino Founders less than 1%, and Women Founders less
                  than 1% of VC funding. The key to strong business ownership,
                  as well as raising communities from poverty is, creating and
                  leveraging capital. Equiliberty does not focus on extractive
                  resources or unicorn deals. We teach people how to leverage
                  what they currently have to grow more. We lower the barrier of
                  entry into business ownership by meeting people where they're
                  at, with the goal of creating local economies that are
                  vibrant, inclusive, and representative of the country's
                  diversity. Equiliberty accomplishes this by serving as a
                  social network for the business owner, but with tools.
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-content'>
                {/* <img src={AboutImg}></img> */}
                <video
                  style={{ width: '100%' }}
                  src='https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_1280_10MG.mp4'
                  controls
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default About;
