import React, { useEffect, useState } from 'react';
import { Table, Space, Button, message } from 'antd';
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
  REGEX_CONSTANTS,
} from '../../../shared/constants';
// API END POINTS
import {
  createCommunity,
  deleteCommunity,
  updateCommunity,
} from '../../../../redux/slices/adminCategorySlice';
import { createUserPost } from '../../../../redux/slices/dashboardSlice';
import { listCommunities } from '../../../../redux/slices/registerSlice';
import { userImageUpload } from '../../../../redux/slices/userProfileSlice';
import { header } from '../../../../api/rest';
import { history } from '../../../../redux/store';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
// CSS
import './adminCommunity.css';

var { ERROR_MESSAGE, IMAGE_EXTENSION_ERROR, VIDEO_EXTENSION_ERROR } = CONSTANTS;

var { CONFIRMATION_POPUP_MODAL, ADD_EDIT_COMMUNITY, ADD_EDIT_COMMUNITY_POST } =
  POP_UP_MODAL_CONSTANTS;
var { CONFIRMATION_HEADER, ADD_COMMUNITY, EDIT_COMMUNITY } =
  POP_UP_MODAL_HEADER_CONSTANTS;

var { ALL_VIDEO_FORMAT_REGEX, ALL_PHOTO_FORMAT_REGEX } = REGEX_CONSTANTS;

function AdminCommunity() {
  const storageData = localStorage.getItem('userData');
  const token = storageData ? JSON.parse(storageData).token : '';
  const [earnToken, setEarnToken] = useState([]);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [currentEntry, setCurrentEntry] = useState({
    id: '',
    name: '',
    description: '',
    city: '',
    zipCode: [],
  });
  const [description, setDescription] = useState('');
  const [activeCommunity, setActiveCommunity] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [updateProfileObj, setUpdateProfileObj] = useState({
    profileImage: '',
    profileVideo: '',
  });
  const [showLoader, setShowLoader] = useState({
    profileImage: false,
    profileVideo: false,
  });
  const [visionImageType, setVisionImageType] = useState('');
  const [fields, setFields] = useState([{ value: null }]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <p className='community-description'>
          {record.description ? record.description : ''}
        </p>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Zip Code',
      dataIndex: 'zipCode',
      key: 'zipCode',
      render: (text, record) => (
        <React.Fragment>
          {record.zipCode.length
            ? record.zipCode.map((value, index) => {
                if (index === record.zipCode.length - 1) {
                  return <span key={index}>{value}</span>;
                } else {
                  return <span key={index}>{value},</span>;
                }
              })
            : ''}
        </React.Fragment>
      ),
    },
    {
      title: 'Profile Photo',
      dataIndex: 'communityPhoto',
      key: 'communityPhoto',
      render: (text, record) => (
        <React.Fragment>
          {record.communityPhoto ? (
            <img
              className='image-td'
              src={record.communityPhoto}
              alt={record.communityPhoto}
            />
          ) : (
            ''
          )}
        </React.Fragment>
      ),
    },
    {
      title: 'Cover Photo',
      dataIndex: 'communityVideo',
      key: 'communityVideo',
      render: (text, record) => (
        <React.Fragment>
          {record.communityVideo ? (
            <img
              className='image-td'
              src={record.communityVideo}
              alt={record.communityPhoto}
            />
          ) : (
            ''
          )}
        </React.Fragment>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            className='update-community-btn'
            onClick={() => {
              setActiveCommunity(record._id)
              toggleModal(ADD_EDIT_COMMUNITY_POST, 'update')
            }}
          >
            Add Post
          </Button>
          <Button
            className='update-community-btn'
            onClick={() => fillUserInfoInModal(record, 'update')}
          >
            Update
          </Button>
          <Button
            className='del-community-btn'
            onClick={() => fillUserInfoInModal(record, 'delete')}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (token) {
      getCommunityList();
    } else {
      history.push('/');
      // message.error(TOKEN_EXPIRED_MESSAGE);
    }
  }, []);

  function fillUserInfoInModal(data, type) {
    if (type === 'update') {
      toggleModal(ADD_EDIT_COMMUNITY, EDIT_COMMUNITY);
    }
    if (type === 'delete') {
      toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
      setDescription(`Are you sure to remove ${data.name}`);
    }

    let values = saveZipcode(data.zipCode);

    setCurrentEntry((prevCurrentEntry) => {
      return {
        ...prevCurrentEntry,
        id: data.id,
        name: data.name,
        description: data.description,
        city: data.city,
        zipCode: values,
        partners: data?.partners,
        business: data?.business,
      };
    });

    setUpdateProfileObj((prevUpdateProfileObj) => {
      // Object.assign would also work
      return {
        ...prevUpdateProfileObj,
        profileImage: data.profilePhoto,
        profileVideo: data.communityVideo,
      };
    });
  }

  function saveZipcode(value) {
    let str = '';
    if (value.length) {
      for (let i = 0; i < value.length; i++) {
        if (i === value.length - 1) {
          str = str + value[i];
        } else {
          str = str + value[i] + ',';
        }
      }
    }
    return str;
  }

  // opening modal by modal type and modal header
  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  // close poup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
    setCurrentEntry((prevCurrentEntry) => {
      return {
        ...prevCurrentEntry,
        id: '',
        name: '',
        description: '',
        city: '',
        zipCode: '',
      };
    });
  }
  // handle file input
  function handleFileInput(event) {
    let { files, name } = event.target;

    if (files.length) {
      let regexType =
        name === 'profileVideo'
          ? ALL_PHOTO_FORMAT_REGEX
          : ALL_PHOTO_FORMAT_REGEX;
      let fileType = name === 'profileVideo' ? 'image' : 'image';
      // let extensionType = files[0].name.slice((Math.max(0, files[0].name.lastIndexOf(".")) || Infinity) + 1);
      let extensionType = files.length
        ? files[0].name.slice(
            (Math.max(0, files[0].name.lastIndexOf('.')) || Infinity) + 1
          )
        : false;
      let finalExtension = '.' + extensionType;
      let status = regexType.test(finalExtension);
      if (extensionType) {
        if (status) {
          setShowLoader((prevShowLoader) => {
            // Object.assign would also work
            return { ...prevShowLoader, [name]: true };
          });
          if (name === 'profileVideo') {
            setVisionImageType('object');
          } else {
            setVisionImageType('');
          }
          var formData = new FormData();
          for (const File of files) {
            formData.append('file', File);
          }
          header.Authorization = token;
          userImageUpload(formData, header)
            .then((res) => {
              setUpdateProfileObj((prevUpdateProfileObj) => {
                // Object.assign would also work
                return { ...prevUpdateProfileObj, [name]: res.Location };
              });
              setShowLoader((prevShowLoader) => {
                // Object.assign would also work
                return { ...prevShowLoader, [name]: false };
              });
            })
            .catch((err) => {
              message.error(err && err.message ? err.message : ERROR_MESSAGE);
              setShowLoader((prevShowLoader) => {
                // Object.assign would also work
                return { ...prevShowLoader, [name]: false };
              });
              setVisionImageType('object');
            });
        } else {
          message.error(
            fileType === 'image' ? VIDEO_EXTENSION_ERROR : IMAGE_EXTENSION_ERROR
          );
          event.target.value = '';
        }
      } else {
        setUpdateProfileObj((prevUpdateProfileObj) => {
          // Object.assign would also work
          return { ...prevUpdateProfileObj, [name]: '' };
        });
        setVisionImageType('object');
      }
    } else {
      event.target.value = '';
      // setVisionImageType('');
    }
  }

  // calling api of respective modal
  function onSubmitPopUpModal(event) {
    setShowPageLoader(true);
    if (popUpModal.modalHeader === ADD_COMMUNITY) {
      let zipCode = event.zipCode.split(','),
        zipCodeLengthStatus = zipCode.length > 10 ? false : true;
      event['communityPhoto'] = updateProfileObj.profileImage;
      event['communityVideo'] = updateProfileObj.profileVideo;
      event['city'] = event.city.toLowerCase();
      event['zipCode'] = zipCode;

      //    getZipcodeArray(event.zipCode);
      if (zipCodeLengthStatus) {
        addCategoryyApi(event);
      } else {
        message.error('Maximum 10 zipcode can be entered');
        setShowPageLoader(false);
      }
    }
    if (popUpModal.modalHeader === EDIT_COMMUNITY) {
      let zipCode = event.zipCode.split(','),
        zipCodeLengthStatus = zipCode.length > 10 ? false : true;
      event['communityPhoto'] = updateProfileObj.profileImage;
      event['communityVideo'] = updateProfileObj.profileVideo;
      event['city'] = event.city.toLowerCase();
      event['zipCode'] = zipCode;

      if (zipCodeLengthStatus) {
        updateCategoryApi(event);
      } else {
        message.error('Maximum 10 zipcode can be entered');
        setShowPageLoader(false);
      }
    }
    if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
      deleteCategoryApi();
    }

    if(popUpModal.type === ADD_EDIT_COMMUNITY_POST){
      event.communityId = activeCommunity;
      event.userId = JSON.parse(storageData)?.payload?.id;
      addCommunityPost(event)
    }
  }

  // fetch community list
  function getCommunityList() {
    header.Authorization = `Bearer ${token}`;
    listCommunities(header)
      .then((res) => {
        setEarnToken(res.data);
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }
  // calling add category api
  function addCategoryyApi(data) {
    header.Authorization = `Bearer ${token}`;
    createCommunity(data, header)
      .then((res) => {
        message.success(res.message);
        getCommunityList();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  // calling add category api
  function addCommunityPost(data) {
    header.Authorization = `${token}`;
    createUserPost(data, header)
      .then((res) => {
        message.success(res.message);
        closePopupModal();
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });

  }
  // calling update category api
  function updateCategoryApi(values) {
    header.Authorization = `Bearer ${token}`;
    let data = { id: currentEntry.id, ...values };
    updateCommunity(data, header)
      .then((res) => {
        message.success(res.message);
        getCommunityList();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  // calling delete category api
  function deleteCategoryApi() {
    header.Authorization = `Bearer ${token}`;
    let data = { id: currentEntry.id };
    deleteCommunity(data, header)
      .then((res) => {
        message.success(res.message);
        getCommunityList();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  // saving value in state on calling handle change api
  function handleChange(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  let addEditCommunityProps = {
      currentEntry,
      showLoader,
      updateProfileObj,
      visionImageType,
      fields,
    },
    addEditCommunityFunctionProps = {
      handleFileInput: handleFileInput,
      handleChange: handleChange,
      handleAdd: handleAdd,
      handleRemove: handleRemove,
    };

  return (
    <React.Fragment>
      {showPageLoader ? <Loader /> : ''}
      <div className='row mt-4 mb-2'>
        <div className='col-12 text-right'>
          <Button
            onClick={() => toggleModal(ADD_EDIT_COMMUNITY, ADD_COMMUNITY)}
          >
            Add Community
          </Button>
        </div>
      </div>
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          description={description}
          {...addEditCommunityProps}
          {...addEditCommunityFunctionProps}
        />
      ) : (
        ''
      )}
      <div className='community-table'>
        <Table dataSource={earnToken} columns={columns} />
      </div>
    </React.Fragment>
  );
}

export default AdminCommunity;
