import React from 'react';
import {
    Modal,
    Button,
    Form
} from 'antd';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { POP_UP_MODAL_HEADER_CONSTANTS } from '../constants';

var {
    SUCCESS_HEADER,
    THANK_FOR_INTREST
} = POP_UP_MODAL_HEADER_CONSTANTS;

export function MessagePopupModal(props) {
    let {
        title,
        description
    } = props;
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    return (
        <React.Fragment>
            <Modal
                title={title}
                centered
                visible={props.visible}
                onOk={(event) => props.onOk(event)}
                onCancel={() => props.onCancel(false)}
                okButtonProps={{ title: "Submit" }}
                footer={null}>
                <Form
                    {...layout}
                    onFinish={(event) => props.onOk(event)}>
                    <div className="row">
                        <div className="col-12 text-center">
                            {title === SUCCESS_HEADER || title === THANK_FOR_INTREST ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '4.5rem' }} /> : <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '4.5rem' }} />}
                        </div>
                    </div>
                    <div className="row mt-4"><div className="col-12 text-center"><p>{description}</p></div></div>
                    <div className="row mt-4"><div className="col-12 text-center"><Button type="primary" htmlType="submit">Ok</Button></div></div>
                </Form>
            </Modal>
        </React.Fragment>
    )
}
