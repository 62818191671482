import React, {
  useState,
  useEffect
} from "react";
import {
  Button,
  Row,
  Layout,
  Card,
  message
} from "antd";
import Container from "../../widgets/container";
import style from '../mentor/mentor.scss'
import {
  history
} from "../../../redux/store";
import {
  header
} from "../../../api/rest";
import { useSelector } from 'react-redux';
// LOADER
import {
  Loader
} from '../../shared/Loader';
// CSS
import '../register/register.sass';
// IMAGES
import DefaultPhoto from '../../../assets/images/userprofiledefault.png';
// API
import {
  userUpdateProfile
} from '../../../redux/slices/userProfileSlice';
import {
  getMentorByCategory,
  // getSelectedMentorCategory
} from '../../../redux/slices/registerSlice';
import {
  getMentorIdByUser
} from "../../../redux/slices/authSlice";
import {
  getAnyUserProfile
} from "../../../redux/slices/userProfileSlice";
import {
  CheckCircleTwoTone
} from '@ant-design/icons'

// POPUPMODAL
import PopUpModal from '../../shared/popupmodal';

// CONSTANT
import { CONSTANTS, POP_UP_MODAL_CONSTANTS } from '../../shared/constants';
// Sound play Component
import { TokenEarnSound } from '../../shared/soundeffect';

var {
  ERROR_MESSAGE,
  UPDATE_PROFILE_TEXT,
  CHOOSE_A_CATEGORY
} = CONSTANTS;
var { CHOOSE_MENTOR_POP_UP_MODAL } = POP_UP_MODAL_CONSTANTS;

const { Meta } = Card;

const Mentors = props => {

  React.useEffect(() => {
    document.title = "Mentors";
  })

  const loginUserData = useSelector(state => state.user);

  const communityId = loginUserData.data ? loginUserData.data.communityId : '';

  const userInfo = props.location ? props.location.state ? props.location.state.data : '' : '';
  const [mentors, setMentors] = useState([]);
  const type = props.type ? props.type : '';
  const storageInfo = localStorage.getItem("userData");
  const payload = storageInfo ? JSON.parse(storageInfo).payload : '';
  const token = storageInfo ? JSON.parse(storageInfo).token : '';
  const [mentorCategoryId, setMentorCategoryId] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: ''
  });
  const [mentorInfo, setMentorInfo] = useState('');
  const [selectedMentorId, setSelectedMentorId] = useState('');
  const [playSound, setPlaySound] = useState(false);


  useEffect(() => {
    (async function fetchData() {
      if (userInfo.mentorCatId) {
        // const result = await get(`/mentors/${userInfo.mentorCatId}`);
        // setMentors(result.data);
        setMentorCategoryId(userInfo.mentorCatId);
      } else {
        if (type) {

        } else {
          chooseCategory();
        }
      }
    })()
  }, [userInfo]);


  useEffect(() => {
    if (type) {
      if (payload) {
        // getSelectedMentorCategoryApi();
        getUserCategoryID();
      }
    }
  }, [])

  useEffect(() => {
    if (mentorCategoryId) {
      getMentorByCategoryApi();

    }
  }, [mentorCategoryId])

  const onClickMentor = (id) => {
    getUserProfileData(id);
    // if (type) {
    //   updateUserMentorId(id)
    // } else {
    //   history.push(`/mentor-details/${id}`);
    // }
  }
  const chooseCategory = () => {
    if (type) {
      props.loadNextTab(CHOOSE_A_CATEGORY);
    } else {
      history.push('/register/choose-category');
    }
  }

  function updateUserMentorId(id) {
    header.Authorization = token;
    const data = {
      id: payload.id,
      mentorId: id
    };
    userUpdateProfile(data, header).then(res => {
      setShowLoader(false);
      closePopupModal();
      message.success(res.message);
      if (type) {
        props.loadNextTab(UPDATE_PROFILE_TEXT)
      } else {
        // history.push('/user/profile/update', {
        //   data: {
        //     type: UPDATE_PROFILE_TEXT
        //   }
        // });
        setPlaySound(true);
        // history.push('/dashboard');
      }
      // props.loadNextTab(REGISTER_MENTOR);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }


  // function getSelectedMentorCategoryApi() {
  //   header.Authorization = token;
  //   getSelectedMentorCategory(payload.id, header).then(res => {
  //     if (res.getMentorData) {
  //       if (res.getMentorData.length) {
  //         setMentorCategoryId(res.getMentorData[0].categoryId)
  //       } else {
  //         setMentorCategoryId([])
  //       }
  //     }
  //     setShowLoader(false);
  //   }).catch(err => {
  //     message.error(err ? err.message : ERROR_MESSAGE);
  //     setShowLoader(false);
  //   })
  // }

  function getMentorByCategoryApi() {
    header.Authorization = token;
    let data = {
      categoryId: mentorCategoryId, communityId: communityId

    };
    getMentorByCategory(data, header).then(res => {
      if (res.data) {
        if (res.data.length) {
          setMentors(res.data);
        }
      }
      setShowLoader(false);
      getSelectedMentorId();
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }

  function getUserCategoryID() {
    header.Authorization = token;
    getMentorIdByUser(payload.id, header).then(res => {
      if (res.updatedUser) {
        setMentorCategoryId(res.updatedUser.categoryId);
      }
      setShowLoader(false);
    }).catch(err => {
      message.error(err ? err.data.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }

  function getUserProfileData(id) {
    header.Authorization = token;
    getAnyUserProfile(id, header).then(res => {
      //
      // setShowPageLoader(false);
      if (res.updatedUser) {
        let fullName = res.updatedUser.firstName + ' ' + res.updatedUser.lastName;
        setMentorInfo(res.updatedUser)
        showPopupModal(CHOOSE_MENTOR_POP_UP_MODAL, fullName);
      }
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      // setShowPageLoader(false);
    })
  }

  function getSelectedMentorId() {
    header.Authorization = token;
    getAnyUserProfile(payload.id, header).then(res => {
      //
      // setShowPageLoader(false);
      if (res.updatedUser) {
        setSelectedMentorId(res.updatedUser.mentorId);
      }
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      // setShowPageLoader(false);
    })
  }

  // show popup modal
  function showPopupModal(type, header) {
    setPopUpModal(prevPopUpModal => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header
      }
    })
  }
  // on submit popup modal call api
  function onSubmitPopUpModal(event) {
    if (popUpModal.type === CHOOSE_MENTOR_POP_UP_MODAL) {
      updateUserMentorId(event)
    }
  }
  // close popup modal
  function closePopupModal() {
    setPopUpModal(prevPopUpModal => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: ''
      }
    });
    // setUpdatedLocationBox(false);
    // setVisionImageType('');
  }


  useEffect(() => {
    if (playSound) {
      setTimeout(() => {
        history.push('/user/profile/update', {
          data: {
            type: UPDATE_PROFILE_TEXT
          }
        });
        setPlaySound(false);
      }, 1000);
    }
  }, [playSound]);

  let mentorInfoVariableProp = {
    mentorInfo
  };

  return (
    <Layout.Content className={`${type ? 'min-height-60vh' : 'min-height-60vh'} ${style.block}`}>
      {
        popUpModal.visible ?
          <PopUpModal
            title={popUpModal.modalHeader}
            visible={popUpModal.visible}
            onCancel={closePopupModal}
            onOk={onSubmitPopUpModal}
            type={popUpModal.type}
            {...mentorInfoVariableProp}
          // values={userNewVision}
          // onChange={handleVisionData}
          // description={currentPostDescription}
          // {...userProfileImageFunction}
          // {...userProfileImageModalVariable}
          // {...updateProfileCustomProps}
          // {...updatProfileCustomVariable}
          // {...followerFollowingList}
          // {...followerFollowListModalFuncProps}
          /> : ''
      }

      {playSound ? <TokenEarnSound /> : ''}
      {showLoader ? <Loader /> : ''}
      <Row className={`header-divider`} />
      {/* <Row className={style.mentor_banner}> */}
      {/* <Row justify={'center'} align={'middle'} className={style.mentor_b_overlay}>

        </Row> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className={style.mentor_b_text}>MENTORS</h2>
          </div>
        </div>
      </div>
      {/* </Row> */}
      <Row className={`header-divider`} />
      <Container style={{ maxWidth: 720, marginBottom: 40 }}>
        {
          mentors.length ?
            <div>
              <div className="row">
                {/* <Row gutter={24} justify={'center'}> */}
                {
                  mentors.map((mentor, index) => {
                    let { firstName, lastName } = mentor,
                      fullName = firstName + ' ' + lastName;
                    return (
                      // <Col key={mentor.id} xs={24} sm={12} md={8} style={{ paddingTop: 12, paddingBottom: 12 }}>
                      <div className="col-md-3" key={`${mentor.id}-${index}`}>
                        <div className="choose-mentor">
                          <Card
                            bordered={false}
                            onClick={() => onClickMentor(mentor.id)}
                            hoverable
                            cover={<img alt="example" src={mentor.userPhoto ? mentor.userPhoto : DefaultPhoto} />} className={`mentor-block`}
                            extra={
                              mentor.id === selectedMentorId ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "1.5rem" }} /> : ''
                            }
                          >
                            <Meta title={fullName} description={mentor.bio} className={`mentor-initials line_clamp`} />
                            <p>{mentor.email}</p>
                          </Card>
                        </div>
                      </div>
                      // </Col>
                      // <Col key={mentor.mentorInfoCode} xs={24} sm={12} md={8} style={{ paddingTop: 12, paddingBottom: 12 }}>
                      //   <Card
                      //     bordered={false}
                      //     onClick={() => onClickMentor(mentor.mentorInfoCode)}
                      //     hoverable
                      //     cover={<img alt="example" src={mentor.mentorPic} />} className={`mentor-block`}
                      //   >
                      //     <Meta title={mentor.mentorName} description={mentor.mentorBusinessName} className={`mentor-initials`} />
                      //     <p>{mentor.mentorBio}</p>
                      //   </Card>
                      // </Col>
                    )
                  }
                  )
                }
                {/* </Row> */}
                <p className="choose-category-btn"  style={{ marginTop: 20 }}>
              <a href="/dashboard">
                <Button type="primary" className="ant-btn ant-btn-primary">
                  Done
                </Button>
                </a>
              </p>
              </div>
            </div> :
            <Row gutter={24} justify={'center'} style={{ marginTop: 40 }}>
              <h4 className="nomentor-heading">No mentors found for this specific category.</h4>
              <div className="choose-category-btn">
                <Button onClick={chooseCategory}>Choose another category</Button>
              </div>

            </Row>
        }
      </Container>
    </Layout.Content>
  )
}

export default Mentors;
