import React,
{
  useEffect,
  useState
} from "react";
import {
  message,
  Divider,
  Avatar
} from 'antd';
import {
  SendOutlined
} from '@ant-design/icons'
// SUBHEADER
import SubHeader from '../../widgets/subheader';
import {
  // listPreviousUserChat,
  // startNewChat,
  // replyToChat,
  getTokBoxSession
} from '../../../redux/slices/messageSlice';
import {
  header
} from "../../../api/rest";
import {
  createRoomForMessage,
  messageOnTheBasisRoomId,
  getPreviousChat,
  getTokSession,
  createRoomForLiberal,
  messageOnTheBasisLiberalRoomId,
  getRecentMessageListOfLiberal

} from '../../../redux/slices/messageSlice';
// LOADERS
import { Loader } from '../../shared/Loader';
// CONSTANTS
import { CONSTANTS } from '../../shared/constants';
// SOCKET
import {
  socket,
  getChatMessage
} from '../../service/socket';
// MOMENT
import moment from 'moment';

var {
  ERROR_MESSAGE,
  ERROR_IMAGE_URL
} = CONSTANTS;

function LiberatorChat(props) {
  // const ENDPOINT = process.env.REACT_APP_BASE_URL;
  const storageData = localStorage.getItem("userData");
  const {
    id,
    firstName,
    lastName
  } = storageData ? JSON.parse(storageData).payload : '';
  const {
    token
  } = storageData ? JSON.parse(storageData) : '';
  const {
    role
  } = storageData ? JSON.parse(storageData).payload : '';
  const [previousChat, setPreviousChat] = useState([]);
  const [privateMessage, setPrivateMessage] = useState('');
  const [roomId, setRoomId] = useState('');
  const [paginationObj, setPaginationObj] = useState({
    page_size: 1, page_limit: 10
  });
  const [totalPage, setTotalPage] = useState('');
  // const [istyping, setIstyping] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const [previousUserChat, setPreviousUserChat] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [showUserChat, setShowUserChat] = useState(false);
  const [isMessageSubmit, setIsMessageSubmit] = useState(false);
  const [showVideoChat, setShowVideoChat] = useState(false);
  // const [sessionId, setSessionId] = useState('');
  const [sessionToken, setSessionToken] = useState('');
  const [userData, setUserData] = useState({
    senderId: '',
    recieverId: ''
  });
  const [userChatHeaderInfo, setUserChatHeaderInfo] = useState({
    userPhoto: '',
    fullName: ''
  });
  // const [mentorId, setMentorId] = useState('');
  const [joinVideo, setJoinVideo] = useState(false)
  const messagingSectionRef = React.useRef('');
  const messagingBoxRef = React.useRef('');
  const messagesRef = React.createRef('');
  const [showPageLoader, setShowPageLoader] = useState(true);
  // const [showSenderDetail, setShowSenderDetail] = useState(false)
  //
  // const senderId = props.location.state ? props.location.state.data.senderId : '';
  // const recieverId = props.location.state ? props.location.state.data.recieverId : '';
  // const recipientName = props.location.state ? props.location.state.data.senderName : '';
  // const room = "asedews"

  useEffect(() => {
    if (token) {

      if (props.location.state) {
        if (props.location.state.data.senderId) {
          // newChatApi();
          setUserData(prevUserData => {
            return {
              ...prevUserData,
              senderId: props.location.state.data.senderId,
              recieverId: props.location.state.data.recieverId
            }
          })
          // if(props.location.state.data.senderId === id){
          //   setShowSenderDetail(true);
          // }else{
          //   setShowSenderDetail(false);
          // }

          if (props.location.state.data.type === 'joinVideoCall') {
            setUserData(prevUserData => {
              return {
                ...prevUserData,
                senderId: props.location.state.data.senderId,
                recieverId: props.location.state.data.recieverId
              }
            });
            // setSessionId(props.location.state.data.session);
            setSessionToken(props.location.state.data.token);
            setJoinVideo(true);
            setShowVideoChat(false);
            setShowChat(false);
          } else {
            if (props.location.state.data.type === 'video') {
              if (role === 'mentor') {
                // getMentorIdByEmailApi();
                // setMentorId(props.location.state.data.senderId)
                // setUserData(prevUserData => {
                //   return {
                //     ...prevUserData,
                //     senderId: props.location.state.data.senderId
                //   }
                // })
                setShowVideoChat(true);
                setJoinVideo(true);
                socket.emit('call-join-room', props.location.state.data.senderId);
                setShowPageLoader(false);
                // setShowVideoChat(false);
              } else {
                // socket.emit('call-join-room', id);
                setShowVideoChat(true);
                setJoinVideo(true);
                setShowPageLoader(false);
              }
              // socket.on('callreceiver', (data) => {
              //
              // });
            } else {
              setShowVideoChat(false);
              setShowChat(true);
              setShowUserChat(false);
              setUserChatHeaderInfo(prevUserChatHeaderInfo => {
                return {
                  ...prevUserChatHeaderInfo,
                  userPhoto: props.location.state.data.userPhoto,
                  fullName: props.location.state.data.fullName
                }
              })
            }
          }
          // getPreviousChatApi();
        } else {
          setShowChat(false);
          setShowUserChat(true);
          setShowVideoChat(false);
        }
      } else {
        // props.history.push('/');
      }
    } else {

    }
    // socket.emit('send-message');
  }, []);


  useEffect(() => {
    if (showVideoChat) {
      // getSessionId();
      getSessionOfUser();
    }
  }, [showVideoChat])


  useEffect(() => {
    if (showChat) {
      fetchRoomId();
    } else {
      if (showVideoChat) {
      } else {
        getPreviousUserChats();
      }
    }
  }, [showChat]);

  useEffect(() => {
    if (joinVideo) {
      fetchRoomId();
    }
  }, [joinVideo])

  function fetchRoomId() {
    header.Authorization = token;
    let data = {
      senderId: userData.senderId,
      recipientId: userData.recieverId
    };
    createRoomForLiberal(data, header).then(res => {
      if (res.data) {

        socket.emit('join-room-liberal', { roomId: res.data.id });
        setRoomId(res.data.id);
      }
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
    })
  }

  useEffect(() => {
    if (isMessageSubmit) {
    } else {
      if (roomId) {
        getMessageList();
      }
    }
  }, [roomId]);


  function getMessageList() {
    let data = {
      roomId: roomId,
      pageNumber: paginationObj.page_size,
      limit: paginationObj.page_limit
    };
    header.Authorization = token;
    messageOnTheBasisLiberalRoomId(data, header).then(res => {
      setShowPageLoader(false);
      if (res.data) {
        // let list = addDateKey(res.data.messages);
        if (isTop) {
          let array = addHorizontalDateBarInMessageList(res.data.messages);

          let data = previousChat.reverse().concat(array).reverse();
          // let data = previousChat.unshift(res.data.messages);
          setPreviousChat(data);
        } else {
          let array = addHorizontalDateBarInMessageList(res.data.messages);
          setPreviousChat(array);
          messagingBoxRef.current && messagingBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }
        let page = Math.ceil(res.data.totalCount / paginationObj.page_limit);
        setTotalPage(page);
        setIsTop(false);
      } else {
        setPreviousChat([]);
        setIsTop(false);
      }

    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);
    })
  }


  // function addDateKey(messageList) {
  //   for (let i = 0; i < messageList.length; i++) {
  //     // let currentDayStatus=moment().isSame(messageList[i].createdAt, 'day'),
  //     // nextDayStatus= i===messageList.length-1?moment().isSame(messageList[i].createdAt, 'day'):moment().isSame(messageList[i+1].createdAt, 'day');
  //     //

  //     // if(currentDayStatus){
  //     //   messageList[i].status =true;

  //     // }else{
  //     //   if(nextDayStatus){
  //     //     messageList[i].status =true;
  //     //   }else{
  //     //     messageList[i].status =false;
  //     //   }
  //     // }
  //   }

  //   return messageList

  // }

  // socket.on('new-message', (data) => {
  //
  // let fullArr = previousChat.concat(data);
  // setPreviousChat(fullArr);
  // });

  function submitMessage(event) {
    event.preventDefault();
    if (privateMessage) {
      socket.emit('send-message-liberal', {
        // senderId: joinVideo ? userData.recieverId : userData.senderId,
        // recipientId: joinVideo ? userData.senderId : userData.recieverId,
        senderId: userData.senderId,
        recipientId: userData.recieverId,
        message: privateMessage,
        roomId: roomId
      });
      setPrivateMessage('');
      // setIstyping(false);
      setIsMessageSubmit(true);
    }
  }

  function handleInput(event) {
    let { value } = event.target;
    if (value) {
      // setIstyping(true);
    } else {
      // setIstyping(false);
    }
    setPrivateMessage(value);
  }

  function getPreviousUserChats() {
    let data = {
      id: id,
      pageNumber: paginationObj.page_size,
      limit: paginationObj.page_limit
    };
    header.Authorization = token;
    getRecentMessageListOfLiberal(data, header).then(res => {
      if (res.data) {
        setPreviousUserChat(res.data.messages)
      }
      setShowPageLoader(false);

    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);

    })
  }

  function showChatModule(senderId, recieverId, fullName, photo) {
    setUserData(prevUserData => {
      return {
        ...prevUserData,
        senderId: senderId,
        recieverId: recieverId
      }
    });
    setUserChatHeaderInfo(prevUserChatHeaderInfo => {
      return {
        ...prevUserChatHeaderInfo,
        userPhoto: photo,
        fullName: fullName
      }
    })
    if (showChat) {
      fetchRoomID(senderId, recieverId);
    } else {
      setShowChat(true);
    }
  }


  function fetchRoomID(senderID, recieverID) {
    header.Authorization = token;
    let data = {
      senderId: senderID,
      recipientId: recieverID
    };
    createRoomForLiberal(data, header).then(res => {
      if (res.data) {
        socket.emit('join-room-liberal', { roomId: res.data.id });
        getMessageLisT(res.data.id);
        // setRoomId(res.data.id);
      }
      setShowPageLoader(false);
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);
    })
  }


  function getMessageLisT(roomID) {
    let data = {
      roomId: roomID,
      pageNumber: paginationObj.page_size,
      limit: paginationObj.page_limit
    };
    header.Authorization = token;
    messageOnTheBasisLiberalRoomId(data, header).then(res => {
      if (res.data) {
        //
        if (isTop) {
          let array = addHorizontalDateBarInMessageList(res.data.messages);
          let data = previousChat.reverse().concat(array).reverse();
          // let data = previousChat.unshift(res.data.messages);
          setPreviousChat(data);
        } else {
          let arr = addHorizontalDateBarInMessageList(res.data.messages);
          setPreviousChat(arr);
          messagingBoxRef.current && messagingBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }
        let page = Math.ceil(res.data.totalCount / paginationObj.page_limit);
        setTotalPage(page);
        setIsTop(false);
      } else {
        setPreviousChat([]);
        setIsTop(false);
      }
      setShowPageLoader(false);

    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);

    })
  }

  function addHorizontalDateBarInMessageList(data) {
    let value = data;
    for (let i = 0; i < value.length; i++) {
      let nextDaydate = i === value.length - 1 ? moment(value[i].createdAt).format('MM/DD/YYYY') : moment(value[i + 1].createdAt).format('MM/DD/YYYY');
      let currentDayDate = moment(value[i].createdAt).format('MM/DD/YYYY');
      let status = moment(currentDayDate).isSame(nextDaydate);
      if (status) {
        value[i]["status"] = false;
      } else {
        value[i]["status"] = true;
      }
    }
    return value;
  }

  useEffect(() => {
    // getChatMessage((err, data) => {
    //   //
    //   if (err) return;
    //   setPreviousChat(oldPreviousChat => [...oldPreviousChat, data]);
    //   messagingBoxRef.current && messagingBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    //   setIsTop(false);
    // });

  socket.on('new-message-liberal', (data) => {
    setPreviousChat(oldPreviousChat => [...oldPreviousChat, data]);
    messagingBoxRef.current && messagingBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    setIsTop(false);
  });
  }, [])


  React.useEffect(() => {
    document.title = "Messaging";
  })

  function handleOnScroll() {
    if (messagingSectionRef.current) {
      let { scrollTop } = messagingSectionRef.current;
      if (scrollTop === 0) {
        let pageSize = paginationObj.page_size, data = pageSize + 1;
        if (data <= totalPage) {
          setPaginationObj(prevPaginationObj => {
            return { ...prevPaginationObj, page_size: data }
          })
          setIsTop(true);
        } else {
          setIsTop(false);
        }
      } else {
      }
    }
  }

  useEffect(() => {
    if (isTop) {
      getMessageList();
    }
  }, [isTop]);

  // TOKBOX VIDEO CHAT
  function getSessionId() {
    let data = {
      userId: userData.senderId,
      mentorId: userData.recieverId,
    };
    header.Authorization = token;
    getTokBoxSession(data, header).then(res => {
      if (res.session) {
        let value = getSessionDetail(res.session);
        if (value) {
          // setSessionId(value.session);
          setSessionToken(value.token);
          let data = {
            callerName: firstName + ' ' + lastName,
            // senderId: role === 'mentor' ? mentorId : userData.senderId,
            // receipentId: role === 'mentor' ? userData.recieverId : userData.senderId,
            senderId: userData.senderId,
            receipentId: userData.recieverId,
            session: value.session,
            token: value.token
          };

          socket.emit("make-call", data);
        }
        setShowPageLoader(false);

      } else {
        // fetch new session id if no session availabale
        getSessionOfUser();
      }
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);

    })
  }

  function getSessionDetail(array) {
    var value = {};
    for (let i = 0; i < array.length; i++) {
      value.session = array[i].session;
      value.token = array[i].token;
    }
    return value;
  }

  function getSessionOfUser() {
    let data = {
      userId: userData.senderId,
      mentorId: userData.recieverId,
    };
    header.Authorization = token;
    getTokSession(data, header).then(res => {
      if (res.session) {
        // setSessionId(res.session.session);
        setSessionToken(res.session.token);
        let data = {
          callerName: firstName + ' ' + lastName,
          senderId: userData.senderId,
          receipentId: userData.recieverId,
          session: res.session.session,
          token: res.session.token
        };
        socket.emit("make-call", data);
        setShowPageLoader(false);

      } else {
        getSessionId();
      }
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);

    })
  }


  return (
    <React.Fragment>
      {showPageLoader ? <Loader /> : ''}
      <SubHeader pathName="Messaging" />
      <div className="chat-section row justify-content-center min-height-60vh">
        {
          showUserChat ? <div className={`col-md-2 col-10 chat-user-detail`} >
            {
              previousUserChat.length ? previousUserChat.map((value, index) => {
                if (value.senderId) {
                  let { recipientId, senderId } = value,
                    fullName = senderId.id === id ? recipientId.firstName + ' ' + recipientId.lastName : senderId.firstName + ' ' + senderId.lastName,
                    userPhoto = senderId.id === id ? recipientId.userPhoto : senderId.userPhoto,
                    senderID = senderId.id === id ? senderId.id : recipientId.id,
                    recieverID = recipientId.id === id ? senderId.id : recipientId.id;
                  return (
                    <a key={`${value.id}-${index}`} onClick={() => showChatModule(senderID, recieverID, fullName, userPhoto)}>
                      <div className="row mt-1 mb-1">
                        <div className="col-md-3 col-2 text-center">
                          <img src={userPhoto ? userPhoto : ERROR_IMAGE_URL} alt={userPhoto} className="chat-user-photo" />
                        </div>
                        <div className="col-md-9 col-8">
                          <div className="mb-1 col-md-12 col-12 text-truncate"><span className="font-weight-bold">{fullName}</span></div>
                          <div className="mb-1 col-12 text-truncate">{value.message}</div>
                        </div>
                      </div>
                    </a>
                  )
                } else {
                  return false
                }
              }) :
                <div classsessionTokenName="row justify">No chat avaialable</div>
            }
          </div> : ''
        }
        {
          showChat ? ''
            : sessionToken ? '' : <div className="d-block col-md-7 col-8 chat_no_found mx-auto">
              <p>No Chat Found</p>
            </div>
        }
        <div className={`${showChat ? 'd-block' : 'd-none chat_not_found'} col-md-7 col-8 chat-box-section-layout`}>
          <div className="top-row">
            <div className="row">
              <div className="col-md-1">
                {userChatHeaderInfo.userPhoto ?
                  <img src={userChatHeaderInfo.userPhoto} alt={userChatHeaderInfo.fullName} />
                  // <Avatar size={40} src={userChatHeaderInfo.userPhoto} />
                  // <Avatar src={<img src={userChatHeaderInfo.userPhoto} alt={userChatHeaderInfo.fullName} />} />
                  :
                  <Avatar style={{ color: 'white', backgroundColor: '#18a4ad' }}>{userChatHeaderInfo.fullName ? userChatHeaderInfo.fullName.split('')[0] : ''}</Avatar>
                }
              </div>
              <div className="col-md-11">
                <p>{userChatHeaderInfo.fullName}</p>
              </div>
            </div>
          </div>
          <div className="chat-panel ">
            <div className="chat-history" onScroll={handleOnScroll} ref={messagingSectionRef}>
              <div className="chat-messages mr-3" ref={messagingBoxRef}>
                <div className="chat-message-box-section" ref={messagesRef}>
                  {
                    previousChat.length ? previousChat.map((value, index) => {
                      let status = value.senderId ? true : false;
                      let currentTime = moment().isSame(value.createdAt, 'day') ? moment(value.createdAt).format("hh:mm a").toString() : moment(value.createdAt).format('dddd MMMM Do YYYY, h:mm a');
                      let hrBarText = moment(value.createdAt).format('MMMM DD YYYY');
                      // let hrBarStatus = moment().isSame(value.createdAt, 'day') ? false : true;
                      if (status) {
                        return (
                          <React.Fragment key={`${value.id}-${index}`}>
                            {value.status ? <Divider>{hrBarText}</Divider> : ''}
                            <div className="chat-box-section" >
                              <div className={`${value.senderId.id === id ? 'text-right' : 'text-left'} mt-1`}>
                                <span>{`${value.senderId.firstName} ${currentTime}`}</span>
                              </div>
                              <div className={`${value.senderId.id === id ? 'right-bubble' : 'left-bubble'} chat-box-bubble`} >
                                <p className="message-text">
                                  {value.message}
                                </p>
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      } else {
                        return false
                      }
                    }) : ''
                  }
                </div>
              </div>
            </div>
          </div>
          <hr />
          <form className="align-items-center pt-1 pb-4 write-chat-section" onSubmit={submitMessage}>
            <div className="row">
              <div className="col-md-11 col-8">
                <textarea value={privateMessage} className="form-control message-textarea chat-module" name="privateMessage" onChange={handleInput} required />
              </div>
              <div className="col-md-1 col-2">
                <button type="submit" className="btn btn-primary btn-submit-message"><SendOutlined /></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}
export default LiberatorChat;
