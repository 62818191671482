import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Space,
  Badge,
  Button,
  message,
  Switch,
  Descriptions,
} from "antd";
import { header } from "../../../../api/rest";
import { history } from "../../../../redux/store";
import {
  getMentorRequests,
  deleteMentorRequests,
  approveMentorRequests,
  toggleFeaturedMentor,
} from "../../../../redux/slices/adminSlice";
import PopUpModal from "../../../shared/popupmodal";
import { Loader } from "../../../shared/Loader";
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from "../../../shared/constants";

var { CONFIRMATION_POPUP_MODAL, ADMIN_APPROVE_MENTOR_POP_UP_MODAL } =
  POP_UP_MODAL_CONSTANTS;
var { CONFIRMATION_HEADER } = POP_UP_MODAL_HEADER_CONSTANTS;

const ExpandedRow = ({ record }) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    gender,
    zipcode,
    race,
    workingStatus,
    language,
    convictedOfFelony,
    likeToBeFeatured,
    isApproved,
    isFeatured,
    industry,
    areaOfInterest,
    businessExpertise,
  } = record;
  return (
    <Descriptions
      title='More Info'
      bordered
      column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
    >
      <Descriptions.Item label='Name'>
        {firstName} {lastName}
      </Descriptions.Item>
      <Descriptions.Item label='Email'>{email}</Descriptions.Item>
      <Descriptions.Item label='Business Expertise'>
        {businessExpertise.name}
      </Descriptions.Item>
      <Descriptions.Item label='Area of Interest'>
        {areaOfInterest}
      </Descriptions.Item>
      <Descriptions.Item label='Industry'>{industry.name}</Descriptions.Item>
      <Descriptions.Item label='Phone'>{phone}</Descriptions.Item>
      <Descriptions.Item label='Gender'>{gender}</Descriptions.Item>
      <Descriptions.Item label='Zip Code'>{zipcode}</Descriptions.Item>
      <Descriptions.Item label='Race'>{race}</Descriptions.Item>
      <Descriptions.Item label='Working Status'>
        {workingStatus}
      </Descriptions.Item>
      <Descriptions.Item label='Language'>{language}</Descriptions.Item>
      <Descriptions.Item label='Convicted Of Felony'>
        {convictedOfFelony ? 'Yes' : 'No'}
      </Descriptions.Item>
      <Descriptions.Item label='Approved'>
        {' '}
        {isApproved ? 'Yes' : 'No'}
      </Descriptions.Item>
      <Descriptions.Item label='Like To Be Featured'>
        {' '}
        {likeToBeFeatured ? 'Yes' : 'No'}
      </Descriptions.Item>
      <Descriptions.Item label='Featured'>
        {' '}
        {isFeatured ? 'Yes' : 'No'}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default function MentorRequests() {
  const storageData = localStorage.getItem("userData");
  const token = storageData ? JSON.parse(storageData).token : "";
  const [requests, setRequests] = useState([]);
  const [description, setDescription] = useState("");
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: "",
    modalHeader: "",
  });
  const [currentEntry, setCurrentEntry] = useState({ id: "" });
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, row) => `${row.firstName} ${row.lastName}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Phone",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    {
      title: "Zip",
      dataIndex: "zipcode",
      key: "zipcode",
    },
    // {
    //   title: "Gender",
    //   dataIndex: "gender",
    //   key: "gender",
    // },
    {
      title: "Approved",
      dataIndex: "isApproved",
      key: "isApproved",
      render: (text, row) =>
        text ? (
          <Badge color="green" text="Yes" />
        ) : (
          <Badge color="orange" text="Pending" />
        ),
    },
    {
      title: "Featured",
      dataIndex: "isFeatured",
      key: "isFeatured",
      render: (text, row) => (
        <Switch
          disabled={!row.isApproved || !row.likeToBeFeatured}
          checked={text}
          checkedChildren="Yes"
          unCheckedChildren="No"
          loading={toggleLoading}
          onChange={() => toggleMentor(row._id)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => setExpandedKeys([record._id])}>
            View
          </Button>
          <Button
            type="primary"
            disabled={record.isApproved}
            onClick={() => {
              setCurrentEntry(record);
              toggleModal(
                ADMIN_APPROVE_MENTOR_POP_UP_MODAL,
                "Approve Mentor Request"
              );
            }}
          >
            Approve
          </Button>
          <Button
            type="primary"
            danger
            disabled={record.isApproved}
            onClick={() => {
              setCurrentEntry(record);
              toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
              setDescription(
                `Are you sure to remove ${record.firstName} ${record.lastName}`
              );
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getRequests();
  }, []);

  // calling toggle modal api
  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  // calling close popup modal function which close popup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: "",
        modalHeader: "",
      };
    });
    setCurrentEntry((prevCurrentEntry) => {
      return {
        ...prevCurrentEntry,
        id: "",
        name: "",
      };
    });
  }
  const getRequests = useCallback(async () => {
    if (token) {
      header.Authorization = `Bearer ${token}`;
      getMentorRequests(header)
        .then((res) => {
          setRequests(res.data);
          setShowPageLoader(false);
        })
        .catch((err) => {
          message.error(
            err && err.message ? err.message : "something wents worng"
          );
        });
    } else {
      history.push("/");
    }
  }, []);

  const toggleMentor = async (email) => {
    setToggleLoading(true);
    header.Authorization = `Bearer ${token}`;
    await toggleFeaturedMentor(email, header);
    await getRequests();
    setToggleLoading(false);
  };
  const handleModalSubmit = useCallback(
    async (data) => {
      header.Authorization = `Bearer ${token}`;
      if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
        await deleteMentorRequests(currentEntry.id, header);
      }
      if (popUpModal.type === ADMIN_APPROVE_MENTOR_POP_UP_MODAL) {
        await approveMentorRequests(currentEntry.id, data, header);

      }
      await getRequests();
      closePopupModal();
    },
    [currentEntry.id]
  );
  return (
    <>
      {showPageLoader ? <Loader /> : ""}
      <div className="row mt-4 mb-2">
        <div className="col-12 text-right">
          {/* <Button
            onClick={() => toggleModal(ADD_EDIT_ADMIN_CATEGORY, ADD_CATEGORY)}
          >
            Add Category
          </Button> */}
        </div>
      </div>
      {popUpModal.visible && (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={handleModalSubmit}
          type={popUpModal.type}
          description={description}
        />
      )}
      <Table
        dataSource={requests}
        columns={columns}
        rowKey={(record) => record.id}
        expandRowByClick
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
          expandedRowKeys: expandedKeys,
          expandIconColumnIndex: -1,
        }}
      />
    </>
  );
}
