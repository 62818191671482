import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Row, Layout, Select, message } from "antd";
import Container from "../../widgets/container";
import { history } from "../../../redux/store";
import {
  //  apiDeleteMethod,
  get,
  header
} from "../../../api/rest";
import MyAncor from "../../widgets/global/ancor";
import { updateUserData, setLoading as running } from "../../../redux/slices/authSlice";
import { getSelectedMentorCategory, updateMentorCategory } from '../../../redux/slices/registerSlice';
import { CONSTANTS, API_END_POINTS_CONSTANT } from '../../shared/constants';
// LOADER
import {
  // SkeletonDashBoardPostLoader,
  Loader
} from '../../shared/Loader';
// CSS
import style from './register.module.sass';
import './register.sass';

var {
  CHOOSE_MENTOR,
  ERROR_MESSAGE
} = CONSTANTS;

var { LIST_MENTOR_CATEGORY } = API_END_POINTS_CONSTANT;

const RegCategory = props => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.user);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(session.loading);
  const [form] = Form.useForm();
  const userInfo = props.location ? props.location.state ? session.data : '' : '';
  const type = props.type ? props.type : '';
  const storageInfo = localStorage.getItem("userData");
  const payload = storageInfo ? JSON.parse(storageInfo).payload : '';
  const token = storageInfo ? JSON.parse(storageInfo).token : '';
  const [showLoader, setShowLoader] = useState(true);
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };

  useEffect(() => {
    (async function fetchData() {
      const result = await get(LIST_MENTOR_CATEGORY);
      if (result) {
        setCategories(result.data);
        setShowLoader(false);
      }
    })()
  }, []);

  useEffect(() => {
    if (loading && !session.loading && session.data) {
      localStorage.setItem("userData", JSON.stringify(session.data));
      // history.push('/dashboard');
      history.push('/profile/update');
    }
    setLoading(session.loading);
  }, [loading, session]);

  const onFinish = values => {
    if (type) {
      setShowLoader(true);
      updateMentorCategoryApi(values.category);
    } else {
      history.push('/register/choose-mentor',
        {
          data: {
            ...userInfo,
            mentorCatId: values.category
          }
        }
      )
    }
  };

  const onClickSkip = async () => {
    if (type) {
      history.push('/dashboard');
    } else {
      if (userInfo) {
        dispatch(running(true));
        const body = {
          "businessId": userInfo.busInfoCode ?? 0,
          "mentorId": userInfo.mentorInfoCode ?? 0,
          "userPhoto": session.data.userPhoto ?? ''
        }
        dispatch(updateUserData(session.data, body));
      } else {
        dispatch(running(false));
        history.push('/dashboard')
      }
    }
  }

  useEffect(() => {
    if (payload) {
      if (type) {
        fetchSelectedMentorCategory();
      }
    }
  }, [])


  function fetchSelectedMentorCategory() {
    header.Authorization = token;
    getSelectedMentorCategory(payload.id, header).then(res => {
      if (res.getMentorData) {
        if (res.getMentorData.length) {
          form.setFieldsValue({
            category: res.getMentorData[0].categoryId
          })
        }
      }
      setShowLoader(false);
    }).catch(err => {
      message.error(err ? err.data.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }


  function updateMentorCategoryApi(categoryId) {
    header.Authorization = token;
    const data = {
      categoryId: categoryId,
      userId: payload.id
    };
    updateMentorCategory(data, header).then(res => {
      message.success(res.message)
      props.loadNextTab(CHOOSE_MENTOR);
      dispatch(running(false));
      setShowLoader(false);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      dispatch(running(false));
      setShowLoader(false);
    })
  }

  return (
    <Layout.Content className={`min-height-60vh ${style.block}`}>
      {showLoader ? <Loader /> : ''}
      {type ? '' : <Row className={`header-divider`} />}
      {/* <Row className={style.title_block} justify={'center'}>
        <Container fluid={true} style={{textAlign: `center`}}>
          You will have access to a mentor with Equiliberty. Now just choose a Mentor.
        </Container>
      </Row> */}
      <Container className={style.reg_container}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} className={`register-form`}>
          <h2 className={style.heading}>You will have access to a mentor with Equiliberty. Now just choose a Mentor Category.</h2>
          <div className={style.form_group}>
            <Form.Item name="category" label={`Select a category for your mentor`} rules={[{ required: true, message: 'Please select mentor category' }]}>
              <Select placeholder="Choose category" allowClear>
                {categories.length ? categories.map(c => <Select.Option value={c._id} key={c._id}>{c.name}</Select.Option>) : ''}
              </Select>
            </Form.Item>
          </div>
          <Row justify={'space-between'} align='middle'>
            {
              !session.data.verify &&
              <MyAncor className="skip-form-btn" onClick={onClickSkip}>{type ? 'Cancel' : 'Skip'}</MyAncor>
            }
            <Button type="primary" htmlType="submit" className={style.submit_btn}>
              Submit
            </Button>
            {/* <div /> */}
          </Row>
        </Form>
      </Container>
    </Layout.Content>
  )
}

export default RegCategory