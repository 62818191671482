//import { createSlice } from '@reduxjs/toolkit';
// import { message } from "antd";
import {
    // post, get ,
    apiGetMethod,
    apiPostMethod,
    apiPutMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

let {
    LIST_BUSINESS_INDUSTRIES,
    ADD_BUSINESS_INFORMATION,
    LIST_COMMUNITIES,
    ASSIGN_COMMUNITY,
    GET_BUSINESS_INFORMATION,
    UPDATE_BUSINESS_INFORMATION,
    GET_USER_COMMUNITY,
    SELECTED_MENTOR_CATEGORY,
    UPDATE_MENTOR_CATEGORY,
    GET_MENTOR_BY_CATEGORY,
    GET_MENTOR_INFO_BY_ID,
    UPDATE_USER_MENTOR,
    LIST_MENTOR_CATEGORY,
    LIST_MENTOR_COMMUNITY
} = API_END_POINTS_CONSTANT;

export const getBusinessIndustries = (headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${LIST_BUSINESS_INDUSTRIES}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const addBusinessDetails = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ADD_BUSINESS_INFORMATION, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getBusinessDetails = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_BUSINESS_INFORMATION}/?userId=${id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
export const updateBusinessDetails = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(UPDATE_BUSINESS_INFORMATION, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const listCommunities = (headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${LIST_COMMUNITIES}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
export const listMentorCommunities = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${LIST_MENTOR_COMMUNITY}?zip=${data}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
export const listMentorCategory = (headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(LIST_MENTOR_CATEGORY, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const assignCommunity = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ASSIGN_COMMUNITY, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getAssignedCommunityOfUser = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_USER_COMMUNITY}?userId=${id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getSelectedMentorCategory = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${SELECTED_MENTOR_CATEGORY}?userId=${id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateMentorCategory = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPutMethod(UPDATE_MENTOR_CATEGORY, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getMentorByCategory = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_MENTOR_BY_CATEGORY}?categoryId=${data.categoryId}&communityId=${data.communityId}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
export const getMentorInfoById = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_MENTOR_INFO_BY_ID}/${id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const updateUserSelectedMentor = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPutMethod(UPDATE_USER_MENTOR, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const mentorRequest = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPostMethod('/mentorrequests', data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
