import React from 'react'
// ANT DESIGN
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
// CSS
import './Loader.css';


export function Loader() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
        <div className="loader">
            <React.Fragment>
                <Spin indicator={antIcon} />
            </React.Fragment>
        </div>
    )
};


export function SkeletonDashBoardPostLoader(props) {
    let { rows,width } = props;
    return (
        <React.Fragment>
            <Skeleton avatar paragraph={{ rows ,width}} active/>
        </React.Fragment>
    )
};

// export  function FullPageLoader(){
//     return(
//         <React.Fragment>
//             <LoadingOutlined />
//         </React.Fragment>
//     )

// }