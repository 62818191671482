import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { message, Table } from 'antd';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import TockenIcon from '../../../../assets/images/token-icon-big.png';
import { Link, useHistory } from 'react-router-dom';
// CONSTANTS
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  // POP_UP_MODAL_HEADER_CONSTANTS,
  REGEX_CONSTANTS,
} from '../../../shared/constants';
// CSS
import './users.scss';
// SUBHEADER
import SubHeader from '../../../widgets/subheader';
// API
import {
  getLedgerList,
  addTokenToWallet,
  getTokenListForUser,
} from '../../../../redux/slices/walletSlice';
import {
  getAnyUserProfile,
  getFollowerList,
  getFollowingList,
  getMenteesApi,
} from '../../../../redux/slices/userProfileSlice';
import { Loader } from '../../../shared/Loader';
import { header } from '../../../../api/rest';
import { formatDate } from '../../../../utils/helpers';
var { ACCEPT_NUMBER } = REGEX_CONSTANTS;
var { ADD_EDIT_TOKEN_TO_WALLET } = POP_UP_MODAL_CONSTANTS;

// var {
//   ADD_TOKEN_TO_WALLET
// } = POP_UP_MODAL_HEADER_CONSTANTS;

const MentorUsers = (props) => {
  // React.useEffect(() => {
  //   document.title = 'MentorUsers';
  // });
  const session = useSelector((state) => state.user);
  const storageData = localStorage.getItem('userData');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const data = localStorage.getItem('userData');
  const payload = data ? JSON.parse(data) : '';
  const listType = props.match.params.listType;
  const user = session?.user;
  let {
    role,
    // moodleToken,
  } = session.data;

  const { moodleToken, id } = storageData
    ? JSON.parse(storageData).payload
    : '';
  const { token } = storageData ? JSON.parse(storageData) : '';
  const [walletCount, setWalletCount] = useState(0);
  const [earnToken, setEarnToken] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(true);

  const [badges, setBadges] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let data = localStorage.getItem('userData');
    if (data) {
      setShowPageLoader(false);
    } else {
      props.history.push('/');
    }
    if (listType === 'following') {
      getFollowing();
    } else if (listType === 'followers') {
      getFollowers();
    } else {
      getUsers();
    }
  }, []);

  const getUsers = async () => {
    let res = await getMenteesApi(
      { Authorization: token },
      props.match.params.mentorId
    );

    setUsers(res);
    setFilteredUsers(res);
  };

  const getFollowers = async () => {
    console.log(user);
    const res = await getFollowerList(id);
    const followers = res.data.map((i) => ({
      ...i.followedById,
      mentorConnectionDate: i.createdAt,
    }));
    setUsers(followers);
    setFilteredUsers(followers);

    console.log('followes', res);
  };
  const getFollowing = async () => {
    const res = await getFollowingList(id);
    const following = res.data.map((i) => ({
      ...i.userId,
      mentorConnectionDate: i.createdAt,
    }));
    setUsers(following);
    setFilteredUsers(following);
  };

  function redirectToUserProfile(userId, userPhoto, userName) {
    const data = {
      senderId: payload.payload.id,
      recieverId: userId,
      senderName: userName,
      type: 'text',
      fullName: userName,
      userPhoto,
    };

    props.history.push('/messaging', {
      data,
    });
  }

  const searchUsers = (e) => {
    const input = e.target.value?.toLowerCase();
    if (!input) setFilteredUsers(users);
    const filtered = users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(input) ||
        user.lastName.toLowerCase().includes(input) ||
        user.email.toLowerCase().includes(input)
    );
    setFilteredUsers(filtered);
  };

  // TABLE

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'col_1',
      key: 'id',
      columnWidth: 120,
      width: 120,
      ellipsis: true,
      render: (text) => (
        <span>
          <span className='mentors-table-tab px-2 py-1 active'>{text}</span>
        </span>
      ),
    },
    {
      title: 'User',
      dataIndex: 'col_2',
      key: 'firstName',
      columnWidth: 400,
      width: 400,
      render: (text, data) => (
        <span class='w-100 d-flex align-items-center'>
          <Link
            to={{
              pathname: '/user-profile',
              state: {
                postId: data._id,
                userType: 'other',
              },
            }}
            // onClick={() => props.redirectToUserProfile(value.userId.id,'other')}
            className='w-100 d-flex align-items-center mr-3'
          >
            {/* AVATAR  */}
            <img className='mentors-table-image mr-2' src={data.userPhoto} />
            {/* NAME */}
            <span className='font-weight-bold text-black'>
              {data.firstName} {data.lastName}
            </span>
          </Link>
          <a
            href='#'
            className='w-100 d-flex align-items-center'
            onClick={() =>
              redirectToUserProfile(
                data._id,
                data.userPhoto,
                `${data.firstName} ${data.lastName}`
              )
            }
          >
            {/* MESSAGE ICON  */}
            <span className='d-flex'>
              <svg
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                width='32px'
                height='32px'
                viewBox='0 0 512 512'
              >
                <path
                  class='cls-1'
                  d='M448,76.56A38.44,38.44,0,0,1,486.4,115v230.4a38.44,38.44,0,0,1-38.4,38.4H66.2l-7.5,7.5-33.1,33.1V115A38.44,38.44,0,0,1,64,76.56H448M448,51H64A64,64,0,0,0,0,115V443.21A17.78,17.78,0,0,0,17.92,461a17.42,17.42,0,0,0,12.45-5.25L76.8,409.36H448a64,64,0,0,0,64-64V115a64,64,0,0,0-64-64Z'
                />
                <path
                  class='cls-1'
                  d='M166.4,217.36a12.8,12.8,0,1,1-12.8,12.8,12.81,12.81,0,0,1,12.8-12.8m0-25.6a38.4,38.4,0,1,0,38.4,38.4,38.4,38.4,0,0,0-38.4-38.4Z'
                />
                <path
                  class='cls-1'
                  d='M256,217.36a12.8,12.8,0,1,1-12.8,12.8,12.81,12.81,0,0,1,12.8-12.8m0-25.6a38.4,38.4,0,1,0,38.4,38.4,38.4,38.4,0,0,0-38.4-38.4Z'
                />
                <path
                  class='cls-1'
                  d='M345.6,217.36a12.8,12.8,0,1,1-12.8,12.8,12.81,12.81,0,0,1,12.8-12.8m0-25.6a38.4,38.4,0,1,0,38.4,38.4,38.4,38.4,0,0,0-38.4-38.4Z'
                />
              </svg>
            </span>
          </a>
        </span>
      ),
    },
    {
      title: listType ? 'Followed On' : 'Connection Date',
      dataIndex: 'mentorConnectionDate',
      key: 'mentorConnectionDate',
      align: 'right',
      render: (text) => (
        <span>
          <span className='font-weight-bold text-12'>
            {listType ? 'Followed' : 'Connected'} On {formatDate(text)}
          </span>
        </span>
      ),
    },
  ];

  return (
    <React.Fragment>
      {/* wallet starts */}
      {showPageLoader ? <Loader /> : ''}
      <SubHeader
        pathName={listType ? 'Your Followers' : 'Your Connections'}
        walletCount={walletCount}
      />

      <section className='mentor-users-section my-4'>
        <div className='container mb-4'>
          {/* BACK BTN ROW  */}
          <div className='row mb-4'>
            <div className='col-12'>
              <div className='w-100'>
                <button className='back-btn' onClick={history.goBack}>
                  Back
                </button>
              </div>
            </div>
          </div>
          {/* BACK BTN ROW END */}
          {/* SEARCH ROW  */}
          <div className='row mb-4'>
            {/* SEARCH INPUT  */}
            <div className='col-12 col-md-8 mb-3 mb-md-0'>
              <div className='w-100'>
                <p className='font-weight-bold'>
                  Search {listType ? 'Followers' : 'Connections'}
                </p>
                <div className='input-container position-relative d-flex align-items-center'>
                  {/* SEARCH ICON  */}
                  <span className='position-absolute px-4'>
                    <svg
                      fill='#000000'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24px'
                      height='24px'
                    >
                      <path d='M 9 2 C 5.1458514 2 2 5.1458514 2 9 C 2 12.854149 5.1458514 16 9 16 C 10.747998 16 12.345009 15.348024 13.574219 14.28125 L 14 14.707031 L 14 16 L 20 22 L 22 20 L 16 14 L 14.707031 14 L 14.28125 13.574219 C 15.348024 12.345009 16 10.747998 16 9 C 16 5.1458514 12.854149 2 9 2 z M 9 4 C 11.773268 4 14 6.2267316 14 9 C 14 11.773268 11.773268 14 9 14 C 6.2267316 14 4 11.773268 4 9 C 4 6.2267316 6.2267316 4 9 4 z' />
                    </svg>
                  </span>
                  {/* INPUT  */}
                  <input
                    className='w-100 py-3 px-4 input-field pl-5 search-input-field'
                    type='text'
                    placeholder='Search Connections'
                    name='search'
                    onKeyUp={searchUsers}
                  />
                </div>
              </div>
            </div>
            {/* CATEGORIES  */}
            <div className='col-12 col-md-4 mb-3 mb-md-0'>
              <div className='w-100'>
                <p className='font-weight-bold'>Categories</p>
                <div className='w-100 d-flex flex-wrap flex-grow-1 align-items-center mt-4'>
                  <span className='filter-categories px-3 py-2 mr-2 mb-2 active'>
                    Members
                  </span>
                  {/* <span className='filter-categories px-3 py-2 mr-2 mb-2'>
                    Archived
                  </span> */}
                </div>
              </div>
            </div>
          </div>
          {/* SEARCH ROW END */}
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 overflow-hidden'>
              <div className='w-100 overflow-x-auto'>
                <Table
                  dataSource={filteredUsers}
                  columns={columns}
                  pagination={{ pageSize: 50 }}
                  scroll={{ x: 1000 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* wallet ends */}
    </React.Fragment>
  );
};

export default MentorUsers;
