//import { createSlice } from '@reduxjs/toolkit';
// import { message } from "antd";
import {
    // post, get ,
    apiGetMethod,
    apiPostMethod,
    apiPutMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

let {
    LIST_USER_JOURNEY,
    CREATE_USER_JOURNEY,
    UPDATE_USER_JOURNEY,
    USERS_GOALS,
    DELETE_USER_JOURNEY
} = API_END_POINTS_CONSTANT;

// export const getBusinessIndustries = (headers) => {
//     return new Promise((resolve, reject) => {
//         apiGetMethod(`${LIST_BUSINESS_INDUSTRIES}`, headers).then(res => {
//             resolve(res)
//         }).catch(err => {
//             reject(err)
//         })
//     })
// };

// export const addBusinessDetails = (data, headers) => {
//     return new Promise((resolve, reject) => {
//     apiPostMethod(ADD_BUSINESS_INFORMATION, data, headers).then(res=>{
//         resolve(res)
//     }).catch(err=>{
//         reject(err)
//     })
// })
// };

export const listUserJourney = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${LIST_USER_JOURNEY}?id=${id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const createUserJourney = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CREATE_USER_JOURNEY, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const deleteUserJourney = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(DELETE_USER_JOURNEY, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const updateUserJourney = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPutMethod(`${UPDATE_USER_JOURNEY}/${data.id}`, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getUsersGoalStatus = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${USERS_GOALS}/?userId=${id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const toggleUsersGoalStatus = (goal, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${USERS_GOALS}/${goal}/toggle`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
