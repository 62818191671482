import React, { useEffect, useState } from 'react';
import Container from '../../widgets/container';
import Mailchimp from 'react-mailchimp-form';
import { Carousel, Row, Col, message } from 'antd';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import ReactMapboxGl, {
  Marker,
  ScaleControl,
  ZoomControl,
  RotationControl,
} from 'react-mapbox-gl';
// import {
//   useDispatch,
//   useSelector
// } from 'react-redux';
import { history } from '../../../redux/store';
import style from './home.module.sass';
// import './home_style.sass';
import './home.scss';
import MyAncor from '../../widgets/global/ancor';
import item1 from '../../../assets/images/banner.jpg';
import img_access from '../../../assets/images/Icons-28.png';
import img_build from '../../../assets/images/Icons-29.png';
import img_unlock from '../../../assets/images/Icons-30.png';
import device_mocks from '../../../assets/images/EQ_device_mocks02.png';
// import marker from '../../../assets/images/marker.png';
// import barImg from '../../../assets/images/EL_icons_Growth.png';
// import impactImg from '../../../assets/images/EL_icons_Impact.png';
// import growImg from '../../../assets/images/EL_icons_value.png';
// import whyBanner from '../../../assets/images/blur_banner.jpg';
// import haulImg from '../../../assets/images/haul_iconsq220.png';
// import tieerImg from '../../../assets/images/tieer_sq.png';
// import dfhouImg from '../../../assets/images/dfhou_logo.png';
import LocalMentor from '../../../assets/images/local-mentor.png';
import BuildCredit from '../../../assets/images/build-credit.png';
import UnlockCapital from '../../../assets/images/unlock-capital.png';
import GreenMapIcon from '../../../assets/images/icon-green.png';
import BlackMapIcon from '../../../assets/images/icon-black.png';
import {
  // CloseOutlined,
  // DeleteOutlined,
  // DashOutlined,
  // EditOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';
import './index.css';
// import { GoogleMap, LoadScript, useJsApiLoader, Marker } from '@react-google-maps/api';
import { getVideoHome } from '../../../redux/slices/adminSlice';
// Headers
import { header } from '../../../api/rest';
// Constants
import {
  CONSTANTS,
  // POP_UP_MODAL_HEADER_CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  HOME_PAGE_ZIP_CODE,
  REGEX_CONSTANTS,
} from '../../shared/constants';
// POPUPMODAL
import PopUpModal from '../../shared/popupmodal';
import { Link } from 'react-router-dom';
import SponsorCarousel from './SponsorCarousel';
import HoustonBusinessJournalLogo from '../../../assets/images/HoustonBusinessJournalLogo.png';
import NPRLogo from '../../../assets/images/NPRLogo.png';
import InnovationMapLogo from '../../../assets/images/InnovationMapLogo.png';
import RadioOne from '../../../assets/images/RadioOne.png';
import DefenderNetwork from '../../../assets/images/DefenderNetwork.png';
// const mapStyles = {
//   height: "100vh",
//   width: "100%"
// };

// const defaultCenter = {
//   lat: 41.3851, lng: 2.1734
// }
// const containerStyle = {
//   width: '100%',
//   height: '400px'
// };

// const center = {
//   lat: 37.772,
//   lng: -122.214
// };
// const position = {
//   lat: 37.772,
//   lng: -122.214
// }

// const welcomeDescription = `
//   We help under-served and under-resourced communities grow and retain its greatest assets.
//   Through our platform you will have access to educational modules each focused on a specific area of entrepreneurship/ business ownership.
//   This transformational learning experience is designed in partnership with our advisors and subject matter experts to help ensure that you have the foundation you need to become financially independent as well as grow our families and communities.
//   Our goal is to expand financial inclusion in the U.S. through access to credit.
//   Many of us had to figure out these things on our own, we did it the hard way. We hope that by making your community more accessible, you are better able to build a future for growth.
// `;
// const whyDescription = `
//   Existing programs focus on venture capital as a funding model, even as studies continue to show Black Founders receive less than 3%,
//   Latino Founders less than 1%, and Women Founders less than 1% of VC funding.
//   The key to strong business ownership, as well as raising communities from poverty is, creating and leveraging capital.
//   Equiliberty does not focus on extractive resources or unicorn deals.
//   We teach people how to leverage what they currently have to grow more.
//   We lower the barrier of entry into business ownership by meeting people where they're at,
//   with the goal of creating local economies that are vibrant, inclusive, and representative of the country's diversity.
//   Equiliberty accomplishes this by serving as a social network for the business owner, but with tools.
// `;

const { ERROR_MESSAGE } = CONSTANTS;
const { COMMUNITY_STATUS_POP_UP_MODAL, MAILCHIMP_POP_UP_MODAL } =
  POP_UP_MODAL_CONSTANTS;
var {
  // ALL_PHOTO_FORMAT_REGEX,
  // ALL_VIDEO_FORMAT_REGEX,
  YOUTUBE_URL_REGEX,
  YOUTUBE_EMBED_URL_REGEX,
} = REGEX_CONSTANTS;

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
});

const sponsors = [
  {
    name: 'Houston Business Journal',
    link: 'https://www.bizjournals.com/houston/news/2021/07/09/new-startup-equiliberty-aims-to-localize-capital.html',
    image: HoustonBusinessJournalLogo,
  },
  {
    name: 'NPR',
    link: 'https://www.houstonpublicmedia.org/tag/equiliberty/',
    image: NPRLogo,
  },
  {
    name: 'Innovation Map',
    link: 'https://houston.innovationmap.com/equiliberty-phillip-yates-wealth-gap-2653729465.html',
    image: InnovationMapLogo,
  },
  {
    name: 'Radio One',
    link: 'https://theboxhouston.com/10301894/tech-company-equiliberty-ready-to-blkthegap-for-the-community/',
    image: RadioOne,
  },
  {
    name: 'The Defender',
    link: 'https://defendernetwork.com/celebrate/faces-houston-events/equiliberty-launch-kicks-off-movement-to-increase-our-access-to-capital/',
    image: DefenderNetwork,
  },
];

function Home(props) {
  const [state] = React.useState({
    lat: 39.86908683481926,
    lon: -102.28369499796335,
    geo: {},
    locationEnable: true,
  });
  // const dispatch = useDispatch();
  // const loginUserData = useSelector(state => state.user);
  const storageData = localStorage.getItem('userData');
  const payload = storageData ? JSON.parse(storageData).payload : '';
  const role = payload ? payload.role : '';
  const apiKey = `${process.env.REACT_APP_HOME_PAGE_GOOGLE_MAP_KEY}`;
  // const [map, setMap] = React.useState(null);
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: apiKey
  // });

  // const [videoList, setVideoList] = useState([]);
  const [link, setLink] = React.useState(null);

  // React.useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) =>{
  //     const lat = position.coords.latitude;
  //     const lon = position.coords.longitude;
  //     //
  //     setState({
  //       lat, lon, locationEnable: true,
  //     });
  //   }, (error) => {
  //     console.error("Error Code = " + error.code + " - " + error.message)
  //     setState({
  //       locationEnable: false
  //     })
  //   });
  // }, []);

  const [playVid, setPlayVid] = React.useState(false);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [description, setDescription] = useState('');
  const [activeCity, setActiveCity] = useState('');
  const [activeZipCodeStatus, setActiveZipCodeStatus] = useState('');

  const videoRef = React.useRef();
  function playVidfunc() {
    let status = !playVid;
    setPlayVid(status);
    if (status) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem(MAILCHIMP_POP_UP_MODAL) !== 'FALSE') {
        toggleModal(MAILCHIMP_POP_UP_MODAL, '');
      }
    }, 15000);
    if (role === 'admin') {
      history.push('/admin/dashboard');
      document.title = 'Admin Dashboard';
    } else {
      getHomePageVideoList();
    }
  }, []);

  function getHomePageVideoList() {
    getVideoHome(header)
      .then((res) => {
        // setShowPageLoader(false);
        let link = getFileLink(res.data);
        setLink(link);
        // setVideoList(res.data);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function getFileLink(data) {
    let arr = '';
    if (data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].homeImagesLink) {
          arr = data[i].homeImagesLink;
          return arr;
        } else {
        }
      }
    } else {
      return arr;
    }
  }

  // On clicking on icon show modal and then redirect to register
  function redirectToSignup(type) {
    if (type.status) {
      // history.push('/register');
      toggleModal(COMMUNITY_STATUS_POP_UP_MODAL, type.areaName);
      setDescription(`${type.areaName} Community Available`);
    } else {
      toggleModal(COMMUNITY_STATUS_POP_UP_MODAL, 'Equiliberty');
      setDescription('Community is coming soon in your area');
    }
    setActiveCity(type.areaName);
    setActiveZipCodeStatus(type.status);
  }
  // open popup modal by passing modal type and modal status
  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  // close poup modal
  function closePopupModal() {
    if (popUpModal.type === MAILCHIMP_POP_UP_MODAL) {
      sessionStorage.setItem(MAILCHIMP_POP_UP_MODAL, 'FALSE');
    }
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
  }

  function onSubmitPopUpModal(event) {
    if (popUpModal.type === MAILCHIMP_POP_UP_MODAL) {
      message.success('Thank you for joining the mailing list!');
    }
    if (activeZipCodeStatus) {
      history.push('/register');
      setActiveZipCodeStatus(false);
    } else {
      closePopupModal();
    }
  }

  // const defaultProps = {
  //   center: {
  //     lat: 59.95,
  //     lng: 30.33
  //   },
  //   zoom: 11
  // };

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, []);

  // const markerFunction = marker => {
  //
  // }

  function getId(url) {
    const regExp = YOUTUBE_EMBED_URL_REGEX;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  let isYoutubeUrl = link ? YOUTUBE_URL_REGEX.test(link) : false;
  const videoId = isYoutubeUrl ? getId(link) : false;

  let url =
    'https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_1280_10MG.mp4';
  let communityStatusModalVariableProps = {
    activeCity,
    activeZipCodeStatus,
  };
  return state.locationEnable ? (
    <Container fluid={true} gutter={false} className={style.home_container}>
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          description={description}
          {...communityStatusModalVariableProps}
        />
      ) : (
        ''
      )}
      {/* <div style={{ position: 'relative' }}>
          <Carousel autoplay={false} className={`home-carousel ${style.home_carousel}`} draggable={false} dots={false}>
            <div className={style.slick_slide}>
              <img src={item1} alt="" />
              <Row className={style.slide_text_wrap} align={'middle'}>
                <div className="head-banner_text">
                  <h2 className="home-slide-text">Where builidng our community is building wealth.</h2>
                  <p> Equiliberty is an online community of makers and creators who use their skill-sets and talent to develop and grow business to create generational and community-driven wealth.. </p>
                  <div className="head-join_btn">
                    <Row className={style.get_started_wrap}>
                      <Container className={style.get_started_container}>
                        <Row justify={`end`} className={style.get_started_inner}>
                          <MyAncor href={'/register'} className={style.get_started}>Join for Free Today</MyAncor>
                        </Row>
                      </Container>
                    </Row>
                  </div>
                </div>
              </Row>
            </div>
            <div className={style.slick_slide}>
              <img src={item1} alt="" />
              <Row className={style.slide_text_wrap} align={'middle'}>
                <h2 className={`home-slide-text ${style.slide_text}`}>where building our community is building wealth</h2>
              </Row>
            </div>
          </Carousel>
        </div> */}

      <section
        className='join-free'
        style={{ 'background-image': 'url(' + item1 + ')' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <AnimatedOnScroll
                animationIn='fadeInLeft'
                animationOut='fadeOutLeft'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className='join-box'>
                  {}
                  <h2>Where building community is building wealth.</h2>
                  <p>
                    Equiliberty is an online community of makers and creators
                    who use their skill-sets and talent to develop and grow
                    business to create generational and community-driven wealth.
                  </p>
                  <Link to='/register'>Join Today!</Link>
                </div>
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section className='lets-build mb-2'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-1'></div>
            <div className='col-lg-5 col-md-6'>
              <AnimatedOnScroll
                animationIn='fadeInLeft'
                animationOut='fadeOutLeft'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <h2>let’s build together...</h2>
                <p>
                  Our digital platform will provide local neighborhoods with
                  geographical access to resources that help launch sustainable
                  businesses, and allows for more communication, collaboration &
                  connections with mentors, customers, and funders.
                </p>
              </AnimatedOnScroll>
            </div>
            <div className='col-lg-5 col-md-6'>
              {/* <AnimatedOnScroll
                animationIn='fadeInRight'
                animationOut='fadeOutRight'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <iframe
                  width='100%'
                  height='400px'
                  title={videoId}
                  src={`https://www.youtube.com/embed/EEfqvomw0X0`}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </AnimatedOnScroll> */}
              {!isYoutubeUrl && !link && (
                <AnimatedOnScroll
                  animationIn='fadeInRight'
                  animationOut='fadeOutRight'
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <iframe
                    width='100%'
                    height='400px'
                    title={videoId}
                    src={`https://www.youtube.com/embed/EEfqvomw0X0`}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                </AnimatedOnScroll>
              )}
              {isYoutubeUrl && (
                <AnimatedOnScroll
                  animationIn='fadeInRight'
                  animationOut='fadeOutRight'
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <iframe
                    width='100%'
                    height='400px'
                    title={videoId}
                    src={`https://www.youtube.com/embed/${videoId}`}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                </AnimatedOnScroll>
              )}
              {(!isYoutubeUrl && link) &&(
                <div className='position-relative'>
                  <video
                    className='video_block'
                    ref={videoRef}
                    src={link ? link : url}
                    width='100%'
                    id=''
                  />
                  <button className='play-btn' onClick={playVidfunc}>
                    {playVid ? (
                      <PauseCircleOutlined style={{ fontSize: '48px' }} />
                    ) : (
                      <PlayCircleOutlined style={{ fontSize: '48px' }} />
                    )}
                  </button>
                </div>
              )}
            </div>
            <div className='col-lg-1'></div>
          </div>
          <div className='row build-box text-center justify-content-center'>
            <div className='col-md-3'>
              <AnimatedOnScroll
                animationIn='fadeInUp'
                animationOut='fadeOutDown'
                animationInDuration={1200}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className='w-100'>
                  <img src={img_access} className='img-fluid' />
                  <p>Access Local Mentors and Business Support</p>
                </div>
              </AnimatedOnScroll>
            </div>
            <div className='col-md-3'>
              <AnimatedOnScroll
                animationIn='fadeInUp'
                animationOut='fadeOutDown'
                animationInDuration={1600}
                animationOutDuration={1200}
                isVisible={true}
              >
                <div className='w-100'>
                  <img src={img_build} className='img-fluid' />
                  <p className='color2'>Build Your Credit </p>
                </div>
              </AnimatedOnScroll>
            </div>
            <div className='col-md-3'>
              <AnimatedOnScroll
                animationIn='fadeInUp'
                animationOut='fadeOutDown'
                animationInDuration={2000}
                animationOutDuration={1400}
                isVisible={true}
              >
                <div className='w-100'>
                  <img src={img_unlock} className='img-fluid' />
                  <p className='color3'>Unlock Capital Opportunities</p>
                </div>
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="map-box pt-5">
            <div style={{"width": "100%"}}>
              <iframe width="100%" height="640"  src={`https://snazzymaps.com/embed/303026`}></iframe>
            </div>
        </section> */}

      {/* <div className="">
          <div className="container">
            <Carousel autoplay={false} className={`home-carousel ${style.home_carousel}`} draggable={false} dots={false}>
              <div>
                <Row className={style.elWrapper}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} align={''}>
                    <h1 className="video_text">Let's Build <br /> Together...</h1> */}
      {/* <p className="carousel-text">Our digital platform will provide local neighborhoods with geographical access to resources that help launch sustainable businesses, and allows for more communication, collaboration, and connections with mentors,customers and funders.</p> */}
      {/* {
                      isYoutubeUrl ?
                        <iframe width="100%" height="400px" title={videoId} src={`https://www.youtube.com/embed/${videoId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        : <div className="position-relative">
                          <video className="video_block" ref={videoRef} src={link ? link : url} width="100%" id="" />
                          <button className="play-btn" onClick={playVidfunc}>{playVid ? <PauseCircleOutlined style={{ fontSize: '48px' }} /> : <PlayCircleOutlined style={{ fontSize: '48px' }} />}</button>
                        </div>
                    }
                  </Col> */}
      {/* <Col xs={24} sm={24} md={24} lg={14} xl={14} align={''}>

                  </Col> */}
      {/* </Row>
              </div> */}
      {/* <div>
                <Row className={style.elWrapper}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} align={''}>
                    <h1 className="video_text">Let's Build <br /> Together..</h1>
                    <p className="carousel-text">Our digital platform will provide local neighborhoods with geographical access to resources that help launch sustainable businesses, and allows for more communication, collaboration, and connections with mentors,customers and funders.</p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14} xl={14} align={''}>
                    <div className="position-relative">
                      <video className="video_block" ref={videoRef} src="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_1280_10MG.mp4" width="100%" id="" />
                      <button className="play-btn" onClick={playVidfunc}>{playVid ? <PauseCircleOutlined style={{ fontSize: '48px' }} /> : <PlayCircleOutlined style={{ fontSize: '48px' }} />}</button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className={style.elWrapper}>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} align={''}>
                    <h1 className="video_text">Let's Build <br /> Together..</h1>
                    <p className="carousel-text">Our digital platform will provide local neighborhoods with geographical access to resources that help launch sustainable businesses, and allows for more communication, collaboration, and connections with mentors,customers and funders.</p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14} xl={14} align={''}>
                    <div className="position-relative">
                      <video className="video_block" ref={videoRef} src="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_1280_10MG.mp4" width="100%" id="" />
                      <button className="play-btn" onClick={playVidfunc}>{playVid ? <PauseCircleOutlined style={{ fontSize: '48px' }} /> : <PlayCircleOutlined style={{ fontSize: '48px' }} />}</button>
                    </div>
                  </Col>
                </Row>
              </div> */}
      {/* </Carousel>
          </div>
        </div> */}
      <section className='map-box d-flex position-relative overflow-hidden mt-2'>
        <iframe
          src='https://snazzymaps.com/embed/303026'
          width='100%'
          height='640px'
          style={{ border: 'none' }}
        ></iframe>
        {/* <Map
          style={`mapbox://styles/mapbox/streets-v11`}
          center={[state.lon, state.lat]}
          zoom={[4]}
          className={style.mapContainerStyle}
        >

          <ScaleControl />
          <ZoomControl />
          <RotationControl />
          {
            HOME_PAGE_ZIP_CODE.map((value, index) => {
              return (
                <Marker
                  coordinates={[value.lat, value.lng]}
                  anchor="bottom"
                  onClick={() => redirectToSignup(value)}
                  key={index}
                  className="cursor-pointer"
                >
                  <img src={value.status ? GreenMapIcon : BlackMapIcon} alt={value.status ? 'GreenMapIcon' : 'BlackMapIcon'} />
                </Marker>
              )
            })
          }
        </Map> */}
      </section>
      {/* {
          isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {
                ARR.map((value, index) => {
                  return (
                    <Marker
                      onLoad={markerFunction}
                      position={value}
                      icon={GreenMapIcon}
                      key={index}
                    />
                  )
                })
              }
            </GoogleMap>
          ) : <></>
        } */}
      {/* <section className="access-sectoin">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <div className="jion-grid">
                      <img src={LocalMentor} alt="LocalMentor" />
                      <p className="local-mentor"> Access Local Mentors and Business Support</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="jion-grid">
                      <img src={BuildCredit} alt="BuildCredit" />
                      <p className="build-credit"> Build Your Credit </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="jion-grid">
                      <img src={UnlockCapital} alt="UnlockCapital" />
                      <p className="unlock-capital">Unlock Capital Opportunities</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="access-content">
                  <h4>Join us in building virtual and local communities based on geo location that enables users to make contributions that helps grow their local economy.</h4>
                  <p>Through our platform you will have access to specialized business development services and financial education. This transformational learning experience is designed in partnership with our community partners to help ensure that you have the resources and foundation you need to become financially independent as well as help grow your business and local economy. Our goal is to expand financial inclusion in the U.S. through access to credit. Many of us had to figure out these things on our own, we did it the hard way. We hope that by making business more accessible, you are better able to build a future for growth.</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      <section className='que-form'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-sm-12'>
              <AnimatedOnScroll
                animationIn='fadeInLeft'
                animationOut='fadeOutLeft'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <img src={device_mocks} className='img-fluid equimg' />
              </AnimatedOnScroll>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <AnimatedOnScroll
                animationIn='fadeInRight'
                animationOut='fadeOutRight'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className='que-form-box col-12 px-0'>
                  <h4 className='text-right'>features include...</h4>
                  <ul>
                    <li>self-paced proprietary learning modules</li>
                    <li>in-platform messaging & video conferencing</li>
                    <li>goal mapping with incentives to keep you on track</li>
                    <li>Push notifications</li>
                    <li>community-based networking & resources</li>
                  </ul>
                </div>
              </AnimatedOnScroll>
              {/* <form>
                            <input type="text" name="" />
                            <input type="text" name="" />
                            <input type="text" name="" />
                            <input type="text" name="" />
                            <input type="text" name="" />
                            <input className="btn_submit" type="submit" value="Submit"/>
                        </form> */}
            </div>
          </div>
        </div>
      </section>
      <section className='joinus'>
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-md-7'>
              <h4>
                Join us in building virtual and local communities based on geo
                location that enables users to make contributions that helps
                grow their local economy.
              </h4>
              <p>
                Through our platform you will have access to specialized
                business development services and financial education. This
                transformational learning experience is designed in partnership
                with our community partners to help ensure that you have the
                resources and foundation you need to become financially
                independent as well as help grow your business and local
                economy. Our goal is to expand financial inclusion in the U.S.
                through access to credit. Many of us had to figure out these
                things on our own, we did it the hard way. We hope that by
                making business more accessible, you are better able to build a
                future for growth.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='join-news'>
        <SponsorCarousel sponsors={sponsors} />
        <br />
        <br />
        <br />
        <div className='container'>
          <div className='row text-center'>
            <div className='col-md-12'>
              <AnimatedOnScroll
                animationIn='fadeInUp'
                animationOut='fadeOutUp'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <h4>Join Equiliberty in making business accessible</h4>
                {/* <form>
                            <input type="email" name="" placeholder="email address"/>
                            <button type="button">subscribe</button>
                        </form> */}
                <Mailchimp
                  action='https://equiliberty.us1.list-manage.com/subscribe/post?u=7ec78db856eb80758bdc9d2b3&amp;id=b034b03a71'
                  //Adding multiple fields:
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'email address',
                      type: 'email',
                      required: true,
                    },
                  ]}
                  // Change predetermined language
                  messages={{
                    sending: 'Sending...',
                    success: 'Thank you for subscribing!',
                    error: 'An unexpected internal error has occurred.',
                    empty: 'You must write an e-mail.',
                    duplicate: 'This email address already subscribed',
                    button: 'subscribe',
                  }}
                  // Add a personalized class
                  className='home-page-subscribe-input'
                />
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="signup-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <form>
                  <div className="form-group">
                    <label>Join Equiliberty in making business accessible</label>
                    <input type="text" className="form-control" />
                    <button type="submit" className="signup-btn" onClick={() => {
                      history.push("/register")
                    }}>Sign Up For Free</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}
    </Container>
  ) : null;
}

export default Home;
