import React from 'react';
// CSS
import './comingSoonPage.css';

function ComingSoonPage() {
  let ref = React.createRef();
  const storageData = localStorage.getItem("userData");
  const { email } = storageData ? JSON.parse(storageData).payload : '';
  const { token } = storageData ? JSON.parse(storageData) : '';
  const xHeader = localStorage.getItem("x-headers");

  React.useEffect(() => {
    if (token) {
      submitForm();
    } else {

    }
  }, [ref]);

  function submitForm() {
    actionCall();
  }

  function actionCall() {
    if (ref.current) {
      ref.current.submit();
      // window.location.href = "https://learning.softuvo.xyz";
    }
  }

  return (
    <React.Fragment>
      <div className="bgimg">
        {/* <div className="middle">
          <h1>COMING SOON</h1>
        </div> */}
      </div>
      <form onSubmit action={process.env.REACT_APP_LEARNING_MODULE_URL} method="post" name="form" id="form" className="d-none" ref={ref}>
        <p><input type="text" name="username" value={email} size="15" /></p>
        <p><input type="password" name="password" value={xHeader} size="15" /></p>
        <p><input type="submit" name="Submit" value="Login" /></p>
      </form>
    </React.Fragment>
  )
}

export default ComingSoonPage
