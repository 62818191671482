import React, { useEffect, useState } from 'react';
import { Table, message, Space, Button } from 'antd';
import parse from 'html-react-parser';
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../../shared/constants';
// API END POINTS
import {
  adminGetAllNews,
  adminCreateNews,
  adminDeleteNews,
  adminUpdateNews,
} from '../../../../redux/slices/adminSlice';
import { header } from '../../../../api/rest';
import { history } from '../../../../redux/store';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
import { unescapeHtml } from '../../../../utils/helpers';
import './adminnews.scss';

var { ERROR_MESSAGE, INVALID_USER } = CONSTANTS;

var { CONFIRMATION_POPUP_MODAL, ADD_EDIT_NEWS } = POP_UP_MODAL_CONSTANTS;
var { ADD_NEWS, EDIT_NEWS, CONFIRMATION_HEADER } =
  POP_UP_MODAL_HEADER_CONSTANTS;

function AdminNews() {
  // const dispatch = useDispatch();
  // const loginUserData = useSelector(state => state.user);
  const storageData = localStorage.getItem('userData');
  const payload = storageData ? JSON.parse(storageData).payload : '';
  const token = storageData ? JSON.parse(storageData).token : '';
  const [liberatorList, setLiberatorList] = useState([]);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [currentEntry, setCurrentEntry] = useState({
    id: '',
    title: '',
    type: '',
    text: '',
    newsStatus: '',
    city: '',
    zip: '',
  });
  const [description, setDescription] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(true);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      render: (text) => parse(unescapeHtml(text)),
    },
    {
      title: 'News Status',
      dataIndex: 'newsStatus',
      key: 'newsStatus',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Zip Code',
      dataIndex: 'zip',
      key: 'zip',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            className='delete-news-btn'
            onClick={() => fillUserInfoInModal(record, 'delete')}
          >
            Delete News
          </Button>
          <Button
            className='update-news-btn'
            onClick={() => fillUserInfoInModal(record, 'update')}
          >
            Update News
          </Button>
        </Space>
      ),
    },
  ];

  function fillUserInfoInModal(data, type) {
    if (type === 'update') {
      toggleModal(ADD_EDIT_NEWS, EDIT_NEWS);
    }
    if (type === 'delete') {
      toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
      setDescription(`Are you sure to remove ${data.name}`);
    }
    setCurrentEntry((prevCurrentEntry) => {
      return {
        ...prevCurrentEntry,
        id: data.id,
        title: data.title,
        type: data.type,
        text: data.text,
        newsStatus: data.newsStatus,
        city: data.city,
        zip: data.zip,
      };
    });
  }

  useEffect(() => {
    if (token) {
      if (payload.role === 'admin') {
        getListResourceApi();
      } else {
        message.error(INVALID_USER);
        history.push('/');
      }
    } else {
      history.push('/');
      // message.error(TOKEN_EXPIRED_MESSAGE);
    }
  }, []);
 
  function addResource(value) {
    header.Authorization = `Bearer ${token}`;
    let data = value;
    adminCreateNews(data, header)
      .then((res) => { 
        message.success(res.message);
        getListResourceApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function updateResourceApi(value) {
    header.Authorization = `Bearer ${token}`;
    let data = {
      id: currentEntry.id,
      ...value,
    };
    adminUpdateNews(data, header)
      .then((res) => {
        message.success(res.message);
        getListResourceApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function deleteResourceApi(value) {
    header.Authorization = `Bearer ${token}`;
    let data = {
      id: value.id,
    };
    adminDeleteNews(data, header)
      .then((res) => {
        message.success(res.message);
        getListResourceApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function getListResourceApi() {
    header.Authorization = `Bearer ${token}`;
    adminGetAllNews(header)
      .then((res) => {
        if (res.newsList) {
          setLiberatorList(res.newsList);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function onSubmitPopUpModal(event) {
    setShowPageLoader(true);
    // event.preventDefault();
    if (popUpModal.modalHeader === ADD_NEWS) {
      addResource(event);
    }
    if (popUpModal.modalHeader === EDIT_NEWS) {
      updateResourceApi(event);
    }
    if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
      deleteResourceApi(currentEntry);
    }
    // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
  }

  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  // close poup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
    setCurrentEntry((prevCurrentEntry) => {
      return {
        ...prevCurrentEntry,
        id: '',
        title: '',
        type: '',
        text: '',
        newsStatus: '',
        city: '',
        zip: '',
      };
    });
  }

  let addEditLiberatorProps = {
    currentEntry,
  };

  return (
    <React.Fragment>
      {showPageLoader ? <Loader /> : ''}
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          description={description}
          {...addEditLiberatorProps}
        />
      ) : (
        ''
      )}
      <div className='row mt-4 mb-2'>
        <div className='col-12 text-right'>
          <Button onClick={() => toggleModal(ADD_EDIT_NEWS, ADD_NEWS)}>
            Add News
          </Button>
        </div>
      </div>
      <Table dataSource={liberatorList} columns={columns} />
    </React.Fragment>
  );
}

export default AdminNews;
