import React, { useEffect, useState, useCallback } from 'react';
import { Table, Space, Tag, Button, message, Switch, Descriptions } from 'antd';
import { apiDeleteMethod, apiGetMethod, header } from '../../../../api/rest';
import PopUpModal from '../../../shared/popupmodal';
import { Loader } from '../../../shared/Loader';
import moment from 'moment';
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../../shared/constants';
import { createAdminUser } from '../../../../redux/slices/adminUserSlice';
const { ADD_EDIT_ADMIN_USER, CONFIRMATION_POPUP_MODAL } =
  POP_UP_MODAL_CONSTANTS;
const { ADD_USER, EDIT_USER, CONFIRMATION_HEADER } =
  POP_UP_MODAL_HEADER_CONSTANTS;

export default function AdminInvitations() {
  const storageData = localStorage.getItem('userData');
  const token = storageData ? JSON.parse(storageData).token : '';
  const [invitations, setInvitations] = useState([]);
  const [description, setDescription] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState('');
  const [activeInvite, setActiveInvite] = useState('');
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [currentEntry, setCurrentEntry] = useState({
    id: '',
    firstName: '',
    lastName: '',
    password: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    role: 'user',
    email: '',
    categoryId: '',
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, row) => `${row.firstName} ${row.lastName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Invited By',
      dataIndex: 'invitedBy',
      key: 'invitedBy',
      render: (text, row) =>
        `${row.invitedBy?.firstName} ${row.invitedBy?.lastName}`,
    },
    {
      title: 'Accepted',
      dataIndex: 'isAccepted',
      key: 'isAccepted',
      render: (text, row) =>
        text ? <Tag color='green'>Yes</Tag> : <Tag color='orange'>Pending</Tag>,
    },
    {
      title: 'Expires On',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      render: (text, row) => moment(row.expiresAt).fromNow(),
    },
    {
      title: 'Invited On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, row) => moment(row.createdAt).fromNow(),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            type='primary'
            disabled={record.isAccepted}
            onClick={() => {
              setCurrentEntry({
                role: record.role,
                email: record.email,
                sponsor: record.invitedBy?._id,
              });
              setActiveInvite(record._id);
              toggleModal(ADD_EDIT_ADMIN_USER, ADD_USER);
            }}
          >
            Accept
          </Button>
          <Button type='primary' disabled={record.isAccepted}>
            Resend
          </Button>
          <Button
            type='primary'
            danger
            disabled={record.isAccepted}
            loading={deleteLoading === record._id}
            onClick={() => deleteInvite(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getInvitations();
  }, []);

  function createUser(value) {
    let data = {
      firstName: value.firstName,
      lastName: value.lastName,
      password: value.password,
      city: value.city,
      state: value.state,
      zipcode: value.zipcode,
      country: value.country,
      role: value.role,
      email: value.email,
      businessName: value.businessName,
      company: value.company,
      sponsor: value.sponsor,
    };
    createAdminUser(data)
      .then((res) => {
        message.success(res.message);
        apiGetMethod(`/sponsors/invites/${activeInvite}/adminAccept`);
        getInvitations();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err?.message);
        setShowPageLoader(false);
      });
  }
  // calling toggle modal api
  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type,
        modalHeader: header,
      };
    });
  }

  // calling close popup modal function which close popup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => ({
      ...prevPopUpModal,
      visible: false,
      type: '',
      modalHeader: '',
    }));
    setCurrentEntry((prevCurrentEntry) => ({
      ...prevCurrentEntry,
      id: '',
      name: '',
    }));
  }
  const getInvitations = useCallback(async () => {
    const invitations = await apiGetMethod('/sponsors/invites');
    setInvitations(invitations);
    setShowPageLoader(false);
  }, []);

  const deleteInvite = async (invitationId) => {
    setDeleteLoading(invitationId);
    await apiDeleteMethod(`/sponsors/invites/${invitationId}`);
    setDeleteLoading('');
    message.success("Invitation Deleted")
    getInvitations()
  };

  function handleModalSubmit(event) {
    setShowPageLoader(true);
    // event.preventDefault();
    if (popUpModal.modalHeader === ADD_USER) {
      console.log('create now');
      console.log(event);
      event.city = event.city.toLowerCase();
      createUser(event);
    }
  }

  //   const handleModalSubmit = useCallback(
  //     async (data) => {
  //       header.Authorization = `Bearer ${token}`;
  //       if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
  //         await deleteMentorRequests(currentEntry.id, header);
  //       }
  //       if (popUpModal.type === ADMIN_APPROVE_MENTOR_POP_UP_MODAL) {
  //         await approveMentorRequests(currentEntry.id, data, header);
  //       }
  //       await getRequests();
  //       closePopupModal();
  //     },
  //     [currentEntry.id]
  //   );
  return (
    <>
      {showPageLoader ? <Loader /> : ''}
      <div className='row mt-4 mb-2'>
        <div className='col-12 text-right'>
          {/* <Button
            onClick={() => toggleModal(ADD_EDIT_ADMIN_CATEGORY, ADD_CATEGORY)}
          >
            Add Category
          </Button> */}
        </div>
      </div>
      {popUpModal.visible && (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={handleModalSubmit}
          type={popUpModal.type}
          description={description}
          currentEntry={currentEntry}
        />
      )}
      <Table
        dataSource={invitations}
        columns={columns}
        rowKey={(record) => record.id}
        expandRowByClick
      />
    </>
  );
}
