import React from 'react';
import { Modal, Button } from 'antd';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// IMAGES
import DocumentIcon from '../../../assets/images/document-icon.png';
import LocationIcon from '../../../assets/images/location-icon.png';
import ImgIcon from '../../../assets/images/img-icon.png';
import { CloseOutlined } from '@ant-design/icons';
// CSS
import './updatePostPopUpModal.css';

export function UpdateJourneyModal(props) {
    let {
        title,
        updatedPostLocation,
        updatedPost,
        activePostImage,
        activePostDocument,
        updatePostDocumentObj,
        updatedLocationBox
    } = props;
    return (
        <Modal
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            okButtonProps={{ title: "Submit" }}
            footer={[
                <Button key="back" onClick={() => props.onCancel(false)} >
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={(event) => props.onOk(event)}>
                    Submit
                </Button>,
            ]}>
            <form>
                <div className="row">
                    <div className="col-md-12 col-12 mb-2">
                        <textarea value={updatedPost} className="form-control" onChange={(event) => props.handlePopUpModalInput(event)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <ul>
                            <li>
                                <a href="/" className="upload-files-icons disabled">
                                    <img src={ImgIcon} alt="ImgIcon" /></a>
                                <input type="file" name="postImage" accept="image/*" className="disabled" onChange={(event) => { props.handlePopUpModalFileInput(event) }} multiple />
                            </li>
                            <li>
                                <a href="/" className="disabled">
                                    <img src={DocumentIcon} alt="DocumentIcon" /></a>
                                <input type="file" name="postDocument" accept=".doc, .docx,.pdf" className="disabled" onChange={(event) => { props.handlePopUpModalFileInput(event) }} multiple />
                            </li>
                            <li>
                                <img onClick={() => props.toggleLocationBox(!updatedLocationBox)} src={LocationIcon} alt="LocationIcon" className="disabled" />
                            </li>
                        </ul>

                        {updatedLocationBox ?
                            <div className="row">
                                <div className="col-12">
                                    <div style={{ width: '350px', marginBottom: '10px' }}>
                                        <GooglePlacesAutocomplete
                                            // apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                            apiKey="AIzaSyBFTEG2DxMcjMh8FUt3lRdXyQ85wJrJj0c"
                                            selectProps={{
                                                // value,
                                                updatedPostLocation,
                                                onChange: (event) => props.handlePopUpModalLocationInpt(event),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {/* IMPORTANT CODE USED IN FUTURE WRITTEN BY MAYANK */}
                        {/* <div className="uploadfile-name">
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                  </div> */}
                    </div>
                    {/* <div className="col-md-6 col-6">
                        <input type="submit" className="form-control post-comment" value="POST"></input>
                    </div> */}
                    <div className="col-md-12 post-documents">
                        {
                            updatePostDocumentObj.postImage === '' ? '' : updatePostDocumentObj.postImage.length ?
                                <div className="col-md-6 uploadfile-name">{
                                    Object.entries(updatePostDocumentObj.postImage).map(([key]) => {
                                        let fileName = updatePostDocumentObj.postImage[key] && updatePostDocumentObj.postImage[key].name && updatePostDocumentObj.postImage[key].name.length > 10 ? updatePostDocumentObj.postImage[key].name.substring(0, 10) + '... ' + updatePostDocumentObj.postImage[key].name.substr(updatePostDocumentObj.postImage[key].name.length - 5) : updatePostDocumentObj.postImage[key].name
                                        return (
                                            <p key={`${fileName}-${key}-postImage`}>{fileName}<span className="cross-icon" onClick={(event) => props.removeUpdatedModalSelectedFile(event, 'postImage', key)}><CloseOutlined /></span></p>
                                        )
                                    })}</div> : ''
                        }
                        {
                            updatePostDocumentObj.postDocument === '' ? '' : updatePostDocumentObj.postDocument.length ?
                                <div className="col-md-6 uploadfile-name">{
                                    Object.entries(updatePostDocumentObj.postDocument).map(([key]) => {
                                        let fileName = updatePostDocumentObj.postDocument[key] && updatePostDocumentObj.postDocument[key].name && updatePostDocumentObj.postDocument[key].name.length > 10 ? updatePostDocumentObj.postDocument[key].name.substring(0, 10) + '... ' + updatePostDocumentObj.postDocument[key].name.substr(updatePostDocumentObj.postDocument[key].name.length - 5) : updatePostDocumentObj.postDocument[key].name
                                        return (
                                            <p key={`${fileName}-${key}-postDocument`}>{fileName}<span className="cross-icon" onClick={(event) => props.removeUpdatedModalSelectedFile(event, 'postDocument', key)}><CloseOutlined /></span></p>
                                        )
                                    })}</div> : ''
                        }
                    </div>
                </div>
                {
                    activePostImage && activePostImage.length ?
                        <div className="row">
                            {
                                activePostImage.map((value, index) => {
                                    if (value) {
                                        return (
                                            <div className="col-md-4 col-4 mb-4">
                                                <div className="position-relative ">
                                                    <img src={value} className="img-thumbnail" alt={`${value}-${index}`} />
                                                    <span className="p-r" id={index} onClick={(event) => props.removeSelectedImageOrDoc(event, index, `activePostImage`)}>
                                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="#ff0000" aria-hidden="true">
                                                            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
                                                    </span>
                                                </div>

                                            </div>
                                        )
                                    } else {
                                        return false
                                    }

                                })
                            }
                        </div>
                        : ''
                }
                {
                    activePostDocument && activePostDocument.length ?
                        <div className="col-md-12 col-12">
                            {
                                activePostDocument.map((value, index) => {
                                    if (value) {
                                        return (
                                            <a href={value} index={index} target="_blank" rel="noreferrer noopener" key={`${value}-${index}`} >
                                                {/* <FileDoneOutlined twoToneColor="#18a4ad" /> */}
                                                <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="file-done" width="25px" height="25px" fill="#18a4ad" aria-hidden="true"><path d="M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 00-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path></svg>
                                            </a>)
                                    } else {
                                        return false;
                                    }
                                })
                            }
                        </div>
                        : ''
                }
            </form>
        </Modal>
    )
}
