import React from "react";
import { Card, Progress } from "antd";
import { StarOutlined } from '@ant-design/icons';
// CSS
import style from './statusItem.module.sass';

const StateItem = props => {
  const { description, title, tokens, status } = props;
  return (
    <div className={style.stateItemContainer}>
      <p className={style.stateItemTitle}>{description}</p>
      <Card className={style.stateCard}>
        <div className={style.itemWrapper}>
          <p className={style.stateItemTitle}>
            {`${title}: `}
            <span className={style.statePercent}>{status === 0 ? 'Not Started' : `${status}%`}</span>
          </p>
          <p className={style.stateTokens}>{`${tokens} tokens`}</p>
        </div>
        <div className={style.progressWrapper}>
          <Progress
            strokeColor={{
              from: '#108ee9',
              to: '#87d068',
            }}
            percent={status}
            showInfo={false}
          />
          <div className={style.starWrapper}>
            <StarOutlined className={style.starIcon} />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default StateItem