import React,
{
  useState,
  useEffect,
  useCallback
} from "react";
import { useSelector } from 'react-redux';
import {
  message,
  Table
} from 'antd';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
// import CommentImg from '../../../assets/images/comment-img.png';
// import MessageIcon from '../../../assets/images/icon-message.png';
import TockenIcon from '../../../assets/images/token-icon-big.png';
// CONSTANTS
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  // POP_UP_MODAL_HEADER_CONSTANTS,
  REGEX_CONSTANTS
} from '../../shared/constants';
// CSS
import './wallet.scss';
// SUBHEADER
import SubHeader from '../../widgets/subheader';
// API
import {
  getLedgerList,
  addTokenToWallet,
  getTokenListForUser
} from '../../../redux/slices/walletSlice';
import {
  // getUserProfile,
  getAnyUserProfile
} from '../../../redux/slices/userProfileSlice';
import {
  header
} from '../../../api/rest';
// LOADERS
import {
  // SkeletonDashBoardPostLoader,
  Loader
} from '../../shared/Loader';
// import { setLoading as running } from "../../../redux/slices/authSlice";
// POPUPMODAL
import PopUpModal from '../../shared/popupmodal';

var {
  // NO_BADGES_TEXT,
  // START_EARNING_BADGES_TEXT,
  TOKEN_EXPIRED_MESSAGE,
  EARN_MORE_TOKEN_TEXT,
  YOUR_BADGES_TEXT,
  ERROR_MESSAGE
} = CONSTANTS;
var {
  ACCEPT_NUMBER
} = REGEX_CONSTANTS
var {
  ADD_EDIT_TOKEN_TO_WALLET
} = POP_UP_MODAL_CONSTANTS;
// var {
//   ADD_TOKEN_TO_WALLET
// } = POP_UP_MODAL_HEADER_CONSTANTS;

export const Wallet = (props) => {
  
  React.useEffect(() => {
    document.title = "Wallet";
  })
  const session = useSelector((state) => state.user);
  const storageData = localStorage.getItem('userData');
  const [ledgerEntries, setLedgerEntries] = useState([]);
  const data = localStorage.getItem('userData'), payload = data ? JSON.parse(data) : '';
  const [tokenAmt, setTokenAmt] = useState('');
  const [userType, setUserType] = useState('');
  let {
    role,
    // moodleToken,
  } = session.data;
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: ''
  });
  const {  moodleToken } = storageData
  ? JSON.parse(storageData).payload
  : '';
  const { token } = storageData ? JSON.parse(storageData) : '';
  const [walletCount, setWalletCount] = useState(0);
  const [earnToken, setEarnToken] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(true);
 
  const columns = [
    {
      title: 'Token Name',
      dataIndex: 'tokenName',
      key: 'tokenName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    }
  ];
  
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    if (storageData) {
      getBadges();
    } else {
      props.history.push('/');
      message.error(TOKEN_EXPIRED_MESSAGE);
    }
  }, [moodleToken]);

  const getBadges = useCallback(async () => {
    let res = await fetch(
      `https://learning.equiliberty.com/webservice/rest/server.php?wstoken=${moodleToken}&wsfunction=core_badges_get_user_badges&moodlewsrestformat=json`
    );
    let json = await res.json();
    if (json.badges) {
      setBadges(json.badges);
    }
  }, [moodleToken]);

  useEffect(() => {
    let data = localStorage.getItem('userData');
    if (data) {
      getLedgerEntriesFromApi();
      getUserProfileInfo();
      getWalletEarning();
    } else {
      props.history.push('/');
    }
  }, []);

  const getUserProfileInfo = () => {
    let storageData = localStorage.getItem('userData');
    let { payload, token } = JSON.parse(storageData);
    // dispatch(running(true));
    // let user_id = {
    //   id: payload.id,
    //   type: 'normal'
    // }
    // dispatch(getUserProfile(user_id));
    header.Authorization = token;
    getAnyUserProfile(payload.id, header).then(res => {
      if (res.updatedUser) {
        setWalletCount(res.updatedUser.badgeToken)
      }
      setShowPageLoader(false);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);
    })
  }

  function getLedgerEntriesFromApi() {
    let {
      id
    } = payload.payload,
      {
        token
      } = payload;
    header.Authorization = token;
    getLedgerList(id, header).then(res => {
      if (res.ledgesData) {
        setLedgerEntries(res.ledgesData)
      }
      setShowPageLoader(false);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);
    })
  }


  function getWalletEarning() {
    let {
      token
    } = payload;
    header.Authorization = token;
    getTokenListForUser(header).then(res => {
      if (res.data) {
        setEarnToken(res.data)
      } else {
        setEarnToken([])
      }
      setShowPageLoader(false);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);
    })
  }

  function addTokenToWalletApi() {
    let data = {
      userId: payload.payload.id,
      token: tokenAmt
    }
    header.Authorization = payload.token;
    addTokenToWallet(data, header).then(res => {
      message.success(res.message);
      closePopupModal();
      getUserProfileInfo();
      getLedgerEntriesFromApi();
      setShowPageLoader(false);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);
    })
  }

  function handleInput(event) {
    let {
      value
    } = event.target;
    let status = ACCEPT_NUMBER.test(value) ? value : '';
    // let status=value.replace(/[|&;$%@"<>()+-,]/g, "");
    if (status > 100) {
      message.error('Maximum 100 token can be added')
    } else {
      setTokenAmt(status);
    }
  }

  // close poup modal
  function closePopupModal() {
    setPopUpModal(prevPopUpModal => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: ''
      }
    });
    setTokenAmt('');
  }

  function onSubmitPopUpModal(event) {
    event.preventDefault();
    setShowPageLoader(true);
    if (popUpModal.type === ADD_EDIT_TOKEN_TO_WALLET) {
      addTokenToWalletApi();
    }
    // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
  }


  // function toggleModal(type, header) {
  //   setPopUpModal(prevPopUpModal => {
  //     return {
  //       ...prevPopUpModal,
  //       visible: true,
  //       type: type,
  //       modalHeader: header
  //     }
  //   });
  // }

  // let data = localStorage.getItem('userData'),
  //   payload = data ? JSON.parse(data) : '';

  let addEditTokenVariable = {
    tokenAmt
  }, addEditTokenFunctionProps = {
    handleInput: handleInput
  }

  return (
    <React.Fragment>
      {/* wallet starts */}
      {showPageLoader ? <Loader /> : ''}
      <SubHeader pathName='Your Wallet' walletCount={walletCount} />
      
      {
        popUpModal.visible ?
          <PopUpModal
            title={popUpModal.modalHeader}
            visible={popUpModal.visible}
            onCancel={closePopupModal}
            onOk={onSubmitPopUpModal}
            type={popUpModal.type}
            {...addEditTokenVariable}
            {...addEditTokenFunctionProps}
          /> : ''
      }
      <section className="wallet-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="wallet-detail">
              <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} animationOutDuration={1500} isVisible={true}>
                <div className="wallet-balance">
                  <img src={TockenIcon} alt="TockenIcon" />
                  <p>You Have:</p>
                  <h2 className="mb-0">{walletCount}</h2>
                  <h2 className="mt-0"><span>tokens</span></h2>
                </div>
              </AnimatedOnScroll>
              <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="ledger-section">
                  <h2>Ledger</h2>
                  <div className="ledgers">
                    {
                      ledgerEntries.length ?
                        ledgerEntries.map((value, index) => {
                          let { badgeToken, isCredited, isDebited, description } = value;
                          let tokenSign = isCredited ? '+' : isDebited ? '-' : '';
                          return (
                            <p key={`${value.id}-${index}`}><span className="tocken-count">{`${tokenSign} ${badgeToken}`}</span> <br />{description}</p>
                          )
                        })
                        :
                        <p className="text-center"> <span className="tocken-count"></span> <br />No Ledger Entries Found </p>
                    }
                  </div>
                </div>
              </AnimatedOnScroll>
              </div>
            </div>
            <div className="col-md-8">
            <AnimatedOnScroll animationIn="fadeInUp" animationOut="fadeOutUp" animationInDuration={800} animationOutDuration={1500} isVisible={true}>
              <div className="earn-tocken">
                <span className="earn-tocken-btn">{EARN_MORE_TOKEN_TEXT}</span>
                {/* <button className="add-tocken" onClick={() => toggleModal(ADD_EDIT_TOKEN_TO_WALLET, ADD_TOKEN_TO_WALLET)}>Add Token</button> */}
                <div className="earn-section-detail">
                  <Table dataSource={earnToken} columns={columns} />
                </div>
                <span className="badge-btn">{YOUR_BADGES_TEXT}</span>
                <div className="nobadge-message">
                  {/* <h1>You Have No Badges</h1> */}
                  {/* <h1>Badges Section Coming Soon</h1> */}
                  {/* <h1>{NO_BADGES_TEXT}</h1>
                  <p>{START_EARNING_BADGES_TEXT}</p> */}
                   {role === 'mentor' ? (
                  ''
                ) : (
                   <div className="row">
                    {badges.length === 0 && 
                    <div className="text-center col-12"><h1>You Have No Badges </h1></div> }
                    {badges.length > 0 &&
                     badges.map((badge) => (
                    <div className="col-md-3">
                     <a
                         href={`https://learning.equiliberty.com/badges/badge.php?hash=${badge.uniquehash}`}
                         target='_blank'
                         className='ml-1'
                        >
                       <img
                                src={`${badge.badgeurl}?token=${moodleToken}`}
                                alt={badge.imagecaption}
                              />
                      </a>
                     </div>
                     ))}
                    </div>
                   )}
                </div>
              </div>
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
      </section>
      {/* wallet ends */}
    </React.Fragment>
  )
}
