import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { apiGetMethod } from '../../api/rest';

const { Meta } = Card;

function LinkPreviewCard({ url }) {
  const [ogData, setOgData] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOgData();
  }, []);

  const getOgData = async () => {
    setLoading(true);
    const res = await apiGetMethod(`/ogs?url=${url}`);
    setOgData(res);
    setLoading(false);
  };
  if (loading) return <span> </span>;
  if (!ogData.success || !ogData?.ogImage?.url)
    return (
      <a href={url} target='blank'>
        {url}
      </a>
    );
  return (
    <a href={url} target='blank'>
      <Card
        hoverable
        className='m-2'
        style={{ width: 400, background: '#e9e9eb' }}
        cover={
          <img
            alt={ogData?.ogTitle}
            src={ogData?.ogImage?.url}
            style={{ maxHeight: '100%', maxHeight: 400, objectFit: 'cover' }}
          />
        }
      >
        <Meta title={ogData?.ogTitle} description={ogData?.ogUrl} />
      </Card>
    </a>
  );
}

export default React.memo(LinkPreviewCard);
