import React from 'react';
import { Modal, Tabs, Divider, Button, Checkbox, Form, Input } from 'antd';
// import LoginForm from "../../widgets/login/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import MyAncor from '../../widgets/global/ancor';
import logo from '../../../assets/images/logo.png';
import { history } from '../../../redux/store';
import style from '../../widgets/header/header.module.sass';
const { TabPane } = Tabs;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

export function LoginModal(props) {
  return (
    <Modal
      visible={props.visible}
      title={
        <>
          <MyAncor href={`/`}>
            <img src={logo} alt={`Equiliberty`} className={style.logo} />
          </MyAncor>
        </>
      }
      onOk={() => props.onOk()}
      onCancel={() => props.onCancel()}
      footer={null}
      className={`auth-modal ${style.modalStyle}`}
    >
      <Tabs
        defaultActiveKey={'login'}
        type={'card'}
        activeKey={'login'}
        size={'large'}
        onChange={props.redirectToSignup}
      >
        <TabPane tab={`Login`} key={`login`}>
          <p className={style.social_login_text}>
            For faster login or register user your social account.
          </p>
          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={(event) => props.responseFacebook(event)}
            fields='name,email,picture'
            cssClass={style.social_btn_fb}
            render={(renderProps) => (
              <Button onClick={renderProps.onClick}>
                <FontAwesomeIcon icon={faGoogle} /> Login with Facebook
              </Button>
            )}
            icon={<FontAwesomeIcon icon={faFacebookSquare} />}
          /> */}
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <a
                href='/'
                className={`${style.social_btn_google} header_social_btn_google__1F-fi`}
                onClick={renderProps.onClick}
              >
                <FontAwesomeIcon icon={faGoogle} /> Login with Google
              </a>
            )}
            scope='profile email'
            buttonText='Login'
            onSuccess={(event) => props.responseGoogle(event)}
            onFailure={(event) => props.responseGoogleError(event)}
            // cookiePolicy={'single_host_origin'}
          />
          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={(event) => props.responseFacebook(event)}
            fields="name,email,picture"
            cssClass={style.social_btn_fb}
            render={renderProps => (
              <Button onClick={renderProps.onClick}>
                <FontAwesomeIcon icon={faGoogle} /> Login with Facebook
              </Button>
            )}
            icon={<FontAwesomeIcon icon={faFacebookSquare} />}
          />
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={renderProps => (
              <a href="/" className={style.social_btn_google} onClick={renderProps.onClick}>
                <FontAwesomeIcon icon={faGoogle} /> Login with Google
              </a>
            )}
            buttonText="Login"
            onSuccess={props.responseGoogle}
            onFailure={props.responseGoogle}
            cookiePolicy={'single_host_origin'}
          /> */}
          <Divider plain>or</Divider>
          {/* <LoginForm setVisiblity={()=>props.onCancel()} hideModal={()=>props.onCancel()} {...props}/> */}
          <Form
            className='login-form'
            {...layout}
            name='basic'
            initialValues={{
              remember: true,
            }}
            onFinish={(event) => props.onOk(event)}
            onFinishFailed={() => props.onFinishFailed()}
            autoComplete='off'
          >
            <Form.Item
              label={<span>Username or Email Address</span>}
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Please input your email',
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<span>Password</span>}
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              {...tailLayout}
              name='remember'
              valuePropName='checked'
              className={`remember-row remember-feild`}
            >
              <>
                <Checkbox>Remember me</Checkbox>
                <Button
                  style={{ float: 'right' }}
                  type='link'
                  onClick={(e) => {
                    history.push('/forgot-password');
                    props.onCancel();
                  }}
                >
                  Lost your password?
                </Button>
              </>
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                loading={props.loader}
                className={`${style.signUp_btn} header_signUp_btn__1FDkp`}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane
          tab={
            <MyAncor
              onClick={(event) => {
                history.push('/register');
              }}
            >
              Register
            </MyAncor>
          }
          key={`signup`}
        >
          Content of Tab Pane 2
        </TabPane>
      </Tabs>
    </Modal>
  );
}
