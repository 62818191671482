import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Row, Select, Button } from "antd";
import Container from "../../../widgets/container";
import countries from "../../../widgets/countries";
import { post, put, get ,header} from "../../../../api/rest";
// CSS
import style from './businessModal.module.sass';

const BusinessModal = props => {
  const { show, handleCancel, handleOk, businessInfo } = props;
  const [visible, setVisible] = useState(show);
  const [loading, setLoading] = useState(false);
  const [industries, setIndustires] = useState([]);
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    async function fetchData() {
      const result = await get('/businessInformation/business-industries');
      setIndustires(result);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  const onFinish = async (values) => {
    const body = {
      "businessName": values.name,
      "busIndustryCode": values.industry,
      "numOfEmployees": (businessInfo && businessInfo.numOfEmployees) ? businessInfo.numOfEmployees : 0,
      "streetAddr": values.address,
      "city": values.city,
      "state": values.state,
      "zip": values.zipcode,
      "country": values.country,
      "grossIncome": 0,
      "phoneNum": values.phoneNumber,
      "fax": values.fax,
      "website": values.website,
    }
    setLoading(true);
    let result;
    if (businessInfo && businessInfo.busInfo > 0) {
      result = await put(`/businessInformation/${businessInfo.busInfo}`, body);
    } else {
      result = await post(`/businessInformation`,header, body);
    }
    setLoading(false);
    if (result && result.busInfo) {
      handleOk(result);
    }
  };

  const onClickOk = () => {
    form.submit();
  }

  return (
    <Modal
      title="Business profile"
      visible={visible}
      onOk={onClickOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onClickOk}>
          Submit
        </Button>,
      ]}
    >
      <Container className={style.reg_container}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} className={`register-form ${style.from}`}>
          <Row className={style.row_item}>
            <Form.Item name="name" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.businessName : ''}>
              <Input placeholder={`Business Name`} className={style.input_item} />
            </Form.Item>
            <Form.Item name="phoneNumber" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.phoneNum : ''}>
              <Input placeholder={`Phone Number`} className={style.input_item} />
            </Form.Item>
          </Row>
          <Row className={style.row_item}>
            <Form.Item name="address" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.streetAddr : ''}>
              <Input placeholder={`Address`} className={style.input_item} />
            </Form.Item>
            <Form.Item name="fax" className={style.row_form_item} initialValue={businessInfo ? businessInfo.Fax : ''}>
              <Input placeholder={`Fax`} className={style.input_item} />
            </Form.Item>
          </Row>
          <Row className={style.row_item}>
            <Form.Item name="city" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.city : ''}>
              <Input placeholder={`City`} className={style.input_item} />
            </Form.Item>
            <Form.Item name="zipcode" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.zip : ''}>
              <Input placeholder={`Zip Code`} className={style.input_item} type={'number'} />
            </Form.Item>
          </Row>
          <Row className={style.row_item}>
            <Form.Item name="state" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.state : ''}>
              <Input placeholder={`State`} className={style.input_item} />
            </Form.Item>
            <Form.Item name="website" className={style.row_form_item} initialValue={businessInfo ? businessInfo.website : ''}>
              <Input placeholder={`Website`} className={style.input_item} tyle="url" />
            </Form.Item>
          </Row>
          <Row className={style.row_item}>
            <Form.Item name="country" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.country : ''}>
              <Select placeholder="Country" allowClear className={style.input_item}>
                {countries.map(c => <Select.Option value={c} key={c}>{c}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item name="industry" rules={[{ required: true }]} className={style.row_form_item} initialValue={businessInfo ? businessInfo.busIndustryCode : ''}>
              <Select placeholder="Industry" allowClear className={style.input_item}>
                {Array.isArray(industries) ? industries.length ? industries.map(i => <Select.Option value={i.BusIndustryCode} key={i.BusIndustryCode}>{i.BusinessIndustry}</Select.Option>) : '' : ''}
              </Select>
            </Form.Item>
          </Row>
        </Form>
      </Container>
    </Modal>
  )
}

export default BusinessModal;