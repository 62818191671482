import React from 'react';
import { Modal, Button, Form, Radio, Checkbox } from 'antd';
import { POP_UP_MODAL_HEADER_CONSTANTS } from '../constants';
import './questionpopup.scss';

const { QUESTION_HEADER } = POP_UP_MODAL_HEADER_CONSTANTS;
export function QuestionPopupModal(props) {
  let {
    title,
    questionSet,
    // description,
    // loading,
    // showCommunityList,
    // questionFormStatus
  } = props;
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const [form] = Form.useForm();
  form.setFieldsValue({
    answer: '',
  });

  return (
    <React.Fragment>
      <Modal
        title={''}
        centered
        visible={props.visible}
        onOk={(event) => props.onOk(event)}
        onCancel={() => props.onCancel(false)}
        okButtonProps={{ title: 'Submit' }}
        footer={null}
      >
        <div className='question-popup'>
          {title === `${QUESTION_HEADER} 1` ? (
            <>
              <div className='text-center'>
                <img src='https://equiliberty-storage.s3.us-east-2.amazonaws.com/doOrNotDo.gif' />
              </div>
              <Form {...layout} onFinish={(event) => props.onOk(event)}>
                {questionSet.length
                  ? questionSet.map((value, index) => {
                      return (
                        <Form.Item
                          name='radio-group'
                          name='answer'
                          label={value.question}
                          key={`${value.id}-${index}`}
                        >
                          <Checkbox.Group options={value.options} />
                          {/* <Radio.Group>
                                            {
                                                value.options.length ? value.options.map((data, id) => {
                                                    return (
                                                        <Radio value={data} style={radioStyle} key={`${index}-${id}`}>{data}</Radio>
                                                    )
                                                }) : ''
                                            }
                                        </Radio.Group> */}
                        </Form.Item>
                      );
                    })
                  : 'No question available Please Contact your admin'}
                <div className='text-center'>
                  <Form.Item>
                    <Button type='primary' htmlType='submit'>
                      {questionSet.length
                        ? questionSet[0].isLast
                          ? 'Submit'
                          : 'Next'
                        : 'Next'}
                    </Button>
                  </Form.Item>
                </div>
              </Form>{' '}
            </>
          ) : (
            <>
              <div className='text-center'>
                <img src='https://equiliberty-storage.s3.us-east-2.amazonaws.com/obamaMicDrop.gif' />
              </div>
              <Form {...layout} onFinish={(event) => props.onOk(event)}>
                {questionSet.length
                  ? questionSet.map((value, index) => {
                      return (
                        <Form.Item
                          name='radio-group'
                          name='answer'
                          label={value.question}
                          key={`${value.id}-${index}`}
                        >
                          <Radio.Group>
                            {value.options.length
                              ? value.options.map((data, id) => {
                                  return (
                                    <Radio
                                      value={data}
                                      style={radioStyle}
                                      key={`${index}-${id}`}
                                    >
                                      {data}
                                    </Radio>
                                  );
                                })
                              : ''}
                          </Radio.Group>
                        </Form.Item>
                      );
                    })
                  : 'No question available Please Contact your admin'}
                <div className='text-center'>
                  <Form.Item>
                    <Button type='primary' htmlType='submit'>
                      {questionSet.length
                        ? questionSet[0].isLast
                          ? 'Submit'
                          : 'Next'
                        : 'Next'}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}
