import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './mentormeetinglist.css';
import {
    message,
    Button,
    notification,
    Table,
    Space,
    Tooltip
} from 'antd';
import {
    header
} from '../../../api/rest';
import {
    getMentorReminderList,
    getMentorUserIdByEmail,
    // getMentorIdByEmail
} from '../../../redux/slices/mentorSlice';
// import {
//     getAnyUserProfile
// } from '../../../redux/slices/userProfileSlice';
import {
    CONSTANTS
} from '../../shared/constants';
import {
    socket
} from '../../service/socket';
// LOADERS
import {
    // SkeletonDashBoardPostLoader,
    Loader
} from '../../shared/Loader';

const {
    ERROR_MESSAGE
} = CONSTANTS
function MentorMeetingList(props) {
    const loginUserInfo = localStorage.getItem('userData');
    const payload = loginUserInfo ? JSON.parse(loginUserInfo) : '';
    const token = payload ? payload.token : '';
    const userId = payload ? payload.payload.id : '';
    const email = payload ? payload.payload.email : '';
    const role = payload ? payload.payload.role : '';
    const showMentor = role ? role === 'mentor' ? true : false : false;
    // const [currentDate, setCurrentDate] = useState(new Date().toISOString());
    const [mentorReminder, setMentorReminder] = useState([]);
    // const [currentDay, setCurrentDay] = useState(30);
    // const [mentorId, setMentorId] = useState('');
    const date = new Date();
    const utcHours = date.setUTCHours(0, 0, 0, 0);
    const addOneDay = new Date(utcHours).toISOString();
    const currentDay = 30, mentorId = '';
    const [showPageLoader, setShowPageLoader] = useState(true);
    useEffect(() => {
        if (showMentor) {
            if (token) {
                // getMentorIdByEmailApi();
                getReminderListApi();
                // for joing calls vidoe room
                // socket.emit('call-join-room', userId);
                // receivbein calls
                socket.on('callreceiver', (data) => {
                    // getUserProfileData(data.senderId);
                    openNotification(data);
                });
            }
        } else {
            props.history.push('/');
            message.error('You cannot acess this page');
        }
    }, [])

    function getReminderListApi() {
        let d = new Date();
        d.setUTCHours(0, 0, 0, 0);
        // let modifyDate = d.setDate(d.getDate());
        // let dateWOZero = new Date(modifyDate).toISOString();
        let data = {
            id: userId,
            day: currentDay,
            // date: dateWOZero
            date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString()
        };
        header.Authorization = token;
        getMentorReminderList(data, header).then(res => {
            if (res.mentorTodayReminder) {
                setMentorReminder(res.mentorTodayReminder);
                getMentorUserIdByEmailApi();
            } else {
                setMentorReminder([]);
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })

    }

    // function getMentorIdByEmailApi() {
    //     header.Authorization = token;
    //     getMentorIdByEmail(email, header).then(res => {
    //         if (res.data) {
    //             setMentorId(res.data.id);
    //         }
    //         setShowPageLoader(false);
    //     }).catch(err => {
    //         message.error(err && err.message ? err.message : ERROR_MESSAGE);
    //         setShowPageLoader(false);
    //     })
    // }

    function getMentorUserIdByEmailApi() {
        header.Authorization = token;
        getMentorUserIdByEmail(email, header).then(res => {
            if (res.data) {
                socket.emit('call-join-room', res.data.id);
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    // function getUserProfileData(id) {
    //     header.Authorization = token;
    //     getAnyUserProfile(id, header).then(res => {
    //         //
    //         setShowPageLoader(false);
    //     }).catch(err => {
    //         message.error(err && err.message ? err.message : ERROR_MESSAGE);
    //         setShowPageLoader(false);
    //     })
    // }


    const columns = [
        {
            title: 'User name',
            dataIndex: 'userId',
            key: 'userId',
            render: userId => <span>{userId.firstName + ' ' + userId.lastName}</span>
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: {
                showTitle: false,
            },
            render: description => (
                <Tooltip placement="topLeft" title={description}>
                    {description}
                </Tooltip>
            ),

        },
        {
            title: 'Date and Time',
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: {
                showTitle: false,
            },
            render: start_date => (
                <Tooltip placement="topLeft" title={moment(start_date).format('MMM DD, YYYY hh:mm a')}>
                    {moment(start_date).format('MMM DD, YYYY hh:mm a')}
                </Tooltip>
            ),

            // render: start_date => <a>{moment(start_date).format('MMM DD, YYYY hh:mm a')}</a>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (

                <Space size="middle">
                    <Button type="primary" onClick={() => redirectToMessaging('text', record)} disabled={moment(record.start_date).format('MM/DD/YYYY') < moment(addOneDay).format('MM/DD/YYYY') ? true : false}>Text Chat</Button>
                    <Button color="#73d13d" onClick={() => redirectToMessaging('video', record)} disabled={moment(record.start_date).format('MM/DD/YYYY') < moment(addOneDay).format('MM/DD/YYYY') ? true : false}>Video Chat</Button>
                </Space>
            ),
        }
    ];


    useEffect(() => {
        if (mentorId) {
            getReminderListApi();
        }

    }, [mentorId])

    function redirectToMessaging(type, value) {
        let fullName = value.userId.firstName + ' ' + value.userId.lastName;
        props.history.push('/messaging',
            {
                data: {
                    senderId: userId,
                    recieverId: value.userId.id,
                    type: type,
                    fullName: fullName
                }
            }
        )
    }

    const close = () => {

    };

    const pickup = (data, key) => {
        notification.close(key);
        props.history.push('/messaging',
            {
                data: {
                    senderId: userId,
                    recieverId: data.receipentId === userId ? data.senderId : data.receipentId,
                    session: data.session,
                    token: data.token,
                    type: 'joinVideoCall'
                }
            }
        )
    };

    const openNotification = (data) => {
        const key = `open${Date.now()}`;
        const btn = (
            <>
                <Button className="hangup-call" type="primary" size="small" onClick={() => notification.close(key)}>Hangup</Button>
                <Button className="pickup-call" type="primary" size="small" onClick={() => pickup(data, key)}>Pickup</Button>
            </>
        );
        notification.open({
            message: 'Calling',
            description:
                `${data.callerName} is calling you`,
            duration: 0,
            btn,
            key,
            onClose: close
        });
    };

    return (
        <React.Fragment>
            <div className="container min-vh-100">
                {showPageLoader ? <Loader /> : ''}
                <div className="row mt-5">
                    <div className="col-md-12">
                        <Table dataSource={mentorReminder} columns={columns} scroll={{ x: true }} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default MentorMeetingList;
