import React from 'react';
import { Modal, Button } from 'antd';
// CONSTANT
import { POP_UP_MODAL_CONSTANTS, POP_UP_MODAL_HEADER_CONSTANTS } from '../constants';

// var {POP_UP_MODAL_HEADER_CONSTANTS,POP_UP_MODAL_CONSTANTS}=CONSTANTS;
var { UNFOLLOW_USER } = POP_UP_MODAL_HEADER_CONSTANTS;
var { FOLLOWER_FOLLOWING_MODAL } = POP_UP_MODAL_CONSTANTS;

export function ConfirmationPopUpModal(props) {
  let { title, description } = props;
  let previousModalStatus = title === UNFOLLOW_USER ? FOLLOWER_FOLLOWING_MODAL : ''
  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false, previousModalStatus)}
      okButtonProps={{ title: "Submit" }}
      footer={[
        <Button key="back" onClick={() => props.onCancel(false, previousModalStatus)} >
          No
                </Button>,
        <Button key="submit" type="primary" onClick={(event) => props.onOk(event)}>
          Yes
                </Button>,
      ]}
    >
      {description}
    </Modal>
  )
}
