import React from 'react';
import { Modal } from 'antd';

export function AddUpdateReminder(props) {
    let {
        title,
        // userVision,
        // values,
        mentorsList,
        modalObj
    } = props,
        {
            reminderDesc,
            reminderTitle,
            mentorId
        } = modalObj;
    return (
        <Modal
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            okButtonProps={{ title: "Submit" }}
            footer={null}>
            <form onSubmit={(event) => props.onOk(event)} autoComplete="off">
                <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" value={reminderTitle} name="reminderTitle" placeholder="Title" onChange={(event) => props.handleInput(event)} required />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea type="text" className="form-control" value={reminderDesc} name="reminderDesc" placeholder="Description" onChange={(event) => props.handleInput(event)} required />
                </div>
                <div className="form-group">
                    <label>Choose Mentor</label>
                    <select placeholder="Choose Mentor" className="form-control" name="mentorId" value={mentorId} onChange={(event) => props.handleInput(event)} required>
                        {mentorsList.length ? <option value="" disabled>select mentor</option> : ''}
                        {mentorsList.length ?
                            mentorsList.map(c =>
                                <option value={c._id} key={c._id}>{c.firstName+' '+ c.lastName}</option>) :
                            <option>No Mentor List</option>
                        }
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </Modal>
    )
}