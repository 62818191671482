import {
    apiGetMethod,
    apiPostMethod,
    apiDeleteMethod
    //  post, get, apiPostMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

let {
    GET_REMAINDER_LIST,
    GET_MENTORS_LIST,
    CREATE_REMINDER,
    DELETE_REMINDER, UPDATE_REMINDER
} = API_END_POINTS_CONSTANT;

export const getReminderList = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_REMAINDER_LIST}?userId=${data.userId}&currentDate=${data.currentDate}&days=${data.days}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getMentorsList = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_MENTORS_LIST}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};


export const createReminder = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CREATE_REMINDER, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateReminder = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(UPDATE_REMINDER, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const deleteReminder = (data, header) => {
    return new Promise((resolve, reject) => {
        apiDeleteMethod(`${DELETE_REMINDER}?reminderId=${data}`, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}