import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Layout, message, Form, Input, Col } from 'antd';
import Container from '../../widgets/container';
import style from './register.module.sass';
import { history } from '../../../redux/store';
import { header } from '../../../api/rest';
import {
  setLoading as running,
  resendVerication as resendVerification,
  logout,
} from '../../../redux/slices/authSlice';
import VerifyEmailImage from '../../../assets/icons/verifyEmail.svg';
// LOADER
import {
  // SkeletonDashBoardPostLoader,
  Loader,
} from '../../shared/Loader';
// CONSTANTS
import { CONSTANTS } from '../../shared/constants';
// CSS
import './register.scss';

const { ERROR_MESSAGE } = CONSTANTS;

const ResendVerifyEmail = (props) => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.user);
  const [loading, setLoading] = useState(session.loading);
  // const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const email = props.location.state ? props.location.state.data : '';

  useEffect(() => {
    if (
      loading &&
      !session.loading &&
      session &&
      session.data &&
      session.data.status
    ) {
      message.success(session.data.message);
      history.push('/user/profile/update', {
        data: 'register',
      });
    }
    setLoading(session.loading);
  }, [loading, session]);

  const resendEmail = () => {
    setIsLoading(true);
    resendVerification({ email }, header)
      .then((res) => {
        message.success(res.message);
        setIsLoading(false);
        dispatch(logout());
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setIsLoading(false);
      });
  };

  return (
    <Layout.Content className={style.block}>
      <Row className={`header-divider`} />
      <Container
        className={`auth-modal min-height-60vh ${style.reg_container}`}
      >
        <Row justify={'center'}>
          <h2>Verify your email</h2>
        </Row>

        <Row justify={'center'}>
          <Col style={{ textAlign: 'center' }}>
            <h6>
              You will need to verify your email to complete registration.
            </h6>
            <br />
            <br />
            <img
              src={VerifyEmailImage}
              alt='Verify your email'
              width='250'
              style={{ textAlign: 'center', margin: 'auto' }}
            />
            <br />
            <p
              style={{
                maxWidth: 600,
                marginTop: '2em',
                marginBottom: '2em',
                display: 'inline-block',
              }}
            >
              An email has been sent to <b>{email}</b> with a link to verify
              your account. If you have not received the email after a few
              minutes, please check your spam folder
            </p>
          </Col>
        </Row>

        <Row justify={'center'}>
          <div className='back-home-btns'>
            <Button
              type='primary'
              className={style.submit_btn}
              onClick={() => history.push('/')}
            >
              Back to home
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={isLoading}
              className={style.submit_btn}
              onClick={resendEmail}
            >
              Resend Email
            </Button>
          </div>
        </Row>
        <Row justify={'center'}></Row>
      </Container>
    </Layout.Content>
  );
};

export default ResendVerifyEmail;
