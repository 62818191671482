import React, { useState } from 'react';
import { Layout, Row, Col, message } from 'antd';
import { header } from '../../../api/rest';
import { useDispatch } from 'react-redux';
import { history } from '../../../redux/store';
// import style from "./footer.module.sass"
import {
  setUserData,
  setLoading as running,
  loginApi,
  googleLoginApi,
  facebookLoginApi,
  searchCommunityByZipcode,
  saveUserPendingCommunity,
} from '../../../redux/slices/authSlice';
import { getTokenCount } from '../../../redux/slices/walletSlice';
import { userUpdateProfile } from '../../../redux/slices/userProfileSlice';
import style from './footer.scss';
import MyAncor from '../global/ancor';
import elLogo from '../../../assets/images/EL-Logo.png';
import footer_logo from '../../../assets/images/footer-logo.png';
import icon_facebook from '../../../assets/images/facebook.png';
import icon_insta from '../../../assets/images/insta.png';
// CONSTANTS
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../shared/constants';
// MOMENT
import moment from 'moment';
// POPUPMODALS
import PopUpModal from '../../shared/popupmodal';
// SOUND PLAY COMPONENT
import { TokenEarnSound } from '../../shared/soundeffect';

const { Footer } = Layout;
export default () => {
  const dispatch = useDispatch();
  const storageData = localStorage.getItem('userData');
  const { token } = storageData ? JSON.parse(storageData) : '';
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  // const [visible, setVisiblity] = useState(false);
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState('');
  const [socialMediaResponse, setSocialMediaResponse] = useState({});
  const [playSound, setPlaySound] = useState(false);

  const { LOGIN_MODAL } = POP_UP_MODAL_CONSTANTS;
  const { ERROR_MESSAGE, NO_COMMUNITY_MESSAGE } = CONSTANTS;
  const { ZIP_CODE_POP_UP_MODAL, MESSAGE_POP_UP_MODAL } =
    POP_UP_MODAL_CONSTANTS;
  const { ZIPCODE_MODAL_HEADING, ERROR_HEADER } = POP_UP_MODAL_HEADER_CONSTANTS;

  // show popup modal
  function showPopupModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }
  // close popupmodal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
  }
  // trigger on submit popup modal
  function onSubmitPopUpModal(event, data) {
    // event.preventDefault();
    if (popUpModal.type === LOGIN_MODAL) {
      const body = {
        email: event.email,
        password: event.password,
      };
      submitLoginData(body);
      setLoader(true);
    }
    if (popUpModal.type === ZIP_CODE_POP_UP_MODAL) {
      checkZipCodeValid(event);
    }
    if (popUpModal.modalHeader === ERROR_HEADER) {
      closePopupModal();
      history.push('/');
    }
  }
  // calling login api
  function submitLoginData(data) {
    loginApi(data, header)
      .then((res) => {
        // saving data in localstorage
        localStorage.setItem('userData', JSON.stringify(res));
        localStorage.setItem('x-headers', data.password);
        // showing success message
        message.success(res.message);
        // saving data in redux
        dispatch(setUserData(res.payload));
        setPlaySound(true);
        setTimeout(() => {
          if (res.payload.role === 'mentor') {
            // setting loader false
            setLoader(false);
            // calling close popupmodal function
            closePopupModal();
            // redirecting mentor dashboard
            history.push('/mentor/dashboard');
          } else if (res.payload.role === 'admin') {
            // calling close popupmodal function
            closePopupModal();
            // redirecting home page
            history.push('/admin/dashboard');
          } else {
            // calling wallet token api
            getWallettokenCountApi(res.payload.id, res.token, 'normal', res);
          }
        }, 1500);
      })
      .catch((err) => {
        // showing error message
        message.error(err ? err.message : ERROR_MESSAGE);
        // setting loader false
        setLoader(false);
        dispatch(running(false));
      });
  }
  // calling wallet token count api
  function getWallettokenCountApi(id, token, type, resp) {

    header.Authorization = token;
    getTokenCount(id, header)
      .then((res) => {
        if (res.ledgesData) {
          let count = 0;
          for (let i = 0; i < res.ledgesData.length; i++) {
            count = count + res.ledgesData[i].tokenCount;
          }
          updateLocalStorage('badgeToken', count);
        }
        // calling close popupmodal function
        closePopupModal();
        setPlaySound(false);
        if (type === 'socialMedia') {
          showPopupModal(ZIP_CODE_POP_UP_MODAL, ZIPCODE_MODAL_HEADING);
        } else if (resp.payload.zipcode && !resp.payload.communityId) {
          message.info('Please Choose Community');
          history.push('/register/choose-community');
        } else {
          history.push('/dashboard');
        }
        dispatch(running(false));
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
        setPlaySound(false);
      });
  }
  // updating local storage
  function updateLocalStorage(inputName, value) {
    let data = localStorage.getItem('userData');
    if (data) {
      var persons = JSON.parse(data);
      persons.payload[inputName] = value;
      localStorage.setItem('userData', JSON.stringify(persons));
    }
  }
  // redirect to signup
  function redirectToSignup(key) {
    if (key === 'signup') {
      // calling close popupmodal function
      closePopupModal();
      history.push('/register');
    } else {
      return null;
    }
  }
  // calling google reponse api
  const responseGoogle = async (response) => {
    let data = { tokenId: response.tokenId };
    googleLoginApi(data, header)
      .then((response) => {
        if (response && response) {
          message.success(response.message);
          localStorage.setItem(
            'x-headers',
            `${response.payload.email}my supersceretpasswordisnone`
          );
          setSocialMediaResponse(response);
          if (response.oldUser) {
            localStorage.setItem('userData', JSON.stringify(response));
            dispatch(setUserData(response.payload));
            history.push('/dashboard');
            closePopupModal();
            // setLoader(false);
          } else {
            getWallettokenCountApi(
              response.payload.id,
              response.token,
              'socialMedia',
              response
            );
          }
        }
      })
      .catch((err) => {

      });
  };
  // calling facebook reponse api
  const responseFacebook = async (response) => {
    if (response.accessToken) {
      let data = { accessToken: response.accessToken, userId: response.userID };
      facebookLoginApi(data, header)
        .then((response) => {
          if (response) {
            message.success(response.message);
            localStorage.setItem(
              'x-headers',
              `${response.payload.email}my supersceretpasswordisnone`
            );
            setSocialMediaResponse(response);
            if (response.oldUser) {
              localStorage.setItem('userData', JSON.stringify(response));
              dispatch(setUserData(response.payload));
              history.push('/dashboard');
              closePopupModal();
              // setLoader(false);
            } else {
              getWallettokenCountApi(
                response.payload.id,
                response.token,
                'socialMedia',
                response
              );
            }
          }
        })
        .catch((err) => {

        });
    } else {

    }
  };
  const responseGoogleError = async (error) => {

  };

  const onFinish = (values) => {
    // dispatch(running(true));
    const body = {
      email: values.email,
      password: values.password,
    };
    // dispatch(login(body));
    submitLoginData(body);
  };

  // Check zipcode is valid or not if valid update zipcode with user profile
  function checkZipCodeValid(data) {

    searchCommunityByZipcode(data, header)
      .then((res) => {
        closePopupModal();
        if (res.data.length) {
          localStorage.setItem('userData', JSON.stringify(socialMediaResponse));
          dispatch(setUserData(socialMediaResponse.payload));
          setLoader(false);
          userUpdateProfileAPI(data);
          redirectToProfileUpdatePage();
        } else {
          showPopupModal(MESSAGE_POP_UP_MODAL, ERROR_HEADER);
          setDescription(NO_COMMUNITY_MESSAGE);
          saveUserPendingCommunityAPI(data);
        }
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  // save pending community data to data base
  function saveUserPendingCommunityAPI(value) {
    let data = {
      firstName: socialMediaResponse.payload.firstName,
      lastName: socialMediaResponse.payload.lastName
        ? socialMediaResponse.payload.lastName
        : '',
      email: socialMediaResponse.payload.email,
      zipcode: value.zipCode,
    };
    saveUserPendingCommunity(data, header)
      .then((res) => {

      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }
  // Call update user profile api to save zipcode which user entered during zipcode modal
  function userUpdateProfileAPI(value) {
    header.Authorization = socialMediaResponse.token;
    let data = {
      zipcode: value.zipCode,
      id: socialMediaResponse.payload.id,
    };
    userUpdateProfile(data, header)
      .then((res) => {

        updateLocalStorage('zipcode', value.zipCode);
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  function redirectToProfileUpdatePage() {
    history.push('/user/profile/update', {
      data: 'register',
    });
  }

  const onFinishFailed = (errorInfo) => {};
  let loginModalFunctionProps = {
      redirectToSignup: redirectToSignup,
      responseFacebook: responseFacebook,
      responseGoogle: responseGoogle,
      onFinish: onFinish,
      onFinishFailed: onFinishFailed,
      responseGoogleError: responseGoogleError,
    },
    loginModalVariableProps = {
      // style,
      loader,
    };

  return (
    <>
      <footer className='new_footer'>
        <div className='container'>
          <div className='row footer'>
            <div className='col-sm-6 col-md-6 col-lg-3 mb-4'>
              <img src={footer_logo} alt='logo' className='img-fluid' />
            </div>
            <div className='col-sm-6 col-md-6 col-lg-4 mb-4'>
              <div className='row'>
                <div className='col-sm-6'>
                  <h6>LINKS</h6>
                  <ul>
                    <li>
                      <MyAncor
                        className={style.footerLink}
                        type='link'
                        href='/'
                      >
                        Home
                      </MyAncor>
                    </li>
                    <li>
                      <MyAncor
                        className={style.footerLink}
                        type='link'
                        href='/contact'
                      >
                        Contact us
                      </MyAncor>
                    </li>
                    <li>
                      <MyAncor
                        className={style.footerLink}
                        type='link'
                        href='/about'
                      >
                        About
                      </MyAncor>
                    </li>
                    {/* <li>
                      <MyAncor
                        className={style.footerLink}
                        type='link'
                        href='/faqs'
                      >
                        FAQ
                      </MyAncor>
                    </li> */}
                    <li>
                      <MyAncor
                        className={style.footerLink}
                        type='link'
                        href='/mentor/register'
                      >
                        Become a Mentor
                      </MyAncor>
                    </li>
                  </ul>
                </div>
                <div className='col-sm-6'>
                  <h6>LEGAL</h6>
                  <ul>
                    <li>
                      <MyAncor
                        className={style.footerLink}
                        type='link'
                        href='/privacy'
                      >
                        Privacy Policy
                      </MyAncor>
                    </li>
                    <li>
                      <a href='#'>Terms of Use</a>
                    </li>
                    <li>
                      <a href='#'>Cookie Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-5'>
              <p className='footer-text'>About</p>
              <p className='footer-text'>
                {' '}
                MISSION: To liberate the skillsets, talents, and ideas of
                creatives and makers to grow sustaining, abundant, and impactful
                businesses that create wealth for their families and
                communities.{' '}
              </p>
              <p className='footer-text'>
                {' '}
                VISION: To end poverty bottom-up by showing communities of
                creators and makers how to use their existing resources and
                talents to grow into thriving businesses, create jobs, develop
                strong local economies and contribute to global prosperity.{' '}
              </p>
            </div>
          </div>
          <div className='copyright'>
            <div className='row'>
              <div className='col-sm-6'>
                <p className={style.copyrightText}>
                  Copyright Equiliberty © {moment().format('YYYY')}. All Rights
                  Reserved
                </p>
              </div>
              <div className='col-sm-6 social-info'>
                <ul>
                  <li>
                    <a href='https://www.instagram.com/find_equiliberty/'>
                      <img src={icon_insta} alt='insta' />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.facebook.com/FindEquiliberty/'>
                      <img src={icon_facebook} alt='facebook' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <Footer className={style.site_footer2}>
        {
        playSound ? <TokenEarnSound /> : ''
      }
      {
        popUpModal.visible ?
          <PopUpModal
            title={popUpModal.modalHeader}
            visible={popUpModal.visible}
            onCancel={closePopupModal}
            onOk={onSubmitPopUpModal}
            type={popUpModal.type}
            description={description}
            {...loginModalFunctionProps}
            {...loginModalVariableProps}
          /> : ''
      }
      <Row className={style.footerWrapper}>
        <Col className={style.elLogoWrapper} xs={12} sm={12} md={12} lg={6} xl={6} align={'middle'}>
          <div className="footer-logo-img">
            <img src={elLogo} alt="" />
            <p>{`Main Headquarters\nHouston, TX`}</p>
          </div>
        </Col>
        <Col className={style.footerLinkWrapper} xs={12} sm={12} md={12} lg={6} xl={6} align={'middle'}>
          <div className="footer-links">
            <p className={style.linkTitle}>Links</p>
            <MyAncor className={style.footerLink} type="link" href="/">Home</MyAncor>
            {token ? '' : <MyAncor className={style.footerLink} type="link" href="" onClick={() => { showPopupModal(LOGIN_MODAL, '') }}>Login</MyAncor>
              //  <MyAncor className={style.footerLink} type="link" onClick={() =>{showPopupModal(LOGIN_MODAL,'')}}>Login</MyAncor>
            }
            <MyAncor className={style.footerLink} type="link" href="/contact">Contact us</MyAncor>
            <MyAncor className={style.footerLink} type="link" href="/privacy">Privacy Policy</MyAncor>
            <MyAncor className={style.footerLink} type="link" href="/about">About</MyAncor>
          </div>
        </Col>
        <Col className={style.footerAboutWrapper} xs={24} sm={24} md={24} lg={12} xl={12} align={'middle'}>
          <div className="footer-content">
            <p className={style.footerAboutTitle} >About</p>
            <p>{
              `
                  MISSION: To liberate the skillsets, talents, and ideas of creatives and makers to grow sustaining,
                  abundant, and impactful businesses that create wealth for their families and communities.
                `
            }</p>
            <p>{`
                  VISION: To end poverty bottom-up by showing communities of creators and makers how to use their existing resources and talents to grow into thriving businesses,
                  create jobs, develop strong local economies and contribute to global prosperity.
                `}</p>
          </div>
        </Col>
      </Row>
      <div className={style.footerDivider} />
      <div className="copyeight-content">
        <p className={style.copyrightText}>Copyright © Equiliberty.com {moment().format('YYYY')}. All Rights Reserved.</p>
      </div>
    </Footer> */}
    </>
  );
};
