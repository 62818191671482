import React from 'react';
import {
    Modal, Form, Divider
    // ,Button 
} from 'antd';
import './socialShareModal.css';
// Constants
import { POP_UP_MODAL_HEADER_CONSTANTS } from '../constants';

var {
    UPLOAD_BANNER_IMAGE,
    UPLOAD_USER_IMAGE,
    UPLOAD_VISION_IMAGE,
    HOME_ABOUT_US_HEADER
} = POP_UP_MODAL_HEADER_CONSTANTS;

export function UpdateEditProfileUpModal(props) {
    let {
        title,
        loadingImagingTag,
        // updateProfileObj,
        visionImageType,
        showLoader
    } = props,
        imageName = title === UPLOAD_BANNER_IMAGE ? 'bannerImage' : title === UPLOAD_USER_IMAGE ? 'userPhoto' : 'visionImage',
        fileUploadText = title === UPLOAD_BANNER_IMAGE || title === UPLOAD_USER_IMAGE ? 'Upload Image' : 'Upload Video';
    // let currentImageType;
    if (loadingImagingTag && loadingImagingTag.showLoader) {
        // currentImageType = props.imageName === "userPhoto" ? loadingImagingTag.showLoader.userPhoto : props.imageName === "bannerImage" ? loadingImagingTag.showLoader.bannerImage : loadingImagingTag.showLoader.visionImage
    }
    return (
        <Modal
            title={title}
            visible={props.visible}
            onOk={props.onOk}
            confirmLoading={showLoader[imageName]}
            onCancel={props.onCancel}>
            <div className="update-vision-video">
                <Form.Item
                    label={title}
                    name={imageName}>
                    <input className="form-control user-image-popup upload-video" type="file" name={imageName} accept={title === UPLOAD_VISION_IMAGE || title === HOME_ABOUT_US_HEADER ? "video/*" : "image/*"} onChange={(event) => { props.holdSelectedImage(event) }} disabled={visionImageType === "text" ? true : false} />
                    <p>{fileUploadText}</p>
                </Form.Item>
                {
                    imageName === 'visionImage' ? <React.Fragment>
                        <Divider>or</Divider>
                        <div className="url-feild">
                            <label>Paste Youtube url</label>
                            <Form.Item
                                name={imageName}>
                                <input type="text" value={imageName} name={imageName} className="ant-input" onChange={(event) => { props.holdSelectedImage(event) }} disabled={visionImageType === "object" ? true : false} />
                            </Form.Item>
                        </div>
                    </React.Fragment> : ''
                }
            </div>
        </Modal>
    )
}