import React, { useEffect, useState } from 'react';
import { message } from 'antd';
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
// Headers
import { header } from '../../../api/rest';
// API
import {
    addEnquiry
} from '../../../redux/slices/userEnquirySlice';
// CONSTANTS
import {
    CONSTANTS,
    REGEX_CONSTANTS
    //   POP_UP_MODAL_HEADER_CONSTANTS,
    //    POP_UP_MODAL_CONSTANTS, 
} from '../../shared/constants';
// POPUPMODAL
// import PopUpModal from '../../shared/popupmodal';
// CSS
import './enquiryForm.css';


// var {
//     CONFIRMATION_HEADER,
//     UPDATE_ENQUIRY,
//     ADD_ENQUIRY
// } = POP_UP_MODAL_HEADER_CONSTANTS;
// var {
//     CONFIRMATION_POPUP_MODAL,
//     ADD_EDIT_CONTACTS_MODAL
// } = POP_UP_MODAL_CONSTANTS;

var {
    ERROR_MESSAGE
} = CONSTANTS;

var { ACCEPT_NUMBER } = REGEX_CONSTANTS;

function EnquiryForm(props) {
    const [enquiryFormObj, setEnquiryFormObj] = useState({
        title: '',
        email: '',
        phoneNumber: '',
        queryMessage: ''
    });
    const [isLoading, showIsLoading] = useState(false);
    const storageData = localStorage.getItem("userData");
    const { id } = storageData ? JSON.parse(storageData).payload : '';
    const { token } = storageData ? JSON.parse(storageData) : '';

    useEffect(() => {
        if (storageData) {
            let { email } = JSON.parse(storageData).payload;
            setEnquiryFormObj(prevEnquiryFormObj => {
                return {
                    ...prevEnquiryFormObj, email: email
                }
            })

        } else {
            props.history.push('/')
        }
    }, []);


    // onchange of all input handle Input function is called saves all data in state
    function handleInput(event) {
        let { name, value } = event.target;
        if (name === 'phoneNumber') {
            let data = value ? ACCEPT_NUMBER.test(value) ? value : '' : '';
            let isFieldBlank = data === '' ? true : false;
            let isLengthValidated = data ? data.length <= 10 ? true : false : false;
            if (isFieldBlank) {
                setEnquiryFormObj(prevEnquiryFormObj => {
                    return {
                        ...prevEnquiryFormObj, [name]: data
                    }
                })
            } else {
                if (isLengthValidated) {
                    setEnquiryFormObj(prevEnquiryFormObj => {
                        return {
                            ...prevEnquiryFormObj, [name]: data
                        }
                    })
                } else {
                    message.error('Phone number cannot exceed 10 digit')
                }
            }
        } else {
            setEnquiryFormObj(prevEnquiryFormObj => {
                return {
                    ...prevEnquiryFormObj, [name]: value
                }
            })
        }
    }

    // check number is invalid or not
    function checkNumberIsValid() {
        let { phoneNumber } = enquiryFormObj;
        if (phoneNumber) {
            if (phoneNumber.length <= 10) {
                return true
            } else {
                return false
            }
        } else {
            message.error('Phone number Field is required');
        }
    }

    // on submitting on form handle submit call and call add enquiry api
    function handleSubmit(event) {
        event.preventDefault();
        showIsLoading(true);
        let status = checkNumberIsValid();
        if (status) {
            addEnquiryApi();
        } else {
            showIsLoading(false);
        }

    }
    // calling add enguiry api
    function addEnquiryApi() {
        let data = {
            userId: id,
            title: enquiryFormObj.title,
            email: enquiryFormObj.email,
            phoneNumber: enquiryFormObj.phoneNumber,
            queryMessage: enquiryFormObj.queryMessage
        };
        header.Authorization = token;
        addEnquiry(data, header).then(res => {
            message.success(res.message);
            showIsLoading(false);
            props.history.push('/dashboard')
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            showIsLoading(false);
        })
    }

    let {
        title,
        email,
        phoneNumber,
        queryMessage
    } = enquiryFormObj;

    React.useEffect(() => {
        document.title = "Enquiry Form";
    })
    
    return (
        <React.Fragment>
            <div className="container mt-2 mb-2 ">
                <div className="row justify-content-center enquiry-section">
                    <div className="col-md-9 col-12">
                        <h3 className="text-center mt-4 mb-4 text-uppercase"> Contact us</h3>
                        <form onSubmit={handleSubmit} autoComplete="off" className="contact-enquiry-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label >Title</label>
                                        <input type="text" className="form-control" value={title} name="title" onChange={(event) => handleInput(event)} required />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email address</label>
                                        <input type="email" className="form-control" value={email} name="email" onChange={(event) => handleInput(event)} required />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input type="tel" className="form-control" value={phoneNumber} name="phoneNumber" onChange={(event) => handleInput(event)} required />
                            </div>
                            <div className="form-group">
                                <label>Message</label>
                                <textarea value={queryMessage} className="form-control enquiry-desc" name="queryMessage" onChange={(event) => handleInput(event)} rows="3" required />
                            </div>
                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-primary text-center"> {
                                    isLoading ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : ''
                                }  Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EnquiryForm;