import React from 'react';
import './sendTocken.scss'
import {
    Modal,
    Form,
    Input,
    Button
} from 'antd';
import {REGEX_CONSTANTS}from '../constants';

const {ACCEPT_NUMBER}=REGEX_CONSTANTS;

export function SendTokenModal(props) {
    let {
        title
    } = props;

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
   
    return (
        <Modal
            className="social-media-share-modal"
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            footer={null}>
            <div className="send-tockenomodal">
                <Form
                    {...layout}
                    onFinish={(event) => props.onOk(event)}>
                    <Form.Item
                         label={<span>Token Amount</span>}
                         name="amount"
                         rules={[
                             {
                                 required: true,
                                 pattern: ACCEPT_NUMBER,
                                 validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please input your token amount'),
                             },
                         ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item >
                            <Button type="primary" htmlType="submit" >
                                Pay
                            </Button>
                        </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}
