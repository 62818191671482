import React, { useEffect, useState } from 'react';
import { message, Divider, Avatar, Button } from 'antd';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
// import MessageIcon from '../../../assets/images/icon-message.png';
// import TockenIcon from '../../../assets/images/token-icon.png';
// import MeetingImg1 from '../../../assets/images/meeting-img1.jpg';
// import MeetingImg2 from '../../../assets/images/meeting-img2.jpg';
// import IconSetting from '../../../assets/images/icon-setting.png';
// import ScreenIcon from '../../../assets/images/screen-icon.png';
// import HideScreenIcon from '../../../assets/images/hide-screen-icon1.png';
// import DefaultImg from '../../../assets/images/default-img.png';
import MicIcon from '../../../assets/images/mic-icon.png';
import VideoIcon from '../../../assets/images/video-icon.png';
import CutIcon from '../../../assets/images/cut-icon.png';
import HideVideoIcon from '../../../assets/images/hide-video-icon1.png';
import UnmuteMicIcon from '../../../assets/images/unmute-mic-icon1.png';
import { SendOutlined } from '@ant-design/icons';
// SUBHEADER
import SubHeader from '../../widgets/subheader';
import './messaging.scss';
import {
  // listPreviousUserChat,
  // startNewChat,
  // replyToChat,
  getTokBoxSession,
} from '../../../redux/slices/messageSlice';

import { header } from '../../../api/rest';
import {
  createRoomForMessage,
  messageOnTheBasisRoomId,
  getPreviousChat,
  getTokSession,
  deleteTokBoxSession,
} from '../../../redux/slices/messageSlice';
// import {
//   getMentorUserIdByEmail
// } from '../../../redux/slices/mentorSlice';
// LOADERS
import {
  // SkeletonDashBoardPostLoader,
  Loader,
} from '../../shared/Loader';

// CONSTANTS
import { CONSTANTS } from '../../shared/constants';
// SOCKET
import { socket, getChatMessage, callDisconnect } from '../../service/socket';
// MOMENT
import moment from 'moment';

var { ERROR_MESSAGE, ERROR_IMAGE_URL } = CONSTANTS;

export const Messaging = (props) => {
  // const [name, setName] = useState('');
  // const [room, setRoom] = useState('');
  // const [users, setUsers] = useState('');
  // const [message1, setMessage] = useState('');
  // const [messages, setMessages] = useState([]);
  // const [flag, setFlag] = useState(0);
  // const ENDPOINT = process.env.REACT_APP_BASE_URL;
  const storageData = localStorage.getItem('userData');
  const {
    id,
    // email,
    firstName,
    lastName,
  } = storageData ? JSON.parse(storageData).payload : '';
  const { token } = storageData ? JSON.parse(storageData) : '';
  const { role } = storageData ? JSON.parse(storageData).payload : '';
  const [previousChat, setPreviousChat] = useState([]);
  const [privateMessage, setPrivateMessage] = useState('');
  const [roomId, setRoomId] = useState('');
  const [paginationObj, setPaginationObj] = useState({
    page_size: 1,
    page_limit: 10,
  });
  const [totalPage, setTotalPage] = useState('');
  // const [istyping, setIstyping] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const [previousUserChat, setPreviousUserChat] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [showUserChat, setShowUserChat] = useState(false);
  const [isMessageSubmit, setIsMessageSubmit] = useState(false);
  const [showVideoChat, setShowVideoChat] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [sessionToken, setSessionToken] = useState('');
  const [userData, setUserData] = useState({
    senderId: '',
    recieverId: '',
  });
  const [videoCallStatus, SetVideoCallStatus] = React.useState({
    error: null,
    connection: 'Connecting',
    publishVideo: true,
  });
  const [publisherStatus, setPublisherStatus] = useState({
    error: null,
    audio: true,
    video: true,
    videoSource: 'camera',
  });
  const [subscriberStatus, setSubscriberStatus] = useState({
    error: null,
    audio: true,
    video: true,
  });
  const [userChatHeaderInfo, setUserChatHeaderInfo] = useState({
    userPhoto: '',
    fullName: '',
  });
  // const [mentorId, setMentorId] = useState('');
  const [joinVideo, setJoinVideo] = useState(false);
  const messagingSectionRef = React.useRef('');
  const messagingBoxRef = React.useRef('');
  const messagesRef = React.createRef('');
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [videoCallDetails, setVideoCallDetails] = useState({
    connectionId: '',
    creationTime: '',
    disConnectionTime: '',
    isVideoCallConnected: false,
  });

  const [morePreviousChat, setMorePreviousChat] = useState(false);
  const [timerValue, setTimerValue] = useState('');
  const [isMessageBoxClicked, setIsMessageBoxClicked] = useState(false);

  //
  // const senderId = props.location.state ? props.location.state.data.senderId : '';
  // const recieverId = props.location.state ? props.location.state.data.recieverId : '';
  // const recipientName = props.location.state ? props.location.state.data.senderName : '';
  // const room = "asedews"

  useEffect(() => {
    if (token) {
      if (props.location.state) {
        if (props.location.state.data.senderId) {
          // newChatApi();
          setUserData((prevUserData) => {
            return {
              ...prevUserData,
              senderId: props.location.state.data.senderId,
              recieverId: props.location.state.data.recieverId,
            };
          });
          // if(props.location.state.data.senderId === id){
          //   setShowSenderDetail(true);
          // }else{
          //   setShowSenderDetail(false);
          // }

          if (props.location.state.data.type === 'joinVideoCall') {
            setUserData((prevUserData) => {
              return {
                ...prevUserData,
                senderId: props.location.state.data.senderId,
                recieverId: props.location.state.data.recieverId,
              };
            });
            setSessionId(props.location.state.data.session);
            setSessionToken(props.location.state.data.token);
            setJoinVideo(true);
            setShowVideoChat(false);
            setShowChat(false);
          } else {
            if (props.location.state.data.type === 'video') {
              if (role === 'mentor') {
                // getMentorIdByEmailApi();
                // setMentorId(props.location.state.data.senderId)
                // setUserData(prevUserData => {
                //   return {
                //     ...prevUserData,
                //     senderId: props.location.state.data.senderId
                //   }
                // })
                setShowVideoChat(true);
                setJoinVideo(true);
                socket.emit(
                  'call-join-room',
                  props.location.state.data.senderId
                );
                setShowPageLoader(false);
                // setShowVideoChat(false);
              } else {
                // socket.emit('call-join-room', id);
                setShowVideoChat(true);
                setJoinVideo(true);
                setShowPageLoader(false);
              }
              // socket.on('callreceiver', (data) => {
              //
              // });
            } else {
              setShowVideoChat(false);
              setShowChat(true);
              setShowUserChat(false);
              setUserChatHeaderInfo((prevUserChatHeaderInfo) => {
                return {
                  ...prevUserChatHeaderInfo,
                  userPhoto: props.location.state.data.userPhoto,
                  fullName: props.location.state.data.fullName,
                };
              });
            }
          }
          // getPreviousChatApi();
        } else {
          setShowChat(false);
          setShowUserChat(true);
          setShowVideoChat(false);
        }
      } else {
        setShowChat(false);
        setShowUserChat(true);
        setShowVideoChat(false);
      }
    } else {
      props.history.push('/');
    }
    // socket.emit('send-message');
  }, []);

  useEffect(() => {
    if (showVideoChat) {
      // getSessionId();
      getSessionOfUser();
    }
  }, [showVideoChat]);

  useEffect(() => {
    if (showChat) {
      fetchRoomId();
    } else {
      if (showVideoChat) {
      } else {
        getPreviousUserChats();
      }
    }
  }, [showChat]);

  useEffect(() => {
    if (joinVideo) {
      fetchRoomId();
    }
  }, [joinVideo]);
  // fetch room id to get message list
  function fetchRoomId() {
    header.Authorization = token;
    let data = {
      senderId: userData.senderId,
      recipientId: userData.recieverId,
    };
    createRoomForMessage(data, header)
      .then((res) => {
        if (res.data) {

          socket.emit('join-room', { roomId: res.data.id });
          setRoomId(res.data.id);
          messageSeenSocket(userData.senderId, res.data.id);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  useEffect(() => {
    if (isMessageSubmit) {
    } else {
      if (roomId) {
        getMessageList();
      }
    }
  }, [roomId]);

  // get message list on the basis of room id
  function getMessageList() {
    let data = {
      roomId: roomId,
      pageNumber: paginationObj.page_size,
      limit: paginationObj.page_limit,
    };
    header.Authorization = token;
    messageOnTheBasisRoomId(data, header)
      .then((res) => {
        setShowPageLoader(false);
        const sortedChats = res.data.messages.length
          ? res.data.messages.sort((a, b) => a.createdAt - b.createdAt)
          : [];
        const messageList = previousChat.length
          ? sortedChats.concat(previousChat)
          : sortedChats;
        let list = addDateKey(messageList);
        if (previousChat.length) {
          setPreviousChat(list);
        } else {
          setPreviousChat(list);
          messagingBoxRef.current &&
            messagingBoxRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
        }

        let page = Math.ceil(res.data.totalCount / paginationObj.page_limit);
        setTotalPage(page);
        setIsTop(false);
        setIsMessageBoxClicked(false);
        // if (res.data) {
        //   // let list = addDateKey(res.data.messages);
        //   if (isTop) {
        //     // let array = addHorizontalDateBarInMessageList(res.data.messages);
        //     // let data = previousChat.reverse().concat(array).reverse();
        //     // let data = previousChat.unshift(res.data.messages);
        //     setPreviousChat(list);
        //   } else {
        //     let array = addHorizontalDateBarInMessageList(res.data.messages);
        //     setPreviousChat(array);
        //     messagingBoxRef.current && messagingBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        //   }
        //   let page = Math.ceil(res.data.totalCount / paginationObj.page_limit);
        //   setTotalPage(page);
        //   setIsTop(false);
        // } else {
        //   setPreviousChat([]);
        //   setIsTop(false);
        // }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function addDateKey(data) {
    // showing date divider in message list
    let dateArr = [],
      finalArr = [],
      resultArr = [];
    for (let m = 0; m < data.length; m++) {
      data[m].isSameDate = false;
      finalArr.push(data[m]);
    }
    for (let i = 0; i < data.length; i++) {
      dateArr.push(moment(data[i].createdAt).format('DD/MM/YYYY'));
    }
    let uniqueData = dateArr.filter(
      (value, index) => dateArr.indexOf(value) === index
    );
    for (let x = 0; x < uniqueData.length; x++) {
      let arr = finalArr.filter((value) => {
        if (moment(value.createdAt).format('DD/MM/YYYY') === uniqueData[x]) {
          return value;
        }
      });
      let sortedChats = arr.sort((a, b) => a.createdAt - b.createdAt);
      sortedChats[0].isSameDate = true;
      for (let c = 0; c < sortedChats.length; c++) {
        resultArr.push(sortedChats[c]);
      }
    }

    return resultArr;
  }

  // socket.on('new-message', (data) => {
  //
  // let fullArr = previousChat.concat(data);
  // setPreviousChat(fullArr);
  // });

  function submitMessage(event) {
    event.preventDefault();
    if (privateMessage) {

      socket.emit('send-message', {
        // senderId: joinVideo ? userData.recieverId : userData.senderId,
        // recipientId: joinVideo ? userData.senderId : userData.recieverId,
        senderId: userData.senderId,
        recipientId: userData.recieverId,
        message: privateMessage,
        roomId: roomId,
      });
      setPrivateMessage('');
      // setIstyping(false);
      setIsMessageSubmit(true);
    }
  }

  function handleInput(event) {
    let { value } = event.target;
    // let data = value.replace(/\s+$/, '');
    if (value) {
      // setIstyping(true);
    } else {
      // setIstyping(false);
    }
    setPrivateMessage(value);
  }
  // get previous user chat list
  function getPreviousUserChats() {
    let data = {
      id: id,
      pageNumber: paginationObj.page_size,
      limit: paginationObj.page_limit,
    };
    header.Authorization = token;
    getPreviousChat(data, header)
      .then((res) => {
        if (res.data) {
          setPreviousUserChat(res.data.messages);
        }
        setShowPageLoader(false);
        setMorePreviousChat(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
        setMorePreviousChat(false);
      });
  }
  // show chat module when user click in previous chat user list
  function showChatModule(senderId, recieverId, fullName, photo) {
    if (recieverId === userData.recieverId) {
      return false;
    }
    setShowPageLoader(true);
    setPreviousChat([]);

    setUserData((prevUserData) => {
      return {
        ...prevUserData,
        senderId: senderId,
        recieverId: recieverId,
      };
    });
    setUserChatHeaderInfo((prevUserChatHeaderInfo) => {
      return {
        ...prevUserChatHeaderInfo,
        userPhoto: photo,
        fullName: fullName,
      };
    });
    setPaginationObj((prevPaginationObj) => {
      return { ...prevPaginationObj, page_size: 1 };
    });
    setIsMessageBoxClicked(true);

    // Calling message seen socket
    messageSeenSocket(recieverId);

    if (showChat) {
      // fetching  room id to get message list
      fetchRoomID(senderId, recieverId);
      // fetchRoomId();
    } else {
      setShowChat(true);
    }
  }

  useEffect(() => {
    if (isMessageBoxClicked) {
      fetchRoomId();
    }
  }, [isMessageBoxClicked]);

  // fetching  room id to get message list
  function fetchRoomID(senderID, recieverID) {
    header.Authorization = token;
    let data = {
      senderId: senderID,
      recipientId: recieverID,
    };
    createRoomForMessage(data, header)
      .then((res) => {
        if (res.data) {
          socket.emit('join-room', { roomId: res.data.id });
          // fetching message list on the basis of Room id
          getMessageLisT(res.data.id);
          // setRoomId(res.data.id);
          messageSeenSocket(recieverID, res.data.id);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  // get message list on the basis of roomid
  function getMessageLisT(roomID) {
    let data = {
      roomId: roomID,
      pageNumber: paginationObj.page_size,
      limit: paginationObj.page_limit,
    };
    header.Authorization = token;
    messageOnTheBasisRoomId(data, header)
      .then((res) => {
        setShowPageLoader(false);
        const sortedChats = res.data.messages.length
          ? res.data.messages.sort((a, b) => a.createdAt - b.createdAt)
          : [];
        const messageList = previousChat.length
          ? sortedChats.concat(previousChat)
          : sortedChats;
        let list = addDateKey(messageList);
        if (previousChat.length) {
          setPreviousChat(list);
        } else {
          setPreviousChat(list);
          messagingBoxRef.current &&
            messagingBoxRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
        }
        let page = Math.ceil(res.data.totalCount / paginationObj.page_limit);
        setTotalPage(page);
        setIsTop(false);

        // if (res.data) {
        //   //
        //   if (isTop) {
        //     let array = addHorizontalDateBarInMessageList(res.data.messages);
        //     let data = previousChat.reverse().concat(array).reverse();
        //     // let data = previousChat.unshift(res.data.messages);
        //     setPreviousChat(data);
        //   } else {
        //     let arr = addHorizontalDateBarInMessageList(res.data.messages);
        //     setPreviousChat(arr);
        //     messagingBoxRef.current && messagingBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        //   }
        //   let page = Math.ceil(res.data.totalCount / paginationObj.page_limit);
        //   setTotalPage(page);
        //   setIsTop(false);
        // } else {
        //   setPreviousChat([]);
        //   setIsTop(false);
        // }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function addHorizontalDateBarInMessageList(data) {
    let value = data;
    for (let i = 0; i < value.length; i++) {
      let nextDaydate =
        i === value.length - 1
          ? moment(value[i].createdAt).format('MM/DD/YYYY')
          : moment(value[i + 1].createdAt).format('MM/DD/YYYY');
      let currentDayDate = moment(value[i].createdAt).format('MM/DD/YYYY');
      let status = moment(currentDayDate).isSame(nextDaydate);
      if (status) {
        value[i]['status'] = false;
      } else {
        value[i]['status'] = true;
      }
    }
    return value;
  }

  // useEffect(() => {
  //   if(isMessageSubmit){
  //     debugger
  //     // after sending message getting updated message using getChatMessage socket
  //     getChatMessage((err, data) => {
  //
  //       if (err) return;
  //       setPreviousChat(oldPreviousChat => [...oldPreviousChat, data]);
  //       messagingBoxRef.current && messagingBoxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  //       setIsTop(false);
  //       setIsMessageSubmit(false);
  //     });
  //   }
  // }, [isMessageSubmit])

  useEffect(() => {

    socket.on('new-message', (msg) => {


      setPreviousChat((oldPreviousChat) => [...oldPreviousChat, msg]);
      messagingBoxRef.current &&
        messagingBoxRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
    });
  }, []);

  React.useEffect(() => {
    // setting page title
    document.title = 'Messaging';
  });
  // on scroll function handleOnScroll function called
  function handleOnScroll() {
    if (messagingSectionRef.current) {
      let {
        //  scrollHeight, clientHeight,  offsetTop
        scrollTop,
      } = messagingSectionRef.current;

      // if user scroll top of the page
      if (scrollTop === 0) {
        // incrementing page size
        let pageSize = paginationObj.page_size,
          data = pageSize + 1;
        // checking current page number with incrementing page number

        if (data <= totalPage) {
          // saving updated page number in state
          setPaginationObj((prevPaginationObj) => {
            return { ...prevPaginationObj, page_size: data };
          });
          setIsTop(true);
        } else {
          setIsTop(false);
        }
      } else {
      }
    }
  }

  useEffect(() => {
    if (isTop) {
      getMessageList();
    }
  }, [isTop]);

  // TOKBOX VIDEO CHAT
  function getSessionId() {
    let data = {
      userId: userData.senderId,
      mentorId: userData.recieverId,
    };
    header.Authorization = token;
    getTokBoxSession(data, header)
      .then((res) => {
        if (res.session) {
          let value = getSessionDetail(res.session);
          if (value) {
            setSessionId(value.session);
            setSessionToken(value.token);
            let data = {
              callerName: firstName + ' ' + lastName,
              // senderId: role === 'mentor' ? mentorId : userData.senderId,
              // receipentId: role === 'mentor' ? userData.recieverId : userData.senderId,
              senderId: userData.senderId,
              receipentId: userData.recieverId,
              session: value.session,
              token: value.token,
            };

            socket.emit('make-call', data);
          }
          setShowPageLoader(false);
        } else {
          // fetch new session id if no session availabale
          getSessionOfUser();
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  // get session detail for  list
  function getSessionDetail(array) {
    var value = {};
    for (let i = 0; i < array.length; i++) {
      value.session = array[i].session;
      value.token = array[i].token;
    }
    return value;
  }

  function handlePublisherSetting(name, value) {
    setPublisherStatus((prevPublisherStatus) => {
      return {
        ...prevPublisherStatus,
        [name]: value,
      };
    });
  }

  const sessionEventHandlers = {
    sessionConnected: (event) => {
      let { connectionId, creationTime } = event.target.connection;
      SetVideoCallStatus({
        ...videoCallStatus,
        connection: 'Connected',
      });
      setVideoCallDetails((prevVideoCallDetails) => {
        return {
          ...prevVideoCallDetails,
          connectionId: connectionId,
          creationTime: creationTime,
          isVideoCallConnected: true,
        };
      });

    },
    sessionDisconnected: (event) => {
      SetVideoCallStatus({
        ...videoCallStatus,
        connection: 'Disconnected',
      });
      event.target.disconnect();

      let time = Date.now();

      setVideoCallDetails((prevVideoCallDetails) => {
        return {
          ...prevVideoCallDetails,
          disConnectionTime: time,
          isVideoCallConnected: false,
        };
      });
      deleteVideoSession('auto');
    },
    sessionReconnected: (event) => {
      SetVideoCallStatus({
        ...videoCallStatus,
        connection: 'Reconnected',
      });


      // SetVideoCallStatus({ connection: 'Reconnected' });
    },
    sessionReconnecting: (event) => {
      SetVideoCallStatus({
        ...videoCallStatus,
        connection: 'Reconnecting',
      });


      // SetVideoCallStatus({ connection: 'Reconnecting' });
    },
  };

  const publisherEventHandlers = {
    accessDenied: () => {

    },
    streamCreated: () => {

    },
    streamDestroyed: ({ reason }) => {

    },
  };

  const subscriberEventHandlers = {
    videoEnabled: () => {

    },
    videoDisabled: () => {

    },
  };

  const onSessionError = (error) => {
    SetVideoCallStatus({ error });

  };

  const onPublish = (event) => {

  };

  const onPublishError = (error) => {
    SetVideoCallStatus({ error });

  };

  const onSubscribe = (event) => {

  };

  const onSubscribeError = (error) => {
    SetVideoCallStatus({
      ...videoCallStatus,
      error,
    });


    // SetVideoCallStatus({ error });
  };

  const toggleVideo = () => {
    SetVideoCallStatus((state) => ({
      ...videoCallStatus,
      publishVideo: !state.publishVideo,
    }));
  };

  // function getMentorIdByEmailApi() {
  //   header.Authorization = token;
  //   getMentorUserIdByEmail(email, header).then(res => {
  //     if (res.data) {
  //       // setMentorId(res.data.id)
  //       setUserData(prevUserData => {
  //         return {
  //           ...prevUserData,
  //           senderId: res.data.id
  //         }
  //       })
  //       setShowVideoChat(true);
  //       setJoinVideo(true);
  //       socket.emit('call-join-room', res.data.id);
  //       setShowPageLoader(false);
  //     }
  //   }).catch(err => {
  //     message.error(err && err.message ? err.message : ERROR_MESSAGE);
  //     setShowPageLoader(false);
  //   })
  // }

  // get tokbox session of user for video call api
  function getSessionOfUser() {
    let data = {
      userId: userData.senderId,
      mentorId: userData.recieverId,
    };
    header.Authorization = token;
    getTokSession(data, header)
      .then((res) => {
        if (res.session) {
          setSessionId(res.session.session);
          setSessionToken(res.session.token);
          let data = {
            callerName: firstName + ' ' + lastName,
            senderId: userData.senderId,
            receipentId: userData.recieverId,
            session: res.session.session,
            token: res.session.token,
          };
          socket.emit('make-call', data);
          setShowPageLoader(false);
        } else {
          getSessionId();
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  // delete video session when user disconnect from video chat
  function deleteVideoSession(type) {
    let data = {
      session: sessionId,
    };
    header.Authorization = token;
    deleteTokBoxSession(data, header)
      .then((res) => {
        if (type === 'auto') {
          // debugger
        } else {
          message.success('Call Ended Sucessfully');
          if (role === 'mentor') {
            props.history.push('/mentor/schedule');
          } else {
            props.history.push('/reminder');
          }
          // setSessionId('');
        }
        let { connectionId, creationTime } = videoCallDetails;
        let sessionEndTime = Date.now();
        let callsDuration = Math.abs((sessionEndTime - creationTime) / 1000);
        let value = {
          callerName: userData.senderId,
          calleName: userData.recieverId,
          connectionId: connectionId,
          tokboxSession: sessionToken,
          callsDuration: callsDuration,
          callStatus: 'disconnected',
        };
        setSessionId('');
        callDisconnect(value);
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function endSession() {
    setShowPageLoader(true);
    deleteVideoSession('manual');
  }
  // load more previous chats
  function loadMorePreviousChat() {
    let pageSize = paginationObj.page_size,
      data = pageSize + 1;
    setPaginationObj((prevPaginationObj) => {
      return { ...prevPaginationObj, page_size: data };
    });
    setMorePreviousChat(true);
  }
  useEffect(() => {
    if (morePreviousChat) {
      getPreviousUserChats();
    }
  }, [morePreviousChat]);

  useEffect(() => {
    if (videoCallDetails.isVideoCallConnected) {
      startTimer();
    }
  }, [videoCallDetails.isVideoCallConnected]);

  // show timer when user connect call with mentor
  function startTimer() {
    var startTimestamp = moment().startOf('day');
    setInterval(function () {
      startTimestamp.add(1, 'second');
      let value = startTimestamp.format('HH:mm:ss');
      setTimerValue(value);
    }, 1000);
  }
  // calling message seen socket
  function messageSeenSocket(recipientId, _roomId) {

    let userId = id;
    socket.emit('message-seen', {
      roomId: _roomId,
      recipientId,
    });
    socket.emit('check-notification', userId);
  }

  return (
    <React.Fragment>
      {showPageLoader ? <Loader /> : ''}
      <SubHeader pathName='Messaging' />
      <div className='container chat-section justify-content-center min-height-60vh'>
        <div className='row my-5'>
          {showUserChat ? (
            <div className={`col-md-4 col-lg-3 col-12 mb-5`}>
              <div className={`w-100 chat-user-detail`}>
                {previousUserChat.length ? (
                  previousUserChat.map((value, index) => {
                    if (value.senderId) {
                      let { recipientId, senderId } = value,
                        fullName =
                          senderId.id === id
                            ? recipientId
                              ? recipientId.firstName +
                                ' ' +
                                recipientId.lastName
                              : ''
                            : senderId
                            ? senderId.firstName + ' ' + senderId.lastName
                            : '',
                        userPhoto =
                          senderId.id === id
                            ? recipientId
                              ? recipientId.userPhoto
                              : ''
                            : senderId
                            ? senderId.userPhoto
                            : '',
                        senderID =
                          senderId.id === id
                            ? senderId.id
                            : recipientId
                            ? recipientId.id
                            : '',
                        recieverID = recipientId
                          ? recipientId.id === id
                            ? senderId.id
                            : recipientId.id
                          : '';
                      return (
                        <React.Fragment>
                          <a
                            key={`${value.id}-${index}`}
                            onClick={() =>
                              showChatModule(
                                senderID,
                                recieverID,
                                fullName,
                                userPhoto
                              )
                            }
                          >
                            <div className='row mt-1 mb-1'>
                              <div className='col-md-3 col-2 text-center'>
                                <img
                                  src={userPhoto ? userPhoto : ERROR_IMAGE_URL}
                                  alt={userPhoto}
                                  className='chat-user-photo'
                                />
                              </div>
                              <div className='col-md-9 col-8 pl-0'>
                                <div className='mb-1 col-md-12 col-12 text-truncate'>
                                  <span className='font-weight-bold'>
                                    {fullName}
                                  </span>
                                </div>
                                <div className='mb-1 col-12 text-truncate'>
                                  {value.message}
                                </div>
                              </div>
                            </div>
                          </a>
                        </React.Fragment>
                      );
                    } else {
                      return false;
                    }
                  })
                ) : (
                  <div className='row justify'>No chat avaialable</div>
                )}
                {previousUserChat.length ? (
                  previousUserChat.length > 8 ? (
                    <div className='row text-center'>
                      <Button type='link' onClick={loadMorePreviousChat}>
                        Load More Chats
                      </Button>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          {showChat ? (
            ''
          ) : sessionToken ? (
            ''
          ) : (
            <div className='d-block col-md-8 col-lg-9 col-12 mx-auto mb-5'>
              <div className='d-flex align-items-center justify-content-center w-100 chat_no_found h-100'>
                <p>No Chat Found</p>
              </div>
            </div>
          )}
          {false ? (
            <div className='d-block col-md-8 col-lg-9 col-12 mx-auto'>
              <div className='d-block w-100 chat_no_found h-100'>
                <OTSession
                  apiKey='47045674'
                  sessionId={sessionId}
                  token={sessionToken}
                  onError={onSessionError}
                  eventHandlers={sessionEventHandlers}
                >
                  {/* <button onClick={getSessionId();}>Call</button> */}
                  <div className='row'>
                    <div className='col-md-6'>
                      <OTPublisher
                        properties={{
                          width: 'auto',
                          height: 500,
                          videoSource:
                            publisherStatus.video === 'screen'
                              ? 'screen'
                              : undefined,
                          publishVideo: publisherStatus.video,
                          publishAudio: publisherStatus.audio,
                        }}
                        onPublish={onPublish}
                        onError={onPublishError}
                        eventHandlers={publisherEventHandlers}
                      />
                    </div>
                    <div className='col-md-6'>
                      <OTStreams>
                        <OTSubscriber
                          properties={{
                            width: 'auto',
                            height: 500,
                            subscribeToAudio: subscriberStatus.audio,
                            subscribeToVideo: subscriberStatus.video,
                          }}
                          onSubscribe={onSubscribe}
                          onError={onSubscribeError}
                          eventHandlers={subscriberEventHandlers}
                          retry={true}
                          maxRetryAttempts={3}
                          retryAttemptTimeout={2000}
                        />
                      </OTStreams>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-md-4 d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0'>
                      <div className='session-icons'>
                        {/* <button type="button" className="btn btn-link btn-sm" ><img src={IconSetting} alt="IconSetting" /></button> */}
                        <button
                          type='button'
                          className='btn btn-link btn-sm mx-3'
                          title={
                            publisherStatus.audio
                              ? 'Mute audio'
                              : 'Unmute audio'
                          }
                          onClick={() =>
                            handlePublisherSetting(
                              'audio',
                              !publisherStatus.audio
                            )
                          }
                        >
                          <img
                            src={
                              publisherStatus.audio ? MicIcon : UnmuteMicIcon
                            }
                            alt={
                              publisherStatus.audio
                                ? 'MicIcon'
                                : 'UnmuteMicIcon'
                            }
                          />
                        </button>
                        <button
                          type='button'
                          className='btn btn-link btn-sm mx-3'
                          title={
                            publisherStatus.video ? 'Hide video' : 'Show video'
                          }
                          onClick={() =>
                            handlePublisherSetting(
                              'video',
                              !publisherStatus.video
                            )
                          }
                        >
                          <img
                            src={
                              publisherStatus.video ? VideoIcon : HideVideoIcon
                            }
                            alt={
                              publisherStatus.video ? VideoIcon : HideVideoIcon
                            }
                          />
                        </button>
                      </div>
                    </div>
                    {/* mayank please remove text-center and text-right class */}
                    <div className='col-md-4 d-flex align-items-center justify-content-center mb-4 mb-md-0'>
                      <div className='session-icons cut-icon text-center'>
                        <button
                          type='button'
                          className='btn btn-link btn-sm'
                          onClick={() => endSession()}
                        >
                          <img src={CutIcon} alt='CutIcon' />
                        </button>
                      </div>
                    </div>
                    <div className='col-md-4 d-flex justify-content-center justify-content-md-end align-items-center mb-4 mb-md-0'>
                      <div className='session-icons screen-icon text-right'>
                        {/* <button type="button" className="btn btn-link"><img src={ScreenIcon} alt="ScreenIcon-1" /></button> */}
                      </div>
                    </div>
                  </div>
                </OTSession>
              </div>
            </div>
          ) : (
            ''
          )}
          {sessionToken ? (
            <section className='message-section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-9 mb-5'>
                    <div className='session-part'>
                      <h3 className='d-flex justify-content-between align-items-end'>
                        In Session: Scheduled Mentor/Mentee Meeting{' '}
                        <span>
                          Time Elapased: {timerValue ? timerValue : '00:00:00'}
                        </span>
                      </h3>
                      {/* <button onClick={getSessionId();}>Call</button> */}
                      <div className='vc-container'>
                        <OTSession
                          apiKey='47045674'
                          sessionId={sessionId}
                          token={sessionToken}
                          onError={onSessionError}
                          eventHandlers={sessionEventHandlers}
                        >
                          <ul>
                            <li className='active'>
                              <div className='meeting-img'>
                                <OTPublisher
                                  properties={{
                                    width: 'auto',
                                    height: 500,
                                    videoSource:
                                      publisherStatus.video === 'screen'
                                        ? 'screen'
                                        : undefined,
                                    publishVideo: publisherStatus.video,
                                    publishAudio: publisherStatus.audio,
                                  }}
                                  onPublish={onPublish}
                                  onError={onPublishError}
                                  eventHandlers={publisherEventHandlers}
                                />{' '}
                              </div>
                            </li>
                            <li>
                              <div className='meeting-img'>
                                <OTStreams>
                                  <OTSubscriber
                                    properties={{
                                      width: 'auto',
                                      height: 500,
                                      subscribeToAudio: subscriberStatus.audio,
                                      subscribeToVideo: subscriberStatus.video,
                                    }}
                                    onSubscribe={onSubscribe}
                                    onError={onSubscribeError}
                                    eventHandlers={subscriberEventHandlers}
                                    retry={true}
                                    maxRetryAttempts={3}
                                    retryAttemptTimeout={2000}
                                  />
                                </OTStreams>
                              </div>
                            </li>
                          </ul>
                          {/* <div className="row">
            <div className="col-md-4">
              <div className="session-icons">
                <a href="/"><img src={IconSetting} alt="IconSetting" /></a>
                <a href="/"><img src={MicIcon} alt="MicIcon" /></a>
                <a href="/"><img src={VideoIcon} alt="VideoIcon" /></a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="session-icons cut-icon">
                <a href="/"><img src={CutIcon} alt="CutIcon" /></a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="session-icons screen-icon">
                <a href="/"><img src={ScreenIcon} alt="ScreenIcon-1" /></a>
              </div>
            </div>
          </div> */}
                          <div className='row py-4'>
                            <div className='col-md-4 d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0'>
                              <div className='session-icons'>
                                {/* <button type="button" className="btn btn-link btn-sm" ><img src={IconSetting} alt="IconSetting" /></button> */}
                                <button
                                  type='button'
                                  className='btn btn-link btn-sm mx-3'
                                  title={
                                    publisherStatus.audio
                                      ? 'Mute audio'
                                      : 'Unmute audio'
                                  }
                                  onClick={() =>
                                    handlePublisherSetting(
                                      'audio',
                                      !publisherStatus.audio
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      publisherStatus.audio
                                        ? MicIcon
                                        : UnmuteMicIcon
                                    }
                                    alt={
                                      publisherStatus.audio
                                        ? 'MicIcon'
                                        : 'UnmuteMicIcon'
                                    }
                                  />
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-link btn-sm mx-3'
                                  title={
                                    publisherStatus.video
                                      ? 'Hide video'
                                      : 'Show video'
                                  }
                                  onClick={() =>
                                    handlePublisherSetting(
                                      'video',
                                      !publisherStatus.video
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      publisherStatus.video
                                        ? VideoIcon
                                        : HideVideoIcon
                                    }
                                    alt={
                                      publisherStatus.video
                                        ? VideoIcon
                                        : HideVideoIcon
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                            <div className='col-md-4 d-flex align-items-center justify-content-center mb-4 mb-md-0'>
                              <div className='session-icons cut-icon text-center'>
                                <button
                                  type='button'
                                  className='btn btn-link btn-sm'
                                  onClick={() => endSession()}
                                >
                                  <img src={CutIcon} alt='CutIcon' />
                                </button>
                              </div>
                            </div>
                            <div className='col-md-4 d-flex justify-content-center justify-content-md-end align-items-center mb-4 mb-md-0'>
                              <div className='session-icons screen-icon text-right'>
                                {/* <button type="button" className="btn btn-link"><img src={ScreenIcon} alt="ScreenIcon-1" /></button> */}
                              </div>
                            </div>
                          </div>
                        </OTSession>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 mb-5'>
                    <div className='chat-section d-flex flex-column'>
                      <h3>Chat</h3>
                      <div
                        className='chat-list'
                        onScroll={handleOnScroll}
                        ref={messagingSectionRef}
                      >
                        {previousChat.length
                          ? previousChat.map((value, index) => {

                              let status = value.senderId ? true : false;
                              if (status) {
                                return (
                                  <div
                                    className='row'
                                    key={`dummychat-${index}`}
                                  >
                                    <div className='col-md-12 d-flex'>
                                      <div className='chat-img mr-2'>
                                        <img
                                          src={value.senderId.userPhoto}
                                          alt={`${
                                            value.senderId.id === id
                                              ? value.senderId.id
                                              : value.recipientId.id
                                          }-${index}`}
                                        />
                                      </div>
                                      <div className='chat-text'>
                                        <p>{value.message}</p>
                                      </div>
                                    </div>
                                  </div>
                                  // <div className="chat-box-section" >
                                  //   <div className={`${value.senderId.id === id ? 'text-right' : 'text-left'} mt-1`}>
                                  //     <span>{`${value.senderId.id === id ? value.senderId.firstName : value.recipientId.firstName }`}</span>
                                  //   </div>
                                  //   <div className={`${value.senderId.id === id ? 'right-bubble' : 'left-bubble'} chat-box-bubble`} >
                                  //     <p className="message-text">
                                  //       {value.message}
                                  //     </p>
                                  //   </div>
                                  // </div>
                                );
                              } else {
                                return false;
                              }
                            })
                          : ''}
                      </div>
                      <div className='msg-box mt-auto'>
                        <form
                          className='align-items-center pt-1 write-chat-section'
                          onSubmit={submitMessage}
                        >
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='form-group mb-0'>
                                <textarea
                                  value={privateMessage}
                                  className='form-control message-textarea chat-only'
                                  name='privateMessage'
                                  onChange={handleInput}
                                  required
                                />
                                <button
                                  type='submit'
                                  className='btn btn-primary btn-submit-message'
                                >
                                  <SendOutlined />
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ''
          )}
          <div
            className={`${
              showChat ? 'd-flex' : 'd-none chat_not_found'
            } col-md-8 col-lg-9 col-12 mx-auto`}
          >
            <div className='col-12 px-0 chat-box-section-layout mx-0'>
              <div className='top-row'>
                <div className='row'>
                  <div className='col-1 col-md-1'>
                    {userChatHeaderInfo.userPhoto ? (
                      <img
                        src={userChatHeaderInfo.userPhoto}
                        alt={userChatHeaderInfo.fullName}
                      />
                    ) : (
                      // <Avatar size={40} src={userChatHeaderInfo.userPhoto} />
                      // <Avatar src={<img src={userChatHeaderInfo.userPhoto} alt={userChatHeaderInfo.fullName} />} />
                      <Avatar
                        style={{ color: 'white', backgroundColor: '#18a4ad' }}
                      >
                        {userChatHeaderInfo.fullName
                          ? userChatHeaderInfo.fullName.split('')[0]
                          : ''}
                      </Avatar>
                    )}
                  </div>
                  <div className='col-11 col-md-11 pl-lg-0'>
                    <p>{userChatHeaderInfo.fullName}</p>
                  </div>
                </div>
              </div>
              <div className='chat-panel '>
                <div
                  className='chat-history'
                  onScroll={handleOnScroll}
                  ref={messagingSectionRef}
                >
                  <div className='chat-messages mr-3' ref={messagingBoxRef}>
                    <div className='chat-message-box-section' ref={messagesRef}>
                      {previousChat.length
                        ? previousChat.map((value, index) => {
                            let status = value.senderId ? true : false;
                            let currentTime = moment().isSame(
                              value.createdAt,
                              'day'
                            )
                              ? moment(value.createdAt)
                                  .format('hh:mm a')
                                  .toString()
                              : moment(value.createdAt).format(' h:mm a');
                            let hrBarText = moment(value.createdAt).format(
                              'MMMM DD YYYY'
                            );
                            // let hrBarStatus = moment().isSame(value.createdAt, 'day') ? false : true;
                            if (status) {
                              return (
                                <React.Fragment key={`${value.id}-${index}`}>
                                  {value.isSameDate ? (
                                    <Divider>{hrBarText}</Divider>
                                  ) : (
                                    ''
                                  )}
                                  <div className='chat-box-section'>
                                    <div
                                      className={`${
                                        value.senderId.id === id
                                          ? 'text-right'
                                          : 'text-left'
                                      } mt-1`}
                                    >
                                      <span>{`${value.senderId.firstName} ${currentTime}`}</span>
                                    </div>
                                    <div
                                      className={`${
                                        value.senderId.id === id
                                          ? 'right-bubble'
                                          : 'left-bubble'
                                      } chat-box-bubble`}
                                    >
                                      <p className='message-text'>
                                        {value.message}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            } else {
                              return false;
                            }
                          })
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <form
                className='align-items-center pt-1 pb-4 write-chat-section'
                onSubmit={submitMessage}
              >
                <div className='row'>
                  <div className='col-md-11 col-8'>
                    <textarea
                      value={privateMessage}
                      className='form-control message-textarea chat-module'
                      name='privateMessage'
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className='col-md-1 col-2'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-submit-message'
                    >
                      <SendOutlined />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
