import React, { useState, useEffect } from "react";
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {
  Row,
  Layout,
  Card,
  Button,
  message
} from "antd";
import Container from "../../widgets/container";
import style from '../register/register.module.sass';
import style2 from './mentors.module.sass';
import '../register/register.sass';
import { useParams } from "react-router-dom";
// import { get } from "../../../api/rest";
import { history } from "../../../redux/store";
import { header } from '../../../api/rest';
import {
  updateUserData,
  setLoading as running,
  getMentorIdByUser
} from "../../../redux/slices/authSlice";
import {
  getMentorInfoById,
  updateUserSelectedMentor
} from '../../../redux/slices/registerSlice';
// LOADER
import {
  // SkeletonDashBoardPostLoader,
  Loader
} from '../../shared/Loader';
// CONSTANTS
import { CONSTANTS } from '../../shared/constants';
// IMAGES
import DefaultPhoto from '../../../assets/images/userprofiledefault.png';
var {
  ERROR_MESSAGE, CHOOSE_A_CATEGORY,
  UPDATE_PROFILE_TEXT
} = CONSTANTS;
const { Meta } = Card;
const Mentor = props => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.user);
  const [loading, setLoading] = useState(session.loading);
  const [mentor, setMentor] = useState(null);
  const { id } = useParams();
  const storageInfo = localStorage.getItem("userData");
  const payload = storageInfo ? JSON.parse(storageInfo).payload : '';
  const token = storageInfo ? JSON.parse(storageInfo).token : '';
  const [mentorCategoryId, setMentorCategoryId] = useState('');
  const type = props.type ? props.type : '';
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (id) {
      async function fetchData() {
        // const result = await get(`/mentorInformation/${id}`);
        // setMentor(result);
        if (id) {
          getMentorInfoApi(id)
        }
      }
      fetchData();
    } else {
      getMentorId();
    }
  }, [id]);

  useEffect(() => {
    if (loading && !session.loading && session.data) {
      // localStorage.setItem("userData", JSON.stringify(session.data));
      // history.push('/dashboard');
      if (type) {
        props.loadNextTab(UPDATE_PROFILE_TEXT)
      } else {
        history.push('/user/profile/update', {
          data: {
            type: UPDATE_PROFILE_TEXT
          }
        });
      }
    }
    setLoading(session.loading);
  }, [loading, session]);

  function getMentorId() {
    header.Authorization = token;
    getMentorIdByUser(payload.id, header).then(res => {
      if (res.updatedUser) {
        if (res.updatedUser.mentorId) {
          setMentorCategoryId(res.updatedUser.mentorId);
          getMentorInfoApi(res.updatedUser.mentorId);
        } else {
        }
      }
      setShowLoader(false);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }

  const onClickRegister = async () => {
    dispatch(running(true));
    const tempUserData = Object.assign({}, session.data);
    tempUserData.MentorInfoCode = id;
    tempUserData.mentor = mentor;
    let type = "mentorchoosing";
    setShowLoader(true);
    dispatch(updateUserData(session.data, { mentorId: id, businessId: session.data.busInfoCode }, type));
  }

  function getMentorInfoApi(mentorId) {
    header.Authorization = token;
    getMentorInfoById(mentorId, header).then(res => {
      setMentor(res.data);
      setShowLoader(false);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }

  function registerMentorFromEditProfile() {
    setShowLoader(true);
    header.Authorization = token;
    const data = {
      userId: payload.id,
      mentorId: mentorCategoryId
    };
    updateUserSelectedMentor(data, header).then(res => {
      setShowLoader(false);
      message.success(res.message);
      props.loadNextTab(UPDATE_PROFILE_TEXT);
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }

  const chooseCategory = () => {
    if (type) {
      props.loadNextTab(CHOOSE_A_CATEGORY)
    } else {
      history.push('/register/choose-category');
    }
  }

  return (
    <Layout.Content className={`${props.type ? 'min-height-60vh' : 'min-height-60vh'} ${style.block}`}>
      {showLoader ? <Loader /> : ''}
      {props.type ? '' : <Row className={`header-divider`} />}
      {
        mentor &&
        <Container style={{ maxWidth: 720, marginBottom: 40, marginTop: 40 }}>
          <div style={{ textAlign: 'center' }}>
            <img alt="example" src={mentor.mentorPic ? mentor.mentorPic : DefaultPhoto} className={style2.photo} />
            <Meta title={mentor.mentorName} description={mentor.mentorBusinessName} className={`mentor-initials`} />
          </div>
          <div className={style2.details}>
            <div>
              <p>Thank you for the opportunity, allowing me to help you grow your business! Here are some initial thoughts you can use until we meet. Please email me to at: <a
                href="mailto:sample@email.com">sample@email.com</a> to schedule your zoom meeting</p>
            </div>
            <div>
              <h2>AdviseStatement from the database</h2>
              <p>Advice Blurb from the database</p>
              <p>Advice Blurb from the database</p>
              <p>Advice Blurb from the database</p>
            </div>

            <div>
              <h2>Finance Tips</h2>
              <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
            </div>

            <div>
              <h2>How to Finance your Business</h2>
              <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
            </div>
          </div>
          <Row justify={'center'}>
            <Button type="primary" className={style.submit_btn} onClick={type ? registerMentorFromEditProfile : onClickRegister} >
              {`${session.userid ? 'Submit' : 'Register'}`}
            </Button>
          </Row>
        </Container>
      }
      {
        mentor ? '' : <Row gutter={24} justify={'center'} style={{ marginTop: 40 }}>
          <h4>No mentors found for this specific category.</h4>
          <Button onClick={chooseCategory}>Choose another category</Button>
        </Row>
      }
    </Layout.Content>
  )
}

export default Mentor;