import React, { useState, useCallback, useRef, useEffect } from "react";
import Cropper from "react-easy-crop";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import getCroppedImg from "./cropImage";

function ImageCropper({
  picture,
  ratio,
  onComplete,
  children,
  showEditButton,
}) {

  const [image, setImage] = useState(picture);
  const cropRatio = ratio || 2.2 / 1;
  const imageInput = useRef();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [edit, setEdit] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    setImage(picture);
  }, [picture]);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setCroppedImage(croppedImage.url);
      setEdit(!edit);
      setImage(croppedImage.url);
      onComplete(croppedImage.blob);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <div>
      <div style={{ position: "absolute", zIndex: 9 }}>
        <input
          ref={imageInput}
          type="file"
          style={{
            height: 0,
            width: 0,
          }}
          onChange={(e) => {
            URL.revokeObjectURL(image);
            setImage(URL.createObjectURL(e.target.files[0]));
            setEdit(true);
          }}
        />
        {/* {!edit && (
          <button
            className='btn btn-primary'
            onClick={() => imageInput.current.click()}
          >
            Edit
          </button>
        )}
        {edit && (
          <button className='btn btn-primary' onClick={showCroppedImage}>
            Save
          </button>
        )} */}
      </div>
      <div style={{ height: 410, position: "relative" }}>
        {edit && (
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={cropRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        )}
        {!edit && (
          <img
            style={{ width: "100%", maxHeight: "100%",height:"100%", objectFit: "cover" }}
            src={image}
          ></img>
        )}
      </div>
      <ul>
        {showEditButton && !edit && (
          <li onClick={() => imageInput.current.click()} style={{ cursor: "pointer" }} className="d-flex align-items-center">
            <EditOutlined style={{ fontSize: "22px", color: "#e9eaeb" }} />
            <span style={{ fontSize: "20px", color: "#e9eaeb",fontWeight: "600" }} className="ml-2">Edit</span>
          </li>
        )}
        {showEditButton && edit && (
          <li onClick={showCroppedImage}>
            <SaveOutlined
              style={{ fontSize: "22px", color: "#e9eaeb" }}
              onClick={showCroppedImage}
            />

            <span style={{ color: "#e9eaeb" }}>Save</span>
          </li>
        )}
        {children}
      </ul>
    </div>
  );
}

export default ImageCropper
