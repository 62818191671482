import React from 'react';
import { Modal, Button } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// Constant
import { POP_UP_MODAL_HEADER_CONSTANTS } from '../constants';

const { EDIT_MENTOR_PROFILE_BIO } = POP_UP_MODAL_HEADER_CONSTANTS;

export function EditVisionPopUpModal(props) {
    let { title, values } = props;
    return (
        <Modal
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            okButtonProps={{ title: "Submit" }}
            footer={[
                <Button key="back" onClick={() => props.onCancel(false)} >
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={(event) => props.onOk(event)}>
                    Submit
                </Button>,
            ]}
        >
            <label>{title === EDIT_MENTOR_PROFILE_BIO ? 'Mentor Bio' : 'User Vision'}</label>
            <CKEditor
                editor={ClassicEditor}
                data={values}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    props.onChange(data)
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
            />
        </Modal>
    )
}