import React, {
    useEffect,
    useState
} from 'react';
import {
    Table,
    Space,
    Button,
    message
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS,
    POP_UP_MODAL_CONSTANTS,
    POP_UP_MODAL_HEADER_CONSTANTS
} from '../../../shared/constants';
// API END POINTS
import {
    addBuisnessIndustry,
    updateBuisnessIndustry,
     deleteBuisnessIndustry
} from '../../../../redux/slices/adminSlice';
import { getBusinessIndustries } from '../../../../redux/slices/registerSlice';
import {
    header
} from '../../../../api/rest';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
import './adminbuisnessindustries.scss';

var {
    ERROR_MESSAGE
} = CONSTANTS;

var {
    CONFIRMATION_POPUP_MODAL,
    ADD_EDIT_BUISNESS_INFORMATION_MODAL
} = POP_UP_MODAL_CONSTANTS;
var {
    CONFIRMATION_HEADER,
    ADD_BUISNESS_INDUSTRY,
    EDIT_BUISNESS_INDUSTRY
} = POP_UP_MODAL_HEADER_CONSTANTS;


function AdminBuisnessIndustries() {
    // const dispatch = useDispatch();
    // const loginUserData = useSelector(state => state.user);
    // const payload = storageData ? JSON.parse(storageData).payload : '';
    const storageData = localStorage.getItem("userData");
    const token = storageData ? JSON.parse(storageData).token : '';
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });
    const [showPageLoader, setShowPageLoader] = useState(true);
    const [description, setDescription] = useState('');
    const [videoList, setVideoList] = useState([]);
    const [currentEntry, setCurrentEntry] = useState({
        id: '',
        name: ''
    });
    const columns = [
        {
            title: 'Industry Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button className="del-community-btn" onClick={() => fillUserInfoInModal(record, 'delete')}>Delete Industry</Button>
                    <Button className="del-community-btn edit-industry-btn" onClick={() => fillUserInfoInModal(record, 'edit')}>Edit Industry</Button>
                </Space>
            ),
        }
    ];

    useEffect(() => {
        if (token) {
            getBusinessIndustriesList();
        }
    }, []);

    // Add buisness industry API
    function addBuisnessIndustryApi(data) {
        addBuisnessIndustry(data).then(res => {
            message.success(res.message);
            getBusinessIndustriesList();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })
    }
    // update buisness industry API
    function updateBuisnessIndustryApi(values) {
        let data = {
            industryId: currentEntry.id,
            ...values
        };
        header.Authorization = `Bearer ${token}`;
        updateBuisnessIndustry(data, header).then(res => {
            message.success(res.message);
            getBusinessIndustriesList();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })
    }

    // remove buisness industry API
    function removeBuisnessIndustry() {
        let data = {
            id: currentEntry.id,
        };
        header.Authorization = `Bearer ${token}`;
        deleteBuisnessIndustry(data, header).then(res => {
            message.success(res.message);
            getBusinessIndustriesList();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })
    }

    function fillUserInfoInModal(record, type) {
        if (type === 'edit') {
            toggleModal(ADD_EDIT_BUISNESS_INFORMATION_MODAL, EDIT_BUISNESS_INDUSTRY);
        } else {
            toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
            setDescription(`Are you sure to remove ${record.name} Industry`);
        }
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: record._id,
                name: record.name
            }
        });
    }

    function toggleModal(type, header) {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: header
            }
        });
    }
    // get buisness industries list
    function getBusinessIndustriesList() {
        header.Authorization = token;
        getBusinessIndustries(header).then(res => {
            setShowPageLoader(false);
            if (res.data.length) {
                setVideoList(res.data);
            } else {
                setVideoList([]);
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function onSubmitPopUpModal(event) {
        setShowPageLoader(true);
        if (popUpModal.modalHeader === ADD_BUISNESS_INDUSTRY) {
            // event.preventDefault();
            addBuisnessIndustryApi(event)
        }
        if (popUpModal.modalHeader === EDIT_BUISNESS_INDUSTRY) {
            updateBuisnessIndustryApi(event)
        }

        if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
            removeBuisnessIndustry();
        }
    }

    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: '',
                name: ''
            }
        });
    }

    let addBuisnessVariableProps = {
        currentEntry
    };

    return (
        <React.Fragment>
            {showPageLoader ? <div className="row"><Loader /></div> : ''}
            {
                popUpModal.visible ?
                    <PopUpModal
                        title={popUpModal.modalHeader}
                        visible={popUpModal.visible}
                        onCancel={closePopupModal}
                        onOk={onSubmitPopUpModal}
                        type={popUpModal.type}
                        description={description}
                        {...addBuisnessVariableProps}
                    // {...homepagemodalfunctionprops}
                    /> : ''
            }
            <div className="row mt-4 mb-2">
                <div className="col-12 text-right">
                    <Button onClick={() => toggleModal(ADD_EDIT_BUISNESS_INFORMATION_MODAL, ADD_BUISNESS_INDUSTRY)} disabled={videoList.length ? videoList.length > 15 ? true : false : false}>Add Industry</Button>
                </div>
            </div>
            <Table columns={columns} dataSource={videoList} />
        </React.Fragment>
    )
}

export default AdminBuisnessIndustries;