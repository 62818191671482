import React from 'react';
import {
    Modal,
    Form,
    Input,
    Button
} from 'antd';
import { REGEX_CONSTANTS } from '../constants';

const { ACCEPT_NUMBER } = REGEX_CONSTANTS;

export function ZipCodePopUpModal(props) {
    let {
        title
        // ,loadingImagingTag,
        // updateProfileObj,
        // visionImageType,
        // showLoader
    } = props;

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    // const tailLayout = {
    //     wrapperCol: { offset: 8, span: 16 },
    // };
    return (
        <React.Fragment>
            <Modal
                className="social-media-share-modal"
                title={title}
                centered
                visible={props.visible}
                onOk={(event) => props.onOk(event)}
                onCancel={() => props.onCancel(false)}
                footer={null}>
                <div className="send-tockenomodal">
                    <Form
                        {...layout}
                        onFinish={(event) => props.onOk(event)}>
                        <Form.Item
                            label={<span>Zipcode</span>}
                            name="zipCode"
                            rules={[
                                {
                                    required: true,
                                    pattern: ACCEPT_NUMBER,
                                    validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid Zipcode'),
                                },
                            ]}
                        >
                            <Input maxLength="6" />
                        </Form.Item>
                        <Form.Item ><Button type="primary" htmlType="submit" >Check</Button></Form.Item>
                    </Form>
                </div>
            </Modal>
        </React.Fragment>
    )
}
