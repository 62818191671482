import React from 'react';
import {
    Modal,
    Button,
    Form,
    Input,
    Row
} from 'antd';
import {
    REGEX_CONSTANTS
    // ,POP_UP_MODAL_HEADER_CONSTANTS
} from '../constants';

var {
     ACCEPT_NUMBER
    //  ,VALID_URL_REGEX 
    } = REGEX_CONSTANTS;
// var { EDIT_LIBERAL } = POP_UP_MODAL_HEADER_CONSTANTS;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export function AddEditEarnWallet(props) {
    let { title, loading } = props;
    const [form] = Form.useForm();
    form.setFieldsValue({
        ...props.currentEntry
    })
    return (
        <Modal
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            okButtonProps={{ title: "Submit" }}
            footer={null}>
            <Form
                {...layout}
                form={form}
                onFinish={(event) => props.onOk(event)}>
                <Form.Item
                    label={"Token Name"}
                    name="tokenName"
                    rules={[{
                        required: true,
                        message: 'Please input your Token Name!',
                    }]}>
                    <Input placeholder="Token Name" />
                </Form.Item>
                <Form.Item
                    label={"Description"}
                    name="description"
                    rules={[{
                        required: true,
                        message: 'Please input your description!'
                    }]}
                    >
                    <Input placeholder="Description" />
                </Form.Item>

                <Form.Item
                    label={"Value"}
                    name="value"
                    rules={[{
                        required: true,
                        pattern: ACCEPT_NUMBER,
                        validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid token amount'),
                    }]}>
                    <Input placeholder="Value" maxLength="3"/>
                </Form.Item>
              
                <Row justify={'center'}>
                    <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                </Row>
            </Form>
        </Modal>
    )
}
