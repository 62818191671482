import React from 'react';
import { Modal, Form, Row, Input, Button, Radio } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import { POP_UP_MODAL_HEADER_CONSTANTS } from '../constants';
// import Bulb from '../../../assets/images/bulb.png';
// import Legal from '../../../assets/images/legal.png';
// import Marketting from '../../../assets/images/marketing.png';
// import Operations from '../../../assets/images/operations.png';
// import Organisation from '../../../assets/images/organisation.png';
// import FinancialGrowth from '../../../assets/images/financialGrowth.png';
import './addedituser.css';


const { EDIT_GOAL } = POP_UP_MODAL_HEADER_CONSTANTS;

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export function AddEditUserGoal(props) {
    let { title, loading, goalImageModal } = props;
    const [form] = Form.useForm();
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    if (title === EDIT_GOAL) {
        form.setFieldsValue({
            ...props.currentEntry
        })
    }
    // const imageClick = (image) => {
    //     setImage(image);
    // }
    return (
        <Modal
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            okButtonProps={{ title: "Submit" }}
            footer={null}>
            <Form
                {...layout}
                form={form}
                onFinish={(event) => props.onOk(event)}>
                <Form.Item
                    label={"Goal name"}
                    name="name"
                    rules={[{
                        required: true,
                        message: 'Please input your Goal Name!',
                    }]}>
                    <Input placeholder="Goal name" />
                </Form.Item>
                <Form.Item
                    label={"Description"}
                    name="description"
                    rules={[{
                        required: true,
                        message: 'Please input your description!',
                    }]}>
                    <Input placeholder="Description" />
                </Form.Item>
                <Form.Item
                    label={"Select a goal image"}
                    name="image"
                    rules={[{
                        required: true,
                        message: 'Please choose on of the goal image',
                    }]}
                >
                    <Radio.Group>
                        {
                            goalImageModal.length ? goalImageModal.map((value, index) => {
                                return (
                                    <React.Fragment key={`${value.originalname}-${index}`}>
                                        <Radio value={value.Location}>
                                            <img src={value.Location} alt={value.originalname} />
                                            {/* <input type="radio" name="selimg" id="selimg1" /> */}
                                        </Radio>
                                    </React.Fragment>
                                )
                            }) : 'No Image Logo available Here'
                        }
                    </Radio.Group>
                    {/* {ImageList.map((image, i) => <><img src={image} alt="logo" onClick={(event) => props.uploadModalImages(event)} />
                        <input type="radio" name="selimg" id="selimg1" /></>
                    )} */}
                    {/* <Spin indicator={antIcon} spinning={modalLoader}>
                <input type="file" onChange={(event)=>props.modalFileInput(event)} accept="image/*"/>
                </Spin> */}
                </Form.Item>
                <Row justify={'center'}>
                    <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                </Row>
            </Form>
        </Modal>
    )
}