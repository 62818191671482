import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Row, Layout, Select, message } from "antd";
import Container from "../../widgets/container";
import style from './register.module.sass'
import './register.sass'
import { history } from "../../../redux/store";
import {
  // get,
  header
} from "../../../api/rest";
import MyAncor from "../../widgets/global/ancor";
import {
  // updateUserData,
  setUserData as setUser, setLoading as running,
  whoami
} from "../../../redux/slices/authSlice";
import {
  listMentorCommunities,
  assignCommunity,
  getAssignedCommunityOfUser
} from '../../../redux/slices/registerSlice';
import {
  getCommunityData
} from '../../../redux/slices/dashboardSlice';
// import {
//   getAnyUserProfile
// } from '../../../redux/slices/userProfileSlice';
// LOADER
import {
  // SkeletonDashBoardPostLoader,
  Loader
} from '../../shared/Loader';
// CONSTANTS
import { CONSTANTS } from '../../shared/constants';
// Sound Notification component
import {  TokenEarnSound } from '../../shared/soundeffect';
//CSS
import './register.scss';


var {
  ERROR_MESSAGE,
  INVALID_USER,
  CHOOSE_A_CATEGORY } = CONSTANTS;

const ChooseCommunity = props => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.user);

  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const type = props.type ? props.type : '';

  const storageInfo = localStorage.getItem("userData");
  const payload = storageInfo ? JSON.parse(storageInfo).payload : '';
  const token = storageInfo ? JSON.parse(storageInfo).token : '';
  const [showLoader, setShowLoader] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [communityDetail, setCommunityDetail] = useState({
    communityPhoto: '',
    communityVideo: '',
    city: '',
    id: '',
    description: '',
    name: ''
  });
  const [playSound, setPlaySound] = useState(false);



  // const userInfo = props.location.state?.data;

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };

  useEffect(() => {
    // (async function fetchData () {
    //   const result = await get('/listMentorCommunities');
    //   setCommunities(result.data);
    // })()
    getCommunities()
  }, []);


  useEffect(() => {
    if (payload) {
      getSelectedCommunity();
    }
  }, [])
  // get communities list
  function getCommunities() {
    const storageData = localStorage.getItem("userData");
    if (storageData) {
      let data = JSON.parse(storageData);
      dispatch(running(true));
      header.Authorization = data.token;
      let value = data.payload.zipcode;
      listMentorCommunities(value, header).then(res => {
        // showing success message
        if (res.data && res.data.length) {
          setCommunities(res.data);
        }
        setShowLoader(false);

      }).catch(err => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowLoader(false);
      })
    }
  }


  // get selected community of user

  function getSelectedCommunity() {
    header.Authorization = token;
    getAssignedCommunityOfUser(payload.id, header).then(res => {
      if (res.data.id) {
        form.setFieldsValue({
          communityId: res.data.communityId
        })
        setSelectedCategory(res.data.communityId);
      }
      setShowLoader(false);
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowLoader(false);
    })
  }

  function handleDropDownInput(event) {
    setSelectedCategory(event)
  }

  useEffect(() => {
    if (selectedCategory) {
      getCommunityDetails();
    }
  }, [selectedCategory])

  // get community details
  function getCommunityDetails() {
    header.Authorization = token;
    let data = selectedCategory;
    getCommunityData(data, header).then(res => {
      if (res.data) {
        setCommunityDetail(prevCommunityDetail => {
          return {
            ...prevCommunityDetail,
            communityPhoto: res.data.communityPhoto,
            communityVideo: res.data.communityVideo,
            city: res.data.city,
            id: res.data.id,
            description: res.data.description,
            name: res.data.name
          }
        })
      }
    }).catch(err => {
      message.error(err ? err.message : ERROR_MESSAGE);
    })
  }

  // useEffect(() => {
  //   if (loading && !session.loading && session.data) {
  //     localStorage.setItem("userData", JSON.stringify(session.data));
  //     history.push('/register/choose-category');
  //   }
  //   setLoading(session.loading);
  // }, []);

  // const onFinish = values => {
  //   history.push('/register/choose-mentor',
  //     {
  //       data: {
  //         ...userInfo,
  //         mentorCatCode: values.category
  //       }
  //     }
  //   )
  // };

  const onFinish = async (values) => {
    const storageData = localStorage.getItem("userData");
    setShowLoader(true);
    if (storageData) {
      setLoading(true);
      let data = JSON.parse(storageData);
      dispatch(running(true));
      header.Authorization = data.authToken ? data.authToken : data.token;
      const body = {
        "userId": data.payload.id,
        "communityId": values.communityId,
        "type": type ? 'update' : 'add'
      }
      // calling asssign comunity api and redirect to choose category page
      assignCommunity(body, header).then(res => {
        // showing success message
        message.success(res.message);
        setShowLoader(false);
        setLoading(false);
        dispatch(whoami())
        if (res && res.status) {
          dispatch(setUser({ ...session.data, communityId: body.communityId }));
          if (type) {
            props.loadNextTab(CHOOSE_A_CATEGORY);
          } else {
            setPlaySound(true);
          }
          // history.push('/register/choose-category',
          //   {
          //     data: {
          //       busInfoCode: result.busInfo,
          //       ...userInfo
          //     }
          //   }
          // );
        }
      }).catch(err => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setLoading(false);
        setShowLoader(false);
      })
    } else {
      message.error(INVALID_USER);
      setLoading(false);

    }
  };

  const onClickSkip = async () => {
    if (type) {
      history.push('/dashboard');
    } else {
      history.push('/register/choose-category');
    }

    // dispatch(running(true));
    // const body = {
    //   "busInfoCode": userInfo.busInfoCode ?? 0,
    //   "mentorInfoCode": userInfo.mentorInfoCode ?? 0,
    //   "userPhoto": session.data.userPhoto ?? ''
    // }
    // dispatch(updateUserData(session.data, body));
  }

  useEffect(() => {
    if (playSound) {
      setTimeout(() => {
        history.push('/register/choose-category');
        setPlaySound(false);
      }, 1000);
    }
  }, [playSound]);

  return (
    <Layout.Content className={`min-height-60vh ${style.block}`}>
                  {playSound ? <TokenEarnSound /> : ''}

      {showLoader ? <Loader /> : ''}
      {type ? '' : <Row className={`header-divider`} />}
      {/* <Row className={style.title_block} justify={'center'}>
        <Container fluid={true} style={{ textAlign: `center` }}>
          Now just choose a Community.
        </Container>
      </Row> */}
      <Container className={style.reg_container}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} className={`register-form`}>
          <h2 className={style.heading}>Now just choose a Community.</h2>
          <div className={style.form_group}>
            <Form.Item name="communityId" label={`Select a community`} rules={[{ required: true, message: 'Please select community' }]}>
              <Select placeholder="Choose Community" onChange={handleDropDownInput} allowClear showSearch>
                {communities.length ? communities.map(c => <Select.Option value={c._id} key={c._id}>{c.name}</Select.Option>) : ''}
              </Select>
            </Form.Item>
          </div>
          {
            selectedCategory ? <div className="row">
              <div className="col-12">
                <div className="community-detail-section">
                  <h2 className="community-name">{communityDetail.name}</h2>
                  <h3>{communityDetail.city}</h3>
                  <p>{communityDetail.description}</p>
                </div>
              </div>
            </div> : ''
          }
          <Row justify={'space-between'} align='middle'>
            {/* {
              !session.data.verify &&
              <MyAncor className="skip-form-btn" onClick={onClickSkip}>{type ? 'Cancel' : 'Skip'}</MyAncor>
            } */}
            <Button type="primary" htmlType="submit" className={style.submit_btn} loading={loading}>
              Submit
            </Button>
            {/* <div /> */}
          </Row>
        </Form>
      </Container>
    </Layout.Content>
  )
}

export default ChooseCommunity
