import React, { useState } from "react";
import { Layout } from "antd";
import style from './base.module.sass';
import SiteHeader from '../../widgets/header';
import SiteFooter from '../../widgets/footer';
// REACT REDUX
import {
    // useDispatch,
    useSelector
} from 'react-redux';
// ADMIN COMPONENTS
import {
    AdminSiderMenu,
    AdminHeader
} from '../admin';

const { Content } = Layout;

export default props => {
    // const dispatch = useDispatch();
    const loginUserData = useSelector(state => state.user);
    const [collapsed, setCollapsed] = useState(false);
    const handleOnCollapse = () => {
        setCollapsed(prevState => !prevState);
    };
    const storageData = localStorage.getItem("userData");
    const role = storageData ? JSON.parse(storageData).payload : '';
    const roleType = role ? role.role : '';
    const [activeMenu, setActiveMenu] = React.useState('/admin/dashboard');

    function handleSideBar(text) {
        setActiveMenu(text);
    }

    React.useEffect(() => {
        if (roleType === 'admin') {
            // let url = window.location.pathname;
            // let urlKey = url.split('/')[2];
            setActiveMenu(`${props.children.props.history.location.pathname}`);
        }
    }, [])

    React.useEffect(() => {
        if (loginUserData.data.id) {
        } else {
            // setActiveMenu('/admin/dashboard')
        }
    }, [loginUserData]);

    return (
        <Layout className={`layout ${style.site_layout}`}>
            {/* {roleType === 'admin'?<AdminHeader collapsed={collapsed} handleOnCollapse={handleOnCollapse} />: <SiteHeader {...props} />} */}
            {roleType === 'admin' ? <AdminSiderMenu collapsed={collapsed} handleOnCollapse={handleOnCollapse} activeMenu={activeMenu} handleSideBar={(event) => handleSideBar(event)} /> : <SiteHeader {...props} />}
            {/* {getHeaderOnRoleBase(roleType)} */}
            {/* {getSideBarOnRoleBase(roleType)} */}
            {roleType === 'admin' ? <GetAdminRoutes collapsed={collapsed} handleOnCollapse={handleOnCollapse} {...props} /> : props.children}
            {roleType === 'admin' ? '' : <SiteFooter />}
        </Layout>
    )
}


function GetAdminRoutes(props) {

    return (
        <React.Fragment>
            <Layout>
                <AdminHeader collapsed={props.collapsed} handleOnCollapse={props.handleOnCollapse} />
                <Content style={{ margin: '24px 16px 0' }}>
                    <div style={{ padding: 24, background: '#fff', minHeight: 20 }}>
                        {props.children}
                    </div>
                </Content>
            </Layout>
        </React.Fragment>
    )
}
