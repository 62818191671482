import io from "socket.io-client";
const apiBaseUrl = process.env.REACT_APP_SOCKET_IO_URL;

export const socket = io(apiBaseUrl);


export const getChatMessage = (cb) => {
  if (!socket) return (true);
  socket.on('new-message', (msg) => {

    return cb(null, msg);
  });
}

export const callDisconnect = (cb) => {
  if (!socket) return (true);
  socket.emit('on-call-end', {
    callerName: cb.callerName,
    calleName: cb.calleName,
    connectionId: cb.connectionId,
    tokboxSession: cb.tokboxSession,
    callsDuration: cb.callsDuration,
    callStatus: cb.callStatus
  });
}


export const getLiberatorChatNotification=(cb)=>{
  if (!socket) return (true);
  socket.on('notification-fire-liberal', (msg) => {

    return cb(null, msg);
  });
}
