import React from 'react';
import {
  Layout,
  Menu,
  // ,Image
} from 'antd';
import {
  DashboardOutlined,
  WalletOutlined,
  SoundOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  // BarsOutlined,
  MenuOutlined,
  TeamOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  FundViewOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
// REACT-ROUTER
// import {Link} from 'react-router-dom';
// import './Style.less';
// CSS
import './adminSideBar.css';
// import ProjectLogo from '../../../../assets/images/EL-Logo.png';
// HISTORY
import { history } from '../../../../redux/store';
// import { faWallet } from '@fortawesome/free-solid-svg-icons';
// REDUCER
import { setSideMenuBar } from '../../../../redux/slices/authSlice';

const { SubMenu } = Menu;
const { Sider } = Layout;

function AdminSiderMenu({
  handleOnCollapse,
  collapsed,
  activeMenu,
  handleSideBar,
}) {
  const theme = 'light';
  const dispatch = useDispatch();
  const activeSideMenu = useSelector((state) => state.user.sideMenuBar);

  // const [activeMenu, setActiveMenu] = React.useState('dashboard');

  const handleSliderMenuClick = (action) => {
    // setActiveMenu(action.key);
    handleSideBar(action.key);
    dispatch(setSideMenuBar(action.key));
    switch (action.key) {
      case '/admin/dashboard':
        history.push('/admin/dashboard');
        break;
      case '/admin/liberator':
        history.push('/admin/liberator');
        break;
      case '/admin/resource':
        history.push('/admin/resource');
        break;
      case '/admin/wallet':
        history.push('/admin/wallet');
        break;
      case '/admin/news':
        history.push('/admin/news');
        break;
      case '/admin/user':
        history.push('/admin/user');
        break;
      case '/admin/post':
        history.push('/admin/post');
        break;
      case '/admin/community':
        history.push('/admin/community');
        break;
      case '/admin/category':
        history.push('/admin/category');
        break;
      case '/admin/setting/home':
        history.push('/admin/setting/home');
        break;
      case '/admin/setting/aboutus':
        history.push('/admin/setting/aboutus');
        break;
      case '/admin/buisness/industries':
        history.push('/admin/buisness/industries');
        break;
      case '/admin/community/upcoming':
        history.push('/admin/community/upcoming');
        break;
      case 'addProduct':
        history.push('/add-product');
        break;
      case 'showCustomers':
        history.push('/customers');
        break;
      case 'addCustomer':
        history.push('/add-customer');
        break;
      case '/admin/mentor-requests':
        history.push('/admin/mentor-requests');
        break;
      case '/admin/surveys':
        history.push('/admin/surveys');
        break;
      case '/admin/invitations':
        history.push('/admin/invitations');
        break;
      default:
        history.push('/');
    }
  };

  let currentActiveSideMenu = activeSideMenu ? activeSideMenu : activeMenu;
  return (
    <Sider
      breakpoint='lg'
      collapsedWidth='80'
      onCollapse={handleOnCollapse}
      collapsed={collapsed}
      width='256'
      theme={theme}
    >
      <a href='/admin/dashboard'>
        <div className='menu-logo' />
      </a>
      <Menu
        mode='inline'
        theme={theme}
        onClick={handleSliderMenuClick}
        selectedKeys={[currentActiveSideMenu]}
        defaultSelectedKeys={[currentActiveSideMenu]}
      >
        <Menu.Item key='/admin/dashboard'>
          <DashboardOutlined />
          <span className='nav-text'>Dashboard</span>
        </Menu.Item>
        <Menu.Item key='/admin/liberator'>
          <UsergroupAddOutlined />
          <span className='nav-text'>Liberator</span>
        </Menu.Item>
        <Menu.Item key='/admin/resource'>
          <DatabaseOutlined />
          <span className='nav-text'>Resource</span>
        </Menu.Item>
        <Menu.Item key='/admin/wallet'>
          <WalletOutlined />
          <span className='nav-text'>Wallet</span>
        </Menu.Item>
        <Menu.Item key='/admin/news'>
          <SoundOutlined />
          <span className='nav-text'>News</span>
        </Menu.Item>
        <Menu.Item key='/admin/user'>
          <UserOutlined />
          <span className='nav-text'>User</span>
        </Menu.Item>
        <Menu.Item key='/admin/post'>
          <FileTextOutlined />
          <span className='nav-text'>Post</span>
        </Menu.Item>
        <Menu.Item key='/admin/community'>
          <TeamOutlined />
          <span className='nav-text'>Community</span>
        </Menu.Item>
        <Menu.Item key='/admin/category'>
          <MenuOutlined />
          <span className='nav-text'>Category</span>
        </Menu.Item>
        <Menu.Item key='/admin/surveys'>
          <FundViewOutlined />
          <span className='nav-text'>Surveys</span>
        </Menu.Item>
        <Menu.Item key='/admin/community/upcoming'>
          <ExclamationCircleOutlined />
          <span className='nav-text'>Upcoming Communities</span>
        </Menu.Item>
        <Menu.Item key='/admin/mentor-requests'>
          <ExclamationCircleOutlined />
          <span className='nav-text'>Mentor Requests</span>
        </Menu.Item>
        <Menu.Item key='/admin/invitations'>
          <ExclamationCircleOutlined />
          <span className='nav-text'>Invitations</span>
        </Menu.Item>
        <SubMenu
          key='setting'
          title={
            <span>
              <SettingOutlined />
              <span>Setting</span>
            </span>
          }
        >
          <Menu.Item key='/admin/setting/home'>
            <span className='nav-text'>Home page</span>
          </Menu.Item>
          <Menu.Item key='/admin/setting/aboutus'>
            <span className='nav-text'>About us</span>
          </Menu.Item>
          <Menu.Item key='/admin/buisness/industries'>
            <span className='nav-text'>Buisness Industries</span>
          </Menu.Item>
        </SubMenu>
        {/*
        <SubMenu
          key="customers"
          title={
            <span>
              <TeamOutlined />
              <span>Customers</span>
            </span>
          }>
          <Menu.Item key="showCustomers">
            <span className="nav-text">Show Customers</span>
          </Menu.Item>
          <Menu.Item key="addCustomer">
            <span className="nav-text">Add Customer</span>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="settings">
          <SettingOutlined />
          <span className="nav-text">Settings</span>
        </Menu.Item>
        <Menu.Item key="reports">
          <FundProjectionScreenOutlined />
          <span className="nav-text">Reports</span>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
}

export default AdminSiderMenu;
