import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Layout, message, Form, Input } from "antd";
import Container from "../../widgets/container";
import style from './register.module.sass';
import { history } from "../../../redux/store";
import { header } from '../../../api/rest';
import { verify, setLoading as running, checkToken, resendVerication, logout } from "../../../redux/slices/authSlice";
// LOADER
import {
  // SkeletonDashBoardPostLoader,
  Loader
} from '../../shared/Loader';
// CONSTANTS
import { CONSTANTS } from '../../shared/constants';
// CSS
import './register.scss';

const { ERROR_MESSAGE } = CONSTANTS

const Verify = ({ match }) => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.user);
  const [loading, setLoading] = useState(session.loading);
  // const [email, setEmail] = useState('');
  const [showAddEmail, setShowAddEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const { token } = match.params;
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      // offset: 8,
      span: 24,
    },
  };

  useEffect(() => {
    checkTokenIsValid(token)
  }, [token]);

  useEffect(() => {
    if (loading && !session.loading && session && session.data && session.data.status) {
      message.success(session.data.message);
      setErrorMessage(session.data.message);
      history.push('/user/profile/update', {
        data: 'register'
      })
      // history.push('/register/business-details');
      // history.push('/');
    }
    setLoading(session.loading);
  }, [loading, session]);

  function checkTokenIsValid(token) {
    let data = {
      email_verify_token: token
    }
    checkToken(data, header).then(res => {
      dispatch(running(true));
      dispatch(verify(token));
    }).catch(err => {
      dispatch(running(false));
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      if (err && err.message) {
        setErrorMessage(err.message)
      }
      setShowLoader(false);
    })
  }

  const onClickResend = (data) => {
    // dispatch(running(true));
    // dispatch(register(body));   
    resendVerication(data, header).then(res => {
      message.success(res.message);
      setShowLoader(false);
      dispatch(logout());
      history.push("/");
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      if (err && err.message) {
        setErrorMessage(err.message)
      }
      setShowLoader(false);
    })
  };

  function showEmailBox() {
    setShowAddEmail(true)
    setErrorMessage('')
  }

  const onFinish = (values) => {
    let data = {
      email: values.email
    }
    setShowLoader(true)
    onClickResend(data);
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <Layout.Content className={style.block}>
      <Row className={`header-divider`} />
      <Container className={`auth-modal min-height-60vh ${style.reg_container}`}>
        {showLoader ? <Loader /> : ''}
        <Row justify={'center'}>
          <h2>{errorMessage ? errorMessage : ''}</h2>
        </Row>
        {
          showAddEmail ? <React.Fragment>
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed} className="verify-email-form">
              <h3>Verify Your Email</h3>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: ' Please enter a valid email address',
                  },
                ]}>
                <Input type="email" />
              </Form.Item>
              <div className="text-center">
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit" >Submit</Button>
                </Form.Item>
              </div>
            </Form>
          </React.Fragment>
            :
            <React.Fragment>
              <Row justify={'center'}>
                <div className="back-home-btns">
                  <Button type="primary" className={style.submit_btn} onClick={() => history.push('/')}>Back to home</Button>
                  <Button type="primary" htmlType="submit" className={style.submit_btn} onClick={showEmailBox}>resend link</Button>
                </div>
              </Row>
              <Row justify={'center'}>
              </Row>
            </React.Fragment>
        }
      </Container>
    </Layout.Content>
  )
}

export default Verify