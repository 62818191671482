import React, {
    useEffect,
    useState
} from "react";
// SOCKET
import { socket } from '../../service/socket';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
// import {
//     momentLocalizer
//     ,Calendar,
//     Views
// } from "react-big-calendar";
import moment from "moment";
// import Container from "../../widgets/container";
import {
    Button,
    Table,
    Space,
    message,
    notification,
    Tooltip
} from "antd";
import {
    getMentorUserIdByEmail
} from '../../../redux/slices/mentorSlice';
import {
    getReminderList,
    getMentorsList,
    deleteReminder,
    updateReminder
} from "../../../redux/slices/reminderSlice";
// import {
//     getAnyUserProfile
// } from '../../../redux/slices/userProfileSlice';
import {
    PlusOutlined
} from '@ant-design/icons';
import {
    header
} from "../../../api/rest";
import {
    POP_UP_MODAL_HEADER_CONSTANTS,
    POP_UP_MODAL_CONSTANTS, CONSTANTS
} from '../../shared/constants';
// LOADERS
import {
    // SkeletonDashBoardPostLoader,
    Loader
} from '../../shared/Loader';
import PopUpModal from '../../shared/popupmodal';
// CSS
import "react-big-calendar/lib/css/react-big-calendar.css";
import './reminder.scss';

// const localizer = momentLocalizer(moment);

var {
    UPDATE_REMINDER,
    REMOVE_REMINDER
} = POP_UP_MODAL_HEADER_CONSTANTS;
var {
    ADD_EDIT_REMINDER,
    CONFIRMATION_POPUP_MODAL
} = POP_UP_MODAL_CONSTANTS;
var { ERROR_MESSAGE } = CONSTANTS;


export const Reminder = (props) => {

    React.useEffect(() => {
        document.title = "Reminder";
    })

    const [events, setEvents] = useState([]);
    // const [visible, setVisible] = useState(0);
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });
    const [modalObj, setModalObj] = useState({
        reminderTitle: '',
        reminderDesc: '',
        mentorId: '',
        reminderId: '',
    });
    const [mentorsList, setMentorsList] = useState([]);
    // payload data of user get by localstorage
    const storageInfo = localStorage.getItem("userData");
    const token = storageInfo ? JSON.parse(storageInfo).token : '';
    const payload = storageInfo ? JSON.parse(storageInfo).payload : '';
    const date = new Date();
    const utcHours = date.setUTCHours(0, 0, 0, 0);
    const addOneDay = new Date(utcHours).toISOString();
    const [showPageLoader, setShowPageLoader] = useState(true);


    useEffect(() => {
        if (token) {
            getReminderListApi();
            socket.emit('call-join-room', payload.id);
            // receivbein calls
            socket.on('callreceiver', (data) => {

                // getUserProfileData(data.senderId);
                openNotification(data);
            });
        }
    }, []);

    const columns = [
        {
            title: 'User',
            dataIndex: 'userId',
            key: 'userId',
            render: userId => <span>{userId.firstName + ' ' + userId.lastName}</span>
        },
        {
            title: 'title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: description => (
                <Tooltip placement="topLeft" title={description}>
                    <span className="single_line_clamp">{description}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Mentor Name',
            dataIndex: 'mentorId',
            key: 'mentorId',
            render: mentorId => <span>{mentorId ? mentorId.firstName + ' ' + mentorId.lastName : ''}</span>
        },
        {
            title: 'Date',
            dataIndex: 'start_date',
            key: 'start_date',
            // render: start_date => <a>{moment(start_date).format('MMM DD, YYYY hh:mm a').toString()}</a>
            ellipsis: {
                showTitle: false,
            },
            render: start_date => (
                <Tooltip placement="topLeft" title={moment(start_date).format('MMM DD, YYYY hh:mm a')}>
                    {moment(start_date).format('MMM DD, YYYY hh:mm a')}
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => redirectToMessaging('text', record)} disabled={record.start_date < addOneDay ? true : false}>Text Chat</Button>
                    <Button color="#73d13d" onClick={() => redirectToMessaging('video', record)} disabled={record.start_date < addOneDay ? true : false}>Video Chat</Button>
                    <Button onClick={() => fillUserInfoInModal(record, 'update')}>Update Reminder</Button>
                    <Button onClick={() => fillUserInfoInModal(record, 'delete')}>Delete Reminder</Button>
                </Space>
            ),
        }
    ];

    function getReminderListApi() {
        header.Authorization = token;
        const data = {
            userId: payload.id,
            currentDate: new Date().toISOString(),
            // currentDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
            days: moment().daysInMonth()
        };
        getReminderList(data, header).then(res => {
            setEvents(res.userTodayReminder);
            setShowPageLoader(false);

        }).catch(err => {
            // throw error
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    // function getUserProfileData(id) {
    //     header.Authorization = token;
    //     getAnyUserProfile(id, header).then(res => {
    //
    //     }).catch(err => {
    //         message.error(err && err.message ? err.message : ERROR_MESSAGE);
    //     })
    // }


    function redirectToMessaging(type, value) {
        const mentorEmail = value.mentorId.email;
        header.Authorization = token;
        getMentorUserIdByEmail(mentorEmail, header).then(res => {
            setShowPageLoader(false);
            props.history.push('/messaging',
                {
                    data: {
                        senderId: value.userId.id,
                        recieverId: res.data.id,
                        type: type,
                        fullName: value.mentorId.firstName + ' ' + value.mentorId.lastName
                    }
                }
            );
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function routeToCalendar() {
        props.history.push('/reminderCalendar')
    }

    function fillUserInfoInModal(data, type) {
        setModalObj(prevModalObj => {
            return {
                ...prevModalObj, reminderId: data.id,
                mentorId: data.mentorId.id,
                reminderTitle: data.title,
                reminderDesc: data.description
            }
        })
        if (type === 'delete') {
            toggleModal(CONFIRMATION_POPUP_MODAL, REMOVE_REMINDER);

        } else {
            toggleModal(ADD_EDIT_REMINDER, UPDATE_REMINDER);

        }
    }

    function getMentorListAPI() {
        header.Authorization = token;
        // Gets mentors list
        getMentorsList(header).then(res => {
            if (res.status) {
                setMentorsList(res.data);
            } else {
                setMentorsList([]);

            }
            setShowPageLoader(false);

        }).catch(err => {
            // error for client
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    // handle input of onchange functio of textbox
    function handleInput(event) {
        let { value, name } = event.target;
        setModalObj(prevModalObj => {
            return {
                ...prevModalObj, [name]: value
            }
        })

    }
    // opening popup modal by passing type and header
    function toggleModal(type, header) {
        getMentorListAPI();
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: header
            }
        });
    }

    // close poup modal
    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        setModalObj(prevModalObj => {
            return {
                ...prevModalObj, reminderTitle: '',
                reminderDesc: '',
                mentorId: ''
            }
        })
    }

    function onSubmitPopUpModal(event) {
        event.preventDefault();
        setShowPageLoader(true);

        if (popUpModal.type === ADD_EDIT_REMINDER) {
            updateReminderApi();
        }
        if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
            deleteReminderApi();
        }
        // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
    }

    // Call update reminder api
    function updateReminderApi() {
        let data = {
            userId: payload.id,
            reminderId: modalObj.reminderId,
            title: modalObj.reminderTitle,
            description: modalObj.reminderDesc,
            mentorId: modalObj.mentorId
        }
        header.Authorization = token;
        updateReminder(data, header).then(res => {
            message.success(res.message);
            closePopupModal();
            getReminderListApi();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);

        })
    }

    // delete reminder api
    function deleteReminderApi() {
        header.Authorization = token;
        deleteReminder(modalObj.reminderId, header).then(res => {
            message.success(res.message);
            closePopupModal();
            getReminderListApi();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);

        })
    }


    const close = () => {

        //     'Notification was closed. Either the close button was clicked or duration time elapsed.',
        // );
    };


    const pickup = (data, key) => {
        notification.close(key);
        props.history.push('/messaging',
            {
                data: {
                    senderId: data.senderId === payload.id ? data.senderId : payload.id,
                    recieverId: data.receipentId === payload.id ? data.senderId : payload.id,
                    session: data.session,
                    token: data.token,
                    type: 'joinVideoCall'
                }
            }
        )
    };

    const openNotification = (data) => {
        const key = `open${Date.now()}`;
        const btn = (
            <>
                <Button type="primary" size="small" onClick={() => notification.close(key)}>Hangup</Button>
                <Button type="primary" size="small" onClick={() => pickup(data, key)}>Pickup</Button>
            </>
        );
        notification.open({
            message: 'Calling',
            description:
                `${data.callerName} is calling you`,
            duration: 0,
            btn,
            key,
            onClose: close
        });
    };


    let addEditReminderVariable = {
        modalObj, mentorsList
    }, addEditReminderFunctionProps = {
        handleInput: handleInput
    };

    return (
        <div className="App">
            {showPageLoader ? <Loader /> : ''}

            <AnimatedOnScroll animationIn="fadeInUp" animationOut="fadeOutUp" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
            <div className="container mt-5 min-height-60vh">
                {
                    popUpModal.visible ?
                        <PopUpModal
                            title={popUpModal.modalHeader}
                            visible={popUpModal.visible}
                            onCancel={closePopupModal}
                            onOk={onSubmitPopUpModal}
                            type={popUpModal.type}
                            {...addEditReminderVariable}
                            {...addEditReminderFunctionProps}
                            description={REMOVE_REMINDER}
                        /> : ''
                }
                <div className="row">
                    <div className="col-md-12 col-12 mb-4">
                        <div className="reminder-head">
                            <h2>Reminder
                                <Button type="primary" onClick={routeToCalendar} icon={<PlusOutlined />} size="large">
                                    Add Reminder
                            </Button>
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="reminder-table">
                            <Table dataSource={events} columns={columns} scroll={{ x: true }} />
                        </div>
                    </div>
                </div>
            </div>
            </AnimatedOnScroll>
        </div>
    )
}
