/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import parse from 'html-react-parser';
// REACT ROUTER
import { Link, NavLink, useHistory } from 'react-router-dom';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import { message, Button, Popover, Tooltip, Popconfirm, Tag } from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  DashOutlined,
  EditOutlined,
  PlayCircleOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
} from '@ant-design/icons';
import SurveyList from './SurveysList';
import SurveyPopup from '../../shared/SurveyPopup';
import { DashboardCard } from '../sponser-dashboard';
// REACT REDUX
import { useSelector, useDispatch } from 'react-redux';
// Images
// import MessageIcon from '../../../assets/images/icon-message.png';
// // import DefaultImg from '../../../assets/images/default-img.png';
// import ProfileImg from '../../../assets/images/profile-img.jpg';
// import Vision from '../../../assets/images/vision.jpg';
// import UserDefaultIcon from '../../../assets/images/user-default-img.png';
// import Latest from '../../../assets/images/latest.png';
// import CoinIcon from '../../../assets/images/coin.svg';
// import Badge1 from '../../../assets/images/badge1.png';
// import Badge2 from '../../../assets/images/badge2.png';
// import Goal1 from '../../../assets/images/goal1.png';
// import Goal2 from '../../../assets/images/goal2.png';
// import Goal3 from '../../../assets/images/goal3.png';
// import Goal4 from '../../../assets/images/goal4.png';
// import ProfileDetail from '../../../assets/images/profile-detail-img.jpg';
import TockenIcon from '../../../assets/images/token-icon.png';
import Thumb from '../../../assets/images/hovers/liked-thumb.png';
import ThumbHover from '../../../assets/images/hovers/liked-thumb-18A4AD.png';
import FollowedThumb from '../../../assets/images/hovers/thumb.png';
import FollowedThumbHover from '../../../assets/images/hovers/thumb-93E90E.png';
import Message from '../../../assets/images/hovers/message-FFFFFF.png';
import MessageHover from '../../../assets/images/hovers/message-18A4AD.png';
import CommentIcon from '../../../assets/images/comment-icon.png';
import HeartIcon from '../../../assets/icons/EQ_Icons_Likes.png';
import EmptyheartIcon from '../../../assets/icons/EQ_Icons_Like.png';
import ShareIcon from '../../../assets/icons/EQ_Icons_Share.png';
import CommentsIcon from '../../../assets/icons/EQ_Icons_Comment.png';
import DocumentIcon from '../../../assets/images/document.svg';
import LocationPin from '../../../assets/images/pin.svg';
import logo from '../../../assets/images/equiliberty-logo.png';
import DefaultBannerImage from '../../../assets/images/image_2021_03_11T11_53_14_686Z.png';
// CSS
import './profile.scss';
// API ACTIONS
import { apiGetMethod, header } from '../../../api/rest';
import { setLoading as running, whoami } from '../../../redux/slices/authSlice';
import {
  getUserProfile,
  updateUserProfile,
  togglefollowUser,
  getFollowerList,
  getFollowingList,
  getUserGoalList,
  createUserGoal,
  updateUserGoal,
  deleteUserGoal,
  tokenPay,
  getGoalImagesApi,
} from '../../../redux/slices/userProfileSlice';
import {
  getUserPost,
  listComments,
  toggleLikeApi,
  addComment,
  deleteCommentApi,
  deleteUserPost,
  updateUserPost,
  fileUpload,
  // , getAllUserPost
} from '../../../redux/slices/dashboardSlice';
import { listMentorCommunities } from '../../../redux/slices/registerSlice';
// SUBHEADER
import SubHeader from '../../widgets/subheader';
// LOADERS
import { SkeletonDashBoardPostLoader } from '../../shared/Loader';
// CONSTANTS
import {
  CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  REGEX_CONSTANTS,
} from '../../shared/constants';
// POPUPMODALS
import PopUpModal from '../../shared/popupmodal';
// Light Box
import Lightbox from 'react-image-lightbox';
// Momemt
import moment from 'moment';
// FILE UPLOAD METHOD
import { userImageUpload } from '../../../redux/slices/userProfileSlice';
// LOADERS
import { Loader } from '../../shared/Loader';
// Sound Notification component
import { TokenSpendSound, TokenEarnSound } from '../../shared/soundeffect';
import ImageCropper from '../../shared/ImageCropper';

var {
  TOKEN_EXPIRED_MESSAGE,
  ERROR_MESSAGE,
  ERROR_IMAGE_URL,
  POST_REMOVE_MESSAGE,
  FILE_LENGTH_LIMIT,
  COMMENT_REMOVE_MESSAGE,
  IMAGE_EXTENSION_ERROR,
  VIDEO_EXTENSION_ERROR,
} = CONSTANTS;
var {
  ALL_PHOTO_FORMAT_REGEX,
  ALL_VIDEO_FORMAT_REGEX,
  YOUTUBE_URL_REGEX,
  YOUTUBE_EMBED_URL_REGEX,
} = REGEX_CONSTANTS;
var {
  EDIT_USER_PROFILE_VISION,
  EDIT_MENTOR_PROFILE_BIO,
  SOCIAL_SHARE,
  CONFIRMATION_HEADER,
  UPDATE_POST_HEADER,
  UPLOAD_BANNER_IMAGE,
  UPLOAD_USER_IMAGE,
  UPLOAD_VISION_IMAGE,
  FOLLOWER_LIST,
  FOLLOWING_LIST,
  UNFOLLOW_USER,
  ADD_GOAL,
  EDIT_GOAL,
  SEND_TOKEN,
} = POP_UP_MODAL_HEADER_CONSTANTS;
var {
  EDIT_VISION_POP_MODAL,
  SOCIAL_SHARE_MODAL,
  CONFIRMATION_POPUP_MODAL,
  UPDATE_POPUP_MODAL,
  UPDATE_PROFILE_MODAL_IMAGES,
  FOLLOWER_FOLLOWING_MODAL,
  ADD_EDIT_GOAL_POP_MODAL,
  SEND_TOKEN_MODAL,
  USER_VISION_MENTOR_BIO_POP_UP_MODAL,
  ADD_EDIT_USER_RESOURCE
} = POP_UP_MODAL_CONSTANTS;

function Profile(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((state) => state.user);
  const user = session.user;

  const [mentors, setMentors] = useState([]);
  const storageData = localStorage.getItem('userData');
  const [allPosts, setAllPosts] = useState([]);
  const [isLoading, showIsLoading] = useState(true);
  let {
    firstName,
    lastName,
    userVision,
    visionImage,
    userPhoto,
    bannerImage: userBannerImage,
    badgeToken,
    createdAt,
    followersCount,
    followingCount,
    isFollowed,
    communityId,
    // city,
    role,
    bio,
    // moodleToken,
    menteeCount,
    availability,
    _id,
  } = session.data;
  const [bannerImage, setBannerImage] = useState(userBannerImage);
  if (!userVision) {
    userVision = `<p>1. Create a "Vision Board" (update your profile/cover page with inspiration for your business and
  make/upload a video explaining what problem you will solve (ex: business idea, wealth, job, etc.)</p>`;
  }
  //
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [currentPostDescription, setCurrentPostDescription] = useState('');
  // const [postInput, setPostInput] = useState('');
  const { id, zipcode, moodleToken } = storageData
    ? JSON.parse(storageData).payload
    : '';
  const { token } = storageData ? JSON.parse(storageData) : '';
  const [comment, setComment] = useState('');
  const [badges, setBadges] = useState([]);
  const [state, setState] = React.useState({
    lat: '28.612821703621936',
    lon: '77.23326859801553',
    geo: {},
    locationEnable: true,
  });
  const [userNewVision, setUserNewVision] = useState('');
  const [lastCommentViewedPostId, setLastCommentViewedPostId] = useState();
  // Light Box
  const [imageViewingPost, setImageViewingPost] = useState({});
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  // COMMENT
  const [toggleCommentBox, setToggleCommentBox] = useState('');
  // user profile
  // const [userProfileInfo, setUserProfileInfo] = useState({});
  const [activePostId, setActivePostId] = useState('');
  const [activeCommentId, setActiveCommentId] = useState('');
  const [updatedPost, setUpdatedPost] = useState('');
  const [updatedPostLocation, setUpdatedPostLocation] = useState('');
  const [activePostDocument, setActivePostDocument] = useState('');
  const [activePostImage, setActivePostImage] = useState('');
  const [updatedLocationBox, setUpdatedLocationBox] = useState(false);
  // const [isSingleFileSelected, setIsSingleFileSelected] = useState(false);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [checkInAddress, setCheckInAddress] = useState();
  const [isSingleUpdatePostFileSelected, setIsSingleUpdatePostFileSelected] =
    useState(false);
  const [updatePostDocumentObj, setUpdatePostDocumentObj] = useState({
    postImage: '',
    postDocument: '',
  });
  // const [mapBoxStatus, setMapBoxStatus] = useState(false);
  // Default status is blank when file choose it will selected when file is uploaded it is set to done
  const [updatePostDocumentObjStatus, setUpdatePostDocumentObjStatus] =
    useState({
      postImage: '',
      postDocument: '',
    });
  const [saveId, setSavedId] = useState('');
  const isSingleUpdatedFileChoosed =
    typeof updatePostDocumentObj.postImage === 'object' &&
    typeof updatePostDocumentObj.postDocument === 'object'
      ? false
      : typeof updatePostDocumentObj.postImage === 'object'
      ? true
      : typeof updatePostDocumentObj.postDocument === 'object'
      ? true
      : false;
  const [updateProfileObj, setUpdateProfileObj] = useState({
    bannerImage: '',
    userPhoto: '',
    visionImage: '',
  });
  const [updateProfileObjStatus, setUpdateProfileObjStatus] = useState({
    bannerImage: '',
    userPhoto: '',
    visionImage: '',
  });
  const [showLoader, setShowLoader] = useState({
    bannerImage: false,
    userPhoto: false,
    visionImage: false,
  });
  const [followerList, setFollowerList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [followerId, setFollowerId] = useState('');
  const [followingId, setFollowingId] = useState('');
  const [communityName, setCommunityName] = useState('');
  const [userType, setUserType] = useState('');
  const [visionImageType, setVisionImageType] = useState('');
  const [userGoalList, setUserGoalList] = useState([]);
  const [currentEntry, setCurrentEntry] = useState({
    id: '',
    description: '',
    name: '',
    status: '',
    image: '',
  });
  // const [goalImage, setGoalImage] = useState('');
  const [modalLoader, setModalLoader] = useState(false);
  const [goalImageModal, setGoalImageModal] = useState([]);

  const [playSound, setPlaySound] = useState(false);
  const [playEarnSound, setPlayEarnSound] = useState(false);
    const [contacts, setContacts] = useState([]);

  // const [isFollowed, setIsFollowed] = useState();

  const getMentors = async () => {
    const mentors = await apiGetMethod('/sponsors/invites?role=mentor');
    setMentors(mentors);
  };


  useEffect(() => {
    document.title = 'Profile';
  }, []);

  useEffect(() => {
    //
    if (storageData) {
      getUserProfileInfo();
      getBadges();
      getMentors();

    } else {
      props.history.push('/');
      message.error(TOKEN_EXPIRED_MESSAGE);
    }
  }, [moodleToken]);

  const getBadges = useCallback(async () => {
    let res = await fetch(
      `https://learning.equiliberty.com/webservice/rest/server.php?wstoken=${moodleToken}&wsfunction=core_badges_get_user_badges&moodlewsrestformat=json`
    );
    let json = await res.json();
    if (json.badges) {
      setBadges(json.badges);
    }
  }, [moodleToken]);

  const getUserProfileInfo = () => {
    let postId = props.location.state ? props.location.state.postId : '';
    let userType = props.location.state ? props.location.state.userType : '';
    let { payload } = JSON.parse(storageData);
    let finalId = postId ? postId : payload.id;
    //
    setSavedId(id);
    setUserType(userType);
    dispatch(running(true));
    let user_id = {
        id: finalId,
        type: userType === 'other' ? 'other' : 'normal',
      },
      followed_user_id = {
        userId: postId,
        followedById: id,
        type: 'following',
      };
    let finalObj = postId ? followed_user_id : user_id;
    dispatch(getUserProfile(finalObj));
    setPlaySound(false);
  };

  useEffect(() => {
    if (storageData) {
      getSingleUserPost();
      if (role === 'mentor') {
      } else {
        getGoalList();
      }
    } else {
      props.history.push('/');
      message.error(TOKEN_EXPIRED_MESSAGE);
    }
  }, []);

  useEffect(() => {
    if (communityId) {
      getCommunityName();
    }
  }, [communityId]);

  function getSingleUserPost() {
    let postId = props.location.state ? props.location.state.postId : '';
    let { payload } = JSON.parse(storageData);
    let id = postId ? postId : payload.id;
    header.Authorization = token;
    getUserPost(id, header)
      .then((res) => {
        if (res.data && res.data.length) {
          setAllPosts(res.data);
        } else {
          setAllPosts([]);
        }
        showIsLoading(false);
        setShowPageLoader(false);
      })
      .catch((err) => {
        showIsLoading(false);
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function getCommunityName() {
    header.Authorization = token;
    let value = zipcode;
    listMentorCommunities(value, header)
      .then((res) => {
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].id === communityId) {
              setCommunityName(res.data[i].name);
            }
          }
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  // show popup modal
  function showPopupModal(type, header, value) {
    let text = role === 'mentor' ? bio : userVision;
    setUserNewVision(text);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
    if (header === EDIT_GOAL) {
      setCurrentEntry((prevCurrentEntry) => {
        return {
          ...prevCurrentEntry,
          id: value.id,
          name: value.name,
          description: value.description,
          image: value.image,
          status: value.status,
        };
      });
    }

    if (type === ADD_EDIT_GOAL_POP_MODAL) {
      getGoalsImagesFromApi();
    }
  }

  // show popup modal
  function showSocialMediaSharePopUp(currentPostDescription) {
    setCurrentPostDescription(currentPostDescription);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: SOCIAL_SHARE_MODAL,
        modalHeader: SOCIAL_SHARE,
      };
    });
  }
  // save user photo vision text and user banner image
  function submitData() {
    // event.preventDefault();
    dispatch(running(true));
    let { payload, token } = JSON.parse(storageData);
    let userVision = userNewVision,
      bio = userNewVision;
    let finalValue = role === 'mentor' ? bio : userVision,
      objKey = role === 'mentor' ? 'bio' : 'userVision';
    let data = {
        emailAddress: payload.email,
        id: payload.id,
      },
      value = {};
    value[objKey] = finalValue;
    const body = {
      ...data,
      ...value,
    };
    header.Authorization = token;
    dispatch(updateUserProfile(body, header));
    // TEMPORARY CODE
    setTimeout(() => {
      getUserProfileInfo();
    }, 2000);
    // updateUserProfileInfo(body,header)
    // getUserProfileInfo();
  }

  function handleVisionData(data) {
    setUserNewVision(data);
  }

  useEffect(() => {
    // calling close popup
    closePopupModal();
    setUpdateProfileObjStatus((prevUpdateProfileObjStatus) => {
      return {
        ...prevUpdateProfileObjStatus,
        bannerImage: '',
        userPhoto: '',
        visionImage: '',
      };
    });
  }, [session]);

  // fetch all comments of a particular post
  function getAllComments(postId) {
    header.Authorization = token;
    listComments(postId, header)
      .then((res) => {
        if (res.data && res.data) {
          setLastCommentViewedPostId(postId);
          if (lastCommentViewedPostId) {
            const index = allPosts.findIndex(
              (post) => post._id === lastCommentViewedPostId
            );
            allPosts[index].comments = [];
          }
          const index = allPosts.findIndex((post) => post._id === postId);
          allPosts[index].comments = res.data;
          setAllPosts([]);
          setAllPosts(allPosts);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  // open light box
  function openLightBox(post, imageIndex) {
    setImageViewingPost(post);
    setImageIndex(imageIndex);
    setIsLightBoxOpen(true);
  }

  // calling toggle like api
  function callingToggleLikeApi(postId, userId, Status) {
    let data = {};
    setShowPageLoader(true);
    header.Authorization = token;
    data.postId = postId;
    data.userId = userId;
    data.status = Status;
    toggleLikeApi(data, header)
      .then((res) => {
        message.success(res.message);
        const index = allPosts.findIndex((post) => post._id === data.postId);
        allPosts[index].liked = data.status;
        if (data.status) {
          allPosts[index].totalLikes += 1;
        } else {
          allPosts[index].totalLikes -= 1;
        }
        setAllPosts([]);
        setAllPosts(allPosts);
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  // Submit comment data
  function submitComment(event) {
    event.preventDefault();
    setShowPageLoader(true);
    let data = {};
    header.Authorization = token;
    data.userId = id;
    data.postId = toggleCommentBox;
    data.comment = comment;
    dispatch(running(true));
    // dispatch(createUserPost(data, header))
    addComment(data, header)
      .then((res) => {
        // showing success message
        message.success(res.message);
        setToggleCommentBox('');
        setComment('');
        setState(state);
        getSingleUserPost();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

    const showAddEditResourcePopupModal = (title, data) => {
      setCurrentEntry((old) => ({...old, ...data}))
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: ADD_EDIT_USER_RESOURCE,
        modalHeader: title,
        currentEntry: data,
      };
    });
  };
  // Toggle comment box
  function toggleCommentBoxfunc(id) {
    setToggleCommentBox(id);
  }
  // save value of user comment
  function handleCommentInput(event) {
    let {
      value,
      // , name
    } = event.target;
    // setCommentBoxObj(prevcommentBoxObj => {
    // return {
    // ...prevcommentBoxObj,
    // [name]:value
    // }
    // })
    setComment(value);
  }

  // Showing confimation poupmodal
  function ToggleConfirmationPopup(postId, id, description, type, modalHeader) {
    setActiveCommentId(id);
    setActivePostId(postId);
    setCurrentPostDescription(description);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: modalHeader,
      };
    });

    if (type === 'UPDATE_POPUP_MODAL') {
      let data = allPosts.filter((x) => {
        if (x.id === postId) {
          return x;
        }
      })[0];
      setUpdatedPost(data.description);
      setUpdatedPostLocation(data.postMap);
      setActivePostDocument(data.postDocument);
      setActivePostImage(data.postImage);
    } else {
    }
  }

  // Calling delete user post api
  function functionDeleteUserPostApi() {
    let data = {
      postId: activePostId,
    };
    header.Authorization = token;
    deleteUserPost(data, header)
      .then((res) => {
        message.success(res.message);
        // getAllComments(activePostId);
        getSingleUserPost();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  // close poup modal
  function closePopupModal(status, modalType) {
    if (modalType === FOLLOWER_FOLLOWING_MODAL) {
      setPopUpModal((prevPopUpModal) => {
        return {
          ...prevPopUpModal,
          visible: true,
          type: FOLLOWER_FOLLOWING_MODAL,
          modalHeader: FOLLOWER_LIST,
        };
      });
      getUserProfileInfo();
      getFollowingListApi();
    } else {
      setPopUpModal((prevPopUpModal) => {
        return {
          ...prevPopUpModal,
          visible: false,
          type: '',
          modalHeader: '',
        };
      });
      setActiveCommentId('');
      setActivePostId('');
    }
    setVisionImageType('');
    setUpdatedLocationBox(false);
  }
  // when user on submit button in delete comment popup modal then api function is called
  function callDeleteCommentApi() {
    // event.preventDefault();
    functionDeleteCommentApi();
  }

  // Calling delete Comment api
  function functionDeleteCommentApi() {
    let data = {
      commentId: activeCommentId,
    };
    header.Authorization = token;
    deleteCommentApi(data, header)
      .then((res) => {
        message.success(res.message);
        // getAllComments(activePostId);
        getSingleUserPost();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  // handle Popup modal location input
  function handlePopUpModalLocationInpt(event) {
    let { label } = event;
    setUpdatedPostLocation(label);
  }
  // save popup modal input values called on onchange function
  function handlePopUpModalFileInput(event) {
    let { files, name } = event.target,
      arrayLength =
        name === 'postImage'
          ? activePostImage.length
          : activePostDocument.length,
      totalCount = arrayLength + files.length;
    if (totalCount > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
    } else {
      if (updatePostDocumentObj[name] === '') {
        setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
          // Object.assign would also work
          return { ...prevUpdatePostDocumentObj, [name]: files };
        });
        setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
          // Object.assign would also work
          return { ...prevUpdatePostDocumentObjStatus, [name]: 'selected' };
        });
      } else {
        let data = addFileListObj(name, files, updatePostDocumentObj, 'update');
        if (data) {
          setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObj, [name]: data };
          });
          setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObjStatus, [name]: 'selected' };
          });
        }
      }
    }
  }

  function handlePopUpModalInput(event) {
    let { value } = event.target;
    setUpdatedPost(value);
  }

  function removeSelectedImageOrDoc(event, index, name) {
    let { id } = event.target;
    //
    if (name === 'activePostDocument') {
      // activePostDocument.splice(index, 1);
      // let arr = activePostDocument;
      let arr = activePostDocument.filter((value, id) => id !== index);
      setActivePostDocument(arr);
    } else {
      let arr = activePostImage.filter((value, id) => id !== index);
      setActivePostImage(arr);
    }
  }

  function toggleLocationBox() {
    setUpdatedLocationBox(true);
  }

  function removeUpdatedModalSelectedFile(event, name, id) {
    let activeArr = Array.prototype.slice.call(updatePostDocumentObj[name]);
    activeArr.splice(id, 1);
    activeArr.forEach(function (file, key) {
      activeArr[key] = file;
    });
    setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
      // Object.assign would also work
      return { ...prevUpdatePostDocumentObj, [name]: activeArr };
    });
  }

  // handling add more file list object
  function addFileListObj(name, file, documentVariable, type) {
    let activeArr = Array.prototype.slice.call(documentVariable[name]),
      fileArr = Array.prototype.slice.call(file);
    let newArray = activeArr.concat(fileArr);
    let activePostImageLength = activePostImage ? activePostImage.length : 0;
    let activePostDocumentLength = activePostDocument
      ? activePostDocument.length
      : 0;
    let activeVariableLength =
      name === 'postImage' ? activePostImageLength : activePostDocumentLength;
    let fileLength =
      type === 'create'
        ? newArray.length
        : activeVariableLength + newArray.length;
    if (fileLength > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
      return false;
    } else {
      newArray.forEach(function (file, key) {
        newArray[key] = file;
      });
      return newArray;
    }
  }

  function onSubmitPopUpModal(event) {
    // event.preventDefault();
    if (currentPostDescription === POST_REMOVE_MESSAGE) {
      setShowPageLoader(true);
      callDeletePostApi();
    }
    if (popUpModal.type === UPDATE_POPUP_MODAL) {
      submitPost(event, 'update');
    }
    if (popUpModal.type === EDIT_VISION_POP_MODAL) {
      submitData();
    }
    if (popUpModal.type === UPDATE_PROFILE_MODAL_IMAGES) {
      if (visionImageType === 'object') {
        uploadModalImages();
      } else {
        callUpdateUserProfile();
      }
    }
    if (popUpModal.modalHeader === ADD_GOAL) {
      setShowPageLoader(true);
      // uploadModalImages();
      createGoalList(event);
    }
    if (popUpModal.modalHeader === EDIT_GOAL) {
      setShowPageLoader(true);
      // event["image"] = goalImage ? goalImage : currentEntry.image;
      updateGoalList(event, 'modal');
    }
    if (currentPostDescription === COMMENT_REMOVE_MESSAGE) {
      setShowPageLoader(true);
      callDeleteCommentApi();
    }
    if (popUpModal.modalHeader === UNFOLLOW_USER) {
      callUnfollowAPI();
    }
    if (popUpModal.modalHeader === SEND_TOKEN) {
      setShowPageLoader(true);
      tokenPayoutApi(event);
    }
    if (popUpModal.type === USER_VISION_MENTOR_BIO_POP_UP_MODAL) {
      closePopupModal();
    }
    // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
  }

  function getGoalList() {
    header.Authorization = token;
    getUserGoalList(id, header)
      .then((res) => {
        //
        if (res.data) {
          setUserGoalList(res.data);
        } else {
          setUserGoalList([]);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setUserGoalList([]);
      });
  }

  function createGoalList(value) {
    header.Authorization = token;
    let data = {
      userId: id,
      ...value,
    };
    createUserGoal(data, header)
      .then((res) => {
        message.success(res.message);
        closePopupModal();
        getGoalList();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function updateGoalList(value, type) {
    header.Authorization = token;
    let values = type === 'modal' ? returnUpdateObj(value) : value;
    header.Authorization = token;
    updateUserGoal(values, header)
      .then((res) => {
        message.success(res.message);
        getGoalList();
        closePopupModal();
        setCurrentEntry((prevCurrentEntry) => {
          return {
            ...prevCurrentEntry,
            id: '',
            description: '',
            name: '',
            status: '',
            image: '',
          };
        });
        setPlayEarnSound(false);
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setPlayEarnSound(false);
      });
  }

  function returnUpdateObj(value) {
    let data = {
      id: currentEntry.id,
      ...value,
    };
    return data;
  }

  function deleteGoalList(data) {
    header.Authorization = token;
    deleteUserGoal(data, header)
      .then((res) => {
        message.success(res.message);
        getGoalList();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function holdSelectedImage(event) {
    let { files, name, type, value } = event.target;
    if (type === 'text') {
      setUpdateProfileObj((prevUpdateProfileObj) => {
        // Object.assign would also work
        return { ...prevUpdateProfileObj, [name]: value };
      });
      if (value) {
        setVisionImageType('text');
      } else {
        setVisionImageType('');
      }
    } else {
      if (files.length) {
        let regexType =
          name === 'visionImage'
            ? ALL_VIDEO_FORMAT_REGEX
            : ALL_PHOTO_FORMAT_REGEX;
        let fileType = name === 'visionImage' ? 'video' : 'image';
        let extensionType = files[0].name.slice(
          (Math.max(0, files[0].name.lastIndexOf('.')) || Infinity) + 1
        );
        let finalExtension = '.' + extensionType;
        let status = regexType.test(finalExtension);
        if (status) {
          setUpdateProfileObj((prevUpdateProfileObj) => {
            // Object.assign would also work
            return { ...prevUpdateProfileObj, [name]: files };
          });
          setUpdateProfileObjStatus((prevUpdateProfileObjStatus) => {
            return { ...prevUpdateProfileObjStatus, [name]: 'selected' };
          });
          setVisionImageType('object');
        } else {
          message.error(
            fileType === 'video' ? VIDEO_EXTENSION_ERROR : IMAGE_EXTENSION_ERROR
          );
        }
      } else {
        event.target.value = '';
        setVisionImageType('');
      }
    }
  }

  function uploadModalImages() {
    let imageName =
      popUpModal.modalHeader === UPLOAD_BANNER_IMAGE
        ? 'bannerImage'
        : popUpModal.modalHeader === UPLOAD_USER_IMAGE
        ? 'userPhoto'
        : 'visionImage';
    //
    let selectedFile = updateProfileObj[imageName];
    // callSingleFileUpload();
    callUploadUserImageApi(selectedFile, imageName);
  }

  async function callUpdateUserProfile() {
    dispatch(running(true));
    let { payload, token } = JSON.parse(storageData);
    const { visionImage, userPhoto, bannerImage } = session.data;
    const body = {
      bannerImage: updateProfileObj.bannerImage
        ? updateProfileObj.bannerImage
        : bannerImage,
      userPhoto: updateProfileObj.userPhoto
        ? updateProfileObj.userPhoto
        : userPhoto,
      visionImage: updateProfileObj.visionImage
        ? updateProfileObj.visionImage
        : visionImage,
      emailAddress: payload.email,
      id: payload.id,
    };
    header.Authorization = token;
    await dispatch(updateUserProfile(body, header));
    getUserProfileInfo();
    dispatch(whoami());
  }
  // Submit post data
  function submitPost(event, type) {
    header.Authorization = token;
    setShowPageLoader(true);
    if (
      updatePostDocumentObj.postDocument === '' &&
      updatePostDocumentObj.postImage === ''
    ) {
      CallUpdatePostApi();
    } else {
      for (const property in updatePostDocumentObj) {
        if (updatePostDocumentObj[property]) {
          uploadDocument(
            header,
            updatePostDocumentObj[property],
            property,
            type
          );
        } else {
        }
      }
    }
  }

  function CallUpdatePostApi() {
    let data = {};
    header.Authorization = token;
    data.id = activePostId;
    data.postImage = updatePostDocumentObj.postImage
      ? finalUploadedImageAndDocument('postImage')
      : activePostImage; // updatePostDocumentObj.postImage;
    data.postMap = updatedLocationBox ? updatedPostLocation : checkInAddress;
    data.postDocument = updatePostDocumentObj.postDocument
      ? finalUploadedImageAndDocument('postDocument')
      : activePostDocument; // updatePostDocumentObj.postDocument;
    data.description = updatedPost;
    data.userId = id;
    dispatch(running(true));
    updateUserPost(data, header)
      .then((res) => {
        message.success(res.message);
        // getAllComments(activePostId);
        setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
          // Object.assign would also work
          return {
            ...prevUpdatePostDocumentObj,
            postImage: '',
            postDocument: '',
          };
        });
        setCheckInAddress('');
        setState(state);
        // setMapBoxStatus(false);
        // setIsSingleFileSelected(false);
        setShowPageLoader(false);
        setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
          // Object.assign would also work
          return {
            ...prevUpdatePostDocumentObjStatus,
            postImage: '',
            postDocument: '',
          };
        });
        setPopUpModal((prevPopUpModal) => {
          return {
            ...prevPopUpModal,
            visible: false,
            type: '',
            modalHeader: '',
          };
        });
        // getPostApi();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function finalUploadedImageAndDocument(type) {
    let arr;
    if (type === 'postDocument') {
      if (activePostDocument.length) {
        arr = updatePostDocumentObj.postDocument.concat(activePostDocument);
        return arr;
      } else {
        return updatePostDocumentObj.postDocument;
      }
    } else {
      if (activePostImage.length) {
        arr = updatePostDocumentObj.postImage.concat(activePostImage);
        return arr;
      } else {
        return updatePostDocumentObj.postImage;
      }
    }
  }

  function uploadDocument(token, property, name, type) {
    var formData = new FormData();
    for (const File of property) {
      formData.append('files', File);
    }
    commonFileUpload(formData, token, name, type);
  }

  function commonFileUpload(formData, header, name, type) {
    fileUpload(formData, header)
      .then((res) => {
        let arr = [];
        for (let i = 0; i < res.image.length; i++) {
          arr.push(res.image[i].Location);
        }
        if (name === 'postImage') {
          setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObj, [name]: arr };
          });
          setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObjStatus, [name]: 'done' };
          });
        }
        if (name === 'postDocument') {
          setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObj, [name]: arr };
          });
          setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObjStatus, [name]: 'done' };
          });
        }
        if (isSingleUpdatedFileChoosed) {
          setIsSingleUpdatePostFileSelected(isSingleUpdatedFileChoosed);
        }
      })
      .catch((err) => {
        setShowPageLoader(false);
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  // for same file choose
  function onInputClick(event) {
    event.target.value = '';
  }

  // when user on submit button in delete comment popup modal then api function is called
  function callDeletePostApi() {
    // event.preventDefault();
    functionDeleteUserPostApi();
  }
  // Calling delete user post api
  function functionDeleteUserPostApi() {
    let data = {
      postId: activePostId,
    };
    header.Authorization = token;
    deleteUserPost(data, header)
      .then((res) => {
        message.success(res.message);
        // getAllComments(activePostId);
        // getPostApi();
        closePopupModal();
        setShowPageLoader(false);
        getSingleUserPost();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  useEffect(() => {
    let { postImage, postDocument } = updatePostDocumentObjStatus;
    if (postDocument === 'done' && postImage === 'done') {
      CallUpdatePostApi();
    }
  }, [updatePostDocumentObjStatus]);

  useEffect(() => {
    if (isSingleUpdatePostFileSelected) {
      CallUpdatePostApi();
    }
  }, [isSingleUpdatePostFileSelected]);
  function uploadBannerImage(imageBlob) {
    header.Authorization = token;

    setShowPageLoader(true);

    const formData = new FormData();
    formData.append('file', imageBlob, 'filename.png');
    // header.Authorization = token;
    userImageUpload(formData, header)
      .then((res) => {
        // message.success(res.message);
        setUpdateProfileObj((prevUpdateProfileObj) => {
          // Object.assign would also work
          return { ...prevUpdateProfileObj, bannerImage: res.Location };
        });
        setUpdateProfileObjStatus((prevUpdateProfileObjStatus) => {
          return { ...prevUpdateProfileObjStatus, bannerImage: 'done' };
        });
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  // calling image upload api
  function callUploadUserImageApi(files, name) {
    // let { payload } = JSON.parse(storageData);
    setShowLoader((prevShowLoader) => {
      // Object.assign would also work
      return { ...prevShowLoader, [name]: true };
    });
    var formData = new FormData();
    for (const File of files) {
      formData.append('file', File);
    }
    header.Authorization = token;
    userImageUpload(formData, header)
      .then((res) => {
        // message.success(res.message);
        setUpdateProfileObj((prevUpdateProfileObj) => {
          // Object.assign would also work
          return { ...prevUpdateProfileObj, [name]: res.Location };
        });
        setShowLoader((prevShowLoader) => {
          // Object.assign would also work
          return { ...prevShowLoader, [name]: false };
        });
        setUpdateProfileObjStatus((prevUpdateProfileObjStatus) => {
          return { ...prevUpdateProfileObjStatus, [name]: 'done' };
        });
        // callUpdateUserProfile();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowLoader((prevShowLoader) => {
          // Object.assign would also work
          return { ...prevShowLoader, [name]: false };
        });
      });
  }
  //CALLING FOLLOW UNFOLLOW API
  function toggleFollowApi(type) {
    let postId = props.location.state ? props.location.state.postId : '';
    let followStatus = isFollowed === 0 ? false : true;
    // let modalValueStatus = type ? type === FOLLOWING_LIST ? false : true : false
    let data = {
      userId: type
        ? type === FOLLOWING_LIST
          ? followingId
          : followerId
        : postId,
      followedById: id,
      status: type ? (type === FOLLOWING_LIST ? false : true) : !followStatus,
    };
    let finalStatus = type
      ? type === FOLLOWING_LIST
        ? false
        : true
      : !followStatus;
    header.Authorization = token;
    setShowPageLoader(true);
    togglefollowUser(data, header)
      .then((res) => {
        message.success(res.message);
        if (finalStatus) {
          setPlaySound(true);
        }
        let timeOutTime = type ? 500 : 2000;
        setTimeout(() => {
          if (type) {
            if (type === FOLLOWING_LIST) {
              getFollowingListApi();
            } else {
              getFollowersListApi();
            }
            getUserProfileInfo();
          } else {
            dispatch(running(true));
            let user_id = {
              userId: postId,
              followedById: id,
              type: 'following',
            };
            dispatch(getUserProfile(user_id));
          }
          setFollowerId('');
          setShowPageLoader(false);
          setPlaySound(false);
        }, timeOutTime);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  // UNDER PROGRESS ENDS HERE
  useEffect(() => {
    if (
      updateProfileObjStatus.bannerImage === 'done' ||
      updateProfileObjStatus.userPhoto === 'done' ||
      updateProfileObjStatus.visionImage === 'done'
    ) {
      callUpdateUserProfile();
    }
  }, [updateProfileObjStatus]);

  useEffect(() => {
    let status = storageData ? JSON.parse(storageData) : '';
    let payload = status ? status.payload : '';
    if (payload) {
      let id = postId ? postId : payload.id;
      if (id === payload.id) {
      } else {
        if (saveId) {
          // showFollowUnfollowStatus();
        }
      }
    }
  }, [saveId]);

  const [playVid, setPlayVid] = useState(false);
  const videoRef = React.useRef();
  function playVidfunc() {
    let status = !playVid;
    setPlayVid(status);
    if (status) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }
  // redirect to message
  function redirectToUserProfile() {
    let postId = props.location.state ? props.location.state.postId : '';
    let fullName = firstName + ' ' + lastName;
    props.history.push('/messaging', {
      data: {
        senderId: id,
        recieverId: postId,
        senderName: fullName,
        type: 'text',
        fullName: fullName,
        userPhoto: userPhoto,
      },
    });
  }

  function fetchFollowerFollowingList(type) {
    let postId = props.location.state ? props.location.state.postId : '';
    if (postId === id) {
      if (type === FOLLOWER_LIST) {
        getFollowersListApi();
      } else {
        getFollowingListApi();
      }
    } else {
      // debugger
      // if (postId === '') {
      // } else {
      // debugger
      // if (type === FOLLOWER_LIST) {
      // getFollowersListApi();
      // } else {
      // getFollowingListApi();
      // }
      // }
    }
  }
  // get follower list of user
  function getFollowersListApi() {
    header.Authorization = token;
    getFollowerList(id, header)
      .then((res) => {
        if (res.data) {
          setFollowerList(res.data);
          showPopupModal(FOLLOWER_FOLLOWING_MODAL, FOLLOWER_LIST);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }
  // get following list of user
  function getFollowingListApi() {
    header.Authorization = token;
    getFollowingList(id, header)
      .then((res) => {
        if (res.data) {
          setFollowingList(res.data);
          showPopupModal(FOLLOWER_FOLLOWING_MODAL, FOLLOWING_LIST);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function toggelConfirmModal(message) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: CONFIRMATION_POPUP_MODAL,
        modalHeader: UNFOLLOW_USER,
      };
    });
    setCurrentPostDescription(message);
  }

  function modalFileInput(event) {
    let { files } = event.target;
    //
    var formData = new FormData();
    for (const File of files) {
      formData.append('file', File);
    }
    setModalLoader(true);
    header.Authorization = token;
    userImageUpload(formData, header)
      .then((res) => {
        // setGoalImage(res.Location);
        setModalLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setModalLoader(false);
      });
  }

  function callFollowAPI(modalTitle, followingId) {
    setFollowerId(followingId);
  }

  useEffect(() => {
    if (followerId) {
      toggleFollowApi(popUpModal.type);
    }
  }, [followerId]);

  function callUnfollowAPI() {
    toggleFollowApi(FOLLOWING_LIST);
  }

  function saveUnfollowUserId(id) {
    setFollowingId(id);
  }
  // toggle user checkbox
  function toggleGoalCheckbox(event) {
    let { value, checked, id } = event.target;
    //
    let data = { status: checked, id: id };
    setShowPageLoader(true);
    if (checked) {
      setPlayEarnSound(true);
      setTimeout(() => {
        updateGoalList(data, 'profile');
      }, 2000);
    } else {
      updateGoalList(data, 'profile');
    }
  }

  // Token Pay Api
  function tokenPayoutApi(values) {
    let data = {
      userId: id,
      ...values,
      receverId: session.data.id,
    };
    header.Authorization = token;
    tokenPay(data, header)
      .then((res) => {
        message.success(res.message);
        setPlaySound(true);
        setTimeout(() => {
          // calling user profile api
          getUserProfileInfo();
          setShowPageLoader(false);
        }, 500);
      })
      .catch((err) => {
        message.error(
          err && err.data.message ? err.data.message : ERROR_MESSAGE
        );
        setShowPageLoader(false);
        setPlaySound(false);
      });
  }

  // useEffect(()=>{
  // if(playSound){
  // // calling user profile api
  // getUserProfileInfo();
  // }
  // },[playSound]);

  function getGoalsImagesFromApi() {
    header.Authorization = token;
    getGoalImagesApi(header)
      .then((res) => {
        if (res.data.length) {
          setGoalImageModal(res.data);
        } else {
          setGoalImageModal([]);
        }
      })
      .catch((err) => {
        message.error(
          err && err.data.message ? err.data.message : ERROR_MESSAGE
        );
      });
  }

  let postId = props.location.state ? props.location.state.postId : '';
  let userProfileImageModalVariable = {
      updateProfileObj,
      showLoader,
    },
    userProfileImageFunction = {
      holdSelectedImage: holdSelectedImage,
    };
  let sessionId = session.data && session.data.id ? session.data.id : '';
  let isIdMatch = id ? (id === sessionId ? true : false) : false;
  let updateProfileCustomProps = {
      handlePopUpModalLocationInpt: handlePopUpModalLocationInpt,
      handlePopUpModalFileInput: handlePopUpModalFileInput,
      handlePopUpModalInput: handlePopUpModalInput,
      removeSelectedImageOrDoc: removeSelectedImageOrDoc,
      toggleLocationBox: toggleLocationBox,
      removeUpdatedModalSelectedFile: removeUpdatedModalSelectedFile,
      onInputClick: onInputClick,
    },
    updatProfileCustomVariable = {
      updatedDescription: updatedPost,
      updatedPostLocation,
      activePostDocument,
      activePostImage,
      updatePostDocumentObj,
      updatedLocationBox,
      showPageLoader,
      visionImageType,
    },
    followerFollowingList = {
      followerList,
      followingList,
    },
    followerFollowListModalFuncProps = {
      toggelConfirmModal: toggelConfirmModal,
      callFollowAPI: callFollowAPI,
      saveUnfollowUserId: saveUnfollowUserId,
    },
    userGoalListPropsVariable = {
      currentEntry,
      modalLoader,
      goalImageModal,
    },
    userGoalListfunctionProps = {
      modalFileInput: modalFileInput,
    };

  function getId(url) {
    const regExp = YOUTUBE_EMBED_URL_REGEX;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  function cancel(e) {
    //
  }

  let isYoutubeUrl = visionImage ? YOUTUBE_URL_REGEX.test(visionImage) : false,
    visionModalHeader =
      role === 'mentor' ? EDIT_MENTOR_PROFILE_BIO : EDIT_USER_PROFILE_VISION;
  const videoId = isYoutubeUrl ? getId(visionImage) : false;
  //
  //
  return (
    <React.Fragment>
      {role === 'user' && <SurveyPopup showOnLoad={false} />}
      {playSound ? <TokenSpendSound /> : ''}
      {playEarnSound ? <TokenEarnSound /> : ''}

      {showPageLoader ? <Loader /> : ''}
      {firstName ? (
        <SubHeader
          pathName={role === 'sponsor' ? `Welcome to ${firstName} ${lastName}` : `${firstName}'S Profile`}
          walletCount={userType === 'other' ? '' : badgeToken}
        />
      ) : (
        ''
      )}
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={imageViewingPost['postImage'][imageIndex]}
          nextSrc={
            imageViewingPost['postImage'][
              (imageIndex + 1) % imageViewingPost['postImage'].length
            ]
          }
          prevSrc={
            imageViewingPost['postImage'][
              (imageIndex + imageViewingPost['postImage'].length - 1) %
                imageViewingPost['postImage'].length
            ]
          }
          onCloseRequest={() => setIsLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex(
              (imageIndex + imageViewingPost['postImage'].length - 1) %
                imageViewingPost['postImage'].length
            )
          }
          onMoveNextRequest={() =>
            setImageIndex(
              (imageIndex + 1) % imageViewingPost['postImage'].length
            )
          }
        />
      )}
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          values={userNewVision}
          onChange={handleVisionData}
          description={currentPostDescription}
          {...userProfileImageFunction}
          {...userProfileImageModalVariable}
          {...updateProfileCustomProps}
          {...updatProfileCustomVariable}
          {...followerFollowingList}
          {...followerFollowListModalFuncProps}
          {...userGoalListPropsVariable}
          {...userGoalListfunctionProps}
        />
      ) : (
        ''
      )}
      <section className='profile-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='profile-detail'>
                <AnimatedOnScroll
                  animationIn='fadeInLeft'
                  animationOut='fadeOutLeft'
                  animationInDuration={800}
                  animationOutDuration={2000}
                  isVisible={true}
                >
                  <div className='profile-img'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='w-100 position-relative'>
                        <img
                          src={userPhoto ? userPhoto : logo}
                          alt={userPhoto ? userPhoto : logo}
                        />
                      </span>
                      <span className='position-absolute edit-icon-30'>
                        <span className='profile-edit-icon-row'>
                          {userType === 'me' ? (
                            <EditOutlined
                              onClick={() =>
                                showPopupModal(
                                  UPDATE_PROFILE_MODAL_IMAGES,
                                  UPLOAD_USER_IMAGE
                                )
                              }
                              className='p-5'
                            />
                          ) : (
                            ''
                          )}
                        </span>
                      </span>
                    </div>
                    <h1>
                      {firstName} {lastName}{' '}
                    </h1>
                  </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll
                  animationIn='fadeInLeft'
                  animationOut='fadeOutLeft'
                  animationInDuration={1000}
                  animationOutDuration={1500}
                  isVisible={true}
                >
                  <div className='wallet-detail'>
                    {role === 'mentor' ? (
                      ''
                    ) : (
                      <h4>
                        <img src={TockenIcon} alt='TockenIcon-1' /> {badgeToken}
                      </h4>
                    )}
                    <p>{communityName}</p>
                    <p>
                      Member Since:{' '}
                      {createdAt
                        ? moment(createdAt).format('MMM DD, YYYY').toString()
                        : ''}
                    </p>
                    <p
                      className='cursor-pointer'
                      onClick={() => history.push('/profile/followers')}
                    >
                      Followers: {followersCount}
                    </p>
                    <p
                      className='cursor-pointer'
                        onClick={() => history.push('/profile/following')}
                    >
                      Following: {followingCount?.toString()}
                    </p>
                    {role === 'mentor' && (
                      <>
                        <p className='cursor-pointer'>Mentors: 1</p>
                        <p
                          className='cursor-pointer'
                          onClick={() => history.push(`/mentor/${_id}/users`)}
                        >
                          Mentoring: {menteeCount}
                        </p>
                      </>
                    )}
                  </div>
                </AnimatedOnScroll>

                {role === 'mentor' ? (
                  <AnimatedOnScroll
                    animationIn='fadeInLeft'
                    animationOut='fadeOutLeft'
                    animationInDuration={1500}
                    animationOutDuration={1000}
                    isVisible={true}
                  >
                    <div className='badges'>
                      <h4>Availability</h4>
                      <div className='w-100'>
                        <div className='col-md-12 text-left py-3'>
                          <h5>
                            From {availability.startTime} to{' '}
                            {availability.endTime}
                          </h5>
                          <ul class='availability-list'>
                            {availability.days.length
                              ? availability.days.map((value, index) => {
                                  return (
                                    <li className='text-capitalize'>{value}</li>
                                  );
                                })
                              : ''}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </AnimatedOnScroll>
                ) : (
                  <h4></h4>
                )}

                {role === 'user' && (
                  <AnimatedOnScroll
                    animationIn='fadeInLeft'
                    animationOut='fadeOutLeft'
                    animationInDuration={1500}
                    animationOutDuration={1000}
                    isVisible={true}
                  >
                    <div className='badges'>
                      <h4>Badges</h4>
                      <div className='w-100'>
                        <div className='col-md-12 text-left py-3'>
                          {badges.length === 0 &&
                            userType === 'me' &&
                            `You have no Badges`}
                          {badges.length === 0 &&
                            userType === 'other' &&
                            `${firstName} ${lastName} have no Badges`}
                          {badges.length > 0 &&
                            badges.map((badge) => (
                              <a
                                href={`https://learning.equiliberty.com/badges/badge.php?hash=${badge.uniquehash}`}
                                target='_blank'
                                className='ml-1'
                              >
                                <img
                                  src={`${badge.badgeurl}?token=${moodleToken}`}
                                  alt={badge.imagecaption}
                                />
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                  </AnimatedOnScroll>
                )}
                {role === 'sponsor' && (
                  <DashboardCard title='Mentors'>
                    {mentors.map((i) => (
                      <p className='cursor-pointer' key={i.id}>
                        {i.email}{' '}
                        <Tag color={i.isAccepted ? 'green' : 'volcano'}>
                          {i.isAccepted ? 'Accepted' : ' Pending'}
                        </Tag>
                      </p>
                    ))}
                  </DashboardCard>
                )}
                {role === 'sponsor' && (
                  <DashboardCard title='Contacts'>
                    {!user?.resources?.length && (
                      <p className='text-center'>No Contacts</p>
                    )}
                    {user?.resources.map((contact) => (
                      <p
                        key={contact._id}
                        className='cursor-pointer'
                        onClick={() =>
                          showAddEditResourcePopupModal('Contact', contact)
                        }
                      >
                        {contact.name}
                      </p>
                    ))}
                  </DashboardCard>
                )}
                {postId === id && role !== 'sponsor' ? (
                  <AnimatedOnScroll
                    animationIn='fadeInLeft'
                    animationOut='fadeOutLeft'
                    animationInDuration={2000}
                    animationOutDuration={800}
                    isVisible={true}
                  >
                    <div className='goals'>
                      <h4>
                        Goals{' '}
                        <Tooltip title='Add Goals'>
                          <Button
                            className='add-goal-btn'
                            shape='circle'
                            icon={<PlusOutlined />}
                            onClick={() =>
                              showPopupModal(ADD_EDIT_GOAL_POP_MODAL, ADD_GOAL)
                            }
                          />
                        </Tooltip>
                      </h4>
                      <div className='col-md-12 form-group my-4'>
                        <div className='px-2'>
                          {userGoalList.length
                            ? userGoalList.map((value, index) => {
                                return (
                                  <div className='w-100 d-flex align-items-center'>
                                    <label
                                      key={`${value.id}-${index}`}
                                      className='w-100 d-flex align-items-center position-relative'
                                    >
                                      <input
                                        type='checkbox'
                                        name='status'
                                        value={value.status}
                                        id={value.id}
                                        checked={value.status}
                                        onClick={toggleGoalCheckbox}
                                      />
                                      <span></span>
                                      <Button
                                        type='text'
                                        className='d-flex align-items-center'
                                      >
                                        <img
                                          src={value.image}
                                          alt={value.image}
                                        />
                                        <span className='goal-name'>
                                          {value.name}
                                        </span>
                                      </Button>
                                    </label>
                                    <div className='d-flex align-items-center'>
                                      <span className='d-flex align-items-center mr-3'>
                                        <EditOutlined
                                          onClick={() =>
                                            showPopupModal(
                                              ADD_EDIT_GOAL_POP_MODAL,
                                              EDIT_GOAL,
                                              value
                                            )
                                          }
                                        />
                                      </span>

                                      <Popconfirm
                                        title='Are you sure to delete this task?'
                                        onConfirm={() =>
                                          deleteGoalList(value.id)
                                        }
                                        onCancel={cancel}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        <DeleteOutlined />
                                      </Popconfirm>
                                    </div>
                                  </div>
                                );
                              })
                            : 'No Goals Created by user'}
                        </div>
                      </div>
                    </div>
                  </AnimatedOnScroll>
                ) : (
                  ''
                )}
                {role === 'user' && (
                  <AnimatedOnScroll
                    animationIn='fadeInLeft'
                    animationOut='fadeOutLeft'
                    animationInDuration={2000}
                    animationOutDuration={800}
                    isVisible={true}
                  >
                    <SurveyList />
                  </AnimatedOnScroll>
                )}
              </div>
            </div>
            <div className='col-md-8'>
              <AnimatedOnScroll
                animationIn='fadeInUp'
                animationOut='fadeOutUp'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div className='profile-detail-content'>
                  <div className='detail-img'>
                    {/* <img className='profile-detail-img' src={bannerImage ? bannerImage : DefaultBannerImage}
                        alt={bannerImage ? bannerImage : DefaultBannerImage} /> */}
                    <ImageCropper
                      picture={userBannerImage || DefaultBannerImage}
                      onComplete={uploadBannerImage}
                      showEditButton={userType === 'me'}
                    >
                      {userType === 'other' && (
                        <>
                          <li className='mx-2 img-hover-sepia h-i-c'>
                            <a
                              className='letter-spacing-1 font-16 color-fff d-flex align-items-center'
                              onClick={() => toggleFollowApi()}
                            >
                              <span className='h-i-c d-flex align-items-center'>
                                <img
                                  src={isFollowed === 0 ? Thumb : FollowedThumb}
                                  alt='Thumb'
                                  className='mr-2 h-o-h'
                                />
                                <img
                                  src={
                                    isFollowed === 0
                                      ? ThumbHover
                                      : FollowedThumbHover
                                  }
                                  alt='Thumb'
                                  className='mr-2 d-o-h'
                                />
                                {isFollowed === 0 ? 'Follow' : 'Unfollow'}
                              </span>
                            </a>
                          </li>
                          <li className='mx-2 img-hover-sepia h-i-c'>
                            <a
                              className='letter-spacing-1 font-16 color-fff d-flex align-items-center'
                              onClick={() => redirectToUserProfile()}
                            >
                              <span className='h-i-c d-flex align-items-center'>
                                <img
                                  className='h-o-h mr-2'
                                  src={Message}
                                  alt='Message'
                                />
                                <img
                                  className='d-o-h mr-2'
                                  src={MessageHover}
                                  alt='Message'
                                />
                                Message
                              </span>
                            </a>{' '}
                          </li>
                          <li className='mx-2 bounce-up-animation'>
                            <a
                              className='letter-spacing-1 font-16 color-fff'
                              onClick={() =>
                                showPopupModal(SEND_TOKEN_MODAL, SEND_TOKEN)
                              }
                            >
                              <img
                                src={TockenIcon}
                                alt='TockenIcon'
                                className='mr-2 tokens'
                              />{' '}
                              Send tokens
                            </a>{' '}
                          </li>
                        </>
                      )}
                    </ImageCropper>
                  </div>
                  <div>
                    {' '}
                    {/* <small style={{ color: 'grey' }}>
                        NOTE: Banner image with 903 x 410 looks good
                      </small> */}
                  </div>
                  {role !== 'sponsor' && (
                    <div className='row mt-2'>
                      <div className={'col-md-6'}>
                        <div className='malcom-content'>
                          <h3>
                            {firstName}'S {role === 'mentor' ? 'Bio' : 'Vision'}{' '}
                            {isIdMatch ? (
                              <Button
                                type='link'
                                onClick={() =>
                                  showPopupModal(
                                    EDIT_VISION_POP_MODAL,
                                    visionModalHeader
                                  )
                                }
                                className='p-0 d-inline-flex'
                              >
                                <svg
                                  viewBox='64 64 896 896'
                                  focusable='false'
                                  className=''
                                  data-icon='edit'
                                  width='22px'
                                  height='22px'
                                  fill='currentColor'
                                  aria-hidden='true'
                                >
                                  <path
                                    d='M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z'
                                    color='#18a4ad'
                                  ></path>
                                </svg>
                              </Button>
                            ) : (
                              ''
                            )}
                          </h3>
                          <div
                            style={{
                              maxHeight: 160,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            className='profile-vision-text'
                          >
                            {role === 'mentor'
                              ? bio
                                ? parse(bio)
                                : ''
                              : userVision
                              ? parse(userVision)
                              : ''}
                          </div>
                          {role === 'mentor' ? (
                            bio ? (
                              bio.length > 240 ? (
                                <button
                                  type='button'
                                  className='read-more_btn'
                                  onClick={() =>
                                    showPopupModal(
                                      USER_VISION_MENTOR_BIO_POP_UP_MODAL,
                                      'User Vision'
                                    )
                                  }
                                >
                                  {' '}
                                  Read more{' '}
                                </button>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )
                          ) : userVision ? (
                            userVision.length > 240 ? (
                              <button
                                type='button'
                                className='read-more_btn'
                                onClick={() =>
                                  showPopupModal(
                                    USER_VISION_MENTOR_BIO_POP_UP_MODAL,
                                    'User Vision'
                                  )
                                }
                              >
                                {' '}
                                Read more{' '}
                              </button>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='malcom-content'>
                          {isYoutubeUrl ? (
                            <iframe
                              width='100%'
                              height='240px'
                              title={videoId}
                              src={`https://www.youtube.com/embed/${videoId}`}
                              frameBorder='0'
                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                              allowFullScreen
                            />
                          ) : (
                            <div className='position-relative'>
                              <video
                                ref={videoRef}
                                src={
                                  visionImage
                                    ? visionImage
                                    : 'https://equiliberty-storage.s3.us-east-2.amazonaws.com/introVIDEO.mp4'
                                }
                                width='100%'
                                autoPlay='autoplay'
                                loop={true}
                                muted
                                id=''
                              />
                              <button
                                className='play-btn'
                                onClick={playVidfunc}
                              >
                                {playVid ? (
                                  <PauseCircleFilled
                                    style={{ fontSize: '48px' }}
                                  />
                                ) : (
                                  <PlayCircleFilled
                                    style={{ fontSize: '48px' }}
                                  />
                                )}
                              </button>
                            </div>
                          )}
                          {userType === 'me' ? (
                            <p
                              style={{ textAlign: 'end' }}
                              className='edit-youtube-btn'
                            >
                              <EditOutlined
                                style={{ fontSize: '25px' }}
                                onClick={() =>
                                  showPopupModal(
                                    UPDATE_PROFILE_MODAL_IMAGES,
                                    UPLOAD_VISION_IMAGE
                                  )
                                }
                              />{' '}
                              <span>Edit</span>
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {isLoading ? (
                    <SkeletonDashBoardPostLoader
                      rows={4}
                      width={['100%', '100%', '100%', '100%']}
                    />
                  ) : allPosts.length ? (
                    <div className='latest-images'>
                      <h3 className='text-left'>Latest from {firstName}</h3>
                      {/* <img src={Latest} alt="Latest" /> */}
                    </div>
                  ) : (
                    ''
                  )}
                  {isLoading ? (
                    <SkeletonDashBoardPostLoader
                      rows={4}
                      width={['100%', '100%', '100%', '100%']}
                    />
                  ) : allPosts.length ? (
                    allPosts.slice(0, 5).map((value, index) => {
                      let { lastName, firstName } = value.userId,
                        userFullName = firstName + ' ' + lastName,
                        postId = value.id,
                        userId = value.userId.id,
                        likeStatus = value.liked ? 0 : 1,
                        createdAt = moment().isSame(value.createdAt, 'day')
                          ? moment(value.createdAt).format('hh:mm a').toString()
                          : moment(value.createdAt)
                              .format('MMM DD, YYYY hh:mm a')
                              .toString();
                      // value.userStatus === "active"
                      if (value.id) {
                        return (
                          <div className='w-100 px-lg-5' key={index}>
                            <div
                              className='w-100 malcom-content mt-3 mb-2 d-block px-5'
                              key={`${userFullName}-${index}`}
                            >
                              {userId === id ? (
                                <div className='row text-right'>
                                  <div className='col-12 col-md-12'>
                                    <div className='delete-update-popup'>
                                      <Popover
                                        placement='top'
                                        content={
                                          <div className='update-btns'>
                                            <p
                                              onClick={() => {
                                                ToggleConfirmationPopup(
                                                  postId,
                                                  userId,
                                                  POST_REMOVE_MESSAGE,
                                                  CONFIRMATION_POPUP_MODAL,
                                                  CONFIRMATION_HEADER
                                                );
                                              }}
                                            >
                                              <DeleteOutlined />{' '}
                                              <span>Delete</span>
                                            </p>
                                            <p
                                              onClick={() => {
                                                ToggleConfirmationPopup(
                                                  postId,
                                                  userId,
                                                  '',
                                                  UPDATE_POPUP_MODAL,
                                                  UPDATE_POST_HEADER
                                                );
                                              }}
                                            >
                                              <EditOutlined />
                                              <span>Update</span>{' '}
                                            </p>
                                          </div>
                                        }
                                      >
                                        <DashOutlined />
                                      </Popover>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              <div className='w-100 d-flex flex-wrap flex-md-nowrap'>
                                <div>
                                  <div className='user-profile-img mr-2'>
                                    <div className='max-width-60'>
                                      <Link
                                        to={{
                                          pathname: '/user/profile',
                                          state: {
                                            postId: id,
                                            userType: 'me',
                                          },
                                        }}
                                        className='font-weight-normal'
                                      >
                                        <img
                                          src={
                                            value.userId.userPhoto
                                              ? value.userId.userPhoto
                                              : ERROR_IMAGE_URL
                                          }
                                          alt={`${
                                            value.userPhoto
                                              ? value.userPhoto
                                              : `ERROR_IMAGE_URL`
                                          }-${index}`}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className='w-100'>
                                  <div className='user-profile-detail'>
                                    <h4 className='w-100 mb-2 d-flex flex-column'>
                                      {userFullName}{' '}
                                      <span className='post-time mt-2'>
                                        {createdAt}
                                      </span>
                                    </h4>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: value.description,
                                      }}
                                    />
                                    {value.postMap ? (
                                      <p className='location-adderss'>
                                        {' '}
                                        <span>
                                          {' '}
                                          <img
                                            src={LocationPin}
                                            alt='LocationPin'
                                          />
                                          {value.postMap}
                                        </span>
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                    {value.postImage.length ? (
                                      <div className='row mb-2'>
                                        {value.postImage.map(
                                          (data, imageIndex) => {
                                            return (
                                              <React.Fragment
                                                key={`${data}-${imageIndex}`}
                                              >
                                                {data ? (
                                                  <div
                                                    className={
                                                      imageIndex === 0
                                                        ? 'col-md-12 col-12'
                                                        : 'col-md-4 col-6'
                                                    }
                                                    onClick={() =>
                                                      openLightBox(
                                                        value,
                                                        imageIndex
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        data
                                                          ? data
                                                          : ERROR_IMAGE_URL
                                                      }
                                                      className=' mt-3 mb-3 post-uploaded-img'
                                                      alt={
                                                        data
                                                          ? `value.postImage-${id}`
                                                          : 'ERROR_IMAGE_URL'
                                                      }
                                                    />
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div className='row mx-0'>
                                      <div className='col-md-6 col-12 px-0'>
                                        <div className='text-left'>
                                          <ul>
                                            <li>
                                              <button
                                                title={
                                                  value.liked
                                                    ? 'Unlike'
                                                    : 'Like'
                                                }
                                                onClick={() =>
                                                  callingToggleLikeApi(
                                                    postId,
                                                    userId,
                                                    likeStatus
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    value.liked
                                                      ? HeartIcon
                                                      : EmptyheartIcon
                                                  }
                                                  alt={`${index}-${
                                                    value.liked
                                                      ? 'HeartIcon'
                                                      : 'EmptyheartIcon'
                                                  }`}
                                                />{' '}
                                              </button>
                                            </li>
                                            <li>
                                              <button>
                                                <img
                                                  src={ShareIcon}
                                                  alt={`${index}-ShareIcon`}
                                                  onClick={() =>
                                                    showSocialMediaSharePopUp(
                                                      value.description
                                                    )
                                                  }
                                                />
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                id={value.id}
                                                onClick={() =>
                                                  toggleCommentBoxfunc(value.id)
                                                }
                                              >
                                                <img
                                                  src={CommentsIcon}
                                                  alt='CommentsIcon'
                                                />{' '}
                                              </button>
                                            </li>
                                          </ul>
                                          <div className='row'>
                                            {value.totalLikes ||
                                            value.totalComments ? (
                                              <div className='col-12'>
                                                <span className='h6 font-weight-bold mr-2'>
                                                  {value.totalLikes
                                                    ? value.totalLikes == 1
                                                      ? value.totalLikes +
                                                        ' like'
                                                      : value.totalLikes +
                                                        ' likes'
                                                    : ''}
                                                </span>
                                                <span className='h6 font-weight-bold mr-2'>
                                                  {value.totalComments
                                                    ? value.totalComments == 1
                                                      ? value.totalComments +
                                                        ' comment'
                                                      : value.totalComments +
                                                        ' comments'
                                                    : ''}
                                                </span>
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-md-6 col-12'>
                                        <div className='text-right'>
                                          {value.postDocument &&
                                          value.postDocument.length ? (
                                            value.postDocument.map(
                                              (value, id) => {
                                                if (value) {
                                                  return (
                                                    <button
                                                      key={`${value}-${id}`}
                                                    >
                                                      <a
                                                        href={value}
                                                        index={id}
                                                        target='_blank'
                                                        rel='noreferrer noopener'
                                                      >
                                                        <img
                                                          src={DocumentIcon}
                                                          alt={`DocumentIcon-${id}`}
                                                        />
                                                        {/* <svg viewBox="64 64 896 896" focusable="false" className=""
                                        data-icon="file-done" width="25px" height="25px" fill="#18a4ad"
                                        aria-hidden="true">
                                        <path
                                          d="M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 00-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z">
                                        </path>
                                      </svg> */}
                                                      </a>
                                                    </button>
                                                  );
                                                } else {
                                                  return (
                                                    <span key={`$-${id}`} />
                                                  );
                                                }
                                              }
                                            )
                                          ) : (
                                            <span />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {toggleCommentBox === value.id ? (
                                      <form
                                        className='my-3'
                                        onSubmit={submitComment}
                                      >
                                        <div className='form-group'>
                                          <textarea
                                            className='form-control'
                                            onChange={(event) =>
                                              handleCommentInput(event)
                                            }
                                            placeholder='Write Comment'
                                            value={comment}
                                            name='comment'
                                            required
                                          />
                                          <div className='text-right'>
                                            <input
                                              type='submit'
                                              className='comment-btn'
                                              value='Comment'
                                            ></input>
                                            <button
                                              type='button'
                                              className='cancel-bnt'
                                              onClick={() =>
                                                toggleCommentBoxfunc('')
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  {(value.comments && value.comments.length) ||
                                  (value.lastComment &&
                                    value.lastComment.length) ? (
                                    <div className='show-comment'>
                                      <h3>Recent Comments</h3>
                                      {value.comments &&
                                      value.comments.length ? (
                                        value.comments.map(
                                          (comment, commentIndex) => {
                                            let {
                                              lastName,
                                              firstName,
                                              userPhoto,
                                            } = comment.userId
                                              ? comment.userId
                                              : '';
                                            userFullName = firstName
                                              ? firstName + ' ' + lastName
                                              : 'Equiliberty User';
                                            let activeUserID = id;
                                            let activeUserCommentId =
                                              comment.userId
                                                ? comment.userId.id
                                                : '';
                                            return (
                                              <div
                                                className='row'
                                                key={commentIndex}
                                              >
                                                <div>
                                                  <div className='comment-img'>
                                                    <img
                                                      src={
                                                        userPhoto
                                                          ? userPhoto
                                                          : ERROR_IMAGE_URL
                                                      }
                                                      alt={`${
                                                        userPhoto
                                                          ? `userPhoto-${commentIndex}`
                                                          : `ERROR_IMAGE_URL-${commentIndex}`
                                                      }`}
                                                    ></img>
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    activeUserID ===
                                                    activeUserCommentId
                                                      ? ''
                                                      : ''
                                                  }
                                                  className='pl-3'
                                                >
                                                  <div className='comment-user-name'>
                                                    <h4 className='mt-0'>
                                                      {userFullName}
                                                    </h4>
                                                  </div>
                                                  <div className='user-comments-detail'>
                                                    <p>{comment.comment}</p>
                                                  </div>
                                                </div>
                                                {activeUserID ===
                                                activeUserCommentId ? (
                                                  <div className='ml-auto pl-3'>
                                                    <div className='comment-img delete-comment-icon'>
                                                      <DeleteOutlined
                                                        onClick={() => {
                                                          ToggleConfirmationPopup(
                                                            postId,
                                                            comment.id,
                                                            COMMENT_REMOVE_MESSAGE,
                                                            CONFIRMATION_POPUP_MODAL,
                                                            CONFIRMATION_HEADER
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <div>
                                          {value.totalComments > 1 && (
                                            // <Button type="link" onClick={() => props.getAllComments(value._id)}>View all <b>{value.totalComments}</b> comments</Button>
                                            <a
                                              className='view-all-comment'
                                              onClick={() =>
                                                getAllComments(value._id)
                                              }
                                            >
                                              View all{' '}
                                              <b>{value.totalComments}</b>{' '}
                                              comments
                                            </a>
                                          )}
                                          {value.lastComment &&
                                          value.lastComment.length
                                            ? value.lastComment.map(
                                                (comment, commentIndex) => {
                                                  let {
                                                    lastName,
                                                    firstName,
                                                    userPhoto,
                                                  } = comment.userId;
                                                  userFullName =
                                                    firstName + ' ' + lastName;
                                                  return (
                                                    <div
                                                      className='row my-2 flex-nowrap'
                                                      key={commentIndex}
                                                    >
                                                      <div className='col-12 d-flex flex-nowrap'>
                                                        <div>
                                                          <div className='comment-img max-width-60'>
                                                            <img
                                                              src={
                                                                userPhoto
                                                                  ? userPhoto
                                                                  : ERROR_IMAGE_URL
                                                              }
                                                              alt={`${commentIndex}`}
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className={
                                                            id ===
                                                            comment.userId.id
                                                              ? ''
                                                              : ''
                                                          }
                                                          className='pl-3'
                                                        >
                                                          <div className='comment-user-name'>
                                                            <h4 className='mt-0'>
                                                              {userFullName}
                                                            </h4>
                                                          </div>
                                                          <div className='user-comments-detail'>
                                                            <p>
                                                              {comment.comment}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        {id ===
                                                        comment.userId.id ? (
                                                          <div className='ml-auto pl-3'>
                                                            <div className='comment-img delete-comment-icon'>
                                                              <DeleteOutlined
                                                                onClick={() => {
                                                                  ToggleConfirmationPopup(
                                                                    postId,
                                                                    comment.id,
                                                                    COMMENT_REMOVE_MESSAGE,
                                                                    CONFIRMATION_POPUP_MODAL,
                                                                    CONFIRMATION_HEADER
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )
                                            : ''}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className='user-comment'
                            key={`${value.userStatus}-${index}`}
                          >
                            <div className='row my-2 flex-nowrap'>
                              <div className='col-12 d-flex flex-nowrap'>
                                <div className='max-width-60'>
                                  <img
                                    className='comment-icon'
                                    src={CommentIcon}
                                    alt='CommentIcon'
                                  />
                                </div>
                                <div>
                                  <div className='comment-content'>
                                    <p>
                                      <span className='comment-user-name'>
                                        There's a new barber shop in your
                                        community ! <br /> Check it out here...
                                      </span>
                                    </p>
                                  </div>
                                  <div className='row'>
                                    <div className='col-md-6 col-6'>
                                      <div className='left-tags'>
                                        <span />
                                        <span />
                                        <span />
                                      </div>
                                    </div>
                                    <div className='col-md-6 col-6'>
                                      <div className='right-tags'>
                                        <span />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <React.Fragment>
                      <div className='row mt-4'>
                        <div className='col-12 text-center'>
                          <h3 className='profile-add-post'>
                            {isIdMatch
                              ? parse(
                                  `Boost your profile with interesting posts.<br /><br /> <a href="/dashboard">Click to add now</a>`
                                )
                              : `No Post Uploaded By User`}
                          </h3>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Profile;
