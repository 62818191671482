import React, { useEffect, useState } from 'react';
import {
    Table,
    message,
    Space,
    Button
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS,
    POP_UP_MODAL_CONSTANTS,
    POP_UP_MODAL_HEADER_CONSTANTS
} from '../../../shared/constants';
// API END POINTS
import {
    createResource,
    getListResource,
    deleteResource,
    updateResource
} from '../../../../redux/slices/adminResourceSlice';
import {
    header
} from '../../../../api/rest';
import {
    history
} from '../../../../redux/store';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
import './adminresource.scss';

var {
    ERROR_MESSAGE,
    INVALID_USER
} = CONSTANTS;

var {
    ADD_EDIT_RESOURCE,
    CONFIRMATION_POPUP_MODAL
} = POP_UP_MODAL_CONSTANTS;
var {
    ADD_RESOURCE,
    EDIT_RESOURCE,
    CONFIRMATION_HEADER
} = POP_UP_MODAL_HEADER_CONSTANTS;

function AdminResource() {
    // const dispatch = useDispatch();
    // const loginUserData = useSelector(state => state.user);
    const storageData = localStorage.getItem("userData");
    const payload = storageData ? JSON.parse(storageData).payload : '';
    const token = storageData ? JSON.parse(storageData).token : '';
    const [liberatorList, setLiberatorList] = useState([]);
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });
    const [currentEntry, setCurrentEntry] = useState({
        id: '',
        name: '',
        address: '',
        email: '',
        phoneNumber: '',
        webAddress: '',
        city: '',
        zip: ''
    })
    const [description, setDescription] = useState('');
    const [showPageLoader, setShowPageLoader] = useState(true);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Web Address',
            dataIndex: 'webAddress',
            key: 'webAddress',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Zip Code',
            dataIndex: 'zip',
            key: 'zip',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button className="delete-resource-btn" onClick={() => fillUserInfoInModal(record, 'delete')}>Delete Resource</Button>
                    <Button className="update-resource-btn" onClick={() => fillUserInfoInModal(record, 'update')}>Update Resource</Button>
                </Space>
            ),
        }
    ];

    function fillUserInfoInModal(data, type) {
        if (type === 'update') {
            toggleModal(ADD_EDIT_RESOURCE, EDIT_RESOURCE)
        }
        if (type === 'delete') {
            toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
            setDescription(`Are you sure to remove ${data.name}`)
        }

        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: data.id,
                name: data.name,
                address: data.address,
                email: data.email,
                phoneNumber: data.phoneNumber,
                webAddress: data.webAddress,
                city: data.city,
                zip: data.zip,
            }
        });
    }

    useEffect(() => {
        if (token) {
            if (payload.role === 'admin') {
                getListResourceApi();
            } else {
                message.error(INVALID_USER);
                history.push('/');
            }
        } else {
            history.push('/');
            // message.error(TOKEN_EXPIRED_MESSAGE);
        }
    }, [])



    function addResource(value) {
        header.Authorization = `Bearer ${token}`;
        let data = value;
        createResource(data, header).then(res => {
            message.success(res.message);
            getListResourceApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function updateResourceApi(value) {
        header.Authorization = `Bearer ${token}`;
        let data = {
            id: currentEntry.id,
            ...value
        }
        updateResource(data, header).then(res => {
            message.success(res.message);
            getListResourceApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function deleteResourceApi(value) {
        header.Authorization = `Bearer ${token}`;
        let data = {
            id: value.id
        }
        deleteResource(data, header).then(res => {
            message.success(res.message);
            getListResourceApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function getListResourceApi() {
        header.Authorization = `Bearer ${token}`;
        getListResource(header).then(res => {
            if (res.data) {
                setLiberatorList(res.data)
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    function onSubmitPopUpModal(event) {
        setShowPageLoader(true);
        // event.preventDefault();
        if (popUpModal.modalHeader === ADD_RESOURCE) {
            addResource(event);
        }
        if (popUpModal.modalHeader === EDIT_RESOURCE) {
            updateResourceApi(event);
        }
        if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
            deleteResourceApi(currentEntry)
        }
        // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
    }


    function toggleModal(type, header) {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: header
            }
        });
    }

    // close poup modal
    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: '',
                name: '',
                address: '',
                email: '',
                phoneNumber: '',
                webAddress: '',
                city: '',
                zip: ''
            }
        });
    }

    let addEditLiberatorProps = {
        currentEntry
    }

    return (
        <React.Fragment>
            {showPageLoader ? <Loader /> : ''}
            {
                popUpModal.visible ?
                    <PopUpModal
                        title={popUpModal.modalHeader}
                        visible={popUpModal.visible}
                        onCancel={closePopupModal}
                        onOk={onSubmitPopUpModal}
                        type={popUpModal.type}
                        description={description}
                        {...addEditLiberatorProps}
                    /> : ''
            }
            <div className="row mt-4 mb-2">
                <div className="col-12 text-right">
                    <Button onClick={() => toggleModal(ADD_EDIT_RESOURCE, ADD_RESOURCE)}>Add Resource</Button>
                </div>
            </div>
            <Table dataSource={liberatorList} columns={columns} />
        </React.Fragment>
    )
}

export default AdminResource