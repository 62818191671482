import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import parse from 'html-react-parser';
import {
  Button,
  Layout,
  message,
  Upload,
  Space,
  Modal,
  Form,
  Input,
  Tag,
  Avatar,
  List,
  Image,
  Select,
} from 'antd';
import TokenIcon from '../../../assets/images/token-icon.png';
import logo from '../../../assets/images/equiliberty-logo.png';
import ImgIcon from '../../../assets/images/hovers/img-181818.png';
import DocumentIcon from '../../../assets/images/hovers/document-181818.png';
import LocationIcon from '../../../assets/images/hovers/location-181818.png';
import HeartIcon from '../../../assets/icons/EQ_Icons_Likes.png';
import EmptyHeartIcon from '../../../assets/icons/EQ_Icons_Like.png';
import ShareIcon from '../../../assets/icons/EQ_Icons_Share.png';
import CommentsIcon from '../../../assets/icons/EQ_Icons_Comment.png';
import { Loader } from '../../shared/Loader';
import SubHeader from '../../widgets/subheader';
import { getUserProfile } from '../../../redux/slices/userProfileSlice';
import { useHistory } from 'react-router-dom';
import './dashboard-new.scss';
import './dashboard.sass';
import {
  HourglassOutlined,
  LikeOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Pie } from '@ant-design/charts';
import { Link } from 'react-router-dom';
import {
  createUserPost,
  getAllUserPost,
  getCommunityData,
  getResourceListByCity,
  toggleLikeApi,
} from '../../../redux/slices/dashboardSlice';
import { apiGetMethod, apiPostMethod } from '../../../api/rest';
import {
  getContactList,
  addUserResources,
} from '../../../redux/slices/contactsSlice';
import moment from 'moment';
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../shared/constants';
import PopUpModal from '../../shared/popupmodal';
import { whoami } from '../../../redux/slices/authSlice';
import LinkPreviewCard from '../../shared/LinkPreviewCard';
import { unescapeHtml } from '../../../utils/helpers';
import {
  assignCommunity,
  listMentorCommunities,
} from '../../../redux/slices/registerSlice';
const {
  SOCIAL_SHARE_MODAL,
  CONFIRMATION_POPUP_MODAL,
  UPDATE_POPUP_MODAL,
  GOAL_CHECKLIST_POP_UP_MODAL,
  ADD_EDIT_USER_RESOURCE,
} = POP_UP_MODAL_CONSTANTS;

const SponsorDashboard = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user);
  const storageData = JSON.parse(localStorage.getItem('userData'));
  const [isLoading, setIsLoading] = useState(false);
  const { id } = storageData?.payload;
  const { firstName, lastName, businessName, company, userPhoto, role } = user;

  useEffect(() => {
    if (id) dispatch(getUserProfile({ id }));
  }, [id]);

  return (
    <Layout.Content>
      <section className='main-section'>
        {isLoading && <Loader />}
        <SubHeader pathName={`${firstName} ${lastName} Dashboard`} />
        <div className='container'>
          <div className='row'>
            <DashBoardYourProfileSection user={user} />
            <DashBoardPostSection user={user} />
            <DashBoardNewsAndUpdateSection role={role} />
          </div>
        </div>
      </section>
    </Layout.Content>
  );
};

export function DashboardCard({ title, onAddClick, children }) {
  return (
    <div className='badges'>
      <h4 style={{ background: '#00a9ab', justifyContent: 'space-between' }}>
        {title} {onAddClick && <PlusCircleOutlined onClick={onAddClick} />}
      </h4>

      <div className='wallet-detail'>{children}</div>
    </div>
  );
}

function DashBoardYourProfileSection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state?.user?.user);
  const { userPhoto, badgeToken } = user;
  const [mentors, setMentors] = useState([]);
  const [userContact, setUserContact] = useState([]);
  const [inviteMentorModal, setInviteMentorModal] = useState(false);
  const [chooseCommunityModal, setChooseCommunityModal] = useState(false);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
    currentEntry: {},
  });

  const handleContactAdd = () => {
    console.log('Add Contact Clicked');
  };
  const handleMentorAdd = () => {
    console.log('Add Mentor Clicked');
    setInviteMentorModal(true);
  };

  const getMentors = async () => {
    const mentors = await apiGetMethod('/sponsors/invites?role=mentor');
    setMentors(mentors);
  };

  const getResourceList = () => {
    setUserContact([...user.resources]);
  };

  const showAddEditResourcePopupModal = (title, data) => {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: ADD_EDIT_USER_RESOURCE,
        modalHeader: title,
        currentEntry: data,
      };
    });
  };

  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
  }

  async function onSubmitPopUpModal(event, da) {
    await addUserResources(event);
    dispatch(whoami());
    closePopupModal();
  }

  useEffect(() => {
    getMentors();
    if (user.zipcode) getResourceList();
    if (user.id) {
      getResourceList();
      // getUserContacts();
    }
    if (user.id && !user.communityId) {
      message.info('No community selected. Please choose a commumnity');
      setChooseCommunityModal(true);
      // history.push('/user/profile/update');
    }
  }, [inviteMentorModal, user]);

  return (
    <div className='col-md-12 col-lg-3'>
      <PopUpModal
        title={popUpModal.modalHeader}
        visible={popUpModal.visible}
        onCancel={closePopupModal}
        onOk={onSubmitPopUpModal}
        type={popUpModal.type}
        isJourneyModal={true}
        // description={currentJourneyDescription}
        currentEntry={popUpModal.currentEntry}
      />
      <InviteModal
        role='mentor'
        isVisible={inviteMentorModal}
        setIsVisible={setInviteMentorModal}
      />
      {user.id && (
        <ChooseCommunityModal
          user={user}
          isVisible={chooseCommunityModal}
          setIsVisible={setChooseCommunityModal}
        />
      )}
      <div className='profile-grid w-100 mb-4 d-inline-block'>
        <div className='w-100'>
          <div className='d-flex align-items-center justify-content-center'>
            <span className='w-100 position-relative'>
              <img
                className='w-100 img-fluid'
                src={userPhoto || logo}
                alt='Sponsor Logo'
              />
            </span>
          </div>
        </div>

        <div className='w-100 mt-4'>
          <div className='wallet-balance d-flex align-items-center'>
            <img src={TokenIcon} alt='TokenIcon' className='mr-3' />
            <p className='font-weight-bold mb-0 text-22px'>{badgeToken}</p>
          </div>
        </div>
      </div>

      <div className='w-100 d-inline-block'>
        <DashboardCard title='Mentors' onAddClick={handleMentorAdd}>
          {mentors.map((i) => (
            <p className='cursor-pointer' key={i.id}>
              {i.email}{' '}
              <Tag color={i.isAccepted ? 'green' : 'volcano'}>
                {i.isAccepted ? 'Accepted' : ' Pending'}
              </Tag>
            </p>
          ))}
        </DashboardCard>

        <DashboardCard
          title='Contacts'
          onAddClick={() =>
            showAddEditResourcePopupModal('Add New Contact', {})
          }
        >
          {!userContact.length && <p className='text-center'>No Contacts</p>}
          {userContact.map((contact) => (
            <p
              key={contact._id}
              className='cursor-pointer'
              onClick={() => showAddEditResourcePopupModal('Contact', contact)}
            >
              {contact.name}
            </p>
          ))}
        </DashboardCard>
      </div>
    </div>
  );
}

function ChooseCommunityModal({ user, isVisible, setIsVisible }) {
  const [communities, setCommunities] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getCommunities();
  }, [user.zipcode]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleSubmit = async (values) => {
    const body = {
      userId: user.id,
      communityId: values.communityId,
      type: 'update',
    };
    setLoading(true);
    try {
      const res = await assignCommunity(body);
      message.success(res.message);
      dispatch(whoami());
      dispatch(getUserProfile({ i: user.id }));
      setIsVisible(false);
    } catch (error) {
      message.error(error.message || 'Failed to set community');
    }
    setLoading(false);
  };
  const getCommunities = async () => {
    try {
      const res = await listMentorCommunities(user.zipcode);
      setCommunities(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title='Choose Community'
      visible={isVisible}
      footer={false}
      closable={false}
    >
      <Form {...layout} onFinish={handleSubmit}>
        <Form.Item
          name='communityId'
          label='Select a community'
          rules={[{ required: true, message: 'Please select community' }]}
        >
          <Select
            placeholder='Choose Community'
            onChange={(val) => form.setFieldsValue({ communityId: val })}
            allowClear
            showSearch
          >
            {communities.map((c) => (
              <Select.Option value={c._id} key={c._id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' size='large' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function InviteModal({ role, isVisible, setIsVisible }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleForm = async (values) => {
    setLoading(true);
    console.log(values);
    const inviteBody = {
      role,
      email: values.email,
    };
    try {
      await apiPostMethod('/sponsors/invites', inviteBody);
      message.success(`An invitation email is sent on ${values.email}`);
      setIsVisible(false);
    } catch (error) {
      message.error('Failed to send invite');
    }
    setLoading(false);
  };

  return (
    <Modal
      title={role === 'user' ? 'Invite A New Creator' : 'Invite A New Mentor'}
      visible={isVisible}
      footer={false}
      onCancel={() => setIsVisible(false)}
    >
      <Form {...layout} form={form} onFinish={handleForm}>
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input type='email' />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 9, span: 14 }}>
          <Button
            type='primary'
            size='large'
            htmlType='submit'
            loading={loading}
          >
            Send Invite
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function DashBoardPostSection({ user }) {
  const [community, setCommunity] = useState({});
  const [posts, setPosts] = useState([]);
  const [description, setDescription] = useState('');
  const [createPostLoading, setCreatePostLoading] = useState(false);

  useEffect(() => {
    if (user.communityId) {
      getCommunity();
      getPosts();
    }
  }, [user.communityId]);

  const getCommunity = async () => {
    getCommunityData(user?.communityId).then((res) => setCommunity(res.data));
  };

  const getPosts = async () => {
    const data = {
      userId: user.id,
      zip: user.zipcode,
      communityId: user.communityId,
    };
    const res = await getAllUserPost(data);
    setPosts(res.postList);
  };

  const handleOnLike = async (id, status) => {
    const likeData = {
      postId: id,
      userId: user.id,
      status,
    };
    await toggleLikeApi(likeData);
    getPosts();
  };

  const createPost = async (e) => {
    e.preventDefault();
    const postData = {
      userId: user.id,
      city: user.city,
      zip: user.zipcode,
      description,
    };
    createUserPost(postData)
      .then(() => {
        message.success('Post added successfully');
        setDescription('');
        getPosts();
      })
      .catch(() => message.error('Failed to create post'))
      .finally(() => setCreatePostLoading(false));
  };

  return (
    <div className='col-md-12 col-lg-6'>
      <div className='community-section'>
        <div className='comment'>
          <h2 className='font-weight-bold'>
            What's happening in {community.name}...
          </h2>

          <form onSubmit={createPost}>
            <div className='form-group'>
              <textarea
                className='form-control'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={
                  user.zipcode
                    ? `Share an update with ${community.name || ''}`
                    : 'Field is disable beacause no zip code available'
                }
                required
                disabled={user.zipcode ? false : true}
              />
              <Space size='large'>
                <Upload>
                  <img className='h-o-h' src={ImgIcon} alt='ImgIcon' />
                </Upload>
                <Upload>
                  <img
                    className='h-o-h'
                    src={DocumentIcon}
                    alt='DocumentIcon'
                  />
                </Upload>
                <Upload>
                  <img
                    className='h-o-h'
                    src={LocationIcon}
                    alt='LocationIcon'
                  />
                </Upload>
              </Space>
              <div className='d-flex align-items-center justify-content-end'>
                <Button
                  type='primary'
                  className='form-control post-comment'
                  value='POST'
                  htmlType='submit'
                  loading={createPostLoading}
                >
                  POST
                </Button>
              </div>
            </div>
          </form>

          <ShowPosts posts={posts} onLike={handleOnLike} />
        </div>
      </div>
    </div>
  );
}

function ShowPosts({ posts, onLike }) {
  const IconText = ({ icon, text, onClick }) => (
    <Space style={{ cursor: 'pointer' }} onClick={onClick}>
      <img src={icon} style={{ maxWidth: 30 }} />
      {text}
    </Space>
  );
  return (
    <>
      <br />
      <List
        itemLayout='vertical'
        size='large'
        className='posts'
        // pagination={{
        //   onChange: (page) => {
        //     console.log(page);
        //   },
        //   pageSize: 3,
        // }}
        dataSource={posts}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <IconText
                onClick={() => onLike(item.id, !item.liked)}
                icon={item.liked ? HeartIcon : EmptyHeartIcon}
                text={item.totalLikes}
                key='list-vertical-star-o'
              />,
              <IconText
                icon={CommentsIcon}
                text={item.totalComments}
                key='list-vertical-like-o'
              />,
              <IconText icon={ShareIcon} key='list-vertical-message' />,
            ]}
            // extra={
            //   <img
            //     width={272}
            //     alt='logo'
            //     src='https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png'
            //   />
            // }
          >
            <List.Item.Meta
              avatar={<Avatar src={item?.userId?.userPhoto} />}
              title={item.userId?.firstName}
              description={moment(item.createdAt).fromNow()}
            />
            <ShowContent description={item.description} />
            {item.postImage.length ? <Image src={item.postImage[0]} /> : ''}
          </List.Item>
        )}
      />
    </>
  );
}

function ShowContent({ description }) {
  const urlRegex =
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gm;
  const urls = description?.match(urlRegex);
  const postDescription = description?.replace(urlRegex, '');
  return (
    <>
      {urls?.length && <LinkPreviewCard url={urls[0]} />}
      {parse(unescapeHtml(postDescription))}
    </>
  );
}

function DashBoardNewsAndUpdateSection(props) {
  const [creators, setCreators] = useState([]);
  const [inviteCreatorModal, setInviteCreatorModal] = useState(false);
  const data = [
    {
      type: 'Completed In Takes',
      value: 27,
    },
    {
      type: 'Completed Goals',
      value: 25,
    },
    {
      type: 'Completed Education',
      value: 18,
    },
    {
      type: 'Have Mentors',
      value: 15,
    },
    {
      type: 'Completed Business Registration',
      value: 10,
    },
  ];

  const config = {
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    legend: {
      position: 'bottom',
    },
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  const handleCreatorAdd = () => {
    console.log('Add Creator Clicked');
    setInviteCreatorModal(true);
  };

  const getCreators = async () => {
    const creators = await apiGetMethod('/sponsors/invites?role=user');
    setCreators(creators);
  };

  useEffect(() => {
    getCreators();
  }, [inviteCreatorModal]);

  return (
    <div className='col-md-12 col-lg-3'>
      <InviteModal
        role='user'
        isVisible={inviteCreatorModal}
        setIsVisible={setInviteCreatorModal}
      />
      <div className='w-100 d-inline-block'>
        <DashboardCard title='Creators' onAddClick={handleCreatorAdd}>
          {creators.map((i) => (
            <p className='cursor-pointer' key={i.id}>
              {i.email}{' '}
              <Tag color={i.isAccepted ? 'green' : 'volcano'}>
                {i.isAccepted ? 'Accepted' : ' Pending'}
              </Tag>
            </p>
          ))}
        </DashboardCard>
        <DashboardCard title='Creator Statistics'>
          <Pie {...config} />
        </DashboardCard>
      </div>
    </div>
  );
}

export default SponsorDashboard;
