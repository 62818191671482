import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';
import {
    // apiGetMethod,
    apiPostMethod,
    apiDeleteMethod,
    apiPutMethod
    //  post, get, apiPostMethod
} from '../../api/rest';
var {
    ADMIN_ADD_COMMUNITY,
    ADMIN_DELETE_COMMUNITY,
    ADMIN_UPDATE_COMMUNITY,
    ADMIN_UPDATE_MENTOR_CATEGORY,
    ADMIN_DELETE_MENTOR_CATEGORY
} = API_END_POINTS_CONSTANT;

export const createCommunity = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ADMIN_ADD_COMMUNITY, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// export const getListResource = ( headers) => {
//     return new Promise((resolve, reject) => {
//         apiGetMethod(GET_LIST_RESOURCE, headers).then(res => {
//             resolve(res)
//         }).catch(err => {
//             reject(err)
//         })
//     })
// };

export const deleteCommunity = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiDeleteMethod(`${ADMIN_DELETE_COMMUNITY}/${data.id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateCommunity = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPutMethod(`${ADMIN_UPDATE_COMMUNITY}`, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateAdminMentorCategory = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ADMIN_UPDATE_MENTOR_CATEGORY, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export const deleteAdminMentorCategory = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiDeleteMethod(`${ADMIN_DELETE_MENTOR_CATEGORY}/${data.id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}