import React, { useEffect, useState } from 'react';
import { Table, Space, Button, message } from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
  REGEX_CONSTANTS,
} from '../../../shared/constants';
// API END POINTS
import {
  addVideoForAboutUs,
  getVideoHome,
  deleteImageFromPageSetting,
  addVideoForHome,
} from '../../../../redux/slices/adminSlice';
import { header } from '../../../../api/rest';
import { fileUpload } from '../../../../redux/slices/dashboardSlice';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
import './adminaboutuspagesetting.scss';

var { ERROR_MESSAGE, VIDEO_EXTENSION_ERROR } = CONSTANTS;

var {
  CONFIRMATION_POPUP_MODAL,
  FILE_UPLOAD_MODAL,
  UPDATE_PROFILE_MODAL_IMAGES,
} = POP_UP_MODAL_CONSTANTS;
var { CONFIRMATION_HEADER, HOME_ABOUT_US_HEADER } =
  POP_UP_MODAL_HEADER_CONSTANTS;

var { ALL_VIDEO_FORMAT_REGEX, YOUTUBE_URL_REGEX, YOUTUBE_EMBED_URL_REGEX } =
  REGEX_CONSTANTS;
function AdminAboutUsPageSetting(props) {
  // const dispatch = useDispatch();
  // const loginUserData = useSelector(state => state.user);
  const storageData = localStorage.getItem('userData');
  const payload = storageData ? JSON.parse(storageData).payload : '';
  const token = storageData ? JSON.parse(storageData).token : '';
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [videoList, setVideoList] = useState([]);
  const [currentId, setCurrentId] = useState('');
  const [visionImageType, setVisionImageType] = useState('');

  const columns = [
    {
      title: 'Video',
      dataIndex: 'homeImagesLink',
      key: 'homeImagesLink',
      render: (text, record) => (
        <React.Fragment>
          {record.aboutusImagesLink.length
            ? record.aboutusImagesLink.map((value, index) => {
                return (
                  <video
                    className='video-td'
                    src={value}
                    controls
                    key={`${value}-${index}`}
                  />
                );
              })
            : ''}
        </React.Fragment>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            className='del-community-btn'
            onClick={() => fillUserInfoInModal(record, 'delete')}
          >
            Delete Video
          </Button>
        </Space>
      ),
    },
  ];

  const isMultiple = true;

  useEffect(() => {
    if (token) {
      getHomePageVideoList();
    }
  }, []);

  const showLoader = {
    bannerImage: false,
    userPhoto: false,
    visionImage: false,
  };
  function fillUserInfoInModal(record, type) {
    toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
    setDescription('Are you sure to remove this entry');
    setCurrentId(record._id);
  }

  function getHomePageVideoList() {
    header.Authorization = `Bearer ${token}`;
    getVideoHome(header)
      .then((res) => {
        setShowPageLoader(false);
        // setVideoList(res.data);
        if (res.data.length) {
          let values = filterData(res.data);
          setVideoList(values);
        } else {
          setVideoList([]);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function addVideoForHomePageApi(value) {
    let data = {
      adminId: payload.id,
      imageUrl: value,
    };
    header.Authorization = `Bearer ${token}`;
    addVideoForAboutUs(data, header)
      .then((res) => {
        getHomePageVideoList();
        closePopupModal();
        message.success(res.message);
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err?.data.message ? err.data.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  // function filter data
  function filterData(data) {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].aboutusImagesLink.length) {
        arr.push(data[i]);
      }
    }
    return arr;
  }

  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
  }

  //   function onSubmitPopUpModal(event) {
  //     setShowPageLoader(true);

  //     if (popUpModal.type === FILE_UPLOAD_MODAL) {
  //       event.preventDefault();
  //       makeformData();
  //     }
  //     if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
  //       callDeleteApi();
  //     }
  //   }
  function onSubmitPopUpModal(event) {
    setShowPageLoader(true);
    if (popUpModal.modalHeader === HOME_ABOUT_US_HEADER) {
      event.preventDefault();
      if (visionImageType === 'text') {
        addVideoForHomePageApi(selectedFile);
      } else {
        makeformData();
      }
    }
    if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
      callDeleteApi();
    }
  }

  function callDeleteApi() {
    header.Authorization = `Bearer ${token}`;
    let data = {
      id: currentId,
    };
    deleteImageFromPageSetting(data, header)
      .then((res) => {
        getHomePageVideoList();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function makeformData() {
    var formData = new FormData();
    for (const File of selectedFile) {
      formData.append('files', File);
    }
    uploadFileApi(formData);
  }

  function handleFileInput(event) {
    let { files, name, type, value } = event.target;

    if (type === 'text') {
      setSelectedFile(value);
      if (value) {
        setVisionImageType('text');
      } else {
        setVisionImageType('');
      }
    } else {
      if (files.length) {
        // let extensionType = files[0].name.slice((Math.max(0, files[0].name.lastIndexOf(".")) || Infinity) + 1);
        let extensionType = files.length
          ? files[0].name.slice(
              (Math.max(0, files[0].name.lastIndexOf('.')) || Infinity) + 1
            )
          : false;
        let finalExtension = '.' + extensionType;
        let status = ALL_VIDEO_FORMAT_REGEX.test(finalExtension);
        if (extensionType) {
          if (status) {
            setSelectedFile(files);
          } else {
            message.error(VIDEO_EXTENSION_ERROR);
            event.target.value = '';
            setSelectedFile('');
          }
        } else {
          setSelectedFile('');
        }
      } else {
        event.target.value = '';
        setSelectedFile('');
      }
    }
  }

  function uploadFileApi(data) {
    header.Authorization = token;
    fileUpload(data, header)
      .then((res) => {
        let arr = composeArrayForMultipleFile(res.image);
        addVideoForAboutusPage(arr);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function composeArrayForMultipleFile(data) {
    let arr = [];
    if (data.length) {
      for (let i = 0; i < data.length; i++) {
        arr.push(data[i].Location);
      }
      return arr;
    } else {
      return arr;
    }
  }

  function addVideoForAboutusPage(value) {
    header.Authorization = `Bearer ${token}`;
    let data = {
      adminId: payload.id,
      aboutusImagesLink: value,
    };
    addVideoForAboutUs(data, header)
      .then((res) => {
        getHomePageVideoList();
        message.success(res.message);
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  let homepagemodalvarprops = {
    selectedFile,
    isMultiple,
    showPageLoader,
  };
  let homepagemodalfunctionprops = {
    holdSelectedImage: handleFileInput,
  };
  let userProfileImageModalVariable = {
    showLoader,
    visionImageType,
  };

  function getId(url) {
    const regExp = YOUTUBE_EMBED_URL_REGEX;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  return (
    <React.Fragment>
      {showPageLoader ? (
        <div className='row'>
          <Loader />
        </div>
      ) : (
        ''
      )}
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          description={description}
          {...homepagemodalvarprops}
          {...homepagemodalfunctionprops}
          {...userProfileImageModalVariable}
        />
      ) : (
        ''
      )}
      {/* disabled={videoList.length ? videoList.length > 2 ? true : false : false} */}
      <div className='row mt-4 mb-2'>
        <div className='col-12 text-right'>
          <Button
            onClick={() =>
              toggleModal(UPDATE_PROFILE_MODAL_IMAGES, HOME_ABOUT_US_HEADER)
            }
            disabled={
              videoList.length ? (videoList.length >= 1 ? true : false) : false
            }
          >
            Add Video
          </Button>
        </div>
      </div>
      {/* <Table columns={columns} dataSource={videoList} /> */}

      <table className='table'>
        <thead>
          <tr>
            <th>Video</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {videoList.length ? (
            videoList.map((record, index) => {
              let isYoutubeUrl = record.aboutusImagesLink
                ? YOUTUBE_URL_REGEX.test(record.aboutusImagesLink[0])
                : false;
              const videoId = isYoutubeUrl
                ? getId(record.aboutusImagesLink[0])
                : false;
              if (record.aboutusImagesLink[0]) {
                return (
                  <tr key={record.id}>
                    <td>
                      {isYoutubeUrl ? (
                        <iframe
                          width='300px'
                          height='180px'
                          src={`https://www.youtube.com/embed/${videoId}`}
                          title={videoId}
                          frameBorder='0'
                          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                          allowFullScreen
                        />
                      ) : (
                        <video
                          className='video-td'
                          src={record.aboutusImagesLink[0]}
                          controls
                        />
                      )}
                    </td>
                    <td>
                      <Button
                        className='del-community-btn'
                        onClick={() => fillUserInfoInModal(record, 'delete')}
                      >
                        Delete Video
                      </Button>
                    </td>
                  </tr>
                );
              } else {
                return false;
              }
            })
          ) : (
            <tr>
              <td colSpan='2' className='text-center'>
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}
export default AdminAboutUsPageSetting;
