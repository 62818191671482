import React, {
  useEffect,
  useState
} from 'react';
import {
  Row,
  Col,
  Card,
  Tooltip,
  message
} from 'antd';
// REACT REDUX
import {
  useDispatch,
  useSelector
} from 'react-redux';
// CONSTANT
import {
  CONSTANTS
} from '../../../shared/constants';
import {
  header
} from '../../../../api/rest';
import {
  history
} from '../../../../redux/store';
import {
  Column
} from '@ant-design/charts';
import {
  InfoCircleFilled
} from '@ant-design/icons';
// API
import {
  getAdminDashboardData
} from '../../../../redux/slices/adminSlice';
// LOADER
import {
  Loader
} from '../../../shared/Loader';
// REDUX
import {setSideMenuBar} from '../../../../redux/slices/authSlice';
// CSS
import './admindashboard.scss';
var {
  ERROR_MESSAGE
  // ,TOKEN_EXPIRED_MESSAGE
} = CONSTANTS;

function AdminDashboard(props) {
  const dispatch = useDispatch();
  const sideMenuBar = useSelector(state => state.user.sideMenuBar);

  const storageData = localStorage.getItem("userData");
  const payload = storageData ? JSON.parse(storageData).payload : '';
  const token = storageData ? JSON.parse(storageData).token : '';
  const [analyticObj, setAnalyticObj] = useState({
    liberalCounts: '0',
    postCounts: '0',
    resourcesCount: '0',
    userCounts: '0',
    categoryCount: '0',
    communityCount: '0'
  });
  const topColResponsiveProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 6,
    style: { marginBottom: 24 },
  };
  const [showPageLoader, setShowPageLoader] = useState(true);
  var data = [
    {
      type: 'January',
      sales: 38,
    },
    {
      type: 'February',
      sales: 52,
    },
    {
      type: 'March',
      sales: 61,
    },
    {
      type: 'April',
      sales: 145,
    },
    {
      type: 'May',
      sales: 48,
    },
    {
      type: 'June',
      sales: 38,
    },
    {
      type: 'July',
      sales: 65,
    },
    {
      type: 'August',
      sales: 71,
    }, {
      type: 'September',
      sales: 24,
    }, {
      type: 'October',
      sales: 18,
    }, {
      type: 'November',
      sales: 54,
    }, {
      type: 'December',
      sales: 99,
    }
  ];
  var config = {
    data: data,
    xField: 'type',
    yField: 'sales',
    scrollbar: { type: 'horizontal' },
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 1,
      },
    },
    meta: {
      type: { alias: 'Month' },
      sales: { alias: 'User' },
    },
  };

  useEffect(() => {
    if (payload.role === 'admin') {
      getAdminDashBoardApi();
    } else {
      history.push('/');
      // message.error(TOKEN_EXPIRED_MESSAGE);
    }
    // if (token) {
    //   getAdminDashBoardApi();
    // }
  }, [])

  function redirectToPage(url) {
    history.push(url);
    dispatch(setSideMenuBar(url));
  }
// get admin dashboard diffrent card count data
  function getAdminDashBoardApi() {
    header.Authorization = `Bearer ${token}`;
    getAdminDashboardData(header).then(res => {
      setAnalyticObj(prevAnalyticObj => {
        return {
          ...prevAnalyticObj, liberalCounts: res.liberalCounts,
          postCounts: res.postCounts,
          resourcesCount: res.resourcesCount,
          userCounts: res.userCounts,
          categoryCount: res.categoryCount,
          communityCount: res.communityCount
        }
      })
      setShowPageLoader(false);
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowPageLoader(false);
    })
  }
  return (
    <React.Fragment>
      {showPageLoader ? <Loader /> : ''}
      <div className="admin-dashboard">
        <Row gutter={24} type="flex">
          <Col {...topColResponsiveProps}>
            <Card hoverable onClick={() => redirectToPage('/admin/user')} title="Total Users" extra={<Tooltip title="Total number of active users">
              <InfoCircleFilled />
            </Tooltip>}>
              <h3>{analyticObj.userCounts}</h3>
              {/* <p>Card content</p>
        <p>Card content</p>
        <p>Card content</p> */}
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card hoverable onClick={() => redirectToPage('/admin/post')} title="Total Post" extra={<Tooltip title="Total number of Post">
              <InfoCircleFilled />
            </Tooltip>}>
              <h3>{analyticObj.postCounts}</h3>
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card hoverable onClick={() => redirectToPage('/admin/resource')} title="Total Resource" extra={<Tooltip title="Total number of Resource">
              <InfoCircleFilled />
            </Tooltip>} >
              <h3>{analyticObj.resourcesCount}</h3>
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card hoverable onClick={() => redirectToPage('/admin/liberator')} title="Total Liberator" extra={<Tooltip title="Total number of Liberator">
              <InfoCircleFilled />
            </Tooltip>} >
              <h3>{analyticObj.liberalCounts}</h3>
            </Card>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col {...topColResponsiveProps}>
            <Card hoverable onClick={() => redirectToPage('/admin/community')} title="Total Community" extra={<Tooltip title="Total number of Community">
              <InfoCircleFilled />
            </Tooltip>} >
              <h3>{analyticObj.communityCount}</h3>
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card hoverable onClick={() => redirectToPage('/admin/category')} title="Total Category" extra={<Tooltip title="Total number of Category">
              <InfoCircleFilled />
            </Tooltip>} >
              <h3>{analyticObj.categoryCount}</h3>
            </Card>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col {...topColResponsiveProps}>
          </Col>
        </Row>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="main-graph">
            <Card hoverable title="Total User" extra={<Tooltip title="Total number of User this month">
              <InfoCircleFilled />
            </Tooltip>}>
              <Column {...config} />
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AdminDashboard
