import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import MessageIcon from "../../../assets/icons/EQ_Icons_Envelope.png";
import TockenIcon from "../../../assets/images/token-icon.png";
// import CommentImg from '../../../assets/images/comment-img.png';
// import DefaultImg from '../../../assets/images/default-img.png';
// import DefaultImg from '../../../assets/images/userprofiledefault.png'
import DefaultPhoto from "../../../assets/images/userprofiledefault.png";
import { useHistory } from "react-router-dom";
// Socket
import { socket } from "../../service/socket";

// REACT REDUX
// import {
//     useDispatch,
//     useSelector
//   } from 'react-redux';

// CSS
import "./subheader.css";

const SubHeader = (props) => {
  // const dispatch = useDispatch();
  // const session = useSelector(state => state.user);
  let { pathName, walletCount, showProfile } = props,
    isPathNameHaveProfile = pathName.includes("Profile"),
    showProfileImage =
      pathName === "Learning" ||
      pathName === "Your Journey" ||
      isPathNameHaveProfile ||
      showProfile ||
      pathName === "Your Wallet" ||
      pathName === "Messaging",
    userData = localStorage.getItem("userData"),
    payload = userData ? JSON.parse(userData).payload : "",
    role = payload ? payload.role : "",
    userId = payload.id;
  // const currentPathName ='';
  let history = useHistory();
  const currentPathName = history.location.pathname;
  const [messageCount, setMessageCount] = useState("0");

  // let liberatorChartPath={
  //     pathname: "/liberatorchat"
  // },

  let messagingChatPath = {
    pathname: "/messaging",
    state: {
      data: {
        type: "text",
      },
    },
  };

  // <a href="/user/profile" className={isPathNameHaveProfile ? 'disabled' : ''}></a>

  function redirectToProfile() {
    if (props.history) {
      history.push("/user/profile", {
        data: {
          type: "text",
        },
      });
    }
  }

  useEffect(() => {
    socket.emit("check-notification", userId);
    socket.on("new-notification", (data) => {

      setMessageCount(data.count);
    });
  }, [userId]);

  return (
    <React.Fragment>
      <section className="breadcrumb-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 order-2 order-md-1 col-md-4 mb-4 mb-md-0">
            <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
              <div className="breadcrumb-content">
                <ul>
                  {showProfileImage ? (
                    <li className="mr-4">
                      <img
                        className="user-img"
                        src={
                          payload
                            ? payload.userPhoto
                              ? payload.userPhoto
                              : DefaultPhoto
                            : DefaultPhoto
                        }
                        alt={
                          pathName === "Messaging"
                            ? "DefaultImg123"
                            : "CommentImg"
                        }
                        onClick={redirectToProfile}
                      />
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="mr-4 position-relative breadcrumb-message-animation">
                    {currentPathName === "/messaging" ? (
                      <a href="/messaging">
                        <div className="d-flex">
                          <span className="d-flex justify-content-center position-relative">
                            <span>
                               <img src={MessageIcon} alt="MessageIcon" />
                            </span>
                            {messageCount === 0 ? (
                            <span className="counts count-bg-green">{messageCount}</span>
                            ) : (
                              <span className="counts">{messageCount}</span>
                            )}
                          </span>
                        </div>
                      </a>
                    ) : (
                      <Link to={messagingChatPath}>
                        <div className="d-flex">
                          <span className="d-flex justify-content-center position-relative">
                            <span>
                               <img src={MessageIcon} alt="MessageIcon" />
                            </span>
                            {messageCount === 0 ? (
                            <span className="counts count-bg-green">{messageCount}</span>
                            ) : (
                              <span className="counts">{messageCount}</span>
                            )}
                          </span>
                        </div>
                      </Link>
                    )}
                  </li>
                  {role === "mentor" ? (
                    ""
                  ) : (
                    <li className="mr-4 bounce-up-animation">
                      <Link
                        to="/wallet"
                        className={pathName === "Your Wallet" ? "disabled" : ""}
                      >
                        <img className="mr-2" src={TockenIcon} alt="TockenIcon" />
                        <span className="font-weight-bold" style={{verticalAlign: "sub"}}>
                          {walletCount
                            ? walletCount
                            : payload
                            ? payload.badgeToken
                              ? payload.badgeToken
                              : 0
                            : 0}
                        </span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </AnimatedOnScroll>
            </div>
            <div className="col-12 order-1 order-md-2 col-md-8 mb-4 mb-md-0">
              <AnimatedOnScroll animationIn="fadeInRight" animationOut="fadeOutRight" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                <div className="breadcrumb-content justify-content-md-end">
                  <p className="text-left text-md-right">{pathName}</p>
                </div>
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SubHeader;
