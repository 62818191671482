import React, { useState, useEffect } from 'react';
import jsonp from 'jsonp';
// import Mailchimp from '../MailChimp';
import { Modal, Form, Input, Row, Col, Button, Select, Result } from 'antd';
import { apiGetMethod } from '../../../api/rest';
import TokenIcon from '../../../assets/images/token-icon.png';
import './MailChimpPopup.scss';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const style = {
  background: '#f1f1f1',
  padding: '15px 25px',
  border: '1px solid #f1f1f1',
  margin: '0em',
  width: '100%',
};

export function MailChimpPopup(props) {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [communitiesLoading, setCommunitiesLoading] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState(false);

  useEffect(() => {
    getCommunities();
  }, []);

  const getCommunities = async () => {
    setCommunitiesLoading(true);
    const res = await apiGetMethod('/getCommunities');
    setCommunities(res);
    setCommunitiesLoading(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const { email, firstName, lastName, zip } = values;
    const url = `https://equiliberty.us1.list-manage.com/subscribe/post-json?u=7ec78db856eb80758bdc9d2b3&id=b034b03a71&EMAIL=${encodeURIComponent(
      email
    )}&FNAME=${firstName}&LNAME=${lastName}&MMERGE6=${zip}&c=__jp0`;
    jsonp(url, { param: 'c' }, (err, data) => {
      setLoading(false);
      setModal(true);
      console.info(data);
      console.error(err);
    });
  };
  return (
    <>
      <Modal
        centered
        visible={props.visible}
        onOk={(event) => props.onOk(event)}
        onCancel={() => props.onCancel(false)}
        footer={null}
        className='MailChimpPopup'
        width={600}
        maskClosable={false}
      >
        <div className='container'>
          <div className='row text-center'>
            <div className='col-md-12'>
              <h2>Join Equiliberty</h2>
              <h5>Where building community is building wealth!</h5>
              <p>
                Start meeting new people and building wealth in your community!
                If you already have an account, sign in to use Equiliberty on
                the web.
              </p>

              <Form
                {...layout}
                onFinish={handleSubmit}
                form={form}
                className='mt-5'
              >
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      name='firstName'
                      labelCol={{ span: 3, offset: 12 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your First Name!',
                        },
                      ]}
                    >
                      <Input placeholder='First Name' style={style} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='lastName'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Last Name!',
                        },
                      ]}
                    >
                      <Input placeholder='Last Name' style={style} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Email!',
                      type: 'email',
                    },
                  ]}
                >
                  <Input
                    placeholder='Email Address'
                    type='email'
                    style={style}
                  />
                </Form.Item>
                <Form.Item
                  name='zip'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your zip code!',
                    },
                  ]}
                >
                  <Input placeholder='Zip Code' type='number' style={style} />
                </Form.Item>

                <Form.Item name='community'>
                  <Select
                    placeholder='Select Community'
                    className='mailChimpSelect'
                    loading={communitiesLoading}
                    disabled={communitiesLoading}
                    onChange={(v) => setSelectedCommunities(v)}
                  >
                    {communities.map((c) => (
                      <Select.Option value={c.name} key={c._id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    type='primary'
                    loading={loading}
                    disabled={loading}
                  >
                    Join Now!
                  </Button>
                </Form.Item>
              </Form>

              {/* <Mailchimp
              action='https://equiliberty.us1.list-manage.com/subscribe/post?u=7ec78db856eb80758bdc9d2b3&amp;id=b034b03a71'
              //Adding multiple fields:
              fields={[
                {
                  name: 'EMAIL',
                  placeholder: 'Email Address',
                  type: 'email',
                  required: true,
                },
              ]}
              // Change predetermined language
              messages={{
                sending: 'Sending...',
                success: 'Thank you for joining the mailing list!',
                error: 'An unexpected internal error has occurred.',
                empty: 'You must write an e-mail.',
                duplicate: 'This email address already subscribed',
                button: 'Join Now!',
              }}
              // Add a personalized class
              className=''
              onSuccess={props.onOk}
            /> */}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        visible={modal}
        onCancel={() => {
          setModal(false);
          props.onOk();
        }}
        footer={null}
      >
        <Result
          icon={
            <img
              className='spinToken'
              src={TokenIcon}
              width='100px'
              alt='Wallet-icon'
            />
          }
          title={`Congratulations! You have just earned 100 tokens!${
            selectedCommunities
              ? `You are now a member of the ${selectedCommunities}`
              : 'You are now a member'
          } `}
          extra={null}
        />
      </Modal>
    </>
  );
}
