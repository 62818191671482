import React from "react";
import PropTypes from 'prop-types';

const Container = props => {

    const { children, fluid, gutter, style, className } = props;
    return (
        <div className={`${className} ${fluid ? `container_fluid` : `container`} ${!gutter || 'gutter'}`} style={style}>
            {children}
        </div>
    )
}

Container.propTypes = {
    fluid: PropTypes.bool,
    gutter: PropTypes.bool,
    className: PropTypes.string,
}

Container.defaultProps = {
    fluid: false,
    gutter: true,
    className: '',
}

export default Container
