import React, {
    useState,
    useEffect
} from 'react';
import {
    Button,
    Form,
    Row,
    Layout,
    message,
    Spin,
    Menu,
    Col,
    Input,
    Select
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import Container from "../../widgets/container";
// COMPONENTS
import RegCategory from '../register/choose-category';
import RegBusinessDetails from "../register/business-details";
import Mentors from "..//mentor";
import ChooseCommunity from '..//register/choose-community';
// import Mentor from '../mentor/single-mentor';
// import UserImg from '../../../assets/images/meeting-img1.jpg';
// import crossImg from '../../../assets/images/cross.svg';
// CSS
import style from './profileupdate.module.sass';
// import './profileupdate.sass';
import './profile-update.scss';
// ICONS
// import { UploadOutlined } from '@ant-design/icons';
// CONSTANTS
import {
    CONSTANTS,
    POP_UP_MODAL_CONSTANTS,
    POP_UP_MODAL_HEADER_CONSTANTS,
    REGEX_CONSTANTS
} from '../../shared/constants';
// POPUPMODAL
import PopUpModal from '../../shared/popupmodal';
// Loader
import { Loader } from '../../shared/Loader'
// COUNTRIES LIST
// import countries from "../../widgets/countries";
// API ACTIONS
import {
    header
} from '../../../api/rest';
import {
    setLoading as running,
    modifyPassword
} from "../../../redux/slices/authSlice";
// import {
//     fileUpload,
// } from '../../../redux/slices/dashboardSlice';
import {
    updateUserProfile,
    getUserProfile,
    userImageUpload,
    getAnyUserProfile,
    submitUserAnswer,
    getQuestionSet,
    getUserAnswers
} from "../../../redux/slices/userProfileSlice";
import {
    listMentorCommunities,
    listMentorCategory
} from '../../../redux/slices/registerSlice';
// Sound Component
import { TokenEarnSound } from '../../shared/soundeffect';
let {
    TOKEN_EXPIRED_MESSAGE,
    ERROR_MESSAGE,
    BUISNESS_DETAILS,
    CHOOSE_A_COMMUNITY,
    CHOOSE_A_CATEGORY,
    CHOOSE_MENTOR,
    REGISTER_MENTOR,
    UPDATE_PROFILE_TEXT,
    IMAGE_EXTENSION_ERROR,
    VIDEO_EXTENSION_ERROR
} = CONSTANTS;
let {
    UPLOAD_VISION_IMAGE,
    CHANGE_PASSWORD_TEXT,
    QUESTION_HEADER
} = POP_UP_MODAL_HEADER_CONSTANTS;
var {
    ALL_PHOTO_FORMAT_REGEX,
    ALL_VIDEO_FORMAT_REGEX,
    ACCEPT_NUMBER
} = REGEX_CONSTANTS;
var {
    CHANGE_PASSWORD_MODAL,
    QUESTION_POP_UP_MODAL
} = POP_UP_MODAL_CONSTANTS;

// const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ProfileUpdate = (props) => {
    React.useEffect(() => {
        const storageData = localStorage.getItem("userData");
        if (storageData) {
            document.title = "Profile Edit"
        } else {
            props.history.push('/');
            message.error(TOKEN_EXPIRED_MESSAGE);
        }
    }, []);
    const [form] = Form.useForm();
    // check if user come first time in page
    const userEntrytype = props.location.state ? props.location.state.data : '';
    const dispatch = useDispatch();
    const session = useSelector(state => state.user);
    const [loading, setLoading] = useState(session.loading);
    const storageData = localStorage.getItem("userData");
    const [userVision, setUserVision] = useState('');
    const [updateProfileObj, setUpdateProfileObj] = useState({
        bannerImage: '',
        userPhoto: '',
        visionImage: ''
    });
    const [showLoader, setShowLoader] = useState({
        bannerImage: false,
        userPhoto: false,
        visionImage: false
    });
    const [current, setCurrent] = useState(UPDATE_PROFILE_TEXT);
    // const [isSingleFileSelected, setIsSingleFileSelected] = useState(false);
    const [updateProfileObjStatus, setUpdateProfileObjStatus] = useState({
        bannerImage: '',
        userPhoto: '',
        visionImage: ''
    });
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });
    const [visionImageType, setVisionImageType] = useState('');
    const [communityList, setCommunityList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [fileToggleStatus, setFileToggleStatus] = useState({
        bannerImage: '',
        userPhoto: '',
        visionImage: ''
    });
    const [showPageLoader, setShowPageLoader] = useState(false);
    const [questionSet, setQuestionSet] = useState([]);
    const [allQuestion, setAllQuestion] = useState([]);
    // const [questionFormStatus, setQuestionFormStatus] = useState({});
    const [actQuestId, setActQuestId] = useState();
    const [currentArrIndex, setCurrentArrIndex] = useState(0);
    const [allAnswers, setAllAnswers] = useState([]);
    const [isAnswerCalled, setIsAnswerCalled] = useState(false);
    const questionFormStatus = {};
    const [playSound, setPlaySound] = useState(false);


    var token = storageData ? JSON.parse(storageData).token : '';
    var userId = storageData ? JSON.parse(storageData).payload.id : '';
    var role = storageData ? JSON.parse(storageData).payload.role : '';
    var zipcode = storageData ? JSON.parse(storageData).payload.zipcode : '';

    //   const isSingleFileChoosed = typeof (updateProfileObj.bannerImage) === "object" && typeof (updateProfileObj.userPhoto) === "object"&& typeof (updateProfileObj.visionImage) === "object" ? false : typeof (updateProfileObj.postImage) === "object" ? true : typeof (updateProfileObj.postDocument) === "object" ? true : false

    useEffect(() => {
        if (storageData) {
            getUserProfileData();
            getUserProfileDataFromAPI();
            if (userEntrytype === 'register') {
                getUserAnswersApi();
                showPopupModal(QUESTION_POP_UP_MODAL, `${QUESTION_HEADER} 1`);
            }
            if (role === 'mentor') {
                getCommunityName();
                getCommunityList();
                setCurrent(UPDATE_PROFILE_TEXT);
                setShowPageLoader(true);
            } else {
                setShowPageLoader(false);
            }
        } else {
            props.history.push('/');
            message.error(TOKEN_EXPIRED_MESSAGE);
        }
    }, []);

    useEffect(() => {
        if (props.location.state) {
            if (props.location.state.data.type) {
                setCurrent(UPDATE_PROFILE_TEXT);
            }
        }
    }, []);

    let badgesStatus = "fine", badgeToken = 0;
    let payload = storageData ? JSON.parse(storageData) : '';
    // let { token, payload } =storageData? JSON.parse(storageData):'',
    // { emailAddress, id } = payload;

    useEffect(() => {
        if (loading && !session.loading && session.data.success) {
            if (userEntrytype === "register") {
                props.history.push('/register/business-details');
            } else {
                setTimeout(() => {
                    if (role === 'mentor') {
                        props.history.push('/mentor/dashboard');
                    } else {
                        if (current === UPDATE_PROFILE_TEXT) {
                            props.history.push('/dashboard');
                        }
                    }
                    setPlaySound(false);
                }, 1500);

                // props.history.push('/user/profile');
            }
        }
        setLoading(session.loading);
    }, [loading, session]);

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    // fetching user profile data
    const getUserProfileData = () => {
        // let { payload } = JSON.parse(storageData);
        if (payload) {
            dispatch(running(true));
            let userId = payload.id ? payload.id : payload.payload.id;
            let user_id = {
                id: userId,
                type: 'normal'
            };
            dispatch(getUserProfile(user_id));
        }
    }


    function getUserProfileDataFromAPI() {
        let token = storageData ? JSON.parse(storageData).token : '';
        let userId = payload.id ? payload.id : payload.payload.id;
        header.Authorization = token;
        getAnyUserProfile(userId, header).then(res => {
            form.setFieldsValue({
                ...res.updatedUser
            });
            setUpdateProfileObj(prevUpdateProfileObj => {
                // Object.assign would also work
                return {
                    ...prevUpdateProfileObj,
                    userPhoto: res.updatedUser.userPhoto,
                    bannerImage: res.updatedUser.bannerImage ? res.updatedUser.bannerImage : '',
                    visionImage: res.updatedUser.userVision ? res.updatedUser.userVision : ''
                };
            });
            setFileToggleStatus(prevFileToggleStatus => {
                return {
                    ...prevFileToggleStatus,
                    userPhoto: res.updatedUser.userPhoto ? true : false,
                    bannerImage: res.updatedUser.bannerImage ? true : false,
                    visionImage: res.updatedUser.userVision ? true : false
                }
            });
            let vision = res.updatedUser.userVision ? res.updatedUser.userVision : '';
            setUserVision(vision);
        }).catch(err => {

            // message.error(err ? err.data.message : ERROR_MESSAGE);
            // message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE);
        })
    }
    // get community list
    function getCommunityList() {
        listMentorCategory(header).then(res => {
            if (res.data) {
                setCategoryList(res.data);
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err ? err.data.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }
    // get selected community name
    function getCommunityName() {
        header.Authorization = token;
        let value = zipcode;
        listMentorCommunities(value, header).then(res => {
            if (res.data) {
                setCommunityList(res.data);
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    const onFinish = async (values) => {
        if (payload) {
            // submitPost();
            // submitPostImages();
            dispatch(running(true));
            const body = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                zipcode: values.zipcode,
                bannerImage: updateProfileObj.bannerImage,
                visionImage: updateProfileObj.visionImage,
                userPhoto: updateProfileObj.userPhoto,
                userVision,
                emailAddress: payload.payload.email,
                id: payload.payload.id
            },
                mentorData = {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    zipcode: values.zipcode,
                    bio: values.bio,
                    categoryId: values.categoryId,
                    phoneNumber: values.phoneNumber,
                    userPhoto: updateProfileObj.userPhoto,
                    bannerImage: updateProfileObj.bannerImage,
                    visionImage: updateProfileObj.visionImage,
                    emailAddress: payload.payload.email,
                    communityId: values.communityId,
                    currentPosition: values.currentPosition,
                    companyName: values.companyName,
                    id: payload.payload.id
                };
            const data = role === 'mentor' ? mentorData : body;
            header.Authorization = payload.token;
            dispatch(updateUserProfile(data, header));
              dispatch(running(false));
            // if(userEntrytype === 'register'){
            //     setPlaySound(true);
            // }

            if (role === 'mentor') {

            } else {
                setPlaySound(true)
            }
        } else {

        }
    };

    function handleFileUploadInputFunction(event) {
        let {
            files,
            name,
            type,
            value
        } = event.target;
        if (type === "text") {
            setUpdateProfileObj(prevUpdateProfileObj => {
                // Object.assign would also work
                return { ...prevUpdateProfileObj, [name]: value };
            });
            if (value) {
                setVisionImageType('text');
            } else {
                setVisionImageType('');
            }
        } else {
            if (files.length) {
                let regexType = name === "visionImage" ? ALL_VIDEO_FORMAT_REGEX : ALL_PHOTO_FORMAT_REGEX;
                let fileType = name === "visionImage" ? 'video' : 'image';
                let extensionType = files.length ? files[0].name.slice((Math.max(0, files[0].name.lastIndexOf(".")) || Infinity) + 1) : false;
                let finalExtension = '.' + extensionType;
                let status = regexType.test(finalExtension);
                if (extensionType) {
                    if (status) {
                        setUpdateProfileObj(prevUpdateProfileObj => {
                            // Object.assign would also work
                            return { ...prevUpdateProfileObj, [name]: files };
                        });
                        setUpdateProfileObjStatus(prevUpdateProfileObjStatus => {
                            return { ...prevUpdateProfileObjStatus, [name]: 'selected' }
                        });
                        setVisionImageType('object');
                    } else {
                        message.error(fileType === 'video' ? VIDEO_EXTENSION_ERROR : IMAGE_EXTENSION_ERROR);
                        event.target.value = "";
                        setVisionImageType('');
                    }
                } else {
                    setUpdateProfileObj(prevUpdateProfileObj => {
                        // Object.assign would also work
                        return { ...prevUpdateProfileObj, [name]: '' };
                    });
                    setUpdateProfileObjStatus(prevUpdateProfileObjStatus => {
                        return { ...prevUpdateProfileObjStatus, [name]: '' }
                    });
                }
            } else {
                event.target.value = "";
                setVisionImageType('');
            }
        }
    }

    // Submit post data
    // function submitPostImages() {
    //     // event.preventDefault();
    //     header.Authorization = payload.token;
    //     // setShowPageLoader(true);
    //     if (updateProfileObj.bannerImage === '' && updateProfileObj.userPhoto === '' && updateProfileObj.visionImage === '') {
    //         submitFinalForm();
    //     } else {
    //         if (updateProfileObjStatus.bannerImage === '' && updateProfileObjStatus.userPhoto === '' && updateProfileObjStatus.visionImage === '') {
    //             submitFinalForm();
    //         } else {
    //             for (const property in updateProfileObj) {
    //                 if (updateProfileObj[property]) {
    //                     uploadDocument(header, updateProfileObj[property], property);
    //                 } else {
    //                 }
    //             }
    //         }
    //     }
    // }

    // function uploadDocument(token, property, name) {
    //     var formData = new FormData();
    //     for (const File of property) {
    //         formData.append('files', File);
    //     }
    //     commonFileUploadApi(formData, token, name)
    // }

    // function commonFileUploadApi(formData, token, name) {
    //     //   userImageUpload
    //     fileUpload(formData, token).then(res => {
    //         setUpdateProfileObj(prevUpdateProfileObj => {
    //             // Object.assign would also work
    //             return { ...prevUpdateProfileObj, [name]: res.Location };
    //         });
    //         setUpdateProfileObjStatus(prevUpdateProfileObjStatus => {
    //             return { ...prevUpdateProfileObjStatus, [name]: 'done' }
    //         })
    //     }).catch(err => {
    //         message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE)
    //         setUpdateProfileObjStatus(prevUpdateProfileObjStatus => {
    //             return { ...prevUpdateProfileObjStatus, [name]: 'error' }
    //         });
    //     })
    // }

    useEffect(() => {
        if (updateProfileObjStatus.bannerImage === 'done' && updateProfileObjStatus.userPhoto === 'done' && updateProfileObjStatus.userPhoto === 'done') {
            submitFinalForm();
        }
    }, [updateProfileObjStatus]);

    function submitFinalForm() {
        dispatch(running(true));
        const body = {
            bannerImage: updateProfileObj.bannerImage,
            userPhoto: updateProfileObj.userPhoto,
            visionImage: updateProfileObj.visionImage,
            badgesStatus,
            badgeToken,
            userVision,
            emailAddress: payload.payload.email,
            id: payload.payload.id
        };
        header.Authorization = payload.token;
        dispatch(updateUserProfile(body, header));
    }

    useEffect(() => {
    }, [updateProfileObj])

    const onFinishFailed = errorInfo => {
    };

    // calling image upload api
    function callUploadUserImageApi(event) {
        let {
            files,
            name,
            type,
            value
        } = event.target;
        if (type === "text") {
            setUpdateProfileObj(prevUpdateProfileObj => {
                // Object.assign would also work
                return { ...prevUpdateProfileObj, [name]: value };
            });
            if (value) {
                setVisionImageType('text');
            } else {
                setVisionImageType('');
            }
        } else {
            if (files.length) {
                let regexType = name === "visionImage" ? ALL_VIDEO_FORMAT_REGEX : ALL_PHOTO_FORMAT_REGEX;
                let fileType = name === "visionImage" ? 'video' : 'image';
                // let extensionType = files[0].name.slice((Math.max(0, files[0].name.lastIndexOf(".")) || Infinity) + 1);
                let extensionType = files.length ? files[0].name.slice((Math.max(0, files[0].name.lastIndexOf(".")) || Infinity) + 1) : false;
                let finalExtension = '.' + extensionType;
                let status = regexType.test(finalExtension);
                if (extensionType) {
                    if (status) {
                        setShowLoader(prevShowLoader => {
                            // Object.assign would also work
                            return { ...prevShowLoader, [name]: true };
                        });
                        if (name === "visionImage") {
                            setVisionImageType('object');
                        } else {
                            setVisionImageType('');
                        }
                        var formData = new FormData();
                        for (const File of files) {
                            formData.append('file', File);
                        }
                        header.Authorization = payload.token;
                        userImageUpload(formData, header).then(res => {
                            setUpdateProfileObj(prevUpdateProfileObj => {
                                // Object.assign would also work
                                return { ...prevUpdateProfileObj, [name]: res.Location };
                            });
                            setShowLoader(prevShowLoader => {
                                // Object.assign would also work
                                return { ...prevShowLoader, [name]: false };
                            });
                        }).catch(err => {
                            message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE)
                            setShowLoader(prevShowLoader => {
                                // Object.assign would also work
                                return { ...prevShowLoader, [name]: false };
                            });
                            setVisionImageType('object');
                        })
                    } else {
                        message.error(fileType === 'video' ? VIDEO_EXTENSION_ERROR : IMAGE_EXTENSION_ERROR);
                        event.target.value = "";
                    }
                } else {
                    setUpdateProfileObj(prevUpdateProfileObj => {
                        // Object.assign would also work
                        return { ...prevUpdateProfileObj, [name]: '' };
                    });
                    setUpdateProfileObjStatus(prevUpdateProfileObjStatus => {
                        return { ...prevUpdateProfileObjStatus, [name]: '' }
                    })
                    setVisionImageType('object');

                }
            } else {
                event.target.value = "";
                setVisionImageType('');
            }
        }
    }
    // load next tab
    function loadNextTab(tabname) {
        setCurrent(tabname)
    }

    useEffect(() => {
        if (session && session.data && session.data.userVision) {
            setUpdateProfileObj(prevUpdateProfileObj => {
                // Object.assign would also work
                return {
                    ...prevUpdateProfileObj,
                    visionImage: session.data.visionImage,
                    userPhoto: session.data.userPhoto,
                    bannerImage: session.data.bannerImage
                };
            });
            setUpdateProfileObjStatus(prevUpdateProfileObjStatus => {
                return {
                    ...prevUpdateProfileObjStatus,
                    bannerImage: '',
                    userPhoto: '',
                    visionImage: ''
                }
            })

        }
    }, [session]);
    // const [openKeys, setOpenKeys] = React.useState(['sub1']);
    // const onOpenChange = keys => {
    //     const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    //     if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //         setOpenKeys(keys);
    //     } else {
    //         setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    //     }
    // };
    const handleClick = e => {
        // setLoading(false);
        if (e.key === UPDATE_PROFILE_TEXT) {
            dispatch(running(false));
            getUserProfileData();
        }
        setCurrent(e.key);
    };
    function saveVisionText(data) {
        setUserVision(data);
    }

    function onSubmitPopUpModal(event) {
        if (popUpModal.type === QUESTION_POP_UP_MODAL) {
            questionAnswerModalOperation(event);
        }
        if (popUpModal.type === CHANGE_PASSWORD_MODAL) {
            if (event.oldPassword) {
                let data = {
                    "oldPassword": event.oldPassword,
                    "newPassword": event.password,
                    "userId": userId
                }
                modifyUserPassword(data);
            }
            event.preventDefault();
        }
    }

    // Question Answer modal operation
    function questionAnswerModalOperation(event) {
        if (questionSet.length) {
            if (event.answer) {
                let data = {
                    questionId: actQuestId,
                    userId: userId,
                    ...event
                };
                let status = questionSet.length ? questionSet[0].isLast : true

                submitQuestionAnswers(data, status);
            } else {
                message.error('Please Choose option')
            }

        } else {
            props.history.push('/register/business-details')
        }
    }
    // calling modify user password api
    function modifyUserPassword(data) {
        header.Authorization = token;
        modifyPassword(data, header).then(res => {
            message.success(res.message);
            closePopupModal();
        }).catch(err => {
            message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE);
        })
    }

    function showPopupModal(type, header) {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: header
            }
        });
    }

    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
    }

    function toggleFileUpload(name, status) {
        setFileToggleStatus(prevFileToggleStatus => {
            return {
                ...prevFileToggleStatus,
                [name]: status
            }
        });
    }

    // Submit Question and answers form
    function submitQuestionAnswers(data, isLast) {
        header.Authorization = token;
        // return false
        submitUserAnswer(data, header).then(res => {

            if (isLast) {
                if (data.answer === "Just getting started" || data.answer === "Business or idea already making money") {
                    props.history.push('/register/business-details');
                } else {
                    closePopupModal();
                }
            } else {
                let index = currentArrIndex + 1;
                extractSubArray(index, allQuestion);
                setCurrentArrIndex(index);
                showPopupModal(QUESTION_POP_UP_MODAL, `${QUESTION_HEADER} ${index + 1}`);
            }
            message.success(res.message)
        }).catch(err => {
            message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE);
        })
    }

    function getQuestionOfUser() {
        header.Authorization = token;
        getQuestionSet(header).then(res => {
            if (res.data.length) {
                fetchUnansweredQuestion(res.data);
                setIsAnswerCalled(false);
            } else {
                setQuestionSet([]);
            }
        }).catch(err => {
            message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE);
        })
    }

    function fetchUnansweredQuestion(data) {
        let questionArr = data;
        if (allAnswers.length) {
            let answerArrId = [];
            for (let k = 0; k < allAnswers.length; k++) {
                let id = allAnswers[k].questionId;
                answerArrId.push(id);
            }
            for (let m = 0; m < answerArrId.length; m++) {
                let index = data.findIndex(x => x.id === answerArrId[m]);
                if (index > -1) {
                    questionArr.splice(index, 1);

                } else {

                }
            }
            setAllQuestion(questionArr);
            if (questionArr.length) {
                extractSubArray(currentArrIndex, questionArr);
            }
        } else {
            setAllQuestion(data);
            if (data.length) {
                extractSubArray(currentArrIndex, data);
            }
        }
    }


    function getUserAnswersApi() {
        header.Authorization = token;
        let data = {
            userId
        };
        getUserAnswers(data, header).then(res => {
            setAllAnswers(res.updateAnswer);
            setIsAnswerCalled(true)
        }).catch(err => {
            message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE);
        })
    }

    function extractSubArray(index, array) {
        let data = array[index], arr = [], id = data._id;
        arr.push(data);
        setQuestionSet(arr);
        setActQuestId(id)
    }

    useEffect(() => {
        if (isAnswerCalled) {
            getQuestionOfUser();
        }
    }, [isAnswerCalled])

    let UpdateProfileSectionVar = {
        layout,
        showLoader,
        userVision,
        loading,
        visionImageType,
        role,
        categoryList,
        communityList,
        fileToggleStatus,
        updateProfileObj
    },
        UpdateProfileSectionFunction = {
            saveVisionText: saveVisionText,
            callUploadUserImageApi: callUploadUserImageApi,
            onFinish: onFinish,
            onFinishFailed: onFinishFailed,
            handleFileUploadInputFunction: handleFileUploadInputFunction,
            showPopupModal: showPopupModal,
            toggleFileUpload: toggleFileUpload
        },
        questionAnswerModal = {
            questionSet,
            questionFormStatus
        };

    return (
        <Layout.Content className={style.block}>
            {playSound ? <TokenEarnSound /> : ''}
            {
                popUpModal.visible ?
                    <PopUpModal
                        title={popUpModal.modalHeader}
                        visible={popUpModal.visible}
                        onCancel={closePopupModal}
                        onOk={onSubmitPopUpModal}
                        type={popUpModal.type}
                        {...questionAnswerModal}
                    /> : ''
            }
            {showPageLoader ? <Loader /> : ''}
            <Row className={`header-divider`} />
            <Container className={`auth-modal ${style.reg_container}`}>
                {userEntrytype === 'register' ? '' : role === 'mentor' ? '' : <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="mb-4">
                    <Menu.Item key={UPDATE_PROFILE_TEXT}>{UPDATE_PROFILE_TEXT}</Menu.Item>
                    <Menu.Item key={BUISNESS_DETAILS}>{BUISNESS_DETAILS}</Menu.Item>
                    <Menu.Item key={CHOOSE_A_COMMUNITY}>{CHOOSE_A_COMMUNITY}</Menu.Item>
                    <Menu.Item key={CHOOSE_A_CATEGORY}>{CHOOSE_A_CATEGORY}</Menu.Item>
                    <Menu.Item key={CHOOSE_MENTOR}>{CHOOSE_MENTOR || REGISTER_MENTOR}</Menu.Item>
                    {/* <Menu.Item key={REGISTER_MENTOR}>{REGISTER_MENTOR}</Menu.Item> */}
                </Menu>}
                {role === 'mentor' ? '' : current === BUISNESS_DETAILS ? <RegBusinessDetails type={BUISNESS_DETAILS} loadNextTab={loadNextTab} /> : ''}
                {role === 'mentor' ? '' : current === CHOOSE_A_COMMUNITY ? <ChooseCommunity type={CHOOSE_A_COMMUNITY} loadNextTab={loadNextTab} /> : ''}
                {role === 'mentor' ? '' : current === CHOOSE_A_CATEGORY ? <RegCategory type={CHOOSE_A_CATEGORY} loadNextTab={loadNextTab} /> : ''}
                {role === 'mentor' ? '' : current === CHOOSE_MENTOR ? <Mentors type={CHOOSE_MENTOR} loadNextTab={loadNextTab} /> : ''}
                {/* {role === 'mentor' ? '' : current === REGISTER_MENTOR ? <Mentor type={REGISTER_MENTOR} loadNextTab={loadNextTab} /> : ''} */}
                {current === UPDATE_PROFILE_TEXT ? <UpdateProfileSection form={form} {...UpdateProfileSectionVar} {...UpdateProfileSectionFunction} /> : ''}
            </Container>
        </Layout.Content>
    )
}

function UpdateProfileSection(props) {
    let {
        showLoader,
        layout,
        userVision,
        loading,
        form,
        visionImageType,
        role,
        categoryList,
        communityList,
        // fileToggleStatus,
        updateProfileObj
    } = props;
    return (
        <React.Fragment>
            <Form
                {...layout}
                name="updatedUser"
                form={form}
                onFinish={(values) => props.onFinish(values)}
                onFinishFailed={() => props.onFinishFailed()}>
                <div className="update-profile-section">
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"First Name"}
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your First Name!',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Last Name"}
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Last Name!',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"City"}
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your city!',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"State"}
                                name="state"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your state!',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    {role === 'mentor' ? <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Current Position"}
                                name="currentPosition"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Current Position!',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Company Name"}
                                name="companyName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Company Name!',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row> : ''}
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Zip Code"}
                                name="zipcode"
                                rules={[
                                    {
                                        required: true,
                                        pattern: ACCEPT_NUMBER,
                                        validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid Zipcode'),
                                    },
                                ]}>
                                <Input maxLength="6" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Email"}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: ' Please enter a valid email address',
                                        type: 'email',
                                    },
                                ]}>
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Country"}
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your country!',
                                    },
                                ]}>
                                <Select className="country-feild" placeholder="Country" allowClear showSearch>
                                    {countries.map(c => <Select.Option value={c} key={c}>{c}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Upload User Photo"}
                                name="userPhoto">
                                <Spin indicator={antIcon} spinning={showLoader.userPhoto}>
                                    <input className="form-control" type="file" name="userPhoto" accept="image/*" onChange={(event) => { props.callUploadUserImageApi(event) }} />
                                </Spin>
                            </Form.Item>
                            {/* {fileToggleStatus.userPhoto ? : ''} */}
                            {/* {
                                fileToggleStatus.userPhoto ? '' : role === 'mentor' ?
                                    <Form.Item>
                                        <div className="user_profile">
                                            <img src={updateProfileObj.userPhoto} alt={updateProfileObj.userPhoto} />
                                            <img src={crossImg} alt="crossImg" className="user_cross" onClick={() => props.toggleFileUpload('userPhoto', true)} />
                                        </div></Form.Item>
                                    : ''
                            } */}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Upload Banner Image"}
                                name="bannerImage">
                                <Spin indicator={antIcon} spinning={showLoader.bannerImage}>
                                    <input className="form-control" type="file" name="bannerImage" accept="image/*" onChange={(event) => { props.callUploadUserImageApi(event) }} />
                                </Spin>
                            </Form.Item>
                            {/* {fileToggleStatus.bannerImage ?  : ''} */}
                            {/* {
                                fileToggleStatus.bannerImage ? '' : role === 'mentor' ?
                                    <Form.Item>
                                        <div className="user_profile">
                                            <img src={updateProfileObj.bannerImage} alt={updateProfileObj.bannerImage} />
                                            <img src={crossImg} alt="crossImg" className="user_cross" onClick={() => props.toggleFileUpload('bannerImage', true)} />
                                        </div></Form.Item>
                                    : ''
                            } */}
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        {
                            role === 'mentor' ?
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label={"Mentor Category"}
                                        name="categoryId"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose your category!',
                                            },
                                        ]}>
                                        <Select className="country-feild" placeholder="Category" allowClear showSearch>
                                            {categoryList.length ? categoryList.map(c => <Select.Option value={c.id} key={c._id}>{c.name}</Select.Option>) : <Select.Option>No Category List Available Please Contact Admin</Select.Option>}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : ''
                        }
                        {
                            role === 'mentor' ?
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        label={"Mentor Community"}
                                        name="communityId"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose your Communty!',
                                            },
                                        ]}>
                                        <Select className="country-feild" placeholder="Community" allowClear showSearch>
                                            {communityList.length ? communityList.map(c => <Select.Option value={c.id} key={c._id}>{c.name}</Select.Option>) : <Select.Option>No Category List Available Please Contact Admin</Select.Option>}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : ''
                        }
                        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item label="Change Password">
                                <Button type="primary" onClick={() => { props.showPopupModal(CHANGE_PASSWORD_MODAL, CHANGE_PASSWORD_TEXT) }}>Change Password</Button>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    {role === 'mentor' ? <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={UPLOAD_VISION_IMAGE}
                                name="visionImage">
                                <Spin indicator={antIcon} spinning={showLoader.visionImage}>
                                    <input className="form-control" type="file" name="visionImage" accept="video/*" onChange={(event) => { props.callUploadUserImageApi(event) }} disabled={visionImageType === "text" ? true : false} />
                                </Spin>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={"Mentor Phone Number"}
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        pattern: ACCEPT_NUMBER,
                                        validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid Phone Number'),
                                        // message: 'Please input your zipcode!',
                                    },
                                ]}>
                                <Input maxLength="10" />
                            </Form.Item>
                        </Col>
                    </Row> : ''}
                    {role === 'mentor' ? '' : <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={UPLOAD_VISION_IMAGE}
                                name="visionImage">
                                <Spin indicator={antIcon} spinning={showLoader.visionImage}>
                                    <input className="form-control" type="file" name="visionImage" accept="video/*" onChange={(event) => { props.callUploadUserImageApi(event) }} disabled={visionImageType === "text" ? true : false} />
                                </Spin>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item label="Change Password">
                                <Button type="primary" onClick={() => { props.showPopupModal(CHANGE_PASSWORD_MODAL, CHANGE_PASSWORD_TEXT) }}>Change Password</Button>
                            </Form.Item>
                        </Col>
                    </Row>}
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Paste Youtube url"
                                name="visionImage">
                                <input type="text" name="visionImage" value={updateProfileObj.visionImage} className="ant-input" onChange={(event) => { props.callUploadUserImageApi(event) }} disabled={visionImageType === "object" ? true : false} />
                            </Form.Item>
                        </Col>
                        {role === 'mentor' ? <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item label="Change Password">
                                <Button type="primary" onClick={() => { props.showPopupModal(CHANGE_PASSWORD_MODAL, CHANGE_PASSWORD_TEXT) }}>Change Password</Button>
                            </Form.Item>
                        </Col> : ''}
                    </Row>
                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                label={role === 'mentor' ? "Mentor Bio" : "User Vision"}
                                name={role === 'mentor' ? "bio" : "userVision"}>
                                {role === 'mentor' ? <Input.TextArea rows={4} /> :
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={userVision}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            props.saveVisionText(data);
                                        }}
                                    />}
                            </Form.Item>
                        </div>
                    </div>
                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" className={style.submit_btn} loading={loading}>Submit</Button>
                    </Row>
                </div>
            </Form>
        </React.Fragment>
    )
}

export default ProfileUpdate;
