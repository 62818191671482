import React, {
    useState,
    useEffect
} from 'react';
import {
    Table,
    message,
    Space,
    Button
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
// Headers
import {
    header
} from '../../../api/rest';
// API
import {
    addContacts,
    // getContactDetails,
    getContactList,
    updateContacts,
    deleteContact
} from '../../../redux/slices/contactsSlice';
// CONSTANTS
import {
    CONSTANTS,
    POP_UP_MODAL_HEADER_CONSTANTS,
    POP_UP_MODAL_CONSTANTS,
    REGEX_CONSTANTS
} from '../../shared/constants';
// POPUPMODAL
import PopUpModal from '../../shared/popupmodal'
var {
    ADD_CONTACT,
    EDIT_CONTACT,
    CONFIRMATION_HEADER
} = POP_UP_MODAL_HEADER_CONSTANTS;
var {
    // SOCIAL_SHARE_MODAL,
    CONFIRMATION_POPUP_MODAL,
    ADD_EDIT_CONTACTS_MODAL
} = POP_UP_MODAL_CONSTANTS;

var {
    TOKEN_EXPIRED_MESSAGE,
    ERROR_MESSAGE,
    // DASHBOARD_PLACEHOLDER,
    // ERROR_IMAGE_URL,
    // FILE_LENGTH_LIMIT,
    // POST_REMOVE_MESSAGE,
    COMMENT_REMOVE_MESSAGE,
    PHONE_NUMBER_VALIDATION_ERROR
} = CONSTANTS;

var { ACCEPT_NUMBER } = REGEX_CONSTANTS;

function UserContacts(props) {

    React.useEffect(() => {
        document.title = "User Contacts";
    })

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: name => <span className="cursor-pointer">{name}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: email => <span className="cursor-pointer">{email}</span>,
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            render: phoneNumber => <span className="cursor-pointer">{phoneNumber}</span>,

        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: address => <span className="cursor-pointer">{address}</span>,

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {/* */}
                        <Button type="primary" icon={<EditOutlined />} onClick={() => fetchCurrentEntryData(record.id, 'edit')}>Edit</Button>
                        <Button type="primary" icon={<DeleteOutlined />} onClick={() => fetchCurrentEntryData(record.id, 'delete')} danger>Delete</Button>

                        {/* <button onClick={() => fetchCurrentEntryData(record.id, 'edit')} >Edit</button> */}
                        {/* <button onClick={() => fetchCurrentEntryData(record.id, 'delete')} >Delete</button> */}
                    </Space>
                )
            }
        }
    ];

    const loginUserInfo = localStorage.getItem('userData');
    const payload = loginUserInfo ? JSON.parse(loginUserInfo) : '';
    const token = payload ? payload.token : '';
    const userId = payload ? payload.payload.id : '';
    const [contactsData, setContactsData] = useState([]);
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });

    const [contactObj, setContactObj] = useState({
        name: '',
        email: '',
        phone: '',
        address: ''
    });

    const [contactId, setContactId] = useState('');

    useEffect(() => {
        if (loginUserInfo) {
            let { id } = JSON.parse(loginUserInfo).payload;
            let { token } = JSON.parse(loginUserInfo);
            getContactListApi(id, token);
        } else {
            message.error(TOKEN_EXPIRED_MESSAGE, '4');
            props.history.push('/');
        }
    }, [])


    const fetchCurrentEntryData = (id, type) => {
        let data = contactsData.filter(x => {
            if (x.id === id) {
                return x
            }
        })[0];
        setContactId(id);
        if (type === 'edit') {
            setContactObj(prevContactObj => {
                return {
                    ...prevContactObj, name: data.name,
                    email: data.email,
                    phone: data.phoneNumber,
                    address: data.address
                }
            })
            triggerPopUpModal(ADD_EDIT_CONTACTS_MODAL, EDIT_CONTACT);
        } else {
            triggerPopUpModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
        }
    }



    function getContactListApi(id, token) {
        header.Authorization = token;
        getContactList(id, header).then(res => {
            if (res.data.length) {
                setContactsData(res.data);
            } else {
                setContactsData([]);
            }
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })

    }

    // function getContactDetailsApi() {
    //     header.Authorization = token;
    //     getContactDetails(contactId, header).then(res => {

    //     }).catch(err => {
    //         message.error(err && err.message ? err.message : ERROR_MESSAGE);
    //     })
    // }

    function addContactListApi() {

        const data = {
            userId,
            name: contactObj.name,
            email: contactObj.email,
            phoneNumber: contactObj.phone,
            address: contactObj.address
        };
        header.Authorization = token;

        addContacts(data, header).then(res => {
            getContactListApi(userId, token);
            closePopupModal();
            message.success(res.message);

        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })
    }

    function deleteContactListApi() {
        header.Authorization = token;
        deleteContact(contactId, header).then(res => {
            getContactListApi(userId, token);
            closePopupModal();
            message.success(res.message);


        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })
    }

    function callUpdateContactListApi() {
        const data = {
            userId,
            contactId: contactId,
            name: contactObj.name,
            email: contactObj.email,
            phoneNumber: contactObj.phone,
            address: contactObj.address
        };
        header.Authorization = token;
        updateContacts(data, header).then(res => {
            getContactListApi(userId, token);
            closePopupModal();
            message.success(res.message);


        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })
    }


    function clearPageState() {
        setContactObj(prevContactObj => {
            return {
                ...prevContactObj, name: '',
                email: '',
                phone: '',
                address: ''
            }
        });
    }


    function triggerPopUpModal(type, headerText) {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: headerText
            }
        });
    }

    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        clearPageState();
    }


    function onSubmitPopUpModal(event) {
        event.preventDefault();
        // let { type } = popUpModal;
        if (popUpModal.modalHeader === EDIT_CONTACT) {
            callUpdateContactListApi();
        }
        if (popUpModal.modalHeader === ADD_CONTACT) {
            addContactListApi();
        }

        if (popUpModal.modalHeader === CONFIRMATION_HEADER) {
            deleteContactListApi();
        }
    }

    function handleInput(event) {
        let { value, name } = event.target;
        if (name === 'phone') {
            let data = ACCEPT_NUMBER.test(value) ? value : '';
            let messageStatus = data ? data.length > 10 ? true : false : false;
            if (messageStatus) {
                message.error(PHONE_NUMBER_VALIDATION_ERROR);
            } else {
                if (data) {
                    setContactObj(prevContactObj => {
                        return {
                            ...prevContactObj, [name]: data
                        }
                    })
                } else {
                    setContactObj(prevContactObj => {
                        return {
                            ...prevContactObj, [name]: data
                        }
                    })
                }
            }
        } else {
            setContactObj(prevContactObj => {
                return {
                    ...prevContactObj, [name]: value
                }
            })
        }
    }

    function handleAddress(event) {
        let { label } = event;

        setContactObj(prevContactObj => {
            return {
                ...prevContactObj, ["address"]: label
            }
        })

    }

    let addEditModalVariable = {
        contactObj
    },
        addEditModalProps = {
            handleInput: handleInput,
            handleAddress: handleAddress
        };

    return (
        <React.Fragment>
            <div className="container mt-2 min-vh-100">
                <div className="row ">
                    {
                        popUpModal.visible ?
                            <PopUpModal
                                title={popUpModal.modalHeader}
                                visible={popUpModal.visible}
                                onCancel={closePopupModal}
                                onOk={onSubmitPopUpModal}
                                description={COMMENT_REMOVE_MESSAGE}
                                type={popUpModal.type}
                                {...addEditModalVariable}
                                {...addEditModalProps}
                            /> : ''
                    }
                    <div className="col-md-12 col-12">
                        <div className="row justify-content-end mb-2">
                            <div className="col-1 col-md-1">
                                <Button type="primary" onClick={() => triggerPopUpModal(ADD_EDIT_CONTACTS_MODAL, ADD_CONTACT)}>Add</Button>
                                {/* <button type="button"  >Add</button> */}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table columns={columns} dataSource={contactsData} fetchCurrentEntryData={fetchCurrentEntryData} pagination={contactsData.length > 10 ? true : false} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserContacts;
