import React, { useEffect, useRef } from "react";
import { Row, Layout, Spin, Avatar } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import Container from "../../widgets/container";
import { EditFilled } from '@ant-design/icons';
import { setUserData as setUser } from "../../../redux/slices/authSlice";
import MyAncor from "../../widgets/global/ancor";
import StateItem from "./statusItem";
import BusinessModal from "./businessModal";
import { useState } from "react";
import { post, put ,header} from '../../../api/rest';
// CSS
import style from './welcome.module.sass';
import './welcome.sass';

const initialProfileData = [
  {
    description: 'Complete the business profile will make your business stand out in the community.',
    title: 'Business Profile',
    status: 0,
    tokens: 100
  },
  {
    description: 'Selecting a mentor can help with uncertainties. Choose a mentor with the experience you need.',
    title: 'Select a Mentor',
    status: 100,
    tokens: 100
  },
  {
    description: 'Complete the questionaire below to help us help you!',
    title: 'Complete Questionaire',
    status: 30,
    tokens: 500
  }
];

const Welcome = props => {
  
  React.useEffect(() => {
    document.title = "Welcome";
  })

  const session = useSelector(state => state.user);
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [upLoading, setUpLoading] = useState(false);
  const [userData, setUserData] = useState(session.data);
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [profileData, setProfileData] = useState(initialProfileData);
  useEffect(() => {
    let tempProfileData = Object.assign([], initialProfileData);
    tempProfileData[0].status = session.data.busInfoCode ? 100 : 0;
    tempProfileData[1].status = session.data.mentorInfoCode ? 100 : 0;
    setProfileData(tempProfileData);
    setUserData(session.data);
  }, [session]);

  const takePicture = () => {
    const fileObj = fileRef.current;
    if (fileObj && !userData.isSocial) fileObj.click();
  }

  const onChoosePicture = async (event) => {
    event.persist();
    if (event.target.files && event.target.files[0]) {
      setUpLoading(true);
      const bodyFormData = new FormData();
      bodyFormData.append('file', event.target.files[0]);
      bodyFormData.append('userId', userData.id);
      const result = await post(`/user/upload-image`, header,bodyFormData);
      if (result.success) {
        const tempUserData = Object.assign({}, userData);
        tempUserData.userPhoto = result.userPhoto;
        dispatch(setUser(tempUserData));
        localStorage.setItem("userData", JSON.stringify(tempUserData));
      }
    }
  }

  const handleCancelBusiness = () => {
    setShowBusinessModal(false);
  }

  const handleOkBusiness = async (businessInfo) => {
    const tempUserData = Object.assign({}, userData);
    tempUserData.busInfoCode = businessInfo.busInfo;
    tempUserData.business = businessInfo;
    await put(`/user/${userData.id}`, { busInfoCode: businessInfo.busInfo });
    dispatch(setUser(tempUserData));
    localStorage.setItem("userData", JSON.stringify(tempUserData));
    setShowBusinessModal(false);
  }
  if (!userData.id) return null;

  return (
    <Layout.Content>
      <Row className={`header-divider`} />
      <Row className={style.profile_header}>
        <div className={style.avatarWrapper}>
          <Avatar className={style.userPhoto} src={userData.userPhoto} size={120} onClick={takePicture}>
            {
              upLoading &&
              <Spin className={style.userPhotoSpin} />
            }
          </Avatar>
          {
            !userData.isSocial &&
            <EditFilled className={style.photoEditIcon} onClick={takePicture} />
          }
          <div className={style.nameWrapper}>
            <p className={style.profileName}>
              {`${userData.firstName} ${userData.lastName}`}</p>
            <p className={style.businessName}>{`${userData.business ? userData.business.businessName : ''}`}</p>
          </div>
        </div>
        <div className={style.profileLinkWrapper}>
          <MyAncor href="/register/choose-category">Select a mentor <EditFilled /></MyAncor>
          <MyAncor onClick={() => setShowBusinessModal(true)}>More <EditFilled /></MyAncor>
        </div>
      </Row>
      <Container>
        {
          profileData.length ? profileData.map((item, index) => (
            <Row key={index} justify={'center'}>
              <StateItem {...item} />
            </Row>
          )) : ''
        }
        <div className={style.moreThinkWrapper}>
          <div className={style.moreThinkCol}>
            <p className={style.moreThinkTitle}>More to think about</p>
            <div className={style.moreThinkLinkWrapper}>
              <MyAncor className={style.moreThinkLink} href="#">Decided on a long term goal?</MyAncor>
              <MyAncor className={style.moreThinkLink} href="#">Created a bank account?</MyAncor>
              <MyAncor className={style.moreThinkLink} href="#">Created a business plan?</MyAncor>
              <MyAncor className={style.moreThinkLink} href="#">Accessed your credit report?</MyAncor>
            </div>
          </div>
          <div className={`${style.moreThinkCol} ${style.mediaWrapper}`}>
            <p className={style.moreThinkTitle}>Start Learning</p>
            <iframe
              title="startLearning"
              className={style.media}
              width={300}
              height={300 * 720 / 1280}
              src={`https://www.youtube.com/embed/5MgBikgcWnY?playsinline=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            />
          </div>
        </div>
        <input ref={fileRef} type='file' id="imgInp" onChange={onChoosePicture} accept="image/*" style={{ visibility: 'hidden' }} />
      </Container>
      <BusinessModal businessInfo={userData.business} show={showBusinessModal} handleOk={handleOkBusiness} handleCancel={handleCancelBusiness} />
    </Layout.Content>
  )
}

export default Welcome;