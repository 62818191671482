import React, { useEffect, useState } from 'react';
import {
    Table,
    message,
    Space,
    Button
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS
} from '../../../shared/constants';
// API END POINTS
import { adminUserPostList } from '../../../../redux/slices/adminSlice';
import {
    header
} from '../../../../api/rest';
import {
    history
} from '../../../../redux/store';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';

var {
    ERROR_MESSAGE
} = CONSTANTS;

function AdminPost() {
    // const dispatch = useDispatch();
    // const loginUserData = useSelector(state => state.user);
    const storageData = localStorage.getItem("userData");
    const token = storageData ? JSON.parse(storageData).token : '';
    const [userPostList, setUserPostList] = useState([]);
    // const [description, setDescription] = useState('');
    // const [filterCity, setFilterCity] = useState('');
    // const [filterId, setFilterId] = useState('');
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });
    const [currentEntry, setCurrentEntry] = useState({
        id: '',
        name: '',
        address: '',
        email: '',
        phoneNumber: '',
        webAddress: '',
        // name: '',
        city: '',
        zip: ''
    });
    const [showPageLoader, setShowPageLoader] = useState(true);
    const description = '', filterCity = '', filterId = '';

    useEffect(() => {
        if (token) {
            getAdminUserPostList();
        } else {
            history.push('/');
            // message.error(TOKEN_EXPIRED_MESSAGE);
        }
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            // key: 'name',
            render: (text, record) => (
                <span key={record.id}  >{record.userId ? record.userId.firstName + ' ' + record.userId.lastName : ''}</span>
            )
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            // key: 'email',
            render: (text, record) => (
                <span key={record.userId ? record.userId.email : ''}>{record.userId ? record.userId.email : ''}</span>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Location',
            dataIndex: 'postMap',
            key: 'postMap',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            // key: 'action',
            render: (text, record) => (
                <Space size="middle" key={record.updatedAt}>
                    {/* <Button onClick={() => fillUserInfoInModal(record, 'update')} disabled>Update Resource</Button> */}
                    <Button onClick={() => fillUserInfoInModal(record, 'delete')} disabled>Delete Post</Button>
                </Space>
            ),
        }
    ];

    function fillUserInfoInModal(data, type) {

        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: data.id,
                name: data.name,
                address: data.address,
                email: data.email,
                phoneNumber: data.phoneNumber,
                webAddress: data.webAddress,
                city: data.city,
                zip: data.zip,
            }
        });
    }

    // get user post list fto show on admin panel
    function getAdminUserPostList() {
        var x = new Date();
        x.setDate(1);
        x.setMonth(x.getMonth() - 1);

        let data = {
            cityName: filterCity ? filterCity.toLowerCase() : '',
            userId: filterId,
            start_date: new Date(x).toISOString(),
            end_date: new Date().toISOString()

        }
        header.Authorization = `Bearer ${token}`;
        adminUserPostList(data, header).then(res => {
            if (res.data) {
                setUserPostList(res.data)
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    function onSubmitPopUpModal(event) {

    }

    // function toggleModal(type, header) {
    //     setPopUpModal(prevPopUpModal => {
    //         return {
    //             ...prevPopUpModal,
    //             visible: true,
    //             type: type,
    //             modalHeader: header
    //         }
    //     });
    // }

    // close poup modal
    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: '',
                name: '',
                address: '',
                email: '',
                phoneNumber: '',
                webAddress: '',
                // name: '',
                city: '',
                zip: ''
            }
        });
    }

    let addEditLiberatorProps = {
        currentEntry
    };
    return (
        <React.Fragment>
            {showPageLoader ? <Loader /> : ''}
            {
                popUpModal.visible ?
                    <PopUpModal
                        title={popUpModal.modalHeader}
                        visible={popUpModal.visible}
                        onCancel={closePopupModal}
                        onOk={onSubmitPopUpModal}
                        type={popUpModal.type}
                        description={description}
                        {...addEditLiberatorProps}
                    /> : ''
            }
            <Table dataSource={userPostList} columns={columns} />
        </React.Fragment>
    )
}
export default AdminPost;
