// import { message } from "antd";
import {
  apiGetMethod,
  apiPostMethod,
  apiDeleteMethod,
  apiPutMethod,
  //  post, get, apiPostMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import { API_END_POINTS_CONSTANT } from '../../components/shared/constants';

let {
  WALLET_LEDGER,
  ADMIN_ADD_COMMUNITY,
  ADMIN_ADD_MENTOR_CATEGORY,
  ADMIN_ADD_BUISNESS_INDUSTRY,
  CREATE_LOCAL_LIBERATOR,
  GET_LIST_LIBERATOR,
  DELETE_LIBERATOR,
  UPDATE_LIBERATOR,
  COMPLETE_TASK_TO_GET_TOKEN,
  CREATE_NEWS,
  ADMIN_GET_ALL_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
  UPDATE_COMPLETE_TASK_TO_GET_TOKEN,
  DELETE_COMPLETE_TASK_TO_GET_TOKEN,
  ADMIN_ANALYTICS,
  ADMIN_USER_POST_LIST,
  CREATE_QUESTION,
  ADD_VIDEO_FOR_ABOUT_US,
  ADD_VIDEO_FOR_HOME,
  GET_VIDEO_HOME,
  GET_VIDEO_LIST,
  REMOVE_ADMIN_IMAGE,
  ADMIN_DELETE_BUISNESS_INDUSTRY,
  ADMIN_UPDATE_BUISNESS_INDUSTRY,
  PENDING_USER_LIST,
} = API_END_POINTS_CONSTANT;

export const getLedgerList = (userId, headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`${WALLET_LEDGER}?userId=${userId}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addCommunity = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(ADMIN_ADD_COMMUNITY, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addBuisnessIndustry = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(ADMIN_ADD_BUISNESS_INDUSTRY, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addMentorCategory = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(ADMIN_ADD_MENTOR_CATEGORY, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// ADMIN LOCAL LIBERATOR SECTION START HERE
export const addLocalLiberator = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(CREATE_LOCAL_LIBERATOR, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getLiberatorList = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(GET_LIST_LIBERATOR, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteLocalLiberator = (data, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`${DELETE_LIBERATOR}?liberatorId=${data.id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateLocalLiberator = (data, headers) => {
  return new Promise((resolve, reject) => {
    apiPutMethod(`${UPDATE_LIBERATOR}/${data.id}`, data, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// ADMIN LOCAL LIBERATOR SECTION END HERE
// ADMIN TASK LIST TO GET TOKEN SECTION START HERE
export const addTaskListToGetToken = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(COMPLETE_TASK_TO_GET_TOKEN, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateTaskListToGetToken = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(UPDATE_COMPLETE_TASK_TO_GET_TOKEN, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteTaskListToGetToken = (data, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(
      `${DELETE_COMPLETE_TASK_TO_GET_TOKEN}?Id=${data.id}`,
      headers
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// ADMIN TASK LIST TO GET TOKEN SECTION END HERE

// ADMIN CREATE NEWS SECTION START HERE
export const adminCreateNews = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(CREATE_NEWS, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const adminGetAllNews = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(ADMIN_GET_ALL_NEWS, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const adminUpdateNews = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(`${UPDATE_NEWS}/${data.id}`, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const adminDeleteNews = (data, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`${DELETE_NEWS}?newsId=${data.id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// ADMIN CREATE NEWS SECTION END HERE

// ADMIN DASHBOARD SECTION STARTS HERE
export const getAdminDashboardData = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(ADMIN_ANALYTICS, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// ADMIN DASHBOARD SECTION END HERE

// ADMIN USER POST SECTION START HERE
export const adminUserPostList = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(ADMIN_USER_POST_LIST, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// ADMIN USER POST SECTION END HERE

export const adminCreateQuestion = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(CREATE_QUESTION, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addVideoForAboutUs = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(ADD_VIDEO_FOR_ABOUT_US, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addVideoForHome = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(ADD_VIDEO_FOR_HOME, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getVideoHome = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(GET_VIDEO_HOME, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getVideoList = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(GET_VIDEO_LIST, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteImageFromPageSetting = (data, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`${REMOVE_ADMIN_IMAGE}/${data.id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// ADMIN BUISNESS INDUSTRY
export const deleteBuisnessIndustry = (data, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`${ADMIN_DELETE_BUISNESS_INDUSTRY}/${data.id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateBuisnessIndustry = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(ADMIN_UPDATE_BUISNESS_INDUSTRY, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const pendingUserList = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(PENDING_USER_LIST, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getMentorRequests = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod('/mentorrequests', headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const toggleFeaturedMentor = (email, headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`/mentors/${email}/featured`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteMentorRequests = (id, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`/mentorrequests/${id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const approveMentorRequests = (id, data, headers) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(`/mentorrequests/${id}/approve`, data, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSurveys = (headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod('/surveys', headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createSurveyApi = (data, headers) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(`/surveys`, data, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editSurveyApi = (id, data, headers) => {
  return new Promise((resolve, reject) => {
    apiPutMethod(`/surveys/${id}`, data, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteSurveyApi = (id, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`/surveys/${id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Questions Api Calls

export const getQuestionsApi = (surveyId, headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`/surveys/${surveyId}/questions`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createQuestionApi = (surveyId, data, headers) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(`/surveys/${surveyId}/questions`, data, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const editQuestionApi = (id, data, headers) => {
  return new Promise((resolve, reject) => {
    apiPutMethod(`/surveys/questions/${id}`, data, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteQuestionApi = (id, headers) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`/surveys/questions/${id}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSurveyUsersApi = (surveyId, headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`/surveys/${surveyId}/users`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getSurveyUsersAnswersApi = (surveyId, userId, headers) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`/surveys/${surveyId}/answers/${userId}`, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
