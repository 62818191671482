import React, {
    useEffect,
    useState
} from 'react';
import {
    Table,
    message,
    Space
    // ,Button,
    // Switch
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS
    // ,POP_UP_MODAL_CONSTANTS,
    // POP_UP_MODAL_HEADER_CONSTANTS
} from '../../../shared/constants';
import {
    header
} from '../../../../api/rest';
// import {
//     history
// } from '../../../../redux/store';
import {
    pendingUserList
} from '../../../../redux/slices/adminSlice';

// POPUPMODAL
// import PopUpModal from '../../../shared/popupmodal';
// LOADER
import {
    Loader
} from '../../../shared/Loader';
// import './adminuser.scss';

var {
    ERROR_MESSAGE
} = CONSTANTS;

// var {
//     ADD_EDIT_ADMIN_USER,
//     CONFIRMATION_POPUP_MODAL
// } = POP_UP_MODAL_CONSTANTS;
// var {
//     ADD_USER, EDIT_USER,
//     CONFIRMATION_HEADER
// } = POP_UP_MODAL_HEADER_CONSTANTS;

// const USER_ROLE = [{title:"User",value:"user"},{title:"Mentor",value:"mentor"}  ];
// const { Option } = Select;



function AdminUserPendingList(props) {
    const storageData = localStorage.getItem("userData");
    const payload = storageData ? JSON.parse(storageData).payload : '';
    const token = storageData ? JSON.parse(storageData).token : '';
    const [showPageLoader, setShowPageLoader] = useState(true);
    const [pendingUserData, setPendingUserData] = useState([]);
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Space size="middle">
                    <span>{record.firstName ? record.firstName : ''} {record.lastName ? record.lastName : ''}   </span>
                </Space>
            ),
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Zip Code',
            dataIndex: 'zipcode',
            key: 'zipcode',
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (text, record) => (
        //         <Space size="middle">
        //             <Button className="delete-resource-btn" onClick={() => fillUserInfoInModal(record, 'delete')}>Delete Resource</Button>
        //             <Button className="update-resource-btn" onClick={() => fillUserInfoInModal(record, 'update')}>Update Resource</Button>
        //         </Space>
        //     ),
        // }
    ];

    useEffect(() => {
        if (token) {
            getPendingUsersList();
        }
    }, []);

    // get pending user list
    function getPendingUsersList() {
        header.Authorization = `Bearer ${token}`;
        pendingUserList(header).then(res => {
            if (res.PendingUsers.length) {
                let arr = res.PendingUsers.length ? res.PendingUsers : [];

                setPendingUserData(arr);
                setShowPageLoader(false);
            }
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    return (
        <React.Fragment>
            {showPageLoader ? <Loader /> : ''}
            <Table dataSource={pendingUserData} columns={columns} />
        </React.Fragment>
    )
}
export default AdminUserPendingList
