import React from 'react';
// Sound Notification
import TokenEarn from '../../assets/soundeffects/mixkit-magical-coin-win-1936.wav';
import TokenSpend from '../../assets/soundeffects/mixkit-money-bag-drop-1989.wav';

export function TokenSpendSound() {
    return (
        <React.Fragment>
            <audio className="audio-element" autoPlay>
                <source src={TokenSpend} />
            </audio>
        </React.Fragment>
    )
}

export function TokenEarnSound() {
    return (
        <React.Fragment>
            <audio className="audio-element" autoPlay>
                <source src={TokenEarn} />
            </audio>
        </React.Fragment>
    )
}