// import { message } from "antd";
import {
    apiGetMethod,
    apiPostMethod
    //  post, get, 
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

let {
    WALLET_LEDGER,
    TOKEN_SUM, ADD_TOKEN,
    GET_TOKEN_LIST_FOR_USER
} = API_END_POINTS_CONSTANT;

export const getLedgerList = (userId, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${WALLET_LEDGER}?userId=${userId}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getTokenCount = (userId, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${TOKEN_SUM}?userId=${userId}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const addTokenToWallet = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ADD_TOKEN, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getTokenListForUser = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(GET_TOKEN_LIST_FOR_USER, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};