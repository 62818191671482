import React, { useEffect, useState } from 'react';
import {
  useDispatch, useSelector
} from 'react-redux';
import AutoScroll from "./components/auto_scroll";
import {
  Route,
  Switch
} from "react-router";
import Home from "./components/screens/home";
import Register from "./components/screens/register";
import Verify from "./components/screens/register/verify";
import ResendVerifyEmail from './components/screens/register/resendVerifyEmail';
import Welcome from "./components/screens/welcome";
import Dashboard from "./components/screens/dashboard";
import CommunityFeed from './components/screens/dashboard/CommunityFeed';
import Contact from "./components/screens/contact";
import {
  Button,
  Result,
  BackTop
} from "antd";
import UpOutlined from "@ant-design/icons/lib/icons/UpOutlined";
import RegCategory from "./components/screens/register/choose-category";
import RegBusinessDetails from "./components/screens/register/business-details";
import Mentors from "./components/screens/mentor";
import Mentor from "./components/screens/mentor/single-mentor";
import ChooseCommunity from './components/screens/register/choose-community';
import { setUserData as setUser } from "./redux/slices/authSlice";
// import { history } from "./redux/store";
// import { Learning } from './components/screens/learning';
import { Wallet } from './components/screens/wallet';
import { Messaging } from './components/screens/messaging';
import Profile from './components/screens/profile';
import { Journey } from './components/screens/journey';
import ForgotPassword from './components/screens/forgotpassword';
import ResetPassword from './components/screens/resetpassword';
import ProfileUpdate from './components/screens/profile/profileupdate';
import UserContacts from './components/screens/usercontacts';
import EnquiryForm from './components/screens/enquiryform';
import ComingSoonPage from './components/shared/comingSoonPage';
import { Reminder } from './components/screens/reminder';
import { ReminderCalendar } from './components/screens/reminderCalendar';
import About from './components/screens/about';
import CommunityDetail from './components/screens/communitydetail';
import LiberatorChat from './components/screens/liberatorchat';
import MentorRegister from './components/screens/mentormodule/mentorregister';
import Faqs from './components/screens/faqs';
import mentorAvailability from './components/screens/mentorAvailability';
// MENTOR USERS
import MentorUsers from './components/screens/mentor/users';
// SPONSER PROFILE
import SponserProfile from './components/screens/sponser-profile';
// SPONSER DASHBOARD
import SponserDashboard from './components/screens/sponser-dashboard';

// CONTEXT
// import { userData } from './context';
// ADMIN PANEL COMPONENTS
import {
  AdminResource,
  AdminLiberator,
  AdminWallet,
  AdminNews,
  AdminUser,
  AdminPost,
  AdminDashboard,
  AdminCommunity,
  AdminCategory,
  AdminQuestionAnswer,
  AdminAboutUsPageSetting,
  AdminHomePageSetting,
  AdminBuisnessIndustries,
  AdminUserPendingList,
  AdminSurveys,
  AdminSurveyQuestions,
  AdminSurveyUsers,
  AdminInvitations,
} from './components/screens/admin';
// import AdminLiberator from './components/screens/admin/adminLiberator';
// Mentor Module
import MentorMeetingList from './components/screens/mentormodule/mentormeetinglist';
// Socket
import { socket } from './components/service/socket';
// LIGHTBOX CSSS
import 'react-image-lightbox/style.css';
// CHAT ELEMENT CSS
// import 'react-chat-elements/dist/main.css';
// SOUND PLAY COMPONENT
import { TokenEarnSound } from '../src/components/shared/soundeffect';
import PrivacyPolicy from './components/screens/privacy';
import MentorRequests from './components/screens/admin/adminmentorrequests';

function App() {
  const storageData = localStorage.getItem("userData");
  const loginUserData = useSelector(state => state.user);
  const [playSound, setPlaySound] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (storageData) {
      dispatch(setUser(JSON.parse(storageData)));
      let payload = JSON.parse(storageData);
      socket.emit('join-room-equiliberty', payload.payload.id);

      if (loginUserData.data.payload) {
        socket.emit('join-room-equiliberty', loginUserData.data.payload.id)
      }
    }
    // else if (!history.location.pathname.includes('verify')||!history.location.pathname.includes('reset-password') ) {
    //   history.push("/");
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, storageData]);

  useEffect(() => {
    socket.on('TokenSend', (data) => {
      if (data === "tokenReceived") {
        setPlaySound(true);
        setTimeout(() => {
          setPlaySound(false);
        }, 1500);
      }
    })
  }, [])

  return (
    <AutoScroll>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/auth/verify/:token' component={Verify} />
        <Route exact path='/auth/verify' component={ResendVerifyEmail} />
        <Route
          exact
          path='/register/business-details'
          component={RegBusinessDetails}
        />
        <Route exact path='/register/choose-category' component={RegCategory} />
        <Route exact path='/register/choose-mentor' component={Mentors} />
        <Route
          exact
          path='/register/choose-community'
          component={ChooseCommunity}
        />
        <Route exact path='/mentor-details/:id' component={Mentor} />
        <Route
          exact
          path='/mentor/availability'
          component={mentorAvailability}
        />
        <Route exact path='/profile' component={Welcome} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/community-feed' component={CommunityFeed} />
        <Route exact path='/contact' component={Contact} />
        {/* <Route exact path='/learning' component={Learning} /> */}
        <Route exact path='/wallet' component={Wallet} />
        <Route exact path='/messaging' component={Messaging} />
        <Route path='/user-profile' component={Profile} />
        <Route exact path='/user/profile' component={Profile} />
        <Route exact path='/user/profile/update' component={ProfileUpdate} />
        <Route exact path='/user/journey' component={Journey} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route
          exact
          path='/auth/reset-password/:token'
          component={ResetPassword}
        />
        <Route exact path='/user/contact' component={UserContacts} />
        <Route exact path='/profile/:listType' component={MentorUsers} />
        <Route exact path='/enquiry-form' component={EnquiryForm} />
        <Route exact path='/learning' component={ComingSoonPage} />
        <Route exact path='/reminder' component={Reminder} />
        <Route exact path='/reminderCalendar' component={ReminderCalendar} />
        <Route exact path='/about' component={About} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path='/faqs' component={Faqs} />
        <Route exact path='/community-detail' component={CommunityDetail} />
        {/* MENTOR MODULE ROUTES */}
        <Route exact path='/mentor/dashboard' component={Dashboard} />
        <Route exact path='/mentor/schedule' component={MentorMeetingList} />
        <Route exact path='/mentor/profile' component={Profile} />
        <Route exact path='/mentor/register' component={MentorRegister} />
        {/* MENTOR USERS ROUTES  */}
        <Route exact path='/mentor/:mentorId/users' component={MentorUsers} />
        {/* SPONSER PROFILE */}
        <Route exact path='/sponser-profile' component={SponserProfile} />
        {/* SPONSER DASHBOARD  */}
        <Route exact path='/sponsor-dashboard' component={SponserDashboard} />
        {/* ADMIN MODULE ROUTE */}
        <Route exact path='/admin/liberator' component={AdminLiberator} />
        <Route exact path='/admin/resource' component={AdminResource} />
        <Route exact path='/admin/wallet' component={AdminWallet} />
        <Route exact path='/admin/news' component={AdminNews} />
        <Route exact path='/admin/user' component={AdminUser} />
        <Route exact path='/admin/post' component={AdminPost} />
        <Route exact path='/admin/dashboard' component={AdminDashboard} />
        <Route exact path='/admin/community' component={AdminCommunity} />
        <Route exact path='/admin/category' component={AdminCategory} />
        <Route exact path='/admin/question' component={AdminQuestionAnswer} />
        <Route exact path='/liberatorchat' component={LiberatorChat} />
        <Route
          exact
          path='/admin/setting/aboutus'
          component={AdminAboutUsPageSetting}
        />
        <Route
          exact
          path='/admin/setting/home'
          component={AdminHomePageSetting}
        />
        <Route
          exact
          path='/admin/buisness/industries'
          component={AdminBuisnessIndustries}
        />
        <Route
          exact
          path='/admin/community/upcoming'
          component={AdminUserPendingList}
        />
        <Route exact path='/admin/mentor-requests' component={MentorRequests} />
        <Route exact path='/admin/surveys' component={AdminSurveys} />
        <Route
          exact
          path='/admin/surveys/:surveyId/questions'
          component={AdminSurveyQuestions}
        />
        <Route
          exact
          path='/admin/surveys/:surveyId/users'
          component={AdminSurveyUsers}
        />
        <Route exact path='/admin/invitations' component={AdminInvitations} />
        <Route
          component={() => (
            <Result
              status='404'
              title='404'
              subTitle='Sorry, the page you visited does not exist.'
              extra={<Button type='primary'>Back Home</Button>}
            />
          )}
        />
      </Switch>
      <BackTop>
        <div className='backTop'>
          <UpOutlined className='backTopIcon' />
        </div>
      </BackTop>
      <Sound playSound={playSound} />
    </AutoScroll>
  );
}

export default App


function Sound(playSound) {
  return (
    <React.Fragment>
      {
        playSound.playSound ? <TokenEarnSound /> : ''
      }
    </React.Fragment>
  )
}
