import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Input, Row, Col, Layout, message, Select } from 'antd';
import Container from '../../widgets/container';
import style from './register.module.sass';
// import './register.sass'
import './register.scss';
import { history } from '../../../redux/store';
import { apiGetMethod, header } from '../../../api/rest';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import jsonp from 'jsonp';
import {
  facebookLoginApi,
  googleLoginApi,
  searchCommunityByZipcode,
  // register,
  setLoading as running,
  setUser,
  setUserData,
  signUpApi,
} from '../../../redux/slices/authSlice';
import {
  // listCommunities,
  listMentorCategory,
} from '../../../redux/slices/registerSlice';
// GEO LOCATION
import Geocode from 'react-geocode';
// COUNTRIES LIST
// import countries from "../../widgets/countries";
// POPUPMODAL
import PopUpModal from '../../shared/popupmodal';
// CONSTANTS
import {
  REGEX_CONSTANTS,
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../shared/constants';
// Sound play Component
import { TokenEarnSound } from '../../shared/soundeffect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { getTokenCount } from '../../../redux/slices/walletSlice';
import { userUpdateProfile } from '../../../redux/slices/userProfileSlice';

var { PASSWORD_VALIDATION_MESSAGE_BCK, ERROR_MESSAGE } = CONSTANTS;

var { ACCEPT_NUMBER, PASSWORD_VALIDATION_REGEX_BCK } = REGEX_CONSTANTS;

var { MESSAGE_POP_UP_MODAL, ZIP_CODE_POP_UP_MODAL, MESSAGE_POP_UP_MODAL } =
  POP_UP_MODAL_CONSTANTS;
var { SUCCESS_HEADER, THANK_FOR_INTREST, ZIPCODE_MODAL_HEADING } =
  POP_UP_MODAL_HEADER_CONSTANTS;

const Register = (props) => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(
    new URLSearchParams(props.location.search).get('token')
  );
  const [communityList, setCommunityList] = useState([]);
  // const [isUserEnableLocation, setIsUserEnableLocation] = useState(false);
  // const [coordinate, setCoordinate] = useState({ latitude: 0, longitude: 0 });
  const [userRole, setUserRole] = useState('user');
  const [showCommunityList, setShowCommunityList] = useState(false);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [description, setDescription] = useState('');
  const [socialMediaResponse, setSocialMediaResponse] = useState({});
  const isUserEnableLocation = false;
  const coordinate = { latitude: 0, longitude: 0 };
  const [form] = Form.useForm();
  const [playSound, setPlaySound] = useState(false);
  const queryParams = new Geocode.setApiKey(
    process.env.REACT_APP_GEOCODE_API_KEY
  );
  // set response language. Defaults to english.
  Geocode.setLanguage('en');
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion('in');
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();
  useEffect(() => {
    const storageData = localStorage.getItem('userData');
    const payload = storageData ? JSON.parse(storageData).payload : '';
    const role = payload ? payload.role : '';
    if (role === 'admin') {
      history.push('/admin/dashboard');
      document.title = 'Admin Dashboard';
    } else if (role === 'user') {
      history.push('/dashboard');
    } else {
    }
  }, []);

  useEffect(() => {
    if (loading && !session.loading && session.data.success) {
      // history.push('/');
    }
    getInvitationInfo();
    setLoading(session.loading);
  }, [loading, session]);
  console.log(token);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    dispatch(running(true));
    const body = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      // city: values.city.toLocaleLowerCase(),
      // state: values.state,
      // country: values.country,
      zipcode: parseInt(values.zipcode),
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
      categoryId: values.categoryId,
      role: userRole,
    };
    setLoading(true);
    setPlaySound(true);
    setTimeout(() => {
      registerUser(body);
    }, 2000);

    // dispatch(register(body));
  };

  const getInvitationInfo = async () => {
    if (!token) return;
    const invitationInfo = await apiGetMethod(`/sponsors/invites/${token}`);
    form.setFieldsValue({
      email: invitationInfo.email,
      firstName: invitationInfo.firstName,
      lastName: invitationInfo.lastName,
    });
  };

  function registerUser(body) {
    body.token = token;
    signUpApi(body, header)
      .then((res) => {
        dispatch(running(false));
        dispatch(setUser({ ...res, success: true, isSocial: true }));
        const url = `https://equiliberty.us1.list-manage.com/subscribe/post-json?u=7ec78db856eb80758bdc9d2b3&id=b034b03a71&EMAIL=${encodeURIComponent(
          body.email
        )}&MMERGE6=${body.zipcode}&FNAME=${body.firstName}&LNAME=${
          body.lastName
        }&c=__jp0`;
        jsonp(url, { param: 'c' }, (err, data) => {
          console.info(data);
          console.error(err);
        });
        if (
          res.message ===
          'we are working hard to bring Equiliberty to your community!!'
        ) {
          setDescription(res.message);
          showPopupModal(MESSAGE_POP_UP_MODAL, THANK_FOR_INTREST);
        } else {
          showPopupModal(MESSAGE_POP_UP_MODAL, SUCCESS_HEADER);
        }
        setDescription(res.message);
        setLoading(false);
        setPlaySound(false);
      })
      .catch((err) => {
        dispatch(running(false));
        dispatch(setUser({ ...err, success: false }));
        setDescription(err ? err.message : 'Error');
        showPopupModal(MESSAGE_POP_UP_MODAL, ERROR_MESSAGE);
        setLoading(false);
        setPlaySound(false);
      });
  }

  const onFinishFailed = (errorInfo) => {};

  function updateLocalStorage(inputName, value) {
    let data = localStorage.getItem('userData');
    if (data) {
      var persons = JSON.parse(data);
      persons.payload[inputName] = value;
      localStorage.setItem('userData', JSON.stringify(persons));
    }
  }

  function getWallettokenCountApi(id, token, type, resp) {
    header.Authorization = token;
    getTokenCount(id, header)
      .then((res) => {
        if (res.ledgesData) {
          let count = 0;
          for (let i = 0; i < res.ledgesData.length; i++) {
            count = count + res.ledgesData[i].tokenCount;
          }
          updateLocalStorage('badgeToken', count);
        }
        closePopupModal();
        if (type === 'socialMedia') {
          showPopupModal(ZIP_CODE_POP_UP_MODAL, ZIPCODE_MODAL_HEADING);
        } else {
          history.push('/dashboard');
        }
        setPlaySound(false);
        dispatch(running(false));
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
        setPlaySound(false);
      });
  }

  const responseGoogle = async (response) => {
    let data = { tokenId: response.tokenId };
    googleLoginApi(data, header)
      .then((response) => {
        if (response && response) {
          if (
            response.message ===
            'we are working hard to bring Equiliberty to your community!!'
          ) {
            setDescription(response.message);
            showPopupModal(MESSAGE_POP_UP_MODAL, THANK_FOR_INTREST);
            return false;
          }
          message.success(response.message);
          localStorage.setItem(
            'x-headers',
            `${response.payload.email}my supersceretpasswordisnone`
          );
          setSocialMediaResponse(response);
          if (response.oldUser && response.payload.communityId) {
            localStorage.setItem('userData', JSON.stringify(response));
            dispatch(setUserData(response.payload));
            history.push('/dashboard');
            closePopupModal();
            // setLoader(false);
          } else {
            getWallettokenCountApi(
              response.payload.id,
              response.token,
              'socialMedia',
              response
            );
          }
        }
      })
      .catch((err) => {});
  };
  const responseFacebook = async (response) => {
    if (response.accessToken) {
      let data = { accessToken: response.accessToken, userId: response.userID };
      facebookLoginApi(data, header)
        .then((response) => {
          if (response) {
            message.success(response.message);
            localStorage.setItem(
              'x-headers',
              `${response.payload.email}my supersceretpasswordisnone`
            );
            setSocialMediaResponse(response);
            if (response.oldUser && response.payload.communityId) {
              localStorage.setItem('userData', JSON.stringify(response));
              dispatch(setUserData(response.payload));
              history.push('/dashboard');
              closePopupModal();
              // setLoader(false);
            } else {
              getWallettokenCountApi(
                response.payload.id,
                response.token,
                'socialMedia',
                response
              );
            }
          }
        })
        .catch((err) => {});
    } else {
    }
  };
  function checkZipCodeValid(data) {
    searchCommunityByZipcode(data, header)
      .then((res) => {
        closePopupModal();
        if (res.data.length) {
          localStorage.setItem('userData', JSON.stringify(socialMediaResponse));
          dispatch(setUserData(socialMediaResponse.payload));
          userUpdateProfileAPI(data);
          redirectToProfileUpdatePage();
        } else {
          showPopupModal(MESSAGE_POP_UP_MODAL, THANK_FOR_INTREST);
          setDescription('NO_COMMUNITY_MESSAGE');
          // saveUserPendingCommunityAPI(data);
        }
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }
  function userUpdateProfileAPI(value) {
    header.Authorization = socialMediaResponse.token;
    let data = {
      zipcode: value.zipCode,
      id: socialMediaResponse.payload.id,
    };
    userUpdateProfile(data, header)
      .then((res) => {
        updateLocalStorage('zipcode', value.zipCode);
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  function redirectToProfileUpdatePage() {
    history.push('/user/profile/update', {
      data: 'register',
    });
  }

  function showCommunity(value) {
    if (value.value === 'mentor') {
      setShowCommunityList(true);
    } else {
      setShowCommunityList(false);
    }
    setUserRole(value.value);
  }

  useEffect(() => {
    if (showCommunityList) {
      getCommunityList();
    }
  }, [showCommunityList]);

  function getCommunityList() {
    listMentorCategory(header)
      .then((res) => {
        if (res.data) {
          setCommunityList(res.data);
        }
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  // show popup modal
  function showPopupModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  // close poup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
  }

  function onSubmitPopUpModal(event) {
    if (popUpModal.type === MESSAGE_POP_UP_MODAL) {
      closePopupModal();
      history.push('/');
    }
    if (popUpModal.type === ZIP_CODE_POP_UP_MODAL) {
      checkZipCodeValid(event);
    }
    if (popUpModal.modalHeader === THANK_FOR_INTREST) {
      closePopupModal();
      history.push('/');
    }
  }

  return (
    <Layout.Content className={style.block}>
      {playSound ? <TokenEarnSound /> : ''}
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          description={description}
          // {...updateProfileCustomProps}
          // {...updatProfileCustomVariable}
        />
      ) : (
        ''
      )}
      <Row className={`header-divider`} />
      <Container className={`auth-modal ${style.reg_container}`}>
        <h1 style={{ color: '#00a9ab', fontWeight: 600 }} className=''>
          Join Equiliberty
        </h1>
        <h5 className='mb-3'>Where building community is building wealth!</h5>
        <h6 className=''>
          Start meeting new people and building wealth in your community! If you
          already have an account, sign in to use Equiliberty on the web
        </h6>
        <br />
        <Form
          {...layout}
          name='basic'
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          className='register-forms'
        >
          <Form.Item
            label={'Email'}
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
                message: ' Please enter a valid email address',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={'First Name'}
                name='firstName'
                rules={[
                  {
                    required: true,
                    message: 'Please input your First Name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={'Last Name'}
                name='lastName'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Last Name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {isUserEnableLocation === false ? (
            <React.Fragment>
              {/* <Row gutter={8}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"City"}
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your city!',
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"State"}
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your state!',
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row> */}
              <Row gutter={8}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={'Zip Code'}
                    name='zipcode'
                    rules={[
                      {
                        required: true,
                        pattern: ACCEPT_NUMBER,
                        validator: (_, value) =>
                          ACCEPT_NUMBER.test(value)
                            ? Promise.resolve()
                            : Promise.reject('Please Enter Valid Zipcode'),
                        // message: 'Please input your zipcode!',
                      },
                    ]}
                  >
                    <Input maxLength='5' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label={'Register as'}>
                    <Select
                      labelInValue
                      className='country-feild'
                      onChange={showCommunity}
                      defaultValue={{
                        label: 'Creator',
                        value: 'user',
                      }}
                      options={[
                        {
                          label: 'Creator',
                          value: 'user',
                        },
                      ]}
                    >
                      {/* //   {REGISTER_ROLE.map((c) => (
                    //     <Select.Option
                    //       label={c === "user" ? "Creator" : "Mentor"}
                    //       value={c}
                    //       key={c}
                    //     >
                    //       {c}
                    //     </Select.Option>
                    //   ))} */}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Country"}
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your country!',
                    },
                  ]}>
                  <Select className="country-feild" placeholder="Country" allowClear showSearch>
                    {countries.map(c => <Select.Option value={c} key={c}>{c}</Select.Option>)}
                  </Select>
                   <Input />
                </Form.Item>
              </Col> */}
              </Row>
            </React.Fragment>
          ) : (
            ''
          )}
          <Row gutter={8}>
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={"Register as"}
                name="role"
              >
                <Select className="country-feild" defaultValue={REGISTER_ROLE[1]} onChange={showCommunity}>
                  {REGISTER_ROLE.map(c => <Select.Option value={c} key={c}>{c}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col> */}
            {showCommunityList ? (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={'Mentor Category'}
                  name='categoryId'
                  rules={[
                    {
                      required: true,
                      message: 'Please choose your mentor category!',
                    },
                  ]}
                >
                  <Select
                    className='country-feild'
                    placeholder='Category'
                    allowClear
                    showSearch
                  >
                    {communityList.map((c) => (
                      <Select.Option value={c._id} key={c._id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {/* <Input /> */}
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Form.Item
            label={'Password'}
            name='password'
            rules={[
              {
                required: true,
                // message: 'Please input your password!',
                pattern: PASSWORD_VALIDATION_REGEX_BCK,
                validator: (_, value) =>
                  PASSWORD_VALIDATION_REGEX_BCK.test(value)
                    ? Promise.resolve()
                    : Promise.reject(PASSWORD_VALIDATION_MESSAGE_BCK),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={'Confirm Password'}
            name='password_confirm'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!'
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Row justify={'center'}>
            <Button
              type='primary'
              htmlType='submit'
              className={style.submit_btn}
              loading={loading}
            >
              Submit
            </Button>
          </Row>
          {/* <Row justify={'center'} className='mt-5'>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              autoLoad={false}
              callback={(event) => responseFacebook(event)}
              fields='name,email,picture'
              cssClass={style.social_btn_fb}
              render={(renderProps) => (
                <Button onClick={renderProps.onClick}>
                  <FontAwesomeIcon icon={faGoogle} /> Register with Facebook
                </Button>
              )}
              textButton='Register with Facebook'
              icon={<FontAwesomeIcon icon={faFacebookSquare} />}
            />
          </Row> */}
          <Row justify={'center'} className='mt-2'>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              render={(renderProps) => (
                <Button onClick={renderProps.onClick} className='googleLogin'>
                  <FontAwesomeIcon icon={faGoogle} /> Register with Google
                </Button>
              )}
              scope='profile email'
              buttonText='Login'
              onSuccess={(event) => responseGoogle(event)}
              // onFailure={(event) => props.responseGoogleError(event)}
              // cookiePolicy={'single_host_origin'}
            />
          </Row>
        </Form>
      </Container>
    </Layout.Content>
  );
};

export default Register;
