import {
  createSlice
} from '@reduxjs/toolkit';
import {
  message
} from "antd";
import {
  post,
  get,
  put,
  header,
  apiPostMethod,
  apiGetMethod
} from '../../api/rest';
// CONSTANTS
import {
  CONSTANTS,
  API_END_POINTS_CONSTANT
} from '../../components/shared/constants';
import { history } from "../store";
const {
  INVALID_CREDENTIAL,
  ERROR_MESSAGE
} = CONSTANTS;
const {
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_USER,
  CHECK_TOKEN,
  RESEND_VERIFICATION,
  CHECK_PASSWORD_TOKEN,
  LOG_OUT,
  MODIFY_PASSWORD,
  GOOGLE_LOGIN, FACEBOOK_LOGIN,
  SEARCH_COMMUNITY_BY_ZIPCODE,
  SAVE_USER_PENDING_COMMUNITY
} = API_END_POINTS_CONSTANT;

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    data: {},
    user: {},
    sideMenuBar: ''
  },
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.loading = false;
      state.data = { ...action.data, ...action.payload };
    },
    setUserInfo: (state, action) => {
      state.user = action.payload
    },
    setUserPhoto: (state, action) => {
      state.data.userPhoto = action.payload;
    },
    handleLogout: (state, action) => {
      state.data = {}
    },
    setSideMenuBar: (state, action) => {
      state.sideMenuBar = action.payload
    }
  },
});

export const { setUser, loading, setUserPhoto, handleLogout, setSideMenuBar, setUserInfo } = authSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const login = data => async dispatch => {
  const result = await post(SIGN_IN, header, data);
  if (result) {
    if (!result.status) {
      message.error(result.message ? result.message : INVALID_CREDENTIAL);
      dispatch(loading(false));
    } else {
      if (result.payload.id) {
        localStorage.setItem("userData", JSON.stringify(result));
        localStorage.setItem('x-headers', data.password);
        message.success(result.message);
        dispatch(setUser(result.payload));
      } else {
        dispatch(loading(false));
        message.error(result.message ?? INVALID_CREDENTIAL);
      }
    }
  } else {
    message.error(ERROR_MESSAGE);
    dispatch(loading(false));
  }
};

export const logout = () => async dispatch => {
  localStorage.clear();
  sessionStorage.clear();
  dispatch(handleLogout());
}

export const loginApi = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(SIGN_IN, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const logoutApi = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(LOG_OUT, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const register = data => async dispatch => {
  const result = await post(SIGN_UP, header, data);
  if (result && (result.status || result.userid)) {
    message.success(result.message);
    dispatch(setUser({ ...result, success: true, isSocial: true }));
  } else {
    dispatch(loading(false));
    dispatch(setUser({ ...result, success: false }));
    message.error(result ? result.message : 'Error');
  }
};

export const updateUserData = (userData, data, type) => async dispatch => {
  let id = userData.payload && userData.payload.id ? userData.payload.id : userData.id;
  const result = await put(`/user/${id}`, data);
  if (type) {
    if (result && result.user) {
      dispatch(setUser({ ...userData, ...result, verify: true }));
      message.success(result ? result.message : 'Success');
    } else {
      dispatch(loading(false));
      message.error(result ? result.message : 'Error');
    }
  } else {
    if (result && result.userid) {
      dispatch(setUser({ ...userData, ...result, verify: true }));
    } else {
      dispatch(loading(false));
      message.error(result ? result.message : 'Error');
    }
  }
};

export const whoami = () => async dispatch => {
  const localUser = localStorage.getItem('userData')
  if(!localUser) return;
  const token = JSON.parse(localUser)?.token;
  let user = await get('/whoami')
  if (!user.status) {
    message.error(user.message)
    localStorage.removeItem('userData')
    dispatch(handleLogout());
    history.push('/')
    return false
  }
  dispatch(setUserInfo(user?.payload))
}

export const verify = token => async dispatch => {
  const result = await get(`${VERIFY_USER}/${token}`);
  if (!result.payload) {
    message.info(result ? result.message : 'Error');
    return history.push('/')
  }
  if (result && result.status) {
    localStorage.setItem("userData", JSON.stringify(result));
    dispatch(setUser({ ...result, success: true }));
  } else {
    dispatch(loading(false));
    message.error(result ? result.message : 'Error');
  }
};

export const forgotPassword = data => async dispatch => {
  const result = await post(FORGOT_PASSWORD, header, data);
  if (result && result.message) {
    dispatch(setUser({ ...result, message: result.message }));
    message.success(result.message);
  } else {
    dispatch(loading(false));
    dispatch(setUser({ ...result, message: result ? result.message : result }));

    message.error(result ? result.message : 'Error');
  }
};

export const resetPassword = (data) => async dispatch => {
  const result = await post(RESET_PASSWORD, header, data);
  if (result && result.message) {
    dispatch(setUser({ ...result, message: result.message }));
    message.success(result.message)
  } else {
    dispatch(loading(false));
    message.error(result ? result.message : 'Error');
  }
};

export const setLoading = (value) => dispatch => {
  dispatch(loading(value));
}

export const setUserData = (data) => dispatch => {
  dispatch(setUser(data));
}

export const uploadUserPhoto = (url) => dispatch => {
  dispatch(setUserPhoto(url));
}

export const resendVerication = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(RESEND_VERIFICATION, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const checkToken = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(CHECK_TOKEN, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const checkResetPasswordToken = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(CHECK_PASSWORD_TOKEN, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const getMentorIdByUser = (data, header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`/getUserProfile?profileUserId=${data}`, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const modifyPassword = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(MODIFY_PASSWORD, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const signUpApi = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(SIGN_UP, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}
export const facebookLoginApi = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(FACEBOOK_LOGIN, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}
export const googleLoginApi = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(GOOGLE_LOGIN, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const searchCommunityByZipcode = (data, header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`${SEARCH_COMMUNITY_BY_ZIPCODE}/${data.zipCode}`, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const saveUserPendingCommunity = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(SAVE_USER_PENDING_COMMUNITY, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUserData = state => state.user;
export default authSlice.reducer;
