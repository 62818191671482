import {
  message
} from "antd";
import {
  post,
  get,
  apiPostMethod,
  apiGetMethod,
  apiDeleteMethod
} from '../../api/rest';
import {
  setUser,
  loading
} from './authSlice';
import {
  API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

let {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  SINGLE_FILE_UPLOAD,
  FOLLOW_USER,
  LIST_FOLLOWERS,
  LIST_FOLLOWING,
  CREATE_GOAL,
  UPDATE_GOAL,
  GET_USER_GOAL,
  DELETE_GOAL,
  TOKEN_PAY_API,
  SET_USER_ANSWER,
  GET_QUESTION_SET,
  GET_GOAL_IMAGES,
  GET_USER_ANSWERS
} = API_END_POINTS_CONSTANT;

// get user profile data
export const getUserProfile = data => async dispatch => {
  const result = data.type === 'following' ? await get(`${GET_USER_PROFILE}?profileUserId=${data.userId}&loggedInUserId=${data.followedById}`) : await get(`${GET_USER_PROFILE}?profileUserId=${data.id}`);
  if (result && result.updatedUser) {
    dispatch(setUser({ ...result.updatedUser, message: result.message }));
    if (data.type === 'normal') {
      updateLocalStorage('badgeToken', result.updatedUser.badgeToken);
    }
    dispatch(loading(false));
  } else {
    dispatch(loading(false));
    message.error(result ? result.message : 'Error');
  }
};
// update user profile data
export const updateUserProfile = (data, token) => async dispatch => {
  const result = await post(`/${UPDATE_USER_PROFILE}`, token, data);

  if (result && (result.status || result.userid)) {
    message.success(result.message);
    let userData = JSON.parse(localStorage.getItem('userData'))
    userData.payload = result.payload
    localStorage.setItem("userData", JSON.stringify(userData))
    // updateLocalStorage('userPhoto', result.payload.userPhoto);

    // dispatch(setUser(result.payload));
  } else {
    dispatch(loading(false));
    message.error(result ? result.message : 'Error');
  }
};


function updateLocalStorage(inputName, value) {
  let data = localStorage.getItem('userData');
  if (data) {
    var persons = JSON.parse(data);
    persons.payload[inputName] = value;
    localStorage.setItem("userData", JSON.stringify(persons));
  }
}


export const userImageUpload = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(SINGLE_FILE_UPLOAD, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const userUpdateProfile = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(`/${UPDATE_USER_PROFILE}`, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


export const togglefollowUser = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(FOLLOW_USER, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const getFollowerList = (id, header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`${LIST_FOLLOWERS}/${id}`, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const getFollowingList = (id, header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`${LIST_FOLLOWING}/${id}`, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const getAnyUserProfile = (id, header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`${GET_USER_PROFILE}?profileUserId=${id}`, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const getUserGoalList = (id, header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`${GET_USER_GOAL}?userId=${id}`, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const createUserGoal = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(CREATE_GOAL, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export const updateUserGoal = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(`${UPDATE_GOAL}?id=${data.id}`, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const deleteUserGoal = (data, token) => {
  return new Promise((resolve, reject) => {
    apiDeleteMethod(`${DELETE_GOAL}?id=${data}`, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const tokenPay = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(TOKEN_PAY_API, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const submitUserAnswer = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(SET_USER_ANSWER, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const getQuestionSet = (header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(GET_QUESTION_SET, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const getGoalImagesApi = (header) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(GET_GOAL_IMAGES, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}
export const getMenteesApi = (header, mentorId) => {
  return new Promise((resolve, reject) => {
    apiGetMethod(`/mentors/${mentorId}/mentees`, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err && err.data ? err.data : err)
    })
  })
}

export const getUserAnswers = (data, token) => {
  return new Promise((resolve, reject) => {
    apiPostMethod(GET_USER_ANSWERS, data, token).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export const updateMentorAvailability = (data, header) => {
  return new Promise((resolve, reject) => {
    apiPostMethod('/updateAvailability', data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
