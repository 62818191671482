import React, { useEffect, useState } from 'react';
import { Table, message, Space, Button, Switch, Select } from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../../shared/constants';
import { header } from '../../../../api/rest';
import { history } from '../../../../redux/store';
import {
  createAdminUser,
  updateAdminUser,
  getAdminUserList,
  updateAdminUserStatus,
  deleteAdminUserStatus,
  convertUserToLocalLiberator,
} from '../../../../redux/slices/adminUserSlice';
import { listMentorCategory } from '../../../../redux/slices/registerSlice';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
import './adminuser.scss';

var { ERROR_MESSAGE } = CONSTANTS;

var { ADD_EDIT_ADMIN_USER, CONFIRMATION_POPUP_MODAL } = POP_UP_MODAL_CONSTANTS;
var { ADD_USER, EDIT_USER, CONFIRMATION_HEADER } =
  POP_UP_MODAL_HEADER_CONSTANTS;

const USER_ROLE = [
  { title: 'Creator', value: 'user' },
  { title: 'Mentor', value: 'mentor' },
  { title: 'Sponsor', value: 'sponsor' },
];
const { Option } = Select;

function AdminUser() {
  // const dispatch = useDispatch();
  // const loginUserData = useSelector(state => state.user);
  const storageData = localStorage.getItem('userData');
  const token = storageData ? JSON.parse(storageData).token : '';
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [userRole, setUserRole] = useState('user');
  const [showCommunityList, setShowCommunityList] = useState(false);
  const [userList, setUserList] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [description, setDescription] = useState('');
  const [currentEntry, setCurrentEntry] = useState({
    id: '',
    firstName: '',
    lastName: '',
    password: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    role: 'user',
    email: '',
    categoryId: '',
    businessName: '',
    companyName: '',
  });
  const [paginationObj, setPaginationObj] = useState({
    page_size: 1,
    page_limit: 10,
  });
  const [isPaginationCalled, setIsPaginationCalled] = useState(false);
  const [totalPage, setTotalPage] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [userRoleFilter, setUserRoleFilter] = useState('user');
  const [isRoleDropdownChanged, setIsRoleDropdownChanged] = useState(false);
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Zip Code',
      dataIndex: 'zipcode',
      key: 'zipcode',
    },
    {
      title: 'Inactive/Active User',
      data: 'status',
      key: 'status',
      render: (text, record) => (
        <Switch
          defaultChecked={record.active}
          onClick={() => toggleUserStatus(record, 'update')}
          disabled={record.role === 'admin' ? true : false}
        />
      ),
    },
    {
      title: 'Make Local Liberator',
      data: 'isLocalLiberal',
      key: 'isLocalLiberal',
      render: (text, record) => (
        <Switch
          defaultChecked={record.isLocalLiberal}
          onClick={() => makeLocalLiberator(record, 'update')}
          disabled={record.role === 'admin' ? true : false}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            className='delete-user-btn'
            onClick={() => fillUserInfoInModal(record, 'delete')}
            disabled={record.role === 'admin' ? true : false}
          >
            Delete User
          </Button>
          <Button
            className='update-user-btn'
            onClick={() => fillUserInfoInModal(record, 'update')}
            disabled={record.role === 'admin' ? true : false}
          >
            Update User
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (token) {
      getAdminUserListApi();
    } else {
      history.push('/');
      // message.error(TOKEN_EXPIRED_MESSAGE);
    }
  }, []);

  // Toggle User Status for active inactive
  function toggleUserStatus(data, type) {
    let value = {
      userId: data._id,
      active: !data.active,
    };
    setShowPageLoader(true);
    updateUserStatus(value);
  }
  // Make user Local Liberator
  function makeLocalLiberator(data) {
    let value = {
      userId: data._id,
      isLocalLiberal: !data.isLocalLiberal,
    };
    setShowPageLoader(true);
    toggleLiberatorStatusOfUser(value);
  }

  function toggleLiberatorStatusOfUser(data) {
    header.Authorization = `Bearer ${token}`;
    convertUserToLocalLiberator(data, header)
      .then((res) => {
        message.success(res.message);
        getAdminUserListApi();
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function fillUserInfoInModal(data, type) {
    let fullName = `${data.firstName} ${data.lastName}`;
    if (type === 'update') {
      toggleModal(ADD_EDIT_ADMIN_USER, EDIT_USER);
    }
    if (type === 'delete') {
      toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
      setDescription(`Are you sure to remove ${fullName}`);
    }
    setCurrentEntry((prevCurrentEntry) => {
      return {
        ...prevCurrentEntry,
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        country: data.country,
        role: data.role,
        email: data.email,
        categoryId: data.categoryId,
        businessName: data.businessName,
        company: data.company
      };
    });
    if (data.role) {
      showCommunity(data.role);
    }
  }

  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  function createUser(value) {
    let data = {
      firstName: value.firstName,
      lastName: value.lastName,
      password: value.password,
      city: value.city,
      state: value.state,
      zipcode: value.zipcode,
      country: value.country,
      role: userRole,
      email: value.email,
      businessName: value.businessName,
      company: value.company,
    };
    header.Authorization = `Bearer ${token}`;
    createAdminUser(data, header)
      .then((res) => {
        message.success(res.message);
        getAdminUserListApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function updateUser(value) {
    let data = {
      userId: currentEntry.id,
      ...value,
    };
    header.Authorization = `Bearer ${token}`;
    updateAdminUser(data, header)
      .then((res) => {
        message.success(res.message);
        getAdminUserListApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function updateUserStatus(data) {
    header.Authorization = `Bearer ${token}`;
    updateAdminUserStatus(data, header)
      .then((res) => {
        message.success(res.message);
        getAdminUserListApi();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function getAdminUserListApi() {
    header.Authorization = `Bearer ${token}`;
    let data = {
      pageNumber: paginationObj.page_size,
      limit: paginationObj.page_limit,
      role: userRoleFilter,
    };
    getAdminUserList(data, header)
      .then((res) => {
        if (res.data) {
          setUserList(res.data);
          setTotalPage(res.totalCount);
          setIsPaginationCalled(false);
        }
        setShowPageLoader(false);
        setIsRoleDropdownChanged(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
        setIsRoleDropdownChanged(false);
      });
  }

  function deleteAdminUserApi(data) {
    header.Authorization = `Bearer ${token}`;
    deleteAdminUserStatus(data, header)
      .then((res) => {
        message.success(res.message);
        getAdminUserListApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function onSubmitPopUpModal(event) {
    setShowPageLoader(true);
    // event.preventDefault();
    if (popUpModal.modalHeader === ADD_USER) {
      event['city'] = event.city.toLowerCase();
      createUser(event);
    }
    if (popUpModal.modalHeader === EDIT_USER) {
      event['city'] = event.city.toLowerCase();
      updateUser(event);
    }
    if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
      deleteAdminUserApi(currentEntry);
    }
    // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
  }

  // close poup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
    setCurrentEntry((prevCurrentEntry) => {
      return {
        ...prevCurrentEntry,
        id: '',
        firstName: '',
        lastName: '',
        password: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        role: '',
        email: '',
      };
    });
  }

  function showCommunity(value) {
    if (value === 'mentor') {
      setShowCommunityList(true);
    } else {
      setShowCommunityList(false);
    }
    setUserRole(value);
  }

  function handleDropdownValue(value) {
    setUserRoleFilter(value);
    setIsRoleDropdownChanged(true);
    setShowPageLoader(true);
  }

  useEffect(() => {
    if (showCommunityList) {
      getCommunityList();
    }
  }, [showCommunityList]);

  function getCommunityList() {
    listMentorCategory(header)
      .then((res) => {
        if (res.data) {
          setCommunityList(res.data);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function onShowSizeChange(current, pageSize) {
    setPaginationObj((prevPaginationObj) => {
      return {
        ...prevPaginationObj,
        page_size: current,
        page_limit: pageSize,
      };
    });
    setIsPaginationCalled(true);
    setShowPageLoader(true);
  }

  useEffect(() => {
    if (isPaginationCalled) {
      getAdminUserListApi();
    }
  }, [isPaginationCalled]);

  useEffect(() => {
    getAdminUserListApi();
  }, [isRoleDropdownChanged]);

  let addEditUserProps = {
      userList,
      userRole,
      showCommunityList,
      communityList,
      currentEntry,
    },
    addEditUserFunctionProps = {
      showCommunity: showCommunity,
    };
  return (
    <div>
      {showPageLoader ? <Loader /> : ''}
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          description={description}
          {...addEditUserProps}
          {...addEditUserFunctionProps}
        />
      ) : (
        ''
      )}
      <div className='row mt-4 mb-2 justify-content-end'>
        <div className='col-md-1'>
          <Select
            style={{ width: '100px' }}
            value={userRoleFilter}
            onChange={handleDropdownValue}
          >
            {USER_ROLE.map((value, index) => {
              return (
                <Option value={value.value} key={`${value.value}-${index}`}>
                  {value.title}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className='col-md-1'>
          <Button onClick={() => toggleModal(ADD_EDIT_ADMIN_USER, ADD_USER)}>
            Add User
          </Button>
        </div>
      </div>
      <Table
        dataSource={userList}
        columns={columns}
        pagination={{
          position: ['none', 'bottomRight'],
          current: paginationObj.page_size,
          total: totalPage,
          onChange: onShowSizeChange,
        }}
        // pagination={false}
      />
      {/* <Pagination defaultCurrent={paginationObj.page_size} current={paginationObj.page_size} total={totalPage} onShowSizeChange={onShowSizeChange} responsive={true}/> */}
    </div>
  );
}

export default AdminUser;
