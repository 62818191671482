import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  Spin,
  Upload,
  InputNumber,
  Typography,
  Table,
  Popconfirm,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { POP_UP_MODAL_HEADER_CONSTANTS } from '../constants';

const { EDIT_COMMUNITY } = POP_UP_MODAL_HEADER_CONSTANTS;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function AddEditCommunity(props) {
  let { title, loading, showLoader, currentEntry } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [business, setBusiness] = useState([]);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [form] = Form.useForm();

  useEffect(() => {
    if (title === EDIT_COMMUNITY) {
      form.setFieldsValue({
        ...props.currentEntry,
      });
      setBusiness(currentEntry.business || []);

      const partners = currentEntry.partners.map((i) => ({
        uid: i._id,
        name: i.name,
        url: i.logo,
        status: 'done',
      }));
      setFileList(partners);
      setBusiness(currentEntry.business.map((i) => ({ ...i, id: i._id })));
    }
  }, []);

  const storageData = localStorage.getItem('userData');
  const token = storageData ? JSON.parse(storageData).token : '';
  const uploadProps = {
    action: `${process.env.REACT_APP_BASE_URL}/uploadfile`,
    headers: {
      authorization: `${token}`,
    },
  };

  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ fileList }) => setFileList(fileList);

  const handleFormSubmit = (e) => {
    e.partners = fileList
      .filter((file) => file.status === 'done')
      .map((file) => ({
        name: file.name,
        logo: file.response ? file.response.Location : file.url,
      }));
    e.business = business;
    props.onOk(e);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <React.Fragment>
      <Modal
        title={title}
        centered
        visible={props.visible}
        onOk={(event) => props.onOk(event)}
        onCancel={() => props.onCancel(false)}
        okButtonProps={{ title: 'Submit' }}
        footer={null}
        maskClosable={false}
        width='80%'
      >
        <Form {...layout} onFinish={handleFormSubmit} form={form}>
          <Form.Item
            label={'Name'}
            name='name'
            rules={[
              {
                required: true,
                message: 'Please input your Name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'Description'}
            name='description'
            rules={[
              {
                required: true,
                message: 'Please input your Description!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={'City'}
                name='city'
                rules={[
                  {
                    required: true,
                    message: 'Please input your city!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={'Zip Code'}
                name='zipCode'
                help='use comma seperator for multiple zipcode'
                rules={[
                  {
                    required: true,
                    pattern: /^\d{5,6}(,?\d{5,6})*$/,
                    validator: (_, value) =>
                      /^\d{5,6}(, ?\d{5,6})*$/.test(value)
                        ? Promise.resolve()
                        : Promise.reject('Please Enter Valid Zipcode'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label={'Profile Photo'}>
                <Spin indicator={antIcon} spinning={showLoader.profileImage}>
                  <input
                    type='file'
                    accept='image/*'
                    name='profileImage'
                    onChange={(event) => props.handleFileInput(event)}
                  />
                </Spin>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={'Cover Photo'}>
                <Spin indicator={antIcon} spinning={showLoader.profileVideo}>
                  <input
                    type='file'
                    accept='image/*'
                    name='profileVideo'
                    onChange={(event) => props.handleFileInput(event)}
                  />
                </Spin>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={'Partner Logos'}>
            <Upload
              {...uploadProps}
              listType='picture-card'
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Form.Item>
          <Form.Item label={'Business'}>
            <BusinessTable business={business} setBusiness={setBusiness} />
          </Form.Item>
          <Row justify={'center'}>
            <Button type='primary' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const BusinessTable = ({ business, setBusiness }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleAdd = () => {
    const id = business.length.toString();
    const newData = [...business];
    newData.push({
      id,
      name: '',
      foundedBy: '',
      website: '',
      industry: '',
    });
    setBusiness(newData);
    setEditingKey(id);
  };

  const handleDelete = (id) => {
    const newData = business.filter((item) => id !== item.id);
    setBusiness(newData);
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...business];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setBusiness(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setBusiness(newData);
        setEditingKey('');
      }
    } catch (errInfo) {

    }
  };

  const columns = [
    {
      title: 'Business Name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: 'Founded by',
      dataIndex: 'foundedBy',
      width: '25%',
      editable: true,
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      width: '25%',
      editable: true,
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '25%',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              style={{
                marginRight: 8,
              }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={business}
        columns={mergedColumns}
        rowClassName='editable-row'
        pagination={false}
      />
      <Button
        onClick={handleAdd}
        type='primary'
        style={{
          marginBottom: 16,
        }}
      >
        Add More Business
      </Button>
    </Form>
  );
};
