import React from 'react';
import {
    Modal,
    Button,
    Form,
    Input,
    Row
} from 'antd';

export function AddAdminCategory(props) {
    let {
        title,
        loading
    } = props;
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    const [form] = Form.useForm();
    form.setFieldsValue({
        ...props.currentEntry
    })
    return (
        <React.Fragment>
            <Modal
                title={title}
                centered
                visible={props.visible}
                onOk={(event) => props.onOk(event)}
                onCancel={() => props.onCancel(false)}
                okButtonProps={{ title: "Submit" }}
                footer={null}>
                <Form {...layout} onFinish={(event) => props.onOk(event)} form={form}>
                    <Form.Item
                        label={"Name"}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your Category Name!',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}
