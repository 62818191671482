import React, { useEffect, useState, useCallback } from 'react';
import { Table, Space, Button, message, Modal } from 'antd';
import { header } from '../../../../api/rest';
import { history } from '../../../../redux/store';
import {
  getSurveyUsersApi,
  getSurveyUsersAnswersApi,
} from '../../../../redux/slices/adminSlice';
import { Loader } from '../../../shared/Loader';

export default function Questions(props) {
  const storageData = localStorage.getItem('userData');
  const token = storageData ? JSON.parse(storageData).token : '';
  const [users, setUsers] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [createQuestionModal, setCreateQuestionModal] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [answers, setAnswers] = useState([]);
  const surveyId = props.match.params.surveyId;
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            type='primary'
            onClick={() => {
              viewResponse(record);
            }}
          >
            View Response
          </Button>
        </Space>
      ),
    },
  ];

  const answerColumns = [
    {
      title: 'Question',
      dataIndex: ['question', 'title'],
      key: 'firstName',
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'lastName',
    },
    {
      title: 'Submitted On',
      dataIndex: 'createdAt',
      key: 'createdAt',
    }
  ];

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = useCallback(async () => {
    if (token) {
      getSurveyUsersApi(surveyId, header)
        .then((res) => {
          setUsers(res);
          setShowPageLoader(false);
        })
        .catch((err) => {
          message.error(err?.message ? err.message : 'something went wrong');
        });
    } else {
      history.push('/');
    }
  }, [token]);

  const viewResponse = async (data) => {
    setActiveUser(data);
    const resp = await getSurveyUsersAnswersApi(surveyId,data.id)
    setAnswers(resp)
    setCreateQuestionModal(true);
  };

  return (
    <>
      {showPageLoader ? <Loader /> : ''}
      <div className='row mt-4 mb-2'>
        <div className='col-12 '>
          <h5>{users.name} is Completed By Following Users</h5>
        </div>
      </div>
      <Table
        dataSource={users.completedBy}
        columns={columns}
        rowKey={(record) => record.id}
      />

      <Modal
        title={`${users.name} Responses by ${activeUser.firstName} ${activeUser.lastName}`}
        centered
        visible={createQuestionModal}
        okButtonProps={{ title: 'Submit' }}
        width={800}
        onCancel={() => {
          setCreateQuestionModal(false);
        }}
        footer={null}
      >
        <Table
          dataSource={answers}
          columns={answerColumns}
          rowKey={(record) => record.id}
        />
      </Modal>
    </>
  );
}
