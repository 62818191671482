import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Input, Row, Layout, Select, message } from "antd";
import Container from "../../widgets/container";
import style from './register.module.sass';
import './register.sass';
import { history } from "../../../redux/store";
// import countries from "../../widgets/countries";
// LOADER
import {
  // SkeletonDashBoardPostLoader,
  Loader
} from '../../shared/Loader';
import {
  // post, get ,
  header
} from "../../../api/rest";
import MyAncor from "../../widgets/global/ancor";
import { setUserData as setUser, setLoading as running } from "../../../redux/slices/authSlice";
import {
  getBusinessIndustries,
  addBusinessDetails,
  getBusinessDetails,
  updateBusinessDetails
} from '../../../redux/slices/registerSlice';
// CONSTANTS
import { CONSTANTS, REGEX_CONSTANTS } from '../../shared/constants';
var {
  ERROR_MESSAGE,
  INVALID_USER,
  CHOOSE_A_COMMUNITY
} = CONSTANTS;

var { ACCEPT_NUMBER } = REGEX_CONSTANTS;

const RegBusinessDetails = (props) => {
  const storageInfo = localStorage.getItem("userData");
  const session = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const [industries, setIndustires] = useState([]);
  const [form] = Form.useForm();
  const userInfo = session.data;
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const type = props.type ? props.type : '';
  const payload = storageInfo ? JSON.parse(storageInfo).payload : '';
  const token = storageInfo ? JSON.parse(storageInfo).token : '';
  // const [businessInfo, setBusinessInfo] = useState({
  //   userId: '',
  //   businessName: '',
  //   industryId: '',
  //   numOfEmployees: '',
  //   streetAddr: '',
  //   city: '',
  //   state: '',
  //   zipCode: '',
  //   country: '',
  //   grossIncome: ''
  // });
  const [isBuisnessInfoAvailable, setIsBuisnessInfoAvailable] = useState(false);


  useEffect(() => {
    // async function fetchData() {
    //   const result = await get('/businessInformation/business-industries');
    //   setIndustires(result);
    // }
    // fetchData();
    getIndustries();
  }, []);


  useEffect(() => {
    if (payload) {
      getBusinessInformation();
    }
  }, [])

  function getIndustries() {
    const storageData = localStorage.getItem("userData");
    if (storageData) {
      let data = JSON.parse(storageData);
      dispatch(running(true));
      header.Authorization = data.token;
      getBusinessIndustries(header).then(res => {
        // showing success message
        if (res.data && res.data.length) {
          setIndustires(res.data);
          setShowLoader(false);
        }
      }).catch(err => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE)
        setShowLoader(false);
      })
    }
  }

  function addBuisnessInformationApi(data) {
    header.Authorization = token;
    addBusinessDetails(data, header).then(res => {
      // showing success message
      message.success(res.message);
      setLoading(false);
      if (res && res.data) {
        dispatch(setUser({ ...session.data, businessInfo: res.data }));
        setShowLoader(false);
        history.push('/register/choose-community');
        // history.push('/register/choose-category',
        //   {
        //     data: {
        //       busInfoCode: result.busInfo,
        //       ...userInfo
        //     }
        //   }
        // );
      }
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowLoader(false);
      setLoading(false);
    })
  }

  function getBusinessInformation() {
    header.Authorization = token;
    getBusinessDetails(payload.id, header).then(res => {
      if (res.businessInformation) {
        if (res.businessInformation.length) {
          // setBusinessInfo(res.businessInformation[0])
          form.setFieldsValue({
            ...res.businessInformation[0]
          })
          setShowLoader(false);
          setIsBuisnessInfoAvailable(true);
          // dispatch(setUser({ ...session.data, businessInfo: res.businessInformation[0] }));
        } else {
          setIsBuisnessInfoAvailable(false);
          setShowLoader(false);

        }
      } else {
        setIsBuisnessInfoAvailable(false);
        setShowLoader(false);

      }
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowLoader(false);

    })
  }

  function updateBuisnessInformation(data) {
    header.Authorization = token;
    updateBusinessDetails(data, header).then(res => {
      setLoading(false);
      setShowLoader(false);
      message.success(res.message);
      props.loadNextTab(CHOOSE_A_COMMUNITY)
    }).catch(err => {
      message.error(err && err.message ? err.message : ERROR_MESSAGE);
      setShowLoader(false);

      setLoading(false);
    })
  }

  const onFinish = async (values) => {
    const storageData = localStorage.getItem("userData");
    if (storageData) {
      setLoading(true);
      let data = JSON.parse(storageData);
      dispatch(running(true));
      header.Authorization = data.token;
      const body = {
        "userId": data.payload.id,
        "businessName": values.businessName,
        "industryId": values.industryId,
        "numOfEmployees": values.numOfEmployees,
        // "streetAddr": values.streetAddr,
        // "city": values.city,
        // "state": values.state,
        // "country": values.country,
        "zipCode": values.zipCode,
        "grossIncome": "0",
        "phoneNum": "",
        "fax": "",
        "website": "",
      }
      setShowLoader(true);
      if (isBuisnessInfoAvailable) {
        if (type) {
          updateBuisnessInformation(body);
        } else {
          addBuisnessInformationApi(body)
        }
      } else {
        addBuisnessInformationApi(body)
      }
    } else {
      message.error(INVALID_USER)
    }
  };

  const onClickSkip = () => {
    if (type) {
      history.push('/dashboard');
    } else {
      history.push('/register/choose-community',
        {
          data: {
            ...userInfo
          }
        }
      );
    }

  }

  return (
    <Layout.Content className={style.block}>
      {showLoader ? <Loader /> : ''}
      {type ? '' : <React.Fragment>
        <Row className={`header-divider`} />
        <Row className={style.title_block} justify={'center'}>Tell us about your Business</Row>
      </React.Fragment>}
      <Container className={style.reg_container}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} autoComplete="off" className={`register-form`}>
          <h2 className={style.heading}>Business Details</h2>
          <div className={style.form_group}>
            <Form.Item name="businessName" rules={[{ required: true, message: 'Please enter your Business Name' }]}>
              <Input placeholder={`Business Name`} className={style.input_item} />
            </Form.Item>
            <Form.Item name="industryId" rules={[{ required: true, message: 'Please select your Industry' }]}>
              <Select placeholder={`Industry`} allowClear showSearch>
                {(industries && industries.length) && industries.map(i => <Select.Option value={i.id} key={i.id}>{i.name}</Select.Option>)}
              </Select>
            </Form.Item>
            <Row className={`register-row-items ${style.row_item}`}>
              <Form.Item name="numOfEmployees" rules={[{ required: true, message: 'Please enter the number of employees' },{
                pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                message: 'Please enter valid number of employees',
            }]} className={style.row_form_item}>
                <Input placeholder={`Number of Employees`} className={`first-row-item ${style.input_item}`} type={'number'} min={'0'} />
              </Form.Item>
              {/* <Form.Item name="grossIncome" rules={[{ required: true, message: 'Please enter PY Gross Income' }]} className={style.row_form_item}>
                <Input placeholder={`PY Gross Income`} className={`last-row-item ${style.input_item}`} type={'number'} />
              </Form.Item> */}
            </Row>
          </div>
          <h2 className={style.heading}>Business Address</h2>
          <div className={style.form_group}>
            {/* <Form.Item name="streetAddr" rules={[{ required: true, message: 'Please enter your Business Address' }]}>
              <Input placeholder={`Address`} className={style.input_item} />
            </Form.Item>
            <Form.Item name="city" rules={[{ required: true, message: 'Please enter your Business City' }]}>
              <Input placeholder={`City`} className={style.input_item} />
            </Form.Item> */}
            {/* <Row className={style.row_item}>
              <Form.Item name="state" rules={[{ required: true, message: 'Please enter your Business State' }]} className={style.row_form_item}>
                <Input placeholder={`State`} className={`first-row-item ${style.input_item}`} />
              </Form.Item>
              <Form.Item name="zipCode"
                rules={[{
                  required: true,
                  // message: 'Please enter your Business zipcode'
                  pattern: ACCEPT_NUMBER,
                  validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid Zipcode'),
                }]} className={style.row_form_item}>
                <Input placeholder={`Zip Code`} className={`last-row-item ${style.input_item}`} maxLength="6" />
              </Form.Item>
            </Row> */}
            <Form.Item name="zipCode"
              rules={[{
                required: true,
                // message: 'Please enter your Business zipcode'
                pattern: ACCEPT_NUMBER,
                validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid Zipcode'),
              }]}>
              <Input placeholder={`Zip Code`} className={`last-row-item ${style.input_item}`} maxLength="6" />
            </Form.Item>
            {/* <Form.Item name="country" rules={[{ required: true, message: 'Please select your Business Country' }]}>
              <Select placeholder="Country" allowClear showSearch >
                {countries.map(c => <Select.Option value={c} key={c}>{c}</Select.Option>)}
              </Select>
            </Form.Item> */}
          </div>
          <Row justify={'space-between'} align='middle'>
            <MyAncor className="skip-form-btn" onClick={onClickSkip}>{type ? 'Cancel' : 'Skip'}</MyAncor>
            <Button type="primary" htmlType="submit" className={style.submit_btn} loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Container>
    </Layout.Content>
  )
}

export default RegBusinessDetails
