import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Row, Select } from 'antd';
import {
  REGEX_CONSTANTS,
  CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../constants';
import countries from '../../widgets/countries';
const { PASSWORD_VALIDATION_REGEX_BCK, ACCEPT_NUMBER } = REGEX_CONSTANTS;
const { PASSWORD_VALIDATION_MESSAGE_BCK, REGISTER_ROLE } = CONSTANTS;
const { EDIT_USER } = POP_UP_MODAL_HEADER_CONSTANTS;

export function AdminUserAddEdit(props) {
  let {
    title,
    // description,
    loading,
    showCommunityList,
    communityList,
  } = props;
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const [form] = Form.useForm();
  console.log(props.currentEntry);
  const [isSponsor, setIsSponsor] = useState(false);
  form.setFieldsValue({
    ...props.currentEntry,
  });

  useEffect(() => {
    if (props.currentEntry?.role === 'sponsor') {
      setIsSponsor(true);
    }
  }, []);
  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      okButtonProps={{ title: 'Submit' }}
      footer={null}
    >
      <Form {...layout} onFinish={(event) => props.onOk(event)} form={form}>
        <Form.Item label={'User role'} name='role'>
          <Select
            className='country-feild'
            onChange={(event) => {
              props.showCommunity(event);
              if (event === 'sponsor') return setIsSponsor(true);
              setIsSponsor(false);
            }}
          >
            {REGISTER_ROLE.map((c) => (
              <Select.Option value={c} key={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'First Name'}
          name='firstName'
          rules={[
            {
              required: !isSponsor,
              message: 'Please input your First Name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Last Name'}
          name='lastName'
          rules={[
            {
              required: !isSponsor,
              message: 'Please input your Last Name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        {isSponsor && (
          <>
            <Form.Item
              label={'Business Name'}
              name='businessName'
              rules={[
                {
                  required: isSponsor,
                  message: 'Please input Business Name!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={'Company'}
              name='company'
              rules={[
                {
                  required: isSponsor,
                  message: 'Please input company!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={'Email'}
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
              type: 'email',
            },
          ]}
        >
          <Input disabled={title === EDIT_USER ? true : false} />
        </Form.Item>
        <Form.Item label={'Sponsor Id'} name='sponsor'>
          <Input />
        </Form.Item>
        <Form.Item
          label={'City'}
          name='city'
          rules={[
            {
              required: !isSponsor,
              message: 'Please input your city!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'State'}
          name='state'
          rules={[
            {
              required: !isSponsor,
              message: 'Please input your state!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Country'}
          name='country'
          rules={[
            {
              required: !isSponsor,
              message: 'Please input your country!',
            },
          ]}
        >
          <Select
            className='country-feild'
            placeholder='Country'
            allowClear
            showSearch
          >
            {countries.map((c) => (
              <Select.Option value={c} key={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {showCommunityList ? (
          <Form.Item
            label={'Mentor Category'}
            name='categoryId'
            rules={[
              {
                required: true,
                message: 'Please choose your mentor category!',
              },
            ]}
          >
            <Select
              className='country-feild'
              placeholder='Category'
              allowClear
              showSearch
            >
              {communityList.map((c) => (
                <Select.Option value={c._id} key={c._id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          ''
        )}
        <Form.Item
          label={'Zip Code'}
          name='zipcode'
          rules={[
            {
              required: !isSponsor,
              pattern: ACCEPT_NUMBER,
              validator: (_, value) =>
                ACCEPT_NUMBER.test(value)
                  ? Promise.resolve()
                  : Promise.reject('Please Enter Valid Zipcode'),
            },
          ]}
        >
          <Input maxLength='6' />
        </Form.Item>
        {title === EDIT_USER ? (
          ''
        ) : (
          <React.Fragment>
            <Form.Item
              label={'Password'}
              name='password'
              rules={[
                {
                  required: true,
                  // message: 'Please input your password!',
                  pattern: PASSWORD_VALIDATION_REGEX_BCK,
                  validator: (_, value) =>
                    PASSWORD_VALIDATION_REGEX_BCK.test(value)
                      ? Promise.resolve()
                      : Promise.reject(PASSWORD_VALIDATION_MESSAGE_BCK),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={'Confirm Password'}
              name='password_confirm'
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </React.Fragment>
        )}
        <Row justify={'center'}>
          <Button type='primary' htmlType='submit' loading={loading}>
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
