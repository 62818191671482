import React from 'react';
import { history } from "../../../redux/store";

const MyAncor = props => <a {...{
  ...props,
  onClick: e => {
    e.preventDefault();
    props.href
      ? history.push(props.href)
      : props.onClick()
  }
}}>{props.children}</a>

export default MyAncor