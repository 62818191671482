import React from 'react';
import Container from '../../widgets/container';
import './faqs.scss';
import { Collapse } from 'antd';
const { Panel } = Collapse;



export default function Faqs() {

React.useEffect(() => {
  document.title = "FAQs";
})

return (
<div>
  <Container fluid={true} gutter={false}>
    <div className='contact-banner-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="faq-hero">
              <h3 className="hero-heading">help center</h3>
              <h4 className="hero-text">
                Get answers to many of the frequently asked questions:
              </h4>
              <p className="hero-text-2">
                General | User | Mentor
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container faqs-section">
      <div className='col-md-12 faqs-section-row'>
        <div>
          <h3 className="heading-text">
            General FAQs
          </h3>
        </div>
        <div className='faq-list-section'>
          <Collapse accordion ghost>
            <Panel header={(<h6>NOTE: Banner image with 903 x 410 looks good</h6>)}
              key='1'
              >
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='2'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='3'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='4'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='5'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
      {/* General Faqs end */}
      <div className='col-md-12 faqs-section-row'>
        <div>
          <h3 className="heading-text">
          User FAQs
          </h3>
        </div>
        <div className='faq-list-section'>
          <Collapse accordion ghost>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)}
              key='1'
              >
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='2'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='3'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='4'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='5'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
      {/* Users Faqs end */}
      <div className='col-md-12 faqs-section-row'>
        <div>
          <h3 className="heading-text">
            Mentor FAQs
          </h3>
        </div>
        <div className='faq-list-section'>
          <Collapse accordion ghost>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)}
              key='1'
              >
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='2'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='3'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='4'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
            <Panel header={(<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h6>)} key='5'>
              <p>
                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
      {/* Mentor Faqs end */}
    </div>
  </Container>
</div>
);
}
