import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
// Moment
import moment from 'moment';
// import Container from "../../widgets/container";
import { message } from 'antd';
import {
  getReminderList,
  getMentorsList,
  createReminder,
} from '../../../redux/slices/reminderSlice';
import PopUpModal from '../../shared/popupmodal';
import { header } from '../../../api/rest';
import {
  CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
} from '../../shared/constants';
import './reminderCalendar.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
var { ERROR_MESSAGE } = CONSTANTS;
var { ADD_REMINDER } = POP_UP_MODAL_HEADER_CONSTANTS;
var { ADD_EDIT_REMINDER } = POP_UP_MODAL_CONSTANTS;

export const ReminderCalendar = (props) => {
  React.useEffect(() => {
    document.title = 'Reminder Calendar';
  });

  const storageInfo = localStorage.getItem('userData');
  const token = storageInfo ? JSON.parse(storageInfo).token : '';
  const payload = storageInfo ? JSON.parse(storageInfo).payload : '';
  // visibility for modal box
  // const [visible, setVisibility] = useState(0);
  const [reminderEvents, setReminderEvents] = useState([]);
  // setting up start and end date
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // setting up mentors list
  const [mentorsList, setMentorsList] = useState([]);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [modalObj, setModalObj] = useState({
    reminderTitle: '',
    reminderDesc: '',
    mentorId: '',
  });
  // const date = new Date();
  // const utcHours = date.setUTCHours(0, 0, 0, 0);

  useEffect(() => {
    // auth token
    header.Authorization = token;
    // get upcoming list api
    getReminderListApi(header);
    // get mentors list api
    getMentorListAPI(header);
  }, []);

  function getReminderListApi(header) {
    let d = new Date();
    d.setUTCHours(0, 0, 0, 0);
    const data = {
      userId: payload.id,
      currentDate: new Date().toISOString(),
      // currentDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
      days: 30,
    };
    getReminderList(data, header)
      .then((res) => {
        if (res.status) {
          setReminderDate(res.userTodayReminder);
        }
      })
      .catch((err) => {
        // error for client
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function getMentorListAPI(header) {
    // Gets mentors list
    getMentorsList(header)
      .then((res) => {
        if (res.status) {
          setMentorsList(res.data);
        }
      })
      .catch((err) => {
        // error for client
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function setReminderDate(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].start = moment(data[i].start_date).toDate();
      data[i].end = moment(data[i].end_date).toDate();
    }
    setReminderEvents(data);
  }

  // Selecting time slot for setting up meeting with mentor
  function handleSelect({ start, end }) {
    // Set start date
    setStartDate(start);
    // Set end date
    setEndDate(end);
    // Set true to modal box
    // setVisibility(true);
    toggleModal(ADD_EDIT_REMINDER, ADD_REMINDER);
  }

  // close modal box
  function onClickOk() {
    console.log(
      'startDate.toISOString()',
      startDate.toISOString(),
      'endDate.toISOString()',
      endDate.toISOString()
    );
    console.log();

    header.Authorization = token;
    // call api for create reminder
    let data = {
      title: modalObj.reminderTitle,
      description: modalObj.reminderDesc,
      start_date: new Date(startDate).toISOString(),
      end_date: new Date(endDate).toISOString(),
      rawStartDate: {
        time: moment(startDate).format('HH:mm'),
        day: moment(startDate).format('dddd'),
      },
      rawEndDate: {
        time: moment(endDate).format('HH:mm'),
        day: moment(endDate).format('dddd'),
      },
      userId: payload.id,
      mentorId: modalObj.mentorId,
    };
    createReminder(data, header)
      .then((res) => {
        if (res.status) {
          getReminderListApi(header);
          message.success(res.message);
          clearReminderData();
        } else {
          message.error(res.message);
          clearReminderData();
        }
      })
      .catch((err) => {
        message.error(
          err && err.data.message ? err.data.message : ERROR_MESSAGE
        );
      });

    // setVisibility(false);
  }

  // clear reminder section data
  function clearReminderData() {
    setModalObj((prevModalObj) => {
      return {
        ...prevModalObj,
        reminderTitle: '',
        reminderDesc: '',
        mentorId: '',
      };
    });
    closePopupModal();
    setStartDate('');
    setEndDate('');
  }

  // handle input of onchange functio of textbox
  function handleInput(event) {
    let { value, name } = event.target;
    setModalObj((prevModalObj) => {
      return {
        ...prevModalObj,
        [name]: value,
      };
    });
  }
  // open popupmodal by passing type and header
  function toggleModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  // close poup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
    setModalObj((prevModalObj) => {
      return {
        ...prevModalObj,
        reminderTitle: '',
        reminderDesc: '',
        mentorId: '',
      };
    });
  }

  function onSubmitPopUpModal(event) {
    event.preventDefault();
    if (popUpModal.type === ADD_EDIT_REMINDER) {
      onClickOk();
    }
    // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
  }

  let addEditReminderVariable = {
      modalObj,
      mentorsList,
    },
    addEditReminderFunctionProps = {
      handleInput: handleInput,
    };
  return (
    <div className='App scheduler-calendar'>
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          {...addEditReminderVariable}
          {...addEditReminderFunctionProps}
        />
      ) : (
        ''
      )}
      <Calendar
        selectable
        defaultDate={moment().toDate()}
        defaultView={Views.WEEK}
        events={reminderEvents}
        localizer={localizer}
        onSelectEvent={(event) => alert(event.title)}
        onSelectSlot={handleSelect}
        resizable
        style={{ height: '100vh' }}
      />
    </div>
  );
};
