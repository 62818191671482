import axios from 'axios';
export const header = {};

axios.interceptors.request.use(
  (config) => {
    const localUser = localStorage.getItem('userData');
    if (localUser && !config.headers.Authorization) {
      const userJson = JSON.parse(localUser);
      config.headers.Authorization = userJson.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const post = async (url, headers, data) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${url}`,
      data,
      { headers }
    );
    let result = response.data;
    return result;
  } catch (error) {
    if (error.response) {
      console.error(
        'Error calling REST API endpoint',
        error.response.data.message
      );
      return error.response.data;
    } else {
      return false;
    }
  }
};

export const put = async (url, data) => {
  try {
    let response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}${url}`,
      data
    );
    let result = response.data;
    return result;
  } catch (error) {
    console.error(
      'Error calling REST API endpoint',
      error.response.data.message
    );
    return error.response.data;
  }
};

export const get = async (url, headers = {}) => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, {
      headers,
    });
    let result = response.data;
    return result;
  } catch (error) {
    console.error(
      'Error calling REST API endpoint',
      error && error.response && error.response.data
        ? error.response.data.message
        : ''
    );
    return error && error.response && error.response.data;
  }
};

export const apiDeleteMethod = (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}${url}`, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const apiMultiDeleteMethod = (url, headers, data) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}${url}`, { headers, data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const apiGetMethod = (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}${url}`, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const apiPatchMethod = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${process.env.REACT_APP_BASE_URL}${url}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const apiPutMethod = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}${url}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const apiPostMethod = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}${url}`, data, { headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const httpPost = (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}${url}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};
