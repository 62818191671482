import React, { useState, createRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiGetMethod, header } from '../../../../api/rest';
import {
  getBusinessIndustries,
  mentorRequest,
} from '../../../../redux/slices/registerSlice';
import { getFeaturedMentors } from '../../../../redux/slices/mentorSlice.js';
import { listMentorCategory } from '../../../../redux/slices/registerSlice';
import { message, Row, Col, Form, Input, Radio, Select, Button } from 'antd';
import AvatarCarousel from '../../../shared/AvatarCarousel';
import MentorsCarousel from './MentorsCarousel';
import Video from '../../../../assets/videos/sample-mp4-file.mp4';
import MentorImg from '../../../../assets/images/mentor1.jpg';
// CONSTANTS
import { CONSTANTS } from '../../../shared/constants';
// CSS
import './mentorregister.scss';
var { ERROR_MESSAGE, LANGUAGE_LIST } = CONSTANTS;

const { Option } = Select;

const MENTOR_lIST = [
  {
    image: MentorImg,
    name: 'Monique Dorsainvil',
    position: 'Public Policy Manager @ Facebook',
  },
  {
    image: MentorImg,
    name: 'Monique Dorsainvil',
    position: 'Public Policy Manager @ Facebook',
  },
  {
    image: MentorImg,
    name: 'Monique Dorsainvil',
    position: 'Public Policy Manager @ Facebook',
  },
  {
    image: MentorImg,
    name: 'Monique Dorsainvil',
    position: 'Public Policy Manager @ Facebook',
  },
  {
    image: MentorImg,
    name: 'Monique Dorsainvil',
    position: 'Public Policy Manager @ Facebook',
  },
  {
    image: MentorImg,
    name: 'Monique Dorsainvil',
    position: 'Public Policy Manager @ Facebook',
  },
  {
    image: MentorImg,
    name: 'Monique Dorsainvil',
    position: 'Public Policy Manager @ Facebook',
  },
];

function MentorRegister(props) {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [industries, setIndustires] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [form] = Form.useForm();
  const [token, setToken] = useState(
    new URLSearchParams(props.location.search).get('token')
  );

  const formRef = createRef();

  function toggleForm() {
    let status = showForm;
    setShowForm(true);
  }

  useEffect(() => {
    if (showForm) {
      if (formRef.current) {
        formRef.current.scrollIntoView();
        // formRef.current.scrollIntoView({ top:formRef.current.clientHeight, behavior: 'smooth' });
      }
    }
  }, [showForm]);
  useEffect(() => {
    async function getMentor() {
      let res = await getFeaturedMentors();
      setMentors(res.data);
    }
    getMentor();
    getInvitationInfo();
  }, []);

  const getInvitationInfo = async () => {
    if (!token) return;
    const invitationInfo = await apiGetMethod(`/sponsors/invites/${token}`);
    form.setFieldsValue({
      email: invitationInfo.email,
      firstName: invitationInfo.firstName,
      lastName: invitationInfo.lastName,
    });
  };

  const MENTOR_RACE = [
    'African American',
    'Hispanic',
    'Asian',
    'White',
    'Other',
  ];
  const AREA_OF_INTEREST = [
    'Computer & Web Support',
    'Mentoring',
    'Facilitator',
    'Fundraising',
    'Leadership',
    'Marketing',
    'Community Relations',
    'Recruiting',
    'Workshop Instructor',
    'Volunteer Development & Training',
    'Other',
  ];
  const GENDER_LIST = ['Male', 'Female'];

  /*function handleFormSubmit(event) {
event.preventDefault();

} */

  const mentorForm = async (values) => {
    // console.log(values)
    // return;
    values.token = token;
    setLoading(true);
    try {
      await mentorRequest(values, header);
      message.success('Request submitted successfully');
      form.resetFields();
    } catch (error) {
      message.error(error.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getIndustries();
    getCommunityList();
  }, []);

  // get industry list
  function getIndustries() {
    getBusinessIndustries(header)
      .then((res) => {
        // showing success message
        if (res.data && res.data.length) {
          setIndustires(res.data);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function getCommunityList() {
    listMentorCategory(header)
      .then((res) => {
        if (res.data) {
          setCommunityList(res.data);
        }
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  return (
    <React.Fragment>
      <section className='mentor-banner-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-6'>
              <div className='banner-content text-md-right'>
                <h1>become a mentor</h1>
                <h3>Legacys are built, brick by brick.</h3>
                <p>
                  How do you build a{' '}
                  <strong>
                    <span style={{ color: '#18a4ad' }}>legacy</span>
                  </strong>{' '}
                  that lasts beyond you? Replicate. Its time to teach aspiring
                  entrepreneurs and creators in your{' '}
                  <strong>
                    <span style={{ color: '#18a4ad' }}>community</span>
                  </strong>{' '}
                  what you know. Our young people have ideas and aspirations
                  that could change our city but they need your knowledge and
                  wisdom to maximise their potential.
                </p>
                <p>
                  {' '}
                  Equiliberty is a new platform that helps individuals monetize
                  their talents and{' '}
                  <strong>
                    <span style={{ color: '#18a4ad' }}>
                      fosters ownership in our community
                    </span>
                  </strong>
                  . We believe that everything we need to solve poverty and{' '}
                  <strong>
                    <span style={{ color: '#18a4ad' }}>
                      create a new class of owners
                    </span>
                  </strong>{' '}
                  is already in the neighborhood and our job is to help bring it
                  out. It's one thing to make a living and another to give life
                  and as an{' '}
                  <strong>
                    <span style={{ color: '#18a4ad' }}>Equiliberty</span>
                  </strong>{' '}
                  mentor, you'll have the opportunity to do that with creatives
                  in your community who are eager to change the very trajectory
                  of their family, neighborhood and world. Partner with
                  Equiliberty today and further your legacy.{' '}
                </p>
                
                <div className='mentor-signup-btn'>
                  <Link to='/mentor/register' onClick={() => toggleForm()}>
                    sign up
                  </Link>
                  {/* <a href=></a> */}
                </div>
                <p> &nbsp; {' '}</p>
                <p>
                Equiliberty is proud to partner with SCORE! SCORE Houston, is a 501(c)(3) nonprofit organization, dedicated to helping small businesses get off the ground, grow, and achieve their goals through education and mentorship. {' '}
                  </p>
                <div className='banner-video'>
                  {/* <video controls src={Video} /> */}
                  <iframe
                    width='100%'
                    className='mentor-yt-video'
                    src={`https://www.youtube.com/embed/6HSEgWqbeXM`}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='meet-mentor'>
        <div className='container'>
          <div className='row d-none'>
            <div className='col-md-12'>
              <div className='mentor-heading'>
                <h1>Meet the Equilberty Mentors</h1>
              </div>
            </div>
          </div>
          {/* <AvatarCarousel images={MENTOR_lIST.map((mentor)=> mentor.image)} /> */}
          <MentorsCarousel images={mentors} />
          {/* <div className="row list-row">
          {MENTOR_lIST.map((value, index) => {
          return (
          <div className="col-md-3" key={`${value.name}-${index}`}>
            <div className="mentor-list">
              <img src={value.image} alt={`${value.image}-${index}`} />
              <div className="mentor-detail">
                <h3>{value.name}</h3>
                <p>{value.position}</p>
              </div>
            </div>
          </div>
          );
          })}
        </div> */}
        </div>
      </section>
      {showForm ? (
        <section
          className='mentor-form-section mb-5 pb-5'
          id='form'
          ref={formRef}
        >
          <div className='container'>
            <div className='row mb-5 pb-3'>
              <div className='col-md-12'>
                <div className='form-heading text-center'>
                  <h1>apply to become a mentor</h1>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <div className='form-steps'>
                  <h4>Step One:</h4>
                  <p>Complete the form.</p>
                  <h4>Step Two:</h4>
                </div>
              </div>

              <div className='col-md-9'>
                <div className='row'>
                  <Form
                    form={form}
                    onFinish={mentorForm}
                    layout='vertical'
                    className='w-100'
                  >
                    <Row>
                      <div className='col-12 col-md-6'>
                        <Form.Item
                          name='firstName'
                          rules={[
                            { required: true, message: 'Enter Your FirstName' },
                          ]}
                        >
                          <Input
                            type='text'
                            placeholder='First Name'
                            style={{
                              border: 'none',
                              width: '100%',
                              background: 'whitesmoke',
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className='col-12 col-md-6'>
                        <Form.Item
                          name='lastName'
                          rules={[
                            { required: true, message: 'Enter Your LastName' },
                          ]}
                        >
                          <Input
                            type='text'
                            placeholder='Last Name'
                            style={{
                              border: 'none',
                              width: '100%',
                              background: 'whitesmoke',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-12 col-md-6'>
                        <Form.Item
                          name='email'
                          rules={[
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!',
                            },
                            {
                              required: false,
                              message: 'Please input your E-mail!',
                            },
                          ]}
                        >
                          <Input
                            type='text'
                            placeholder='Email'
                            style={{
                              border: 'none',
                              width: '100%',
                              background: 'whitesmoke',
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className='col-12 col-md-6'>
                        <Form.Item
                          name='phone'
                          rules={[
                            {
                              required: true,
                              min: 9,
                              max: 15,
                              message: 'Please enter valid phone',
                            },
                          ]}
                        >
                          <Input
                            type='text'
                            placeholder='Phone'
                            style={{
                              border: 'none',
                              width: '100%',
                              background: 'whitesmoke',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Row>

                    {/* <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="street address" />
                  </div>
                </div>
              </div> */}

                    <Row>
                      {/* <div className="col-md-6">
                  <div className="form-group">
                    <select className="form-control">
                      <option>City</option>
                      <option>City</option>
                      <option>City</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <select className="form-control">
                      <option>State</option>
                      <option>State</option>
                      <option>State</option>
                    </select>
                  </div>
                </div> */}
                      <div className='col-12 col-md-6'>
                        <Form.Item
                          name='zipcode'
                          rules={[
                            {
                              required: true,
                              len: 5,
                              message: 'Enter valid Zip Code',
                            },
                          ]}
                        >
                          <Input
                            type='text'
                            placeholder='Zip Code'
                            style={{
                              border: 'none',
                              width: '100%',
                              background: 'whitesmoke',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-12'>
                        <Form.Item
                          label='Are you currently working?'
                          style={{ fontWeight: '600' }}
                          name='workingStatus'
                        >
                          <Radio.Group>
                            <Radio value='FULL_TIME'>Yes, Full Time</Radio>
                            <Radio value='PART_TIME'>Yes, Part Time</Radio>
                            <Radio value='NO'>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-12 col-md-6 col-xl-4'>
                        <Form.Item name='gender'>
                          <Select
                            placeholder='Gender'
                            bordered={false}
                            style={{
                              width: '100%',
                              background: 'whitesmoke',
                              paddingTop: '4px',
                              paddingBottom: '6px',
                            }}
                          >
                            <Option selected disabled readOnly>
                              Gender
                            </Option>
                            {GENDER_LIST.map((value, index) => {
                              return (
                                <Option value={value} key={`${value}-${index}`}>
                                  {value}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col-12 col-md-6 col-xl-4'>
                        <Form.Item name='race'>
                          <Select
                            placeholder='Mentor Race'
                            bordered={false}
                            style={{
                              width: '100%',
                              background: 'whitesmoke',
                              paddingTop: '4px',
                              paddingBottom: '6px',
                            }}
                          >
                            <Option selected disabled readOnly>
                              Mentor Race
                            </Option>
                            {MENTOR_RACE.map((value, index) => {
                              return (
                                <Option value={value} key={`${value}-${index}`}>
                                  {value}
                                </Option>
                              );
                            })}
                            {/* <option>Race</option>
                      <option>Race</option>
                      <option>Race</option> */}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col-12 col-md-6 col-xl-4'>
                        <Form.Item name='language'>
                          <Select
                            placeholder='Language'
                            bordered={false}
                            style={{
                              width: '100%',
                              background: 'whitesmoke',
                              paddingTop: '4px',
                              paddingBottom: '6px',
                            }}
                          >
                            <Option selected disabled readOnly>
                              Language(s)
                            </Option>
                            {LANGUAGE_LIST.map((value, index) => {
                              return (
                                <Option value={value} key={`${value}-${index}`}>
                                  {value}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-12'>
                        <Form.Item
                          label='Have you been convicted of a felony?'
                          style={{ fontWeight: '600' }}
                          name='convictedOfFelony'
                        >
                          <Radio.Group>
                            <Radio value='true'>Yes</Radio>
                            <Radio value='false'>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-12 px-0'>
                        <div className='col-12'>
                          <Form.Item name='businessExpertise'>
                            {/* <input type="text" className="form-control" placeholder="business expertise" /> */}
                            <Select
                              bordered={false}
                              style={{
                                width: '100%',
                                background: 'whitesmoke',
                                paddingTop: '8px',
                                paddingBottom: '10px',
                              }}
                              placeholder='Choose Business expertise'
                            >
                              <Option selected disabled readOnly>
                                Choose business expertise
                              </Option>
                              {communityList.length
                                ? communityList.map((c) => (
                                    <Option value={c._id} key={c._id}>
                                      {c.name}
                                    </Option>
                                  ))
                                : 'No community Available Please Conatct Admin'}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className='col-12'>
                          <Form.Item name='areaOfInterest'>
                            {/* <input type="text" className="form-control" placeholder="areas of interest" /> */}
                            <Select
                              bordered={false}
                              style={{
                                width: '100%',
                                background: 'whitesmoke',
                                paddingTop: '8px',
                                paddingBottom: '10px',
                              }}
                              placeholder='Choose Area of Interest'
                            >
                              <Option selected disabled readOnly>
                                Choose area of interest
                              </Option>
                              {AREA_OF_INTEREST.map((value, index) => {
                                return (
                                  <Option
                                    value={value}
                                    key={`${value}-${index}`}
                                  >
                                    {value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className='col-12'>
                          <Form.Item name='industry'>
                            {/* <input type="text" className="form-control" placeholder="industry experience" /> */}
                            <Select
                              bordered={false}
                              style={{
                                width: '100%',
                                background: 'whitesmoke',
                                paddingTop: '8px',
                                paddingBottom: '10px',
                              }}
                              placeholder='Choose Industry'
                            >
                              <Option selected disabled readOnly>
                                Choose Industry
                              </Option>

                              {industries &&
                                industries.length &&
                                industries.map((i) => (
                                  <Option value={i.id} key={i.id}>
                                    {i.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className='col-12'>
                          <Form.Item name='notes'>
                            <Input.TextArea
                              rows={3}
                              placeholder='How will your professional experience and interest help you contribute as an Equiliberty Mentor?'
                              style={{
                                border: 'none',
                                background: 'whitesmoke',
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className='col-12'>
                          <Form.Item name='source'>
                            <Input
                              type='text'
                              placeholder='How did you hear about us?'
                              style={{
                                border: 'none',
                                background: 'whitesmoke',
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className='col-12'>
                          <Form.Item
                            label='Would you like to be featured on the homepage if selected?'
                            name='likeToBeFeatured'
                            style={{ fontWeight: '600' }}
                          >
                            <Radio.Group>
                              <Radio value='true'>Yes</Radio>
                              <Radio value='false'>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className='col-12'>
                          <Form.Item>
                            <Button
                              htmlType='submit'
                              size='large'
                              loading={loading}
                              disabled={loading}
                              style={{
                                background: '#18a4ad',
                                color: 'white',
                                width: '100%',
                                height: 'auto',
                                fontSize: '16px',
                              }}
                              className='h-auto py-3'
                            >
                              Apply To Be A Equiliberty Mentor
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

export default MentorRegister;
