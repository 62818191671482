import React from 'react';
import { Modal } from 'antd';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export function AddEditContactPopupModal(props) {
  let { title, contactObj } = props,
    { name, email, phone, address } = contactObj;
  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      okButtonProps={{ title: "Submit" }}
      footer={null}
    // footer={[
    //   <Button key="back" onClick={() => props.onCancel(false)} >
    //     Cancel
    //           </Button>,
    //   <Button key="submit" type="primary" onClick={(event) => props.onOk(event)}>
    //     Submit
    //           </Button>,
    // ]}
    >
      <form onSubmit={(event) => props.onOk(event)} autoComplete="off">
        <div className="form-group">
          <label >Name</label>
          <input type="text" className="form-control" value={name} name="name" onChange={(event) => props.handleInput(event)} required />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input type="email" className="form-control" value={email} name="email" onChange={(event) => props.handleInput(event)} required />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input type="tel" className="form-control" value={phone} name="phone" onChange={(event) => props.handleInput(event)} required />
        </div>
        <div className="form-group">
          <label>Address</label>
          <GooglePlacesAutocomplete
            // apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
            apiKey="AIzaSyBFTEG2DxMcjMh8FUt3lRdXyQ85wJrJj0c"
            // onClick={(event) => handleAddess(event)}
            selectProps={{
              // value,
              address,
              name: 'address',
              onChange: (event) => props.handleAddress(event),
            }}
          />
          {/* <textarea value={address} className="form-control" name="address" onChange={(event) => props.handleInput(event)} required/> */}
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </Modal>
  )
}