import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSurveys,
  submitAnswer,
  getAllSurveys,
  setShowPopup,
  activeSurveySelector,
  showPopupSelector,
} from '../../redux/slices/surveySlice';
import {
  Modal,
  Input,
  Radio,
  Space,
  Select,
  Checkbox,
  Form,
  Button,
  message,
  notification,
} from 'antd';
import TokenIcon from '../../assets/images/token-icon.png';
import './SurveyPopup.scss';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function SurveyPopup({ showOnLoad = true }) {
  const dispatch = useDispatch();
  const surveys = useSelector(getAllSurveys);
  const activeSurvey = useSelector(activeSurveySelector);
  const showPopup = useSelector(showPopupSelector);

  useEffect(() => {
    dispatch(getSurveys());
    if (showOnLoad) dispatch(setShowPopup(true));
  }, []);

  const handleCancel = () => {
    if (activeSurvey.totalAnsweredQuestions === 0)
      return message.warn('Please answer one of the question');
    dispatch(setShowPopup(false));
  };

  return (
    <Modal
      title={activeSurvey?.name}
      visible={showPopup && surveys?.length}
      footer={false}
      onCancel={handleCancel}
    >
      {activeSurvey?.questions?.length && <SurveyForm />}
      {!activeSurvey?.questions?.length && (
        <h5 className='text-center py-5'>No Question Available</h5>
      )}
    </Modal>
  );
}

const openNotification = () => {
  notification.success({
    message: 'Awesome',
    description: 'You just earned 25 tokens!!',
    icon: <img className='spinToken' src={TokenIcon} alt='Wallet-icon' />,
    className: 'customNotification',
  });
};

const SurveyForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const activeSurvey = useSelector(activeSurveySelector);
  const question = activeSurvey?.questions[activeQuestion];
  const isLastQuestion = activeQuestion + 1 === activeSurvey?.questions.length;

  const handleForm = async (values) => {
    const res = await dispatch(
      submitAnswer({
        survey: activeSurvey._id,
        question: question._id,
        answer: values.answer,
      })
    );
    openNotification();
    dispatch(getSurveys());
    if (res.error) {
      setLoading(false);
      return message.error(res.error.message);
    }
    // if (!isLastQuestion) setActiveQuestion(activeQuestion + 1);
    if (isLastQuestion) dispatch(setShowPopup(false));
    form.resetFields();
  };

  return (
    <Form {...layout} form={form} onFinish={handleForm}>
      <h3>{question?.title}</h3>

      <RenderSurveyField question={question} form={form} />
      <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
        <Button type='primary' size='large' htmlType='submit' loading={loading}>
          {isLastQuestion ? 'Submit' : 'Next'}
        </Button>
      </Form.Item>
    </Form>
  );
};

const RenderSurveyField = ({ question, form }) => {
  return (
    <Form.Item
      name='answer'
      rules={[
        {
          required: question?.isRequired,
        },
      ]}
    >
      {question?.inputType === 'TEXT' && (
        <Input
          placeholder='Type Answer here'
          onChange={(e) => form.setFieldsValue({ answer: e.target.value })}
        />
      )}

      {question?.inputType === 'RADIO' && (
        <Radio.Group
          onChange={(e) => form.setFieldsValue({ answer: e.target.value })}
        >
          <Space direction='vertical'>
            {question?.options.map((option) => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )}

      {question?.inputType === 'SELECT' && (
        <Select
          style={{ width: '100%' }}
          onChange={(val) => {
            form.setFieldsValue({ answer: val });
          }}
        >
          {question?.options.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      )}

      {question?.inputType === 'CHECKBOX' && (
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(values) =>
            form.setFieldsValue({ answer: values.join(', ') })
          }
        >
          <Space direction='vertical'>
            {question?.options.map((option) => (
              <Checkbox key={option} value={option}>
                {option}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      )}
    </Form.Item>
  );
};
