import React from 'react';
import { Modal, Button } from 'antd';



export function FileUploadModal(props) {
    let { title ,isMultiple,showPageLoader} = props;
    return (
        <React.Fragment>
            <Modal
                title={title}
                centered
                visible={props.visible}
                onOk={(event) => props.onOk(event)}
                onCancel={() => props.onCancel(false)}
                okButtonProps={{ title: "Submit" }}
                footer={null}
            >
                <form onSubmit={(event) => props.onOk(event)}>
                    <div className="row mb-4">
                        <div className="col-md-8">
                            <label>File Upload</label>
                            <input type="file" onChange={(event) => props.handleFileInput(event)} multiple={isMultiple}/>
                        </div>
                    </div>
                    <Button type="primary" htmlType="submit" loading={showPageLoader}>Submit</Button>
                </form>
            </Modal>
        </React.Fragment>
    )
}
