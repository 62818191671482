import React, {
    useState
} from 'react';
import {
    Button,
    message,
    Input, Form, Checkbox
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS
} from '../../../shared/constants';

// API END POINTS
import {
    adminCreateQuestion
} from '../../../../redux/slices/adminSlice';

import {
    header
} from '../../../../api/rest';


var {
    ERROR_MESSAGE
} = CONSTANTS;

function AdminQuestionAnswer() {
    // const dispatch = useDispatch();
    // const loginUserData = useSelector(state => state.user);
    const storageData = localStorage.getItem("userData");
    const token = storageData ? JSON.parse(storageData).token : '';
    const [isLast, setIsLast] = useState(false)
    const [form] = Form.useForm();

    function createQuestionForUser(data) {
        header.Authorization = `Bearer ${token}`;
        adminCreateQuestion(data, header).then(res => {
            message.success(res.message);
        }).catch(err => {
            message.error(err && err.data.message ? err.data.message : ERROR_MESSAGE)
        })
    }

    function submitForm(values) {
        let questionOptions = values.options.split(','),
            questionOptionsStatus = questionOptions.length > 5 ? false : true;
        if (questionOptionsStatus) {
            let data = {
                question: values.question,
                options: questionOptions,
                isLast
            };
            createQuestionForUser(data);
        } else {
            message.error('Maximum 5 option can be Entered')
        }
    }

    function onChange(e) {

        setIsLast(e.target.checked);
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                layout="vertical"
                onFinish={submitForm}
            >
                <Form.Item label="Question" name="question" required>
                    <Input placeholder="input placeholder" />
                </Form.Item>
                <Form.Item label="Please Input Option" name="options" required>
                    <Input placeholder="input placeholder" />
                </Form.Item>
                <Form.Item label="Last question" name="isLast" required>
                    <Checkbox checked={isLast} onChange={onChange} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" >Submit</Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    )
}
export default AdminQuestionAnswer
