// reducers.js
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './slices/authSlice';
import postReducer from './slices/dashboardSlice';
import surveyReducer from './slices/surveySlice';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user: authReducer,
    post: postReducer,
    survey: surveyReducer,
  });
