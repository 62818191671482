// import { message } from "antd";
import {
    apiGetMethod,
    apiPostMethod,
    apiDeleteMethod,
    //apiPutMethod,  post, get, apiPostMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import { API_END_POINTS_CONSTANT } from '../../components/shared/constants';

let {
    CREATE_ADMIN_USER,
    UPDATE_ADMIN_USER,
    ADMIN_USER_LIST,
    UPDATE_ADMIN_USER_STATUS,
    ADMIN_USER_DELETE,
    CONVERT_TO_LOCAL_LIBERATOR
} = API_END_POINTS_CONSTANT;

export const createAdminUser = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CREATE_ADMIN_USER, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateAdminUser = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(UPDATE_ADMIN_USER, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateAdminUserStatus = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(UPDATE_ADMIN_USER_STATUS, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export const getAdminUserList = (data, header) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${ADMIN_USER_LIST}?page=${data.pageNumber}&limit=${data.limit}&role=${data.role}`, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const deleteAdminUserStatus = (data, header) => {
    return new Promise((resolve, reject) => {
        apiDeleteMethod(`${ADMIN_USER_DELETE}/${data.id}`, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export const convertUserToLocalLiberator = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CONVERT_TO_LOCAL_LIBERATOR, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}