import React from "react";
import { Carousel, Avatar } from "antd";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import "./MentorsCarousel.scss";

function MentorsCarousel({ images }) {

  return (
    <div className="mentorCarousel">
      <Carousel
        slidesToShow={4}
        autoplay
        arrows
        draggable
        pauseOnFocus
        accessibility
        lazyLoad="ondemand"
        nextArrow={<CaretRightOutlined />}
        prevArrow={<CaretLeftOutlined />}
      >
        {images.map((image, idx) => (
          <div key={idx}>
            <Avatar src={image} size={250} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default React.memo(MentorsCarousel);
