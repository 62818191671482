import React from 'react';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Avatar,
  message
} from 'antd';
import {
  // useSelector,
  useDispatch
} from "react-redux";
import {
  history
} from '../../../../redux/store';
// ADMIN API
import {
  header
} from '../../../../api/rest';
import {
  logoutApi,
  logout
} from '../../../../redux/slices/authSlice';
import {
  CONSTANTS
} from '../../../shared/constants';
// CSS
import "./adminHeader.css";

const { Header } = Layout;
const { SubMenu } = Menu;
const {
  LOG_OUT_MESSAGE,
} = CONSTANTS

function AdminHeader({ collapsed, handleOnCollapse }) {
  const dispatch = useDispatch();
  // const userData = useSelector(state => state.user);
  const storageData = localStorage.getItem("userData");
  const role = storageData ? JSON.parse(storageData).payload : '';
  const roleId = role ? role.id : '';
  const token = storageData ? JSON.parse(storageData).token : '';
  const firstName = role ? role.firstName : '';
  const getCollapseIcon = () => {
    if (collapsed) {
      return (
        <MenuUnfoldOutlined onClick={handleOnCollapse} className="trigger" />
      );
    }
    return <MenuFoldOutlined onClick={handleOnCollapse} className="trigger" />;
  };

  // const handleLanguageMenuClick = () => { };
  const handleSettingMenuClick = () => { };
  const handleLogout = () => {
    header.Authorization = token;
    let data = {
      userId: roleId
    };
    logoutApi(data, header).then(res => {
      dispatch(logout());
    }).catch(err => {
      dispatch(logout());

    })
    success();
  }
  const success = () => {
    message.success(LOG_OUT_MESSAGE, 5);
    history.push("/");
  };

  return (
    <Header className="admin_header" style={{ background: '#fff', padding: 0 }}>
      <div
        style={{
          float: 'left',
          width: '100%',
          alignSelf: 'center',
          display: 'flex',
        }}
      >
        {window.innerWidth > 992 && getCollapseIcon()}
      </div>
      {/* <Menu
        // onClick={this.handleLanguageMenuClick}
        mode="horizontal"
        className="menu"
      >
        <SubMenu title={<QuestionCircleOutlined />} />
      </Menu>
      <Menu
        // onClick={this.handleLanguageMenuClick}
        mode="horizontal"
        className="menu"
      >
        <SubMenu
          title={
            <Badge dot>
              <BellOutlined />
            </Badge>
          }
        />
      </Menu>
      <Menu
        onClick={handleLanguageMenuClick}
        mode="horizontal"
        className="menu"
      >
        <SubMenu title={<GlobalOutlined />}>
          <Menu.Item key="en">
            <span role="img" aria-label="English">
              🇺🇸 English
            </span>
          </Menu.Item>
          <Menu.Item key="it">
            <span role="img" aria-label="Italian">
              🇮🇹 Italian
            </span>
          </Menu.Item>
        </SubMenu>
      </Menu> */}
      <Menu onClick={handleSettingMenuClick} mode="horizontal" className="menu">
        <SubMenu title={getUsernameAvatar(firstName)}>
          <Menu.Item key="setting:1">
            <span>
              <UserOutlined />
              Profile
            </span>
          </Menu.Item>
          <Menu.Item key="setting:2" onClick={() => handleLogout()}>
            <span>
              <LogoutOutlined />
              Logout
            </span>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  );
}

function getColor(username) {
  const colors = [
    '#ffa38a',
    '#a9a7e0',
    '#D686D4',
    '#96CE56',
    '#4A90E2',
    '#62b3d0',
    '#ef7676',
  ];
  const firstChar = username.charCodeAt(0);
  const secondChar = username.charCodeAt(1);
  const thirdChar = username.charCodeAt(2);

  return colors[(firstChar + secondChar + thirdChar) % 7];
}

const getUsernameAvatar = (username, size = 'large') => {
  return (
    <div>
      <Avatar
        style={{
          backgroundColor: getColor(username),
          verticalAlign: 'middle',
        }}
        size={size}
      >
        {username ? username.charAt(0).toUpperCase() : ''}
      </Avatar>
    </div>
  );
};

export default AdminHeader;
