import {
    // post, get ,
    apiGetMethod,
    apiPostMethod,
    // apiPutMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import { API_END_POINTS_CONSTANT } from '../../components/shared/constants';
var {
    GET_PRIVATE_MESSAGE,
    NEW_CHAT,
    REPLY_CHAT,
    ROOM_ID_MESSAGES,
    CREATE_ROOM,
    GET_PREVIOUS_CHAT,
    TOK_BOX_VIDEO_CHAT,
    GET_TOK_BOX_SESSION,
    DELETE_TOK_BOX_SESSION,
    CREATE_ROOM_FOR_LIBERAL,
    MESSAGE_LIST,
    RECENT_MESSAGES_LIBERAL
} = API_END_POINTS_CONSTANT;

export const listPreviousUserChat = (senderId, recipientId, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_PRIVATE_MESSAGE}?senderId=${senderId}&recipientId=${recipientId}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const startNewChat = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(NEW_CHAT, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const replyToChat = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(REPLY_CHAT, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const createRoomForMessage = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CREATE_ROOM, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const messageOnTheBasisRoomId = (data, token) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${ROOM_ID_MESSAGES}/${data.roomId}?page=${data.pageNumber}&limit=${data.limit}`, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getPreviousChat = (data, token) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_PREVIOUS_CHAT}/${data.id}?page=${data.pageNumber}&limit=${data.limit}`, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getTokBoxSession = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(TOK_BOX_VIDEO_CHAT, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getTokSession = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(GET_TOK_BOX_SESSION, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const deleteTokBoxSession = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(DELETE_TOK_BOX_SESSION, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};


// LIBERATOR CHAT APIS
export const createRoomForLiberal = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CREATE_ROOM_FOR_LIBERAL, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const messageOnTheBasisLiberalRoomId = (data, token) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${MESSAGE_LIST}/${data.roomId}?page=${data.pageNumber}&limit=${data.limit}`, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getRecentMessageListOfLiberal = (data, token) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${RECENT_MESSAGES_LIBERAL}/${data.id}?page=${data.pageNumber}&limit=${data.limit}`, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};