import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Input, Row, Layout, } from "antd";
import Container from "../../widgets/container";

import style from './forgotpassword.module.sass'
import './forgot-password.scss'
import { history } from "../../../redux/store";
import { setLoading as running, forgotPassword } from "../../../redux/slices/authSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // checking if network error
    if (session.data.name && session.data.name === "Error") {
    } else {
      // if email verification message recieved then it will redirect to home
      if (loading && !session.loading && session.data.message) {
        // message.success(session.data.message);
        history.push('/');
      }
    }
    setLoading(session.loading);
  }, [loading, session]);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    dispatch(running(true));
    const body = {
      email: values.email,
    }
    dispatch(forgotPassword(body));
  };

  const onFinishFailed = errorInfo => {
    //
  };

  React.useEffect(() => {
    document.title = "Forgot Password";
  })

  return (
    <Layout.Content className={`${style.block} min-height-70vh`}>
      <Row className={`header-divider`} />
      <Container className={`auth-modal ${style.reg_container}`}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="forgot-password-form"
          >
            <h3>Forgot Password</h3>
          <Form.Item
            label={"Email"}
            name="email"
            rules={[
              {
                required: true,
                message: ' Please enter a valid email address',
                type:'email'
              },
            ]}>
            <Input />
          </Form.Item>
          {/* <Row gutter={8}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"First Name"}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input your First Name!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Last Name"}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          </Row> */}
          {/* <Form.Item
            label={"Password"}
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={"Confirm Password"}
            name="password_confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item> */}
          <Row justify={'center'}>
            <Button type="primary" htmlType="submit" className={style.submit_btn} loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Container>
    </Layout.Content>
  )
}

export default ForgotPassword;
