import React from 'react';
import { Modal, Button, Form } from 'antd';
import logo from '../../../assets/images/equiliberty-logo.png';
import './mentor-modal.scss'

export function ChooseMentorModal(props) {
    let {
        title,
        //   description,
        loading,
        mentorInfo
    } = props;
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    return (
        <React.Fragment>
            <Modal
                title={title}
                centered
                visible={props.visible}
                onOk={(event) => props.onOk(event)}
                onCancel={() => props.onCancel(false)}
                okButtonProps={{ title: "Submit" }}
                width={1000}
                footer={null}>
                <div className="mentor-popup">
                    <Form
                        {...layout}
                        onFinish={() => props.onOk(mentorInfo ? mentorInfo.id : '')}>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="mentor-img">
                                    <img src={mentorInfo ? mentorInfo.userPhoto : logo} alt={mentorInfo ? mentorInfo.userPhoto : logo} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <h2>{mentorInfo ? mentorInfo.firstName + ' ' + mentorInfo.lastName : ''}</h2>
                                <h4>{mentorInfo ? mentorInfo.email : ''}</h4>
                                <h4 className="text-capitalize">{mentorInfo ? mentorInfo.city : ''}</h4>
                                <h5>{mentorInfo ? mentorInfo.currentPosition : ''} </h5>
                                <h6> {mentorInfo ? mentorInfo.companyName : ''}</h6>
                                <p>{mentorInfo ? mentorInfo.bio : ''}</p>
                            </div>
                        </div>
                        <div className="row justify-content-right">
                            <div className="col-12">
                                <div className="register-mentor-btn">
                                    <Button type="primary" htmlType="submit" loading={loading}>Connect with Mentor</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </React.Fragment>
    )
}
// export default ChooseMentorModal;