import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import App from './App'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './redux/store'
import Base from "./components/screens/base/base"

const store = configureStore(/* provide initial state if any */)

ReactDOM.render(
  <Provider store={store}>
    <Base>
      <ConnectedRouter history={history}>
      <App/>
      </ConnectedRouter>
    </Base>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
