import React from 'react';
import {
    Modal,
    Button,
    Form
} from 'antd';
import ComingSoon from '../../../assets/images/coming-soon.svg';
import './communitystatusmodal.css'

export function CommunityStatusModal(props) {
    let {
        title,
        description,
        activeZipCodeStatus
    } = props;
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    return (
        <React.Fragment>
            <Modal
                title={title}
                centered
                visible={props.visible}
                onOk={(event) => props.onOk(event)}
                onCancel={() => props.onCancel(false)}
                okButtonProps={{ title: "Submit" }}
                footer={null}>

                <Form
                    {...layout}
                    onFinish={(event) => props.onOk(event)}>
                    <div className="row">
                        <div className="col-12 text-center">
                            {activeZipCodeStatus ? '' : <img src={ComingSoon} alt="ComingSoon" className="coming-soon-img" />}
                        </div>
                    </div>
                    <div className="row mt-4"><div className="col-12 text-center"><p>{description}</p></div></div>
                    <div className="row mt-4"><div className="col-12 text-center"><Button type="primary" htmlType="submit">Ok</Button></div></div>
                </Form>
            </Modal>
        </React.Fragment>
    )
}
