import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { httpPost, apiGetMethod } from '../../api/rest';

const surveyAdapter = createEntityAdapter();

const initialState = surveyAdapter.getInitialState({
  loading: false,
  surveys: [],
  completed: [],
  pending: [],
  activeSurvey: '',
  showPopup: false,
});

export const getSurveys = createAsyncThunk(
  'surveys/getSurveys',
  async () => await apiGetMethod(`/surveys`)
);

export const submitAnswer = createAsyncThunk(
  'surveys/submitAnswer',
  async (answer) =>
    httpPost(`/surveys/questions/${answer.question}/answer`, answer)
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    // loading: (state, action) => {
    //   state.loading = action.payload;
    // },
    setActiveSurvey: (state, action) => {
      state.activeSurvey = action.payload;
    },
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    // setPendingSurveys: (state, action) => {
    //   state.pending = action.payload;
    // },
    // setCompletedSurveys: (state, action) => {
    //   state.pending = action.payload;
    // },
    // setPendingSurveys: (state, action) => {
    //   state.pending = action.payload;
    // },
  },
  extraReducers: {
    [getSurveys.fulfilled]: (state, action) => {
      state.surveys = action.payload;
      state.activeSurvey = action.payload[0]?.id;
      state.loading = false;
    },
    [getSurveys.pending]: (state) => {
      state.loading = true;
    },
  },
});

export default surveySlice.reducer;

// Action creators are generated for each case reducer function
export const { setActiveSurvey, setShowPopup } = surveySlice.actions;

export const survey = (state) => state?.survey;
export const getAllSurveys = createSelector(survey, (survey) => survey.surveys);
export const activeSurveySelector = createSelector(
  getAllSurveys,
  survey,
  (surveys, state) => surveys.find((item) => item.id === state.activeSurvey)
);
export const showPopupSelector = createSelector(
  survey,
  (survey) => survey.showPopup
);

// export const showPopup = (state)
// export function getAllSurveys(state){
//   return state
// }
// export const { selectAll: getAllSurveys, selectById: selectTodoById } =
//   surveyAdapter.getSelectors((state) => state.surveys);
