import React, {
    useEffect,
    useState
} from 'react';
import {
    Table,
    Space,
    Button,
    message
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS,
    POP_UP_MODAL_CONSTANTS,
    POP_UP_MODAL_HEADER_CONSTANTS
} from '../../../shared/constants';
// API END POINTS
import {
    addMentorCategory
} from '../../../../redux/slices/adminSlice';
import {
    updateAdminMentorCategory,
    deleteAdminMentorCategory
} from '../../../../redux/slices/adminCategorySlice';
import {
    listMentorCategory
} from '../../../../redux/slices/registerSlice';
import {
    header
} from '../../../../api/rest';
import {
    history
} from '../../../../redux/store';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
// CSS
import './admincategory.scss';

var {
    ERROR_MESSAGE
} = CONSTANTS;

var {
    CONFIRMATION_POPUP_MODAL,
    ADD_EDIT_ADMIN_CATEGORY
} = POP_UP_MODAL_CONSTANTS;
var {
    CONFIRMATION_HEADER,
    ADD_CATEGORY,
    EDIT_CATEGORY
} = POP_UP_MODAL_HEADER_CONSTANTS;

function AdminCategory() {
    // const dispatch = useDispatch();
    // const loginUserData = useSelector(state => state.user);
    const storageData = localStorage.getItem("userData");
    // const payload = storageData ? JSON.parse(storageData).payload : '';
    const token = storageData ? JSON.parse(storageData).token : '';
    const [earnToken, setEarnToken] = useState([]);
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });

    const [currentEntry, setCurrentEntry] = useState({
        id: '',
        name: ''
    })
    const [description, setDescription] = useState('');
    const [showPageLoader, setShowPageLoader] = useState(true);
    // const [communityData, setCommunityData] = useState({
    //     communityPhoto: '',
    //     communityVideo: ''
    // });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button className="delete-category-btn" onClick={() => fillUserInfoInModal(record, 'delete')}>Delete Category</Button>
                    <Button className="update-category-btn" onClick={() => fillUserInfoInModal(record, 'update')}>Update Category</Button>
                </Space>
            ),
        }
    ];

    useEffect(() => {
        if (token) {
            getCommunityList();
        } else {
            history.push('/');
            // message.error(TOKEN_EXPIRED_MESSAGE);
        }
    }, []);

    function fillUserInfoInModal(data, type) {
        if (type === 'update') {
            toggleModal(ADD_EDIT_ADMIN_CATEGORY, EDIT_CATEGORY)
        }
        if (type === 'delete') {
            toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
            setDescription(`Are you sure to remove ${data.name}`)
        }
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: data.id,
                name: data.name
            }
        });
    }


    // calling toggle modal api
    function toggleModal(type, header) {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: header
            }
        });
    }

    // calling close popup modal function which close popup modal
    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: '',
                name: ''
            }
        });
    }
    // handle file input
    function handleFileInput(event) {
        let { files, name } = event.target;

    }

    function onSubmitPopUpModal(event) {
        setShowPageLoader(true);
        if (popUpModal.modalHeader === ADD_CATEGORY) {
            addCategoryyApi(event);
        }
        if (popUpModal.modalHeader === EDIT_CATEGORY) {
            updateCategoryApi(event);
        }
        if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
            deleteCategoryApi();
        }
    }

    // get community list form api
    function getCommunityList() {
        header.Authorization = `Bearer ${token}`;
        listMentorCategory(header).then(res => {
            // save token earn status
            setEarnToken(res.data);
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
        })
    }

    // calling add category api
    function addCategoryyApi(data) {
        header.Authorization = `Bearer ${token}`;
        addMentorCategory(data, header).then(res => {
            message.success(res.message);
            getCommunityList();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }
    // calling update category api
    function updateCategoryApi(values) {
        header.Authorization = `Bearer ${token}`;
        let data = { categoryId: currentEntry.id, ...values }
        updateAdminMentorCategory(data, header).then(res => {
            message.success(res.message);
            getCommunityList();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }
    // calling delete category api
    function deleteCategoryApi() {
        header.Authorization = `Bearer ${token}`;
        let data = { id: currentEntry.id };
        deleteAdminMentorCategory(data, header).then(res => {
            message.success(res.message);
            getCommunityList();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    // calling close popup modal function which close popup modal
    // function closePopupModal() {
    //     setPopUpModal(prevPopUpModal => {
    //         return {
    //             ...prevPopUpModal,
    //             visible: false,
    //             type: '',
    //             modalHeader: ''
    //         }
    //     });
    //     setCurrentEntry(prevCurrentEntry => {
    //         return {
    //             ...prevCurrentEntry,
    //             id: '',
    //             name: '',
    //             description: '',
    //             city: '',
    //             zipCode: '',
    //         }
    //     });
    // }

    let addEditCommunityProps = {
        currentEntry
    }, addEditCommunityFunctionProps = {
        handleFileInput: handleFileInput
    };

    return (
        <React.Fragment>
            {showPageLoader ? <Loader /> : ''}
            <div className="row mt-4 mb-2">
                <div className="col-12 text-right">
                    <Button onClick={() => toggleModal(ADD_EDIT_ADMIN_CATEGORY, ADD_CATEGORY)}>Add Category</Button>
                </div>
            </div>
            {
                popUpModal.visible ?
                    <PopUpModal
                        title={popUpModal.modalHeader}
                        visible={popUpModal.visible}
                        onCancel={closePopupModal}
                        onOk={onSubmitPopUpModal}
                        type={popUpModal.type}
                        description={description}
                        {...addEditCommunityProps}
                        {...addEditCommunityFunctionProps}
                    /> : ''
            }
            <Table dataSource={earnToken} columns={columns} />
        </React.Fragment>
    )
}
export default AdminCategory;
