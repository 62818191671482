import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  Space,
  Tag,
  Button,
  message,
  Switch,
  Select,
  Descriptions,
  Modal,
  Form,
  Input,
  Row,
} from 'antd';
import { header } from '../../../../api/rest';
import { history } from '../../../../redux/store';
import {
  getQuestionsApi,
  createQuestionApi,
  deleteQuestionApi,
  editQuestionApi,
} from '../../../../redux/slices/adminSlice';
import { Loader } from '../../../shared/Loader';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const { Option } = Select;

export default function Questions(props) {
  const storageData = localStorage.getItem('userData');
  const token = storageData ? JSON.parse(storageData).token : '';
  const [questions, setQuestions] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createQuestionModal, setCreateQuestionModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
    const [inputType, setInputType] = useState('TEXT');
  const [form] = Form.useForm();
  const surveyId = props.match.params.surveyId;
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Info',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'Input Type',
      dataIndex: 'inputType',
      key: 'inputType',
    },
    {
      title: 'Survey',
      dataIndex: ['survey', 'name'],
      key: 'survey.name',
    },

    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (text) =>
        text ? (
          <Tag color='green'>Active</Tag>
        ) : (
          <Tag color='red'>Disabled</Tag>
        ),
    },
    {
      title: 'Required',
      dataIndex: 'isRequired',
      key: 'isRequired',
      render: (text) =>
        text ? (
          <Tag color='green'>Required</Tag>
        ) : (
          <Tag color='red'>Optional</Tag>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            type='primary'
            disabled={deleteLoading}
            onClick={() => {
              handleEdit(record);
            }}
          >
            Edit
          </Button>
          <Button
            type='primary'
            danger
            disabled={deleteLoading}
            onClick={() => {
              deleteSurvey(record.id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = useCallback(async () => {
    if (token) {
      header.Authorization = `Bearer ${token}`;
      getQuestionsApi(surveyId, header)
        .then((res) => {
          setQuestions(res);
          setShowPageLoader(false);
        })
        .catch((err) => {
          message.error(err?.message ? err.message : 'something went wrong');
        });
    } else {
      history.push('/');
    }
  }, [token]);

  const createQuestion = async (values) => {
    setLoading(true);
    try {
      await createQuestionApi(surveyId, values, {
        Authorization: `Bearer ${token}`,
      });
      message.success('Question created');
      setCreateQuestionModal(false);
      getQuestions();
      form.resetFields();
    } catch (err) {
      message.error(err?.message ? err.message : 'something went wrong');
    }
    setLoading(false);
  };

  const deleteSurvey = async (id) => {
    setDeleteLoading(true);
    try {
      await deleteQuestionApi(id, { Authorization: `Bearer ${token}` });
      message.success('Question Deleted');
      getQuestions();
    } catch (err) {
      message.error(err?.message ? err.message : 'something went wrong');
    }
    setDeleteLoading(false);
  };

  const handleEdit = async (record) => {
    form.setFieldsValue({
      title: record.title,
      description: record.description,
      info: record.info,
      inputType: record.inputType,
      isActive: record.isActive,
      options: record.options,
      inputType: record.inputType,
      isRequired: record.isRequired,
    });
    setIsEdit(record.id);
    setInputType(record.inputType);
    setCreateQuestionModal(true);
  };

  const handleEditQuestion = async (values) => {
    try {
      await editQuestionApi(isEdit, values, {
        Authorization: `Bearer ${token}`,
      });
      await getQuestions();
      message.success('Question Updated');
      setIsEdit(false);
      setCreateQuestionModal(false);
      form.resetFields();
    } catch (err) {
      message.error(err?.message ? err.message : 'something went wrong');
    }
  };

  return (
    <>
      {showPageLoader ? <Loader /> : ''}
      <div className='row mt-4 mb-2'>
        <div className='col-12 text-right'>
          <Button onClick={() => setCreateQuestionModal(true)}>
            Add Question
          </Button>
        </div>
      </div>
      <Table
        dataSource={questions}
        columns={columns}
        rowKey={(record) => record.id}
      />

      <Modal
        title={isEdit ? 'Edit Question' : 'Add Question'}
        centered
        visible={createQuestionModal}
        okButtonProps={{ title: 'Submit' }}
        onCancel={() => {
          setCreateQuestionModal(false);
          setIsEdit(false);
        }}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          onFinish={isEdit ? handleEditQuestion : createQuestion}
        >
          <Form.Item
            label={'Title'}
            name='title'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size='small' />
          </Form.Item>
          <Form.Item label={'Description'} name='description'>
            <Input />
          </Form.Item>
          <Form.Item label={'Info'} name='info'>
            <Input />
          </Form.Item>
          <Form.Item label={'Question Type'} name='inputType'>
            <Select
              defaultValue='TEXT'
              onChange={(val) => { form.setFieldsValue({ inputType: val }); setInputType(val)}}
            >
              <Option value='TEXT'>Text</Option>
              <Option value='SELECT'>Dropdown</Option>
              <Option value='RADIO'>Radio</Option>
              <Option value='CHECKBOX'>Checkbox</Option>
            </Select>
          </Form.Item>
          {inputType !== 'TEXT' && (
            <Form.List name='options'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <div className='d-flex align-items-start'>
                      <Form.Item
                        {...field}
                        key={field.key}
                        // name={[field.name, 'option']}
                        // fieldKey={[field.fieldKey, 'option']}
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder='Enter Option'
                          style={{
                            padding: '5px 10px',
                            border: '1px solid #d9d9d9',
                          }}
                        />
                      </Form.Item>

                      <MinusCircleOutlined
                        style={{ fontSize: 25, marginLeft: 10 }}
                        onClick={() => remove(field.name)}
                      />
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Option
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          <Form.Item
            label={'Active'}
            name='isActive'
            labelCol={{ span: 3, offset: 0 }}
          >
            <Switch defaultChecked />
          </Form.Item>
          <Form.Item
            label={'Required'}
            name='isRequired'
            labelCol={{ span: 4, offset: 0 }}
          >
            <Switch defaultChecked />
          </Form.Item>

          <Row justify={'center'}>
            <Button type='primary' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
