import React,
{
    useEffect,
    useState
} from 'react';
import {
    Table,
    message,
    Space,
    Button
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS,
    POP_UP_MODAL_CONSTANTS,
    POP_UP_MODAL_HEADER_CONSTANTS
} from '../../../shared/constants';
// API END POINTS
import {
    addTaskListToGetToken,
    updateTaskListToGetToken,
    deleteTaskListToGetToken
} from '../../../../redux/slices/adminSlice';
import {
    getTokenListForUser
} from '../../../../redux/slices/walletSlice'
import {
    header
} from '../../../../api/rest';
import {
    history
} from '../../../../redux/store';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import { Loader } from '../../../shared/Loader';
// CSS
import './adminwallet.scss';

var {
    ERROR_MESSAGE,
    INVALID_USER
} = CONSTANTS;

var {
    CONFIRMATION_POPUP_MODAL,
    ADD_EDIT_EARN_WALLET
} = POP_UP_MODAL_CONSTANTS;
var {
    CONFIRMATION_HEADER,
    CREATE_WALLET_EARNING,
    EDIT_WALLET_EARNING
} = POP_UP_MODAL_HEADER_CONSTANTS;

function AdminWallet() {
    // const dispatch = useDispatch();
    // const loginUserData = useSelector(state => state.user);
    const storageData = localStorage.getItem("userData");
    const payload = storageData ? JSON.parse(storageData).payload : '';
    const token = storageData ? JSON.parse(storageData).token : '';
    const [earnToken, setEarnToken] = useState([]);
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });
    const [currentEntry, setCurrentEntry] = useState({
        id: '',
        tokenName: '',
        description: '',
        value: '',
    })
    const [description, setDescription] = useState('');
    const [showPageLoader, setShowPageLoader] = useState(true);
    const columns = [
        {
            title: 'Token Name',
            dataIndex: 'tokenName',
            key: 'tokenName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button className="delete-wallet-btn" onClick={() => fillUserInfoInModal(record, 'delete')}>Delete Wallet</Button>
                    <Button className="update-wallet-btn" onClick={() => fillUserInfoInModal(record, 'update')}>Update Wallet</Button>
                </Space>
            ),
        }
    ];

    function fillUserInfoInModal(data, type) {
        if (type === 'update') {
            toggleModal(ADD_EDIT_EARN_WALLET, EDIT_WALLET_EARNING)
        }
        if (type === 'delete') {
            toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
            setDescription(`Are you sure to remove ${data.tokenName}`)
        }

        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: data.id,
                tokenName: data.tokenName,
                description: data.description,
                value: data.value,
            }
        });
    }

    useEffect(() => {
        if (token) {
            if (payload.role === 'admin') {
                getListResourceApi();
            } else {
                message.error(INVALID_USER)
                history.push('/')
            }
        } else {
            history.push('/');
            // message.error(TOKEN_EXPIRED_MESSAGE);
        }
    }, [])

    function addResource(value) {
        header.Authorization = `Bearer ${token}`;
        let data = value;
        addTaskListToGetToken(data, header).then(res => {
            message.success(res.message);
            getListResourceApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function updateResourceApi(value) {
        header.Authorization = `Bearer ${token}`;
        let data = {
            Id: currentEntry.id,
            ...value
        }
        updateTaskListToGetToken(data, header).then(res => {
            message.success(res.message);
            getListResourceApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function deleteResourceApi(value) {
        header.Authorization = `Bearer ${token}`;
        let data = {
            id: value.id
        }
        deleteTaskListToGetToken(data, header).then(res => {
            message.success(res.message);
            getListResourceApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function getListResourceApi() {
        header.Authorization = token;
        getTokenListForUser(header).then(res => {
            if (res.data) {
                setEarnToken(res.data)
            } else {
                setEarnToken([])
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    function onSubmitPopUpModal(event) {
        setShowPageLoader(true);
        // event.preventDefault();
        if (popUpModal.modalHeader === CREATE_WALLET_EARNING) {
            addResource(event);
        }
        if (popUpModal.modalHeader === EDIT_WALLET_EARNING) {
            updateResourceApi(event);
        }
        if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
            deleteResourceApi(currentEntry)
        }
        // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
    }

    function toggleModal(type, header) {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: header
            }
        });
    }

    // close poup modal
    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: '',
                tokenName: '',
                description: '',
                value: '',
            }
        });
    }

    let addEditLiberatorProps = {
        currentEntry
    }

    return (
        <React.Fragment>
            {showPageLoader ? <Loader /> : ''}
            {
                popUpModal.visible ?
                    <PopUpModal
                        title={popUpModal.modalHeader}
                        visible={popUpModal.visible}
                        onCancel={closePopupModal}
                        onOk={onSubmitPopUpModal}
                        type={popUpModal.type}
                        description={description}
                        {...addEditLiberatorProps}
                    /> : ''
            }
            <div className="row mt-4 mb-2">
                <div className="col-12 text-right">
                    <Button onClick={() => toggleModal(ADD_EDIT_EARN_WALLET, CREATE_WALLET_EARNING)}>Add Wallet Earning</Button>
                </div>
            </div>
            <Table dataSource={earnToken} columns={columns} />
        </React.Fragment>
    )
}

export default AdminWallet;