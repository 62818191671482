import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';
import {
    apiGetMethod,
    apiPostMethod,
    apiDeleteMethod,
    apiPutMethod
    //  post, get, apiPostMethod
} from '../../api/rest';
var {
    CREATE_RESOURCE,
    GET_LIST_RESOURCE,
    DELETE_RESOURCE,
    UPDATE_RESOURCE
} = API_END_POINTS_CONSTANT;

export const createResource = (data, header) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CREATE_RESOURCE, data, header).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getListResource = (headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(GET_LIST_RESOURCE, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const deleteResource = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiDeleteMethod(`${DELETE_RESOURCE}?resourcesId=${data.id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateResource = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPutMethod(`${UPDATE_RESOURCE}/${data.id}`, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}