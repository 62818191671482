import {
    // post, get ,
    apiGetMethod
} from '../../api/rest';
import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

var {
    MENTOR_REMINDER_lIST,
    MENTOR_ID_BY_EMAIL,
    GET_USER_BY_EMAIL
} = API_END_POINTS_CONSTANT;

export const getMentorReminderList = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${MENTOR_REMINDER_lIST}?mentorId=${data.id}&currentDate=${data.date}&days=${data.day}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getMentorIdByEmail = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${MENTOR_ID_BY_EMAIL}?email=${data}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
export const getMentorUserIdByEmail = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_USER_BY_EMAIL}?email=${data}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getFeaturedMentors = () => {
    return new Promise((resolve, reject) => {
        apiGetMethod('/mentors/featured').then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
