import React from 'react';
import Container from '../../widgets/container';
import style from './contact.module.sass';
// import item1 from '../../../assets/images/banner.jpg';
// import MailFilled from "@ant-design/icons/lib/icons/MailFilled";
// import PhoneFilled from "@ant-design/icons/lib/icons/PhoneFilled";
// CSS
import './contact.scss';

export default (props) => {
  React.useEffect(() => {
    document.title = "Contact Us";
  })
  return (
    <Container fluid={true} gutter={false} className={style.contactContainer}>
      {/* <div className={style.contactBannerWrapper}>
        <img src={item1} alt="" className={style.contactBannerImg} />
        <div className={style.contactWrapper}>
          <p className={style.contactText}>Contact</p>
        </div>
      </div>
      <div className={style.contactContent}>
        <p className={style.contactInfoTitle}>Contact <span>Info</span></p>
        <p className={style.contactInfoText}>
          You have just invested in a new vehicle, the one of your dreams.
          Where you take it is up to you, let us help you get it off the lot.
          </p>
        <div className={style.infoRow}>
          <MailFilled className={style.infoIcon} />
          <p>
            {`Email\n`}<a href="mailto:info@findequiliberty.com">info@findequiliberty.com</a>
          </p>
        </div>
        <div className={style.infoRow}>
          <PhoneFilled className={style.infoIcon} />
          <p>
            {`Phone\n`}<a href="tel:281-819-6753">281-819-6753</a>
          </p>
        </div>
      </div> */}
      <div className='contact-banner-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='banner-content'>
                <h2>contact us</h2>
                {/* <h3>CTA</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='contact-main-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='contact-detail'>
                <h3>Contact Details</h3>
                <p>
                  Email:{' '}
                  <a href='mailto:info@equiliberty.com'>info@equiliberty.com</a>
                </p>
                <p>
                  Phone: <a href='tel:+18323180378'>(832) 318-0378</a>
                </p>
              </div>
            </div>

            <div className='col-md-8'>
              <div className='contact-form'>
                <form action=''>
                  {/*} <div className='form-group'>
                    <input
                      type='text'
                      placeholder='Name'
                      className='form-control'
                      required
                    ></input>
              </div> */}
                  {/* <div className='form-group'>
                    <input
                      type='email'
                      placeholder='Email'
                      className='form-control'
                      required
                    ></input>
            </div> */}
                  {/* <div className="form-group">
                  <input type="text" placeholder="How did you hear about us?" className="form-control" required></input>
                </div> */}
                  {/*<div className="form-group">
                    <label className="label">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</label>
                    <label><input type="checkbox" name="check1"></input>Value 1</label> <label><input type="checkbox" name="check1"></input> Value 2</label> <label><input type="checkbox" name="check1"></input> Value 3</label> <label><input type="checkbox" name="check1"></input> Value 4</label>
                    <input type="text" placeholder="How did you hear about us?" className="form-control" required></input>
                  </div> */}
                  {/* <div className="form-group">
                    <textarea className="form-control" placeholder="Details" required></textarea>
                </div> */}
                  {/* <div className="form-group">
                    <input type="text" placeholder="How did you hear about us?" className="form-control" required></input>
              </div> */}
                  {/*} <div className="form-group">
                    <input className="submit-btn" type="submit" value="Contact"></input>
            </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
