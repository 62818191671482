/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
// REACT REDUX
import { useDispatch, useSelector } from 'react-redux';
import MessageIcon from '../../../assets/images/icon-message.png';
// import TockenIcon from '../../../assets/images/token-icon.png';
// import DefaultImg from '../../../assets/images/default-img.png';
import Calender from '../../../assets/images/calender.png';
import Dart from '../../../assets/images/dart.png';
import ImgIcon from '../../../assets/images/hovers/img-181818.png';
import ImgIconHover from '../../../assets/images/hovers/img-18a4ad.png';
import DocumentIcon from '../../../assets/images/hovers/document-181818.png';
import DocumentIconHover from '../../../assets/images/hovers/document-18A4AD.png';
import LocationIcon from '../../../assets/images/hovers/location-181818.png';
import LocationIconHover from '../../../assets/images/hovers/location-18A4AD.png';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import { getUserProfile } from '../../../redux/slices/userProfileSlice';
// import PostImg from '../../../assets/images/post-img.png';
// ANT DESIGN
import {
  message,
  Menu,
  // Layout,Spin, Popover
  // ,Row,Avatar, Card,Button , Input,Upload,
} from 'antd';
// Momemt
import moment from 'moment';
// Light Box
import Lightbox from 'react-image-lightbox';
import {
  CloseOutlined,
  // ,
  // DeleteOutlined,
  // DashOutlined,
  // EditOutlined
} from '@ant-design/icons';
// CONSTANTS
import {
  CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../shared/constants';
import LocationPin from '../../../assets/images/pin.svg';
import { header } from '../../../api/rest';
// SUBHEADER
import SubHeader from '../../widgets/subheader';
// LOADERS
import { SkeletonDashBoardPostLoader, Loader } from '../../shared/Loader';
import { setLoading as running, whoami } from '../../../redux/slices/authSlice';
import {
  fileUpload,
  getResourceListByCity,
  createUserPost,
} from '../../../redux/slices/dashboardSlice';
import {
  listUserJourney,
  createUserJourney,
  updateUserJourney,
  getUsersGoalStatus,
  toggleUsersGoalStatus,
  deleteUserJourney,
} from '../../../redux/slices/journeySlice';
import { getReminderList } from '../../../redux/slices/reminderSlice';
import {
  getContactList,
  addUserResources,
} from '../../../redux/slices/contactsSlice';
// POPUPMODALS
import PopUpModal from '../../shared/popupmodal';
// Sound Notification component
import { TokenEarnSound } from '../../shared/soundeffect';
import { unescapeHtml } from '../../../utils/helpers';
// CSS
import './journey.scss';

var {
  SOCIAL_SHARE,
  CONFIRMATION_HEADER,
  UPDATE_JOURNEY_HEADER,
  ADD_USER_RESOURCE,
  EDIT_USER_RESOURCE,
  SHARE_TO_COMMUNITY,
} = POP_UP_MODAL_HEADER_CONSTANTS;

var {
  SOCIAL_SHARE_MODAL,
  CONFIRMATION_POPUP_MODAL,
  UPDATE_POPUP_MODAL,
  GOAL_CHECKLIST_POP_UP_MODAL,
  ADD_EDIT_USER_RESOURCE,
} = POP_UP_MODAL_CONSTANTS;
var {
  USER_JOURNEY_TITLE_PLACEHOLDER,
  USER_JOURNEY_DESCRIPTION_PLACEHOLDER,
  JOURNEY_YOUR_CONTACTS,
  JOURNEY_WHATS_NEXT,
  JOURNEY_GOALS_CHECKLIST,
  TOKEN_EXPIRED_MESSAGE,
  ERROR_IMAGE_URL,
  ERROR_MESSAGE,
  FILE_LENGTH_LIMIT,
  JOURNEY_REMOVE_MESSAGE,
} = CONSTANTS;
// const { SubMenu } = Menu;

export const Journey = (props) => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.user);
  const [userData, setUserData] = useState(session.data);
  const [userReminder, setUserReminder] = useState('');
  const [reminderTitle, setReminderTitle] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [postDocumentObj, setPostDocumentObj] = useState({
    postImage: '',
    // postMap: '',
    postDocument: '',
  });
  const [postDocumentObjStatus, setPostDocumentObjStatus] = useState({
    postImage: '',
    postMap: '',
    postDocument: '',
  });

  const [updatePostDocumentObj, setUpdatePostDocumentObj] = useState({
    postImage: '',
    postDocument: '',
  });

  // Default status is blank when file choose it will selected when file is uploaded it is set to done
  const [updatePostDocumentObjStatus, setUpdatePostDocumentObjStatus] =
    useState({
      postImage: '',
      postDocument: '',
    });

  const [isSingleFileSelected, setIsSingleFileSelected] = useState(false);
  const storageData = localStorage.getItem('userData');
  const [isLoading, showIsLoading] = useState(true);
  const { id, city, zipcode } = storageData
    ? JSON.parse(storageData).payload
    : '';
  const { token } = storageData ? JSON.parse(storageData) : '';
  const [allJournies, setAllJournies] = useState([]);
  const [mapBoxStatus, setMapBoxStatus] = useState(false);
  // const [toggleCommentBox, setToggleCommentBox] = useState('');

  const [currentJourneyDescription, setCurrentJourneyDescription] =
    useState('');
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
    currentEntry: {},
  });

  // const [activeCommentId, setActiveCommentId] = useState('');
  const [activePostId, setActivePostId] = useState('');
  const [updatedLocationBox, setUpdatedLocationBox] = useState(false);

  const [checkInAddress, setCheckInAddress] = useState();
  const [isSingleUpdatePostFileSelected, setIsSingleUpdatePostFileSelected] =
    useState(false);
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedPostLocation, setUpdatedPostLocation] = useState('');
  const [activePostDocument, setActivePostDocument] = useState('');
  const [activePostImage, setActivePostImage] = useState('');

  const isSingleFileChoosed =
    typeof postDocumentObj.postImage === 'object' &&
    typeof postDocumentObj.postDocument === 'object'
      ? false
      : typeof postDocumentObj.postImage === 'object'
      ? true
      : typeof postDocumentObj.postDocument === 'object'
      ? true
      : false;
  const isSingleUpdatedFileChoosed =
    typeof updatePostDocumentObj.postImage === 'object' &&
    typeof updatePostDocumentObj.postDocument === 'object'
      ? false
      : typeof updatePostDocumentObj.postImage === 'object'
      ? true
      : typeof updatePostDocumentObj.postDocument === 'object'
      ? true
      : false;

  // Light Box
  const [imageViewingJourney, setImageViewingJourney] = useState({});
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  // User Conatct
  const [userContact, setUserContact] = useState([]);
  // const [isNewMessageArrived, setIsNewMessageArrived] = useState(false);
  // const [messageCount, setMessageCount] = useState('0');
  //user goals
  const [userGoal, setUserGoal] = useState('');
  const [currentReminder, setCurrentReminder] = useState([]);
  const [currentMenu, setCurrentMenu] = useState('primary');
  const [appendDataInContact, setAppendDataInContact] = useState(false);
  const [playSound, setPlaySound] = useState(false);

  const isNewMessageArrived = false,
    messageCount = 0;

  useEffect(() => {
    const storageData = localStorage.getItem('userData');
    if (!storageData) {
      props.history.push('/');
      message.error(TOKEN_EXPIRED_MESSAGE);
    } else {
      setUserData(session.data);
    }
  }, [userData]);

  useEffect(() => {
    const storageData = localStorage.getItem('userData');
    if (storageData) {
      getUserProfileInfo();
      listJourney();
      getContactsListFromApi();
      getGoalStatusFromApi();
      getReminderListApi();
      getResourceList();
    }
  }, []);

  useEffect(() => {
    let { postImage, postDocument } = postDocumentObjStatus;
    if (postDocument === 'done' && postImage === 'done') {
      createJourney();
    }
  }, [postDocumentObjStatus]);

  useEffect(() => {
    if (isSingleFileSelected) {
      createJourney();
    }
  }, [isSingleFileSelected]);

  // List user journey
  function listJourney() {
    header.Authorization = token;
    listUserJourney(id, header)
      .then((res) => {
        if (res.data && res.data.length) {
          setAllJournies(res.data);
        } else {
          setAllJournies([]);
        }
        showIsLoading(false);
      })
      .catch((err) => {
        showIsLoading(false);
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function getReminderListApi() {
    let d = new Date();
    d.setUTCHours(0, 0, 0, 0);
    let modifyDate = d.setDate(d.getDate());
    let dateWOZero = new Date(modifyDate).toISOString();
    let data = {
      userId: id,
      days: 1,
      currentDate: dateWOZero,
    };
    header.Authorization = token;
    getReminderList(data, header)
      .then((res) => {
        if (res.userTodayReminder) {
          setCurrentReminder(res.userTodayReminder);
        } else {
          setCurrentReminder([]);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function getGoalStatusFromApi() {
    header.Authorization = token;
    getUsersGoalStatus(id, header)
      .then((res) => {
        setUserGoal(res.updatedUser);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function getUserProfileInfo() {
    let data = JSON.parse(storageData);
    dispatch(running(true));
    let user_id = {
      id: data.payload.id,
      type: 'normal',
    };
    dispatch(getUserProfile(user_id));
  }

  useEffect(() => {
    getResourceList();
  }, [session.user]);
  function getResourceList() {
    header.Authorization = token;
    getResourceListByCity(zipcode, header)
      .then((res) => {
        if (res.data) {
          if (session?.user?.resources) {

            let referenceIds = session.user.resources.map(
              (item) => item.referenceId
            );
            let notFilled = res.data.filter(
              (item) => !referenceIds.includes(item._id)
            );
            setUserContact([...notFilled, ...session.user.resources]);
          } else {
            setUserContact([...res.data]);
          }
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }
  function userGoalsChecklistToggle(goal) {
    setUserGoal((prev) => ({ ...prev, [goal]: !userGoal[goal] }));
    header.Authorization = token;
    toggleUsersGoalStatus(goal, header);
  }

  // Handle Form Submit
  function handleSubmit(event, type) {
    if (event && type === 'create') {
      showConfirmationPopup(
        5,
        CONFIRMATION_POPUP_MODAL,
        CONFIRMATION_HEADER,
        'Would you like to update the goals check list?'
      );
      event.preventDefault();
      return false;
    }
    header.Authorization = token;
    setShowPageLoader(true);
    if (type === 'create') {
      // event.preventDefault();
      if (
        postDocumentObj.postDocument === '' &&
        postDocumentObj.postImage === ''
      ) {
        createJourney();
      } else {
        for (const property in postDocumentObj) {
          // if(postDocumentObj[property] || po)
          if (postDocumentObj[property]) {
            // if (typeof (postDocumentObj.postImage) === "object" && typeof (postDocumentObj.postDocument) === "object") {
            //   // setIsdataLoad3(true)
            // } else if (typeof (postDocumentObj.postImage) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else if (typeof (postDocumentObj.postDocument) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else {
            // }
            uploadDocument(header, postDocumentObj[property], property, type);
          } else {
          }
        }
      }
    } else {
      if (
        updatePostDocumentObj.postDocument === '' &&
        updatePostDocumentObj.postImage === ''
      ) {
        updateUserJourneyApi();
      } else {
        for (const property in updatePostDocumentObj) {
          // if(postDocumentObj[property] || po)
          if (updatePostDocumentObj[property]) {
            // if (typeof (postDocumentObj.postImage) === "object" && typeof (postDocumentObj.postDocument) === "object") {
            //   // setIsdataLoad3(true)
            // } else if (typeof (postDocumentObj.postImage) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else if (typeof (postDocumentObj.postDocument) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else {
            // }
            uploadDocument(
              header,
              updatePostDocumentObj[property],
              property,
              type
            );
          } else {
          }
        }
      }
    }
  }

  // Upload documents
  function uploadDocument(token, property, name, type) {
    var formData = new FormData();
    for (const File of property) {
      formData.append('files', File);
    }
    commonFileUpload(formData, token, name, type);
  }

  function commonFileUpload(formData, header, name, type) {
    fileUpload(formData, header)
      .then((res) => {
        let arr = [];
        for (let i = 0; i < res.image.length; i++) {
          arr.push(res.image[i].Location);
        }
        if (type === 'create') {
          if (name === 'postImage') {
            setPostDocumentObj((prevPostDocumentObj) => {
              return { ...prevPostDocumentObj, [name]: arr };
            });
            setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
              return { ...prevPostDocumentObjStatus, [name]: 'done' };
            });
          }
          if (name === 'postDocument') {
            setPostDocumentObj((prevPostDocumentObj) => {
              return { ...prevPostDocumentObj, [name]: arr };
            });
            setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
              return { ...prevPostDocumentObjStatus, [name]: 'done' };
            });
          }
          if (isSingleFileChoosed) {
            setIsSingleFileSelected(isSingleFileChoosed);
          }
        } else {
          if (name === 'postImage') {
            setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
              return { ...prevUpdatePostDocumentObj, [name]: arr };
            });
            setUpdatePostDocumentObjStatus(
              (prevUpdatePostDocumentObjStatus) => {
                return { ...prevUpdatePostDocumentObjStatus, [name]: 'done' };
              }
            );
          }
          if (name === 'postDocument') {
            setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
              // Object.assign would also work
              return { ...prevUpdatePostDocumentObj, [name]: arr };
            });
            setUpdatePostDocumentObjStatus(
              (prevUpdatePostDocumentObjStatus) => {
                // Object.assign would also work
                return { ...prevUpdatePostDocumentObjStatus, [name]: 'done' };
              }
            );
          }
          if (isSingleUpdatedFileChoosed) {
            setIsSingleUpdatePostFileSelected(isSingleUpdatedFileChoosed);
          }
        }
      })
      .catch((err) => {
        setShowPageLoader(false);
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  // handle input of onchange functio of textbox
  function handleInput(event) {
    let { value, name } = event.target;
    if (name === 'userReminder') {
      setUserReminder(value);
    } else {
      setReminderTitle(value);
    }
  }

  function handleCheckInInput(event) {
    let { label } = event;
    setCheckInAddress(label);
  }

  function removeSelectedFile(event, name, id) {
    let activeArr = Array.prototype.slice.call(postDocumentObj[name]);
    activeArr.splice(id, 1);
    activeArr.forEach(function (file, key) {
      activeArr[key] = file;
    });
    let data = activeArr.length ? activeArr : '';
    // delete postDocumentObj[name][id]/
    setPostDocumentObj((prevPostDocumentObj) => {
      return { ...prevPostDocumentObj, [name]: data };
    });
  }

  function shareToCommunity(currentEntry) {

    setUpdatedTitle(currentEntry.title);
    setUpdatedDescription(
      `<h3>${currentEntry.title}</h3> ${unescapeHtml(currentEntry.description)}`
    );
    setActivePostDocument(
      currentEntry.journeyDocument
        ? currentEntry.journeyDocument.filter(function (el) {
            return el !== '';
          })
        : []
    );
    setUpdatedPostLocation(currentEntry.journeyMap);
    setCheckInAddress(currentEntry.journeyMap);
    setActivePostImage(
      currentEntry.journeyImage
        ? currentEntry.journeyImage.filter(function (el) {
            return el !== '';
          })
        : []
    );
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: UPDATE_POPUP_MODAL,
        modalHeader: SHARE_TO_COMMUNITY,
        isJourneyModal: false,
      };
    });
  }

  async function onSubmitPopUpModal(event, da) {
    // if (event.preventDefault) {
    //   event.preventDefault();
    // }
    // if (currentJourneyDescription === POST_REMOVE_MESSAGE) {
    //   callDeletePostApi();
    // }


    if (
      popUpModal.type === UPDATE_POPUP_MODAL &&
      popUpModal.modalHeader === SHARE_TO_COMMUNITY
    ) {

      let data = {};
      data.postImage = activePostImage;
      data.postMap = updatedPostLocation;
      data.postDocument = activePostDocument;
      data.description = updatedDescription;
      data.userId = id;
      data.city = city;
      data.zip = zipcode;
      header.Authorization = token;
      createUserPost(data, header)
        .then((res) => {
          // showing success message
          message.success('Journey Shared Successfully');
          setShowPageLoader(false);
          setPopUpModal((prevPopUpModal) => {
            return {
              ...prevPopUpModal,
              visible: false,
              type: '',
              modalHeader: '',
            };
          });
        })
        .catch((err) => {
          message.error(
            err && err.data.message ? err.data.message : ERROR_MESSAGE
          );
          setShowPageLoader(false);
        });
      return;
    }

    if (popUpModal.type === UPDATE_POPUP_MODAL) {
      handleSubmit(event, 'update');
    }
    if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
      deleteUserJourneyApi();
    }
    if (popUpModal.type === CONFIRMATION_POPUP_MODAL && reminderTitle) {
      showGoalCheckListPopupModal();
    }
    if (popUpModal.type === ADD_EDIT_USER_RESOURCE) {

      header.Authorization = token;
      await addUserResources(event, header);
      dispatch(whoami());
      closePopupModal();
    }
    if (popUpModal.type === GOAL_CHECKLIST_POP_UP_MODAL && reminderTitle) {
      handleSubmit(false, 'create');
      setPopUpModal((prevPopUpModal) => {
        return {
          ...prevPopUpModal,
          visible: false,
          type: '',
          modalHeader: '',
        };
      });
    }

    if (popUpModal.type === SOCIAL_SHARE_MODAL && event?.title) {
      shareToCommunity(event);
    }
  }

  function showGoalCheckListPopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: GOAL_CHECKLIST_POP_UP_MODAL,
        modalHeader: 'GOALS CHECKLIST',
      };
    });
  }
  function showAddEditResourcePopupModal(title = ADD_USER_RESOURCE, data) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: ADD_EDIT_USER_RESOURCE,
        modalHeader: title,
        currentEntry: data,
      };
    });
  }

  // close poup modal
  function closePopupModal() {
    if (popUpModal.type === CONFIRMATION_POPUP_MODAL && reminderTitle) {
      handleSubmit(false, 'create');
    }
    if (popUpModal.type === GOAL_CHECKLIST_POP_UP_MODAL && reminderTitle) {
      handleSubmit(false, 'create');
    }
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
    // setActiveCommentId('');
    // setActivePostId('');
  }

  function handlePopUpModalInput(event) {
    let { value, name } = event.target;
    if (name) {
      setUpdatedTitle(value);
    } else {
      setUpdatedDescription(value);
    }
  }

  function handlePopUpModalFileInput(event) {
    let { files, name } = event.target,
      arrayLength =
        name === 'postImage'
          ? activePostImage.length
          : activePostDocument.length,
      totalCount = arrayLength + files.length;
    if (totalCount > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
    } else {
      if (updatePostDocumentObj[name] === '') {
        setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
          // Object.assign would also work
          return { ...prevUpdatePostDocumentObj, [name]: files };
        });
        setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
          // Object.assign would also work
          return { ...prevUpdatePostDocumentObjStatus, [name]: 'selected' };
        });
      } else {
        let data = addFileListObj(name, files, updatePostDocumentObj, 'update');
        if (data) {
          setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObj, [name]: data };
          });
          setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObjStatus, [name]: 'selected' };
          });
        }
      }
    }
  }

  // Hold value of user location in edit journey
  function handlePopUpModalLocationInpt(event) {
    let { label } = event;
    setUpdatedPostLocation(label);
  }

  // Remove Uploaded File and images
  function removeSelectedImageOrDoc(event, index, name) {
    let { id } = event.target;

    if (name === 'activePostDocument') {
      // activePostDocument.splice(index, 1);
      // let arr = activePostDocument;
      let arr = activePostDocument.filter((value, id) => id !== index);
      setActivePostDocument(arr);
    } else {
      let arr = activePostImage.filter((value, id) => id !== index);
      setActivePostImage(arr);
    }
  }

  function toggleLocationBox() {
    setUpdatedLocationBox(true);
  }

  function removeUpdatedModalSelectedFile(event, name, id) {
    let activeArr = Array.prototype.slice.call(updatePostDocumentObj[name]);
    activeArr.splice(id, 1);
    activeArr.forEach(function (file, key) {
      activeArr[key] = file;
    });
    let data = activeArr.length ? activeArr : '';
    setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
      // Object.assign would also work
      return { ...prevUpdatePostDocumentObj, [name]: data };
    });
  }

  // for same file choose
  function onInputClick(event) {
    event.target.value = '';
  }

  // handling add more file list object
  // function addFileListObj(name, file, documentVariable, type) {
  //   let activeArr = Array.prototype.slice.call(documentVariable[name]),
  //     fileArr = Array.prototype.slice.call(file);
  //   let newArray = activeArr.concat(fileArr);
  //   let activePostImageLength = activePostImage ? activePostImage.length : 0;
  //   let activePostDocumentLength = activePostDocument ? activePostDocument.length : 0;
  //   let activeVariableLength = name === "postImage" ? activePostImageLength : activePostDocumentLength;
  //   let fileLength = type === 'create' ? newArray.length : activeVariableLength + newArray.length;

  //   if (fileLength > 5) {
  //     message.error(FILE_LENGTH_LIMIT, 10);
  //     return false;
  //   } else {
  //     newArray.forEach(function (file, key) {
  //       newArray[key] = file
  //     });
  //     return newArray;
  //   }

  // }

  // Create Journey
  function createJourney() {
    let data = {};
    header.Authorization = token;
    data.journeyImage = postDocumentObj.postImage;
    data.journeyMap = checkInAddress;
    data.journeyDocument = postDocumentObj.postDocument;
    data.title = reminderTitle;
    data.description = userReminder;
    data.userId = id;
    dispatch(running(true));
    // dispatch(createUserPost(data, header))
    setPlaySound(true);
    createUserJourney(data, header)
      .then((res) => {
        // showing success message
        message.success(res.message);
        setPostDocumentObj((prevPostDocumentObj) => {
          // Object.assign would also work
          return {
            ...prevPostDocumentObj,
            postImage: '',
            // postMap: '',
            postDocument: '',
          };
        });
        setCheckInAddress('');
        setReminderTitle('');
        setUserReminder('');
        // setState(state);
        setMapBoxStatus(false);
        setIsSingleFileSelected(false);
        setShowPageLoader(false);
        setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
          // Object.assign would also work
          return {
            ...prevPostDocumentObjStatus,
            postImage: '',
            postMap: '',
            postDocument: '',
          };
        });
        // calling to get updated list of journey
        getGoalStatusFromApi();
        listJourney();
        getUserProfileInfo();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  // oepn light box
  function openLightBox(journey, imageIndex) {
    setImageViewingJourney(journey);
    setImageIndex(imageIndex);
    setIsLightBoxOpen(true);
  }

  // Toggle map box
  function toggleMapBox(status) {
    setMapBoxStatus(status);
  }

  // Share Popup
  function showSharePopup(currentJourneyDescription, entity = {}) {
    setCurrentJourneyDescription(currentJourneyDescription);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: SOCIAL_SHARE_MODAL,
        modalHeader: SOCIAL_SHARE,
        currentEntry: entity,
      };
    });
  }

  // Confimation pop-up
  function ToggleConfirmationPopup(
    journeytId,
    id,
    description,
    type,
    modalHeader
  ) {
    // setActiveCommentId(id);
    setActivePostId(journeytId);
    setCurrentJourneyDescription(description);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: modalHeader,
      };
    });

    if (type === 'UPDATE_POPUP_MODAL') {
      let data = allJournies.filter((x) => {
        if (x._id === journeytId) {
          return x;
        }
      })[0];
      let postDocument = data.journeyDocument
        ? data.journeyDocument.filter(function (el) {
            return el !== '';
          })
        : [];
      let postImage = data.journeyImage
        ? data.journeyImage.filter(function (el) {
            return el !== '';
          })
        : [];
      setUpdatedTitle(data.title);
      setUpdatedDescription(data.description);
      setUpdatedPostLocation(data.journeyMap);
      setCheckInAddress(data.journeyMap);
      setActivePostDocument(postDocument);
      setActivePostImage(postImage);
    }
  }

  function deleteUserJourneyApi() {
    let data = {
      id: activePostId,
    };
    header.Authorization = token;
    deleteUserJourney(data, header)
      .then((res) => {
        message.success(res.message);
        setPopUpModal((prevPopUpModal) => {
          return {
            ...prevPopUpModal,
            visible: false,
            type: '',
            modalHeader: '',
          };
        });
        listJourney();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function showConfirmationPopup(id, type, modalHeader, description) {
    setActivePostId(id);
    setCurrentJourneyDescription(description);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: modalHeader,
      };
    });
  }
  function showGoalChecklistConfirmationPopup(
    id,
    type,
    modalHeader,
    description
  ) {
    setActivePostId(id);
    setCurrentJourneyDescription(description);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: modalHeader,
      };
    });
  }

  // To store selected files and images
  function storeUploads(event) {
    let { files, name } = event.target;

    // if (files.length > 5) {
    //   message.error(FILE_LENGTH_LIMIT, 10);
    // } else {
    //   setPostDocumentObj(prevPostDocumentObj => {
    //     return { ...prevPostDocumentObj, [name]: files };
    //   });

    //   setPostDocumentObjStatus(prevPostDocumentObjStatus => {
    //     return { ...prevPostDocumentObjStatus, [name]: 'selected' };
    //   });
    // }
    // let { files, name } = event.target;
    if (files.length > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
    } else {
      if (postDocumentObj[name] === '') {
        setPostDocumentObj((prevPostDocumentObj) => {
          // Object.assign would also work
          return { ...prevPostDocumentObj, [name]: files };
        });
        setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
          // Object.assign would also work
          return { ...prevPostDocumentObjStatus, [name]: 'selected' };
        });
      } else {
        let data = addFileListObj(name, files, postDocumentObj, 'create');
        if (data) {
          setPostDocumentObj((prevPostDocumentObj) => {
            // Object.assign would also work
            return { ...prevPostDocumentObj, [name]: data };
          });
          setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
            // Object.assign would also work
            return { ...prevPostDocumentObjStatus, [name]: 'selected' };
          });
        }
      }

      // setShowLoader(prevShowLoader => {
      //   // Object.assign would also work
      //   return { ...prevShowLoader, [name]: true };
      // });
    }
  }

  // handling add more file list object
  function addFileListObj(name, file, documentVariable, type) {
    let activeArr = Array.prototype.slice.call(documentVariable[name]),
      fileArr = Array.prototype.slice.call(file);
    let newArray = activeArr.concat(fileArr);
    let activePostImageLength = activePostImage ? activePostImage.length : 0;
    let activePostDocumentLength = activePostDocument
      ? activePostDocument.length
      : 0;
    let activeVariableLength =
      name === 'postImage' ? activePostImageLength : activePostDocumentLength;
    let fileLength =
      type === 'create'
        ? newArray.length
        : activeVariableLength + newArray.length;

    if (fileLength > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
      return false;
    } else {
      newArray.forEach(function (file, key) {
        newArray[key] = file;
      });
      return newArray;
    }
  }

  React.useEffect(() => {
    document.title = 'Journey';
  });

  function finalUploadedImageAndDocument(type) {
    let arr;
    if (type === 'postDocument') {
      if (activePostDocument.length) {
        arr = updatePostDocumentObj.postDocument.concat(activePostDocument);
        return arr;
      } else {
        return updatePostDocumentObj.postDocument;
      }
    } else {
      if (activePostImage.length) {
        arr = updatePostDocumentObj.postImage.concat(activePostImage);
        return arr;
      } else {
        return updatePostDocumentObj.postImage;
      }
    }
  }

  function updateUserJourneyApi() {
    let data = {};
    data.journeyImage = updatePostDocumentObj.postImage
      ? finalUploadedImageAndDocument('postImage')
      : activePostImage; //    updatePostDocumentObj.postImage;
    data.journeyMap = updatedLocationBox ? updatedPostLocation : checkInAddress;
    data.journeyDocument = updatePostDocumentObj.postDocument
      ? finalUploadedImageAndDocument('postDocument')
      : activePostDocument; // updatePostDocumentObj
    // data.journeyMap = checkInAddress;
    // data.journeyImage = postDocumentObj.postImage;
    // data.journeyDocument = postDocumentObj.postDocument;
    data.title = updatedTitle;
    data.description = updatedDescription;
    data.id = activePostId;
    setPlaySound(true);
    setTimeout(() => {
      updateJourneyApiFunction(data);
    }, 2000);
  }

  function updateJourneyApiFunction(data) {
    header.Authorization = token;
    updateUserJourney(data, header)
      .then((res) => {
        setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
          // Object.assign would also work
          return {
            ...prevUpdatePostDocumentObj,
            postImage: '',
            postDocument: '',
          };
        });
        setCheckInAddress('');
        // setState(state);
        setMapBoxStatus(false);
        setIsSingleFileSelected(false);
        setShowPageLoader(false);
        setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
          // Object.assign would also work
          return {
            ...prevUpdatePostDocumentObjStatus,
            postImage: '',
            postDocument: '',
          };
        });
        setPopUpModal((prevPopUpModal) => {
          return {
            ...prevPopUpModal,
            visible: false,
            type: '',
            modalHeader: '',
          };
        });
        listJourney();
        setPlaySound(false);
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setPlaySound(false);
      });
  }

  useEffect(() => {
    let { postImage, postDocument } = updatePostDocumentObjStatus;
    if (postDocument === 'done' && postImage === 'done') {
      updateUserJourneyApi();
    }
  }, [updatePostDocumentObjStatus]);

  useEffect(() => {
    if (isSingleUpdatePostFileSelected) {
      updateUserJourneyApi();
    }
  }, [isSingleUpdatePostFileSelected]);

  function getContactsListFromApi() {
    if (appendDataInContact) {
      header.Authorization = token;
      getContactList(id, header)
        .then((res) => {
          if (res.data.length) {
            if (userContact.length) {
              let arr = res.data,
                finalArr = userContact.concat(arr);
              setUserContact(finalArr);
            } else {
              setUserContact(res.data);
            }
          }
          setShowPageLoader(false);
        })
        .catch((err) => {
          message.error(err && err.message ? err.message : ERROR_MESSAGE);
          setShowPageLoader(false);
        });
    }
  }

  const handleMenuClick = (e) => {
    if (e.key === currentMenu) return;
    setCurrentMenu(e.key);
    setShowPageLoader(true);
    if (e.key === 'ViewAll') {
      setAppendDataInContact(true);
    } else {
      setAppendDataInContact(false);
      setUserContact([]);
      setShowPageLoader(false);
    }
  };

  useEffect(() => {
    if (appendDataInContact) {
      getContactsListFromApi();
    } else {
      getResourceList();
    }
  }, [appendDataInContact]);

  let updateJourneyCustomProps = {
      handlePopUpModalLocationInpt: handlePopUpModalLocationInpt,
      handlePopUpModalFileInput: handlePopUpModalFileInput,
      handlePopUpModalInput: handlePopUpModalInput,
      removeSelectedImageOrDoc: removeSelectedImageOrDoc,
      toggleLocationBox: toggleLocationBox,
      removeUpdatedModalSelectedFile: removeUpdatedModalSelectedFile,
      onInputClick: onInputClick,
      updateDescription: setUpdatedDescription,
    },
    updatJourneyCustomVariable = {
      updatedTitle,
      updatedDescription,
      updatedPostLocation,
      activePostDocument,
      activePostImage,
      updatePostDocumentObj,
      updatedLocationBox,
      showPageLoader,
    };

  return (
    <React.Fragment>
      {playSound ? <TokenEarnSound /> : ''}
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={imageViewingJourney['journeyImage'][imageIndex]}
          nextSrc={
            imageViewingJourney['journeyImage'][
              (imageIndex + 1) % imageViewingJourney['journeyImage'].length
            ]
          }
          prevSrc={
            imageViewingJourney['journeyImage'][
              (imageIndex + imageViewingJourney['journeyImage'].length - 1) %
                imageViewingJourney['journeyImage'].length
            ]
          }
          onCloseRequest={() => setIsLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex(
              (imageIndex + imageViewingJourney['journeyImage'].length - 1) %
                imageViewingJourney['journeyImage'].length
            )
          }
          onMoveNextRequest={() =>
            setImageIndex(
              (imageIndex + 1) % imageViewingJourney['journeyImage'].length
            )
          }
        />
      )}

      <SubHeader pathName='Your Journey' />

      <section className='journy-section'>
        {popUpModal.visible ? (
          <PopUpModal
            title={popUpModal.modalHeader}
            visible={popUpModal.visible}
            onCancel={closePopupModal}
            onOk={onSubmitPopUpModal}
            type={popUpModal.type}
            isJourneyModal={true}
            description={currentJourneyDescription}
            currentEntry={popUpModal.currentEntry}
            {...updateJourneyCustomProps}
            {...updatJourneyCustomVariable}
          />
        ) : (
          ''
        )}
        {showPageLoader ? <Loader /> : ''}
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='journy-sidebar'>
              <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={700} animationOutDuration={2200} isVisible={true}>
                <h4>{JOURNEY_WHATS_NEXT}</h4>
                </AnimatedOnScroll>
                {/* <h1>Some <br />Reminder</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.</p> */}
                {currentReminder.length ? (
                  currentReminder.map((value, index) => {
                    let { mentorId } = value,
                      { firstName, lastName } = mentorId,
                      fullName = firstName
                        ? firstName
                        : '' + ' ' + lastName
                        ? lastName
                        : '';
                    return (
                      <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={800} animationOutDuration={2000} isVisible={true}>
                      <div
                        className='meeting-date row mb-4'
                        key={`${value.id}-${index}`}
                      >
                        <div className='col-12 d-flex align-items-center'>
                          <img src={Calender} alt='Calender-1' />
                          <div className='d-flex justify-content-center flex-column pl-3'>
                            <h4 className='mb-2'>Meeting with {fullName} </h4>
                            <p className='mb-0'>
                              {moment(value.start_date).format(
                                'MMMM DD,YYYY, h:mm a'
                              )}{' '}
                              <br />
                              {value.description} <br />
                              <Link to='/reminder' className='text-black'>
                                See Full Calendar
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      </AnimatedOnScroll>
                    );

                  })
                ) : (
                  <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={1000} animationOutDuration={1500} isVisible={true}>
                  <div className='meeting-date row mb-4'>
                    <div className='col-12 d-flex align-items-center'>
                      <img src={Calender} alt='Calender-1' />
                      <div className='d-flex justify-content-center flex-column pl-3'>
                        <h4 className='mb-2'>No meeting available </h4>
                        <p className='mb-0'>
                          <Link to='/reminder' className='text-black'>
                            See Full Calendar
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  </AnimatedOnScroll>
                )}
                {/* <div className="journy-contact">
                  <h2> <Link to="/reminder" className="text-white">Reminder Section</Link> </h2>
                </div> */}
                <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={1500} animationOutDuration={1000} isVisible={true}>
                <div className='journy-contact'>
                  <h2 className='mb-0'>{JOURNEY_YOUR_CONTACTS} </h2>
                  {isNewMessageArrived ? (
                    <p>
                      <img src={MessageIcon} alt='MessageIcon' /> View Messages{' '}
                      <span className='text-danger'>
                        `(${messageCount ? messageCount : 0})`
                      </span>
                    </p>
                  ) : (
                    ''
                  )}
                  <Menu
                    onClick={handleMenuClick}
                    selectedKeys={[currentMenu]}
                    mode='horizontal'
                  >
                    <Menu.Item key='primary'>Primary</Menu.Item>
                    <Menu.Item key='ViewAll'>View All</Menu.Item>
                  </Menu>
                  <ul className='pl-3'>
                    {/* {
                      userContact.length ? <a href="/user/contact" >Go to contact page</a> : ''
                    } */}
                    {userContact.length ? (
                      userContact.map((value, index) => {
                        return (
                          <li
                            onClick={() =>
                              showAddEditResourcePopupModal(
                                EDIT_USER_RESOURCE,
                                value
                              )
                            }
                            key={
                              appendDataInContact
                                ? `${value.id}-${index}`
                                : index
                            }
                          >
                            {value.name}
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        {appendDataInContact ? (
                          <React.Fragment>
                            No Contacts Added{' '}
                            <a href='/user/contact'>Go to contact page</a>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            No Resource available
                          </React.Fragment>
                        )}{' '}
                      </li>
                    )}
                    <li
                      onClick={() =>
                        showAddEditResourcePopupModal(ADD_USER_RESOURCE, {})
                      }
                    >
                      Add New
                    </li>
                    {/* <li>Urban League Business Advisor</li>
                    <li>Score Mentor </li>
                    <li>Office of Business Opportunity</li>
                    <li>Bob 'Mentor Williams</li>
                    <li>Unity Bank</li>
                    <li>Sheila 'Accountant" Lee</li>
                    <li>Don "Lawyer Johnson</li>
                    <li>Impact Hub Mentor</li> */}
                  </ul>
                </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOutLeft" animationInDuration={2000} animationOutDuration={800} isVisible={true}>
                <div className='goal-checklist'>
                  <h2>{JOURNEY_GOALS_CHECKLIST}</h2>
                  <p>
                    <img src={Dart} alt='Dart' /> You're making progress! Keep
                    up with your goals.
                  </p>
                  <p>{
                            userGoal
                              ? userGoal.isUserRegisteredBusiness
                                ? userGoal.isUserRegisteredBusiness
                                : false
                              : false
                          }</p>
                  <ul>
                    <li
                      onClick={() =>
                        userGoalsChecklistToggle('isVisionBoardCreated')
                      }
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.isVisionBoardCreated
                                ? userGoal.isVisionBoardCreated
                                : false
                              : false
                          }
                          readOnly
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>
                          1. Create a "Vision Board"
                        </span>{' '}
                        {/* (update your profile/cover page with inspiration for
                        your business and make/upload a video explaining what
                        problem you will solve (ex: business idea, wealth, job,
                        etc.) */}
                      </div>
                    </li>
                    <li
                      onClick={() =>
                        userGoalsChecklistToggle(
                          'isBusinessEducationModuleCompleted'
                        )
                      }
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.isBusinessEducationModuleCompleted
                                ? userGoal.isBusinessEducationModuleCompleted
                                : false
                              : false
                          }
                          readOnly
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>
                          2. Complete "Business Education" modules
                        </span>
                      </div>
                    </li>
                    <li
                      onClick={() =>
                        userGoalsChecklistToggle('isUserRegisteredBusiness')
                      }
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.isUserRegisteredBusiness
                                ? userGoal.isUserRegisteredBusiness
                                : false
                              : false
                          }
                          readOnly
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>
                          3. "Register Your Business"
                        </span>
                      </div>
                    </li>
                    <li
                      onClick={() =>
                        userGoalsChecklistToggle('isUserRequestEmployeeId')
                      }
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.isUserRequestEmployeeId
                                ? userGoal.isUserRequestEmployeeId
                                : false
                              : false
                          }
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>
                          4. Request an "Employee Identification Number"
                        </span>
                      </div>
                    </li>
                    <li
                      onClick={() =>
                        userGoalsChecklistToggle('isUserOpenBankAccount')
                      }
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.isUserOpenBankAccount
                                ? userGoal.isUserOpenBankAccount
                                : false
                              : false
                          }
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>
                          5. Open a "Bank Account"
                        </span>
                      </div>
                    </li>
                    <li
                      onClick={() => userGoalsChecklistToggle('marketResearch')}
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.marketResearch
                                ? userGoal.marketResearch
                                : false
                              : false
                          }
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>6. Market Research</span>
                      </div>
                    </li>
                    <li
                      onClick={() => userGoalsChecklistToggle('businessModel')}
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.businessModel
                                ? userGoal.businessModel
                                : false
                              : false
                          }
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>7. Business Model</span>
                      </div>
                    </li>
                    <li
                      onClick={() => userGoalsChecklistToggle('designProduct')}
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.designProduct
                                ? userGoal.designProduct
                                : false
                              : false
                          }
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>8. Design Product </span>
                      </div>
                    </li>
                    <li
                      onClick={() =>
                        userGoalsChecklistToggle('developMarketingPlanStrategy')
                      }
                    >
                      <label>
                        <input
                          type='checkbox'
                          name=''
                          checked={
                            userGoal
                              ? userGoal.developMarketingPlanStrategy
                                ? userGoal.developMarketingPlanStrategy
                                : false
                              : false
                          }
                        />
                        <span />
                      </label>
                      <div>
                        <span className='list-content'>
                          9. Develop "Marketing Plan Strategy"{' '}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                </AnimatedOnScroll>
              </div>
            </div>

            <div className='col-md-8'>
            <AnimatedOnScroll animationIn="fadeInUp" animationOut="fadeOutUp" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
              <div className='whats-new-section'>
                <h3>What's New?</h3>
                <form onSubmit={(event) => handleSubmit(event, 'create')}>
                  <div className='form-group'>
                    <input
                      className='form-control'
                      placeholder={USER_JOURNEY_TITLE_PLACEHOLDER}
                      value={reminderTitle}
                      name='reminderTitle'
                      onChange={handleInput}
                      required
                    />
                    <CKEditor
                      editor={ClassicEditor}
                      data={userReminder}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setUserReminder(data);
                      }}
                      config={{
                        placeholder: USER_JOURNEY_DESCRIPTION_PLACEHOLDER,
                      }}
                      name='userReminder'
                      required
                    />
                    {/* <textarea
                      className="form-control"
                      style={{ height: "150px" }}
                      placeholder={USER_JOURNEY_DESCRIPTION_PLACEHOLDER}
                      value={userReminder}
                      name="userReminder"
                      onChange={handleInput}
                      required
                    /> */}
                    <div className='row mt-4 align-items-center'>
                      <div className='col-md-6 col-7'>
                        {/* <div className="attachment-icons">
                          <a href="/"><img src={ImgIcon} alt="ImgIcon" /></a>
                          <input type="file" name="postImage" accept="image/*" className="disabled" onChange={(event) => { storeUploads(event) }} multiple />
                          <a href="/"><img src={DocumentIcon} alt="DocumentIcon" /></a>
                          <input type="file" name="postDocument" accept=".doc, .docx,.pdf" className="disabled" onChange={(event) => { storeUploads(event) }} multiple />
                          <a href="/"><img onClick={() => toggleMapBox(!mapBoxStatus)} src={LocationIcon} alt="LocationIcon" className="disabled" /></a>
                        </div> */}
                        <ul className='comment_icone'>
                          <li className='h-i-c'>
                            {/* <Spin spinning={postImage}> */}
                            <a
                              href='/'
                              className='upload-files-icons disabled mr-3 d-flex'
                            >
                              <span className='h-i-c'>
                                <img
                                  className='h-o-h'
                                  src={ImgIcon}
                                  alt='ImgIcon'
                                />
                                <img
                                  className='d-o-h'
                                  src={ImgIconHover}
                                  alt='ImgIcon'
                                />
                              </span>
                            </a>
                            <input
                              type='file'
                              name='postImage'
                              accept='image/*'
                              className='disabled'
                              onChange={(event) => {
                                storeUploads(event);
                              }}
                              multiple
                            />
                            {/* </Spin> */}
                            {/* <a href="/" className="disabled"><img src={ImgIcon} alt="ImgIcon" /></a> */}
                          </li>
                          <li className='h-i-c'>
                            {/* <Spin spinning={postDocument}> */}
                            <a href='/' className='disabled d-flex'>
                              <span className='h-i-c mr-3'>
                                <img
                                  className='h-o-h'
                                  src={DocumentIcon}
                                  alt='DocumentIcon'
                                />
                                <img
                                  className='d-o-h'
                                  src={DocumentIconHover}
                                  alt='DocumentIcon'
                                />
                              </span>
                            </a>
                            <input
                              type='file'
                              name='postDocument'
                              accept='.doc, .docx,.pdf'
                              className='disabled'
                              onChange={(event) => {
                                storeUploads(event);
                              }}
                              multiple
                            />
                            {/* </Spin> */}
                            {/* <a href="/" className="disabled"><img src={DocumentIcon} alt="DocumentIcon" /></a> */}
                          </li>
                          <li className='h-i-c'>
                            <span className='h-i-c'>
                              <img
                                onClick={() => toggleMapBox(!mapBoxStatus)}
                                src={LocationIcon}
                                alt='LocationIcon'
                                className='disabled location-icon h-o-h'
                              />
                              <img
                                onClick={() => toggleMapBox(!mapBoxStatus)}
                                src={LocationIconHover}
                                alt='LocationIcon'
                                className='disabled location-icon d-o-h'
                              />
                            </span>
                          </li>
                        </ul>

                        {mapBoxStatus ? (
                          <div style={{ width: '350px', marginBottom: '10px' }}>
                            <GooglePlacesAutocomplete
                              // apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                              apiKey='AIzaSyBFTEG2DxMcjMh8FUt3lRdXyQ85wJrJj0c'
                              // onClick={(event) => handleAddess(event)}
                              selectProps={{
                                // value,
                                checkInAddress,
                                onChange: (event) => handleCheckInInput(event),
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='col-md-6 col-5'>
                        {/* <a className="document-btn" href="/">Document</a> */}
                        <input
                          type='submit'
                          className='document-btn'
                          value='DOCUMENT'
                        ></input>
                      </div>

                      <div className='col-md-12 journey-documents'>
                        {postDocumentObj.postImage === '' ? (
                          ''
                        ) : postDocumentObj.postImage.length ? (
                          <div className='col-md-6 uploadfile-name'>
                            {Object.entries(postDocumentObj.postImage).map(
                              ([key]) => {
                                let fileName =
                                  postDocumentObj.postImage[key] &&
                                  postDocumentObj.postImage[key].name &&
                                  postDocumentObj.postImage[key].name.length >
                                    10
                                    ? postDocumentObj.postImage[
                                        key
                                      ].name.substring(0, 10) +
                                      '... ' +
                                      postDocumentObj.postImage[
                                        key
                                      ].name.substr(
                                        postDocumentObj.postImage[key].name
                                          .length - 5
                                      )
                                    : postDocumentObj.postImage[key].name;
                                return (
                                  <p key={`${fileName}-${key}-postImage`}>
                                    {fileName}
                                    <span
                                      className='cross-icon'
                                      onClick={(event) =>
                                        removeSelectedFile(
                                          event,
                                          'postImage',
                                          key
                                        )
                                      }
                                    >
                                      <CloseOutlined />
                                    </span>
                                  </p>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        {postDocumentObj.postDocument === '' ? (
                          ''
                        ) : postDocumentObj.postDocument.length ? (
                          <div className='col-md-6 uploadfile-name'>
                            {Object.entries(postDocumentObj.postDocument).map(
                              ([key]) => {
                                let fileName =
                                  postDocumentObj.postDocument[key] &&
                                  postDocumentObj.postDocument[key].name &&
                                  postDocumentObj.postDocument[key].name
                                    .length > 10
                                    ? postDocumentObj.postDocument[
                                        key
                                      ].name.substring(0, 10) +
                                      '... ' +
                                      postDocumentObj.postDocument[
                                        key
                                      ].name.substr(
                                        postDocumentObj.postDocument[key].name
                                          .length - 5
                                      )
                                    : postDocumentObj.postDocument[key].name;
                                return (
                                  <p key={`${fileName}-${key}-postDocument`}>
                                    {fileName}
                                    <span
                                      className='cross-icon'
                                      onClick={(event) =>
                                        removeSelectedFile(
                                          event,
                                          'postDocument',
                                          key
                                        )
                                      }
                                    >
                                      <CloseOutlined />
                                    </span>
                                  </p>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </form>

                <div className='posts'>
                  {isLoading ? (
                    <SkeletonDashBoardPostLoader
                      rows={4}
                      width={['100%', '100%', '100%', '100%']}
                    />
                  ) : allJournies && allJournies.length ? (
                    <div>
                      <h3>Past entries...</h3>
                      <div
                        className='panel-group'
                        id='accordion'
                        role='tablist'
                        aria-multiselectable='true'
                      >
                        {allJournies.map((journey, index) => {
                          if (journey.userId) {
                            let //  { lastName, firstName, userPhoto } = journey.userId,
                              // userFullName = firstName + ' ' + lastName,
                              journeyId = journey._id,
                              userId = journey.userId._id,
                              //moment(value.createdAt).format("MMM DD, YYYY hh:mm a").toString()             createdAt = moment().isSame(journey.createdAt, 'day') ? moment(journey.createdAt).format("hh:mm a").toString() : moment(journey.createdAt).format("MM.DD.YYYY hh:mm a").toString();
                              createdAt = moment(journey.createdAt)
                                .format('MM.DD.YYYY')
                                .toString();
                            if (journey.id) {
                              return (
                                <div
                                  className='panel panel-default'
                                  key={`${userId}-${index}`}
                                >
                                  <div
                                    className='panel-heading'
                                    role='tab'
                                    id={`heading_${index}`}
                                  >
                                    <h4 className='panel-title'>
                                      <a
                                        role='button'
                                        data-toggle='collapse'
                                        data-parent='#accordion'
                                        href={`#collapse_${index}`}
                                        aria-expanded='false'
                                        aria-controls={`collapse_${index}`}
                                      >
                                        {journey.title}{' '}
                                        <span>Posted: {createdAt}</span>
                                      </a>
                                    </h4>
                                  </div>
                                  <div
                                    id={`collapse_${index}`}
                                    className='panel-collapse collapse in'
                                    role='tabpanel'
                                    aria-labelledby={`heading_${index}`}
                                  >
                                    <div className='panel-body'>
                                      <div className='row'>
                                        <div className='col-md-9'>
                                          <p>
                                            {parse(
                                              unescapeHtml(journey.description)
                                            )}
                                          </p>
                                          {journey.journeyMap ? (
                                            <p className='location-address'>
                                              {' '}
                                              <span>
                                                {' '}
                                                <img
                                                  src={LocationPin}
                                                  alt='LocationPin'
                                                />
                                                {journey.journeyMap}
                                              </span>
                                            </p>
                                          ) : (
                                            ''
                                          )}
                                          {journey.journeyImage.length ? (
                                            <div className='row mb-2'>
                                              {journey.journeyImage.map(
                                                (imageUrl, imageIndex) => {
                                                  return (
                                                    <React.Fragment
                                                      key={`${imageIndex}`}
                                                    >
                                                      {imageUrl ? (
                                                        <div
                                                          className='col-md-4 journeyImg'
                                                          onClick={() =>
                                                            openLightBox(
                                                              journey,
                                                              imageIndex
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              imageUrl
                                                                ? imageUrl
                                                                : ERROR_IMAGE_URL
                                                            }
                                                            alt={
                                                              imageUrl
                                                                ? journey.title
                                                                : ERROR_IMAGE_URL
                                                            }
                                                          />
                                                        </div>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </React.Fragment>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                          {/* <ul>
                                          <li><img src={PostImg} alt="PostImg-1" /></li>
                                          <li><img src={PostImg} alt="PostImg-2" /></li>
                                        </ul> */}

                                          <div className='col-md-12 col-12'>
                                            <div className='row'>
                                              <div className='col-md-6 col-6'>
                                                <div className='right-tags'>
                                                  {journey.journeyDocument &&
                                                  journey.journeyDocument
                                                    .length ? (
                                                    journey.journeyDocument.map(
                                                      (doc, docIndex) => {
                                                        if (doc) {
                                                          return (
                                                            <a
                                                              href={doc}
                                                              index={docIndex}
                                                              target='_blank'
                                                              rel='noreferrer noopener'
                                                              key={`${doc}-${docIndex}`}
                                                            >
                                                              {/* <FileDoneOutlined twoToneColor="#18a4ad" /> */}
                                                              <svg
                                                                viewBox='64 64 896 896'
                                                                focusable='false'
                                                                className=''
                                                                data-icon='file-done'
                                                                width='25px'
                                                                height='25px'
                                                                fill='#18a4ad'
                                                                aria-hidden='true'
                                                              >
                                                                <path d='M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 00-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z'></path>
                                                              </svg>
                                                            </a>
                                                          );
                                                        } else {
                                                          return (
                                                            <span
                                                              key={`${doc}-${docIndex}`}
                                                            />
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <span />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='col-md-3'>
                                          <div className='edit-post-btns'>
                                            <a
                                              className='btn-edit'
                                              onClick={() => {
                                                ToggleConfirmationPopup(
                                                  journeyId,
                                                  userId,
                                                  '',
                                                  UPDATE_POPUP_MODAL,
                                                  UPDATE_JOURNEY_HEADER
                                                );
                                              }}
                                            >
                                              Edit
                                            </a>
                                            <a
                                              className='btn-edit btn-delete'
                                              onClick={() => {
                                                showConfirmationPopup(
                                                  journeyId,
                                                  CONFIRMATION_POPUP_MODAL,
                                                  CONFIRMATION_HEADER,
                                                  JOURNEY_REMOVE_MESSAGE
                                                );
                                              }}
                                            >
                                              Delete
                                            </a>
                                            <a
                                              className='btn-share'
                                              onClick={() =>
                                                showSharePopup(
                                                  journey.description,
                                                  journey
                                                )
                                              }
                                            >
                                              Share...
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          } else {
                            return false;
                          }
                        })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              </AnimatedOnScroll>
            </div>
          </div>
        </div>
      </section>
      {/* your journy ends */}
    </React.Fragment>
  );
};
