// import { message } from "antd";
import {
    apiGetMethod,
    apiPutMethod,
    apiPostMethod,
    apiDeleteMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

var {
    DELETE_CONTACT,
    GET_CONTACT_LIST,
    GET_CONTACT_DETAILS,
    UPDATE_CONTACT,
    ADD_CONTACT
} = API_END_POINTS_CONSTANT;


export const addContacts = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ADD_CONTACT, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export const getContactDetails = (id, token) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_CONTACT_DETAILS}/${id}`, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getContactList = (id, token) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_CONTACT_LIST}/${id}`, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateContacts = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPutMethod(UPDATE_CONTACT, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export const deleteContact = (id, token) => {
    return new Promise((resolve, reject) => {
        apiDeleteMethod(`${DELETE_CONTACT}/${id}`, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const addUserResources = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod('/users/resources', data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
