import React, {
    useState,
    useEffect
} from 'react';
import {
    Table,
    message,
    Space,
    Button
} from 'antd';
// REACT REDUX
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// CONSTANT
import {
    CONSTANTS,
    POP_UP_MODAL_CONSTANTS,
    POP_UP_MODAL_HEADER_CONSTANTS
} from '../../../shared/constants';
// API END POINTS
import {
    addLocalLiberator,
    getLiberatorList,
    deleteLocalLiberator,
    updateLocalLiberator
} from '../../../../redux/slices/adminSlice';
import {
    header
} from '../../../../api/rest';
import {
    history
} from '../../../../redux/store';
// POPUPMODAL
import PopUpModal from '../../../shared/popupmodal';
// LOADER
import {
    Loader
} from '../../../shared/Loader';
import './adminliberal.scss';

var {
    ERROR_MESSAGE,
    INVALID_USER
} = CONSTANTS;

var {
    ADD_EDIT_LIBERAL_LIST,
    CONFIRMATION_POPUP_MODAL
} = POP_UP_MODAL_CONSTANTS;
var {
    ADD_LIBERAL,
    EDIT_LIBERAL,
    CONFIRMATION_HEADER
} = POP_UP_MODAL_HEADER_CONSTANTS;

function AdminLiberator() {
    // const dispatch = useDispatch();
    // const loginUserData = useSelector(state => state.user);
    const storageData = localStorage.getItem("userData");
    const payload = storageData ? JSON.parse(storageData).payload : '';
    const token = storageData ? JSON.parse(storageData).token : '';
    const [liberatorList, setLiberatorList] = useState([]);
    const [popUpModal, setPopUpModal] = useState({
        visible: false,
        type: '',
        modalHeader: ''
    });
    const [currentEntry, setCurrentEntry] = useState({
        id: '',
        contactName: '',
        address: '',
        email: '',
        phoneNumber: '',
        city: '',
        state: '',
        zip: ''
    });
    const [description, setDescription] = useState('');
    const [showPageLoader, setShowPageLoader] = useState(true);
    const columns = [
        {
            title: 'Contact Name',
            dataIndex: 'contactName',
            key: 'contactName',
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Zip Code',
            dataIndex: 'zip',
            key: 'zip',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button className="delete-liberal-btn" onClick={() => fillUserInfoInModal(record, 'delete')}>Delete Liberal</Button>
                    <Button className="update-liberal-btn" onClick={() => fillUserInfoInModal(record, 'update')}>Update Liberal</Button>
                </Space>
            ),
        }
    ];

    function fillUserInfoInModal(data, type) {
        if (type === 'update') {
            toggleModal(ADD_EDIT_LIBERAL_LIST, EDIT_LIBERAL)
        }
        if (type === 'delete') {
            toggleModal(CONFIRMATION_POPUP_MODAL, CONFIRMATION_HEADER);
            setDescription(`Are you sure to remove ${data.contactName}`)
        }

        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: data.id,
                contactName: data.contactName,
                address: data.address,
                email: data.email,
                phoneNumber: data.phoneNumber,
                city: data.city,
                state: data.state,
                zip: data.zip
            }
        });
    }

    useEffect(() => {
        if (token) {
            if (payload.role === 'admin') {
                getLiberatorListApi();
            } else {
                message.error(INVALID_USER);
                history.push('/');
            }
        } else {
            history.push('/');
            // message.error(TOKEN_EXPIRED_MESSAGE);
        }
    }, [])



    function addLiberator(value) {
        header.Authorization = `Bearer ${token}`;
        let data = value;
        addLocalLiberator(data, header).then(res => {
            message.success(res.message);
            getLiberatorListApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function updateLiberator(value) {
        header.Authorization = `Bearer ${token}`;
        let data = {
            id: currentEntry.id,
            ...value
        }
        updateLocalLiberator(data, header).then(res => {
            message.success(res.message);
            getLiberatorListApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }

    function deleteLiberator(value) {

        header.Authorization = `Bearer ${token}`;
        let data = {
            id: value.id
        }

        deleteLocalLiberator(data, header).then(res => {
            message.success(res.message);
            getLiberatorListApi();
            closePopupModal();
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);

        })

    }

    function getLiberatorListApi() {
        header.Authorization = `Bearer ${token}`;
        getLiberatorList(header).then(res => {
            if (res.data) {
                setLiberatorList(res.data)
            }
            setShowPageLoader(false);
        }).catch(err => {
            message.error(err && err.message ? err.message : ERROR_MESSAGE);
            setShowPageLoader(false);
        })
    }


    function onSubmitPopUpModal(event) {
        setShowPageLoader(true);
        // event.preventDefault();
        if (popUpModal.modalHeader === ADD_LIBERAL) {
            addLiberator(event);
        }
        if (popUpModal.modalHeader === EDIT_LIBERAL) {
            updateLiberator(event);
        }
        if (popUpModal.type === CONFIRMATION_POPUP_MODAL) {
            deleteLiberator(currentEntry)
        }
        // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
    }


    function toggleModal(type, header) {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: true,
                type: type,
                modalHeader: header
            }
        });
    }

    // close poup modal
    function closePopupModal() {
        setPopUpModal(prevPopUpModal => {
            return {
                ...prevPopUpModal,
                visible: false,
                type: '',
                modalHeader: ''
            }
        });
        setCurrentEntry(prevCurrentEntry => {
            return {
                ...prevCurrentEntry,
                id: '',
                name: '',
                address: '',
                email: '',
                phoneNumber: '',
                webAddress: '',
                city: '',
                zip: ''
            }
        });
    }

    let addEditLiberatorProps = {
        currentEntry
    }

    return (
        <React.Fragment>
            {showPageLoader ? <Loader /> : ''}
            {
                popUpModal.visible ?
                    <PopUpModal
                        title={popUpModal.modalHeader}
                        visible={popUpModal.visible}
                        onCancel={closePopupModal}
                        onOk={onSubmitPopUpModal}
                        type={popUpModal.type}
                        description={description}
                        {...addEditLiberatorProps}
                    /> : ''
            }
            <div className="row mt-4 mb-2">
                <div className="col-12 text-right">
                    <Button onClick={() => toggleModal(ADD_EDIT_LIBERAL_LIST, ADD_LIBERAL)}>Add Liberal</Button>
                </div>
            </div>
            <Table dataSource={liberatorList} columns={columns} />
        </React.Fragment>
    )
}

export default AdminLiberator;
