import React from 'react';
// CONSTANTS
import { POP_UP_MODAL_CONSTANTS } from '../constants';
// POPUPMODALS
import { EditVisionPopUpModal } from './editVisionPopUpModal';
import { SocialShareModal } from './socialShareModal';
import { ConfirmationPopUpModal } from './confirmationPopUpModal';
import { UpdatePostPopUpModal } from './updatePostPopUpModal';
import { AddEditContactPopupModal } from './addeditcontactpopupmodal';
// import { UpdatePostPopUpModal } from './updatePostPopUpModal'
import { UpdateJourneyModal } from './updateJourneyModal';
import { UpdateEditProfileUpModal } from './updateEditProfileUpModal';
import { FollowerFollowingListModal } from './followerFollowingListModal';
import { AddUpdateReminder } from './addupdatereminder';
import { AddEditTokenWallet } from './addedittokenwallet';
import { ChangePasswordModal } from './changepasswordmodal'
import { LoginModal } from './loginmodal';
import { AddEditLocalLiberator } from './addeditlocalliberator';
import { AddEditResource } from './addeditresource';
import { AddEditEarnWallet } from './addeditearnwallet';
import { AddEditNews } from './addeditnews';
import { AdminUserAddEdit } from './adminuseraddedit';
import { AddEditCommunity } from './addeditcommunity';
import AddUpdateCommunityPost from './AddUpdateCommunityPost';
import { AddAdminCategory } from './addadmincategory';
import { ChooseMentorModal } from './choosementormodal';
import { AddEditUserGoal } from './addeditusergoal';
import { MessagePopupModal } from './messagepopupmodal';
import { SendTokenModal } from './sendtokenmodal';
import { QuestionPopupModal } from './questionpopupmodal';
import { ZipCodePopUpModal } from './zipcodepopupmodal';
import { FileUploadModal } from './fileuploadmodal';
import { AddEditBuisnessIndustries } from './addeditbuisnessindustries';
import { CommunityStatusModal } from './communitystatusmodal';
import { UserVisionBioPopUpModal } from './uservisionbiopopupmodal';
import { GoalsChecklistModal } from './goalsChecklistModal';
import { AdminApproveMentorModal } from './adminMentorApproveModal';
import { AddEditUserResource } from './AddEditUserResource';
import { MailChimpPopup } from './MailChimpPopup';

var {
  EDIT_VISION_POP_MODAL,
  SOCIAL_SHARE_MODAL,
  CONFIRMATION_POPUP_MODAL,
  UPDATE_POPUP_MODAL,
  ADD_EDIT_CONTACTS_MODAL,
  UPDATE_JOURNEY_MODAL,
  UPDATE_PROFILE_MODAL_IMAGES,
  FOLLOWER_FOLLOWING_MODAL,
  ADD_EDIT_REMINDER,
  ADD_EDIT_TOKEN_TO_WALLET,
  CHANGE_PASSWORD_MODAL,
  LOGIN_MODAL,
  ADD_EDIT_LIBERAL_LIST,
  ADD_EDIT_RESOURCE,
  ADD_EDIT_EARN_WALLET,
  ADD_EDIT_NEWS,
  ADD_EDIT_ADMIN_USER,
  ADD_EDIT_COMMUNITY,
  ADD_EDIT_COMMUNITY_POST,
  ADD_EDIT_ADMIN_CATEGORY,
  CHOOSE_MENTOR_POP_UP_MODAL,
  ADD_EDIT_GOAL_POP_MODAL,
  MESSAGE_POP_UP_MODAL,
  SEND_TOKEN_MODAL,
  QUESTION_POP_UP_MODAL,
  ZIP_CODE_POP_UP_MODAL,
  FILE_UPLOAD_MODAL,
  ADD_EDIT_BUISNESS_INFORMATION_MODAL,
  COMMUNITY_STATUS_POP_UP_MODAL,
  USER_VISION_MENTOR_BIO_POP_UP_MODAL,
  GOAL_CHECKLIST_POP_UP_MODAL,
  ADMIN_APPROVE_MENTOR_POP_UP_MODAL,
  ADD_EDIT_USER_RESOURCE,
  MAILCHIMP_POP_UP_MODAL,
} = POP_UP_MODAL_CONSTANTS;

function PopUpModal(props) {
    function getModelContent(props) {
        switch (props.type) {
          case EDIT_VISION_POP_MODAL: {
            return <EditVisionPopUpModal {...props} />;
          }
          case SOCIAL_SHARE_MODAL: {
            return <SocialShareModal {...props} />;
          }
          case CONFIRMATION_POPUP_MODAL: {
            return <ConfirmationPopUpModal {...props} />;
          }
          case UPDATE_POPUP_MODAL: {
            return <UpdatePostPopUpModal {...props} />;
          }
          case ADD_EDIT_CONTACTS_MODAL: {
            return <AddEditContactPopupModal {...props} />;
          }
          case UPDATE_JOURNEY_MODAL: {
            return <UpdateJourneyModal {...props} />;
          }
          case UPDATE_PROFILE_MODAL_IMAGES: {
            return <UpdateEditProfileUpModal {...props} />;
          }
          case FOLLOWER_FOLLOWING_MODAL: {
            return <FollowerFollowingListModal {...props} />;
          }
          case ADD_EDIT_REMINDER: {
            return <AddUpdateReminder {...props} />;
          }
          case ADD_EDIT_TOKEN_TO_WALLET: {
            return <AddEditTokenWallet {...props} />;
          }
          case CHANGE_PASSWORD_MODAL: {
            return <ChangePasswordModal {...props} />;
          }
          case LOGIN_MODAL: {
            return <LoginModal {...props} />;
          }
          case ADD_EDIT_LIBERAL_LIST: {
            return <AddEditLocalLiberator {...props} />;
          }
          case ADD_EDIT_RESOURCE: {
            return <AddEditResource {...props} />;
          }
          case ADD_EDIT_EARN_WALLET: {
            return <AddEditEarnWallet {...props} />;
          }
          case ADD_EDIT_NEWS: {
            return <AddEditNews {...props} />;
          }
          case ADD_EDIT_ADMIN_USER: {
            return <AdminUserAddEdit {...props} />;
          }
          case ADD_EDIT_COMMUNITY: {
            return <AddEditCommunity {...props} />;
          }
          case ADD_EDIT_COMMUNITY_POST: {
            return <AddUpdateCommunityPost {...props} />;
          }
          case ADD_EDIT_ADMIN_CATEGORY: {
            return <AddAdminCategory {...props} />;
          }
          case CHOOSE_MENTOR_POP_UP_MODAL: {
            return <ChooseMentorModal {...props} />;
          }
          case ADD_EDIT_GOAL_POP_MODAL: {
            return <AddEditUserGoal {...props} />;
          }
          case MESSAGE_POP_UP_MODAL: {
            return <MessagePopupModal {...props} />;
          }
          case SEND_TOKEN_MODAL: {
            return <SendTokenModal {...props} />;
          }
          case QUESTION_POP_UP_MODAL: {
            return <QuestionPopupModal {...props} />;
          }
          case ZIP_CODE_POP_UP_MODAL: {
            return <ZipCodePopUpModal {...props} />;
          }
          case FILE_UPLOAD_MODAL: {
            return <FileUploadModal {...props} />;
          }
          case ADD_EDIT_BUISNESS_INFORMATION_MODAL: {
            return <AddEditBuisnessIndustries {...props} />;
          }
          case COMMUNITY_STATUS_POP_UP_MODAL: {
            return <CommunityStatusModal {...props} />;
          }
          case USER_VISION_MENTOR_BIO_POP_UP_MODAL: {
            return <UserVisionBioPopUpModal {...props} />;
          }
          case GOAL_CHECKLIST_POP_UP_MODAL: {
            return <GoalsChecklistModal {...props} />;
          }
          case ADMIN_APPROVE_MENTOR_POP_UP_MODAL: {
            return <AdminApproveMentorModal {...props} />;
          }
          case ADD_EDIT_USER_RESOURCE: {
            return <AddEditUserResource {...props} />;
          }
          case MAILCHIMP_POP_UP_MODAL: {
            return <MailChimpPopup {...props} />;
          }
          default: {
            return false;
          }
        }
    }
    let ModalData = getModelContent(props);
    return ModalData;
}

export default PopUpModal
