// import { message } from "antd";
import {
    apiPostMethod
    //  post, get, apiPostMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import {
    API_END_POINTS_CONSTANT
} from '../../components/shared/constants';

let {
    ADD_ENQUIRY
} = API_END_POINTS_CONSTANT;

export const addEnquiry = (data, headers) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ADD_ENQUIRY, data, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};