import React from 'react';
import { Modal } from 'antd';

export function AddEditTokenWallet(props) {
    let { title,tokenAmt } = props;
    return (
        <Modal
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            okButtonProps={{ title: "Submit" }}
            footer={null}
            // footer={[
            //     <Button key="back" onClick={() => props.onCancel(false)} >
            //         Cancel
            //     </Button>,
            //     <Button key="submit" type="primary" onClick={(event) => props.onOk(event)}>
            //         Submit
            //     </Button>,
            // ]}
        >
            <form onSubmit={(event) => props.onOk(event)} autoComplete="off">
                <div className="form-group">
                    <label>Add Token</label>
                    <input type="text" className="form-control" min="1" max="1000" value={tokenAmt} name="tokenAmt" placeholder="10" onChange={(event)=>props.handleInput(event)} required />
                </div>
                <button type="submit" className="btn btn-primary">Add Token</button>
            </form>
        </Modal>
    )
}