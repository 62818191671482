import { createSlice } from '@reduxjs/toolkit';
// import { message } from "antd";
import {
    // post, get ,
    apiGetMethod,
    apiPostMethod,
    apiPutMethod
} from '../../api/rest';
// import { setUser, loading } from './authSlice';
import { API_END_POINTS_CONSTANT } from '../../components/shared/constants';

export const userPostSlice = createSlice({
    name: 'userPost',
    initialState: {
        loading: false,
        data: {}
    },
    reducers: {
        setUserPost: (state, action) => {
            state.loading = false;
            state.data = { ...action.data, ...action.payload };
        }
    },
});

export const { setUserPost } = userPostSlice.actions;

let {
    GET_USER_POST,
    CREATE_USER_POST,
    GET_ALL_USER_POST,
    MULTIPLE_FILE_UPLOAD,
    TOGGLE_lIKE,
    VIEW_COMMENTS,
    ADD_COMMENTS,
    // GET_NEWS,
    DELETE_COMMENTS,
    DELETE_USER_POST,
    UPDATE_DASHBOARD_POST,
    VIEW_LIKE_USER_LIST,
    GET_LIBERATOR_LIST_BY_CITY,
    GET_RESOURCE_LIST_BY_CITY,
    GET_COMMUNITY_DATA,
    SHARE_SOCIAL
} = API_END_POINTS_CONSTANT;

// OLD CODE PLEASE DO NOT REMOVED USED IN FUTURE IF USED

// get user post data
// export const getUserPost = id => async dispatch => {
//     const result = await get(`${GET_USER_POST}?id=${id}`);
//     if (result && result.updatedUser) {
//         dispatch(setUser({ ...result.updatedUser, message: result.message }));
//         dispatch(loading(false));
//     } else {
//         dispatch(loading(false));
//         message.error(result ? result.message : 'Error');
//     }
// };
// create user post
// export const createUserPost = (data, token) => async dispatch => {
//     const result = await post(CREATE_USER_POST, token, data);
//     if (result && (result.status || result.userid)) {
//         message.success(result.message);
//         dispatch(loading(false));
//         dispatch(setUser({ ...result, success: true, message: result.message }));
//     } else {
//         dispatch(loading(false));
//         message.error(result ? result.message : 'Error');
//     }
// };

// get ALL user post data
// export const getAllUserPost = () => async dispatch => {
//     const result = await get(GET_ALL_USER_POST);
//     if (result && result.postList) {
//         dispatch(setUserPost({ result:result.postList, message: result.message }));
//         dispatch(loading(false));
//     } else {
//         dispatch(loading(false));
//         message.error(result ? result.message : 'Error');
//     }
// };


export const getUserPost = (id, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_USER_POST}?id=${id}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};
export const createUserPost = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(CREATE_USER_POST, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getAllUserPost = (data, page_size, page_limit, token) => {
    // const storageData = JSON.parse(localStorage.getItem("userData"));
    // if(storageData) {
    //     const data = {
    //         userId: storageData.payload.id,
    //         city: storageData.payload.city
    //     }
    //     const headers = {}
    //     return new Promise((resolve, reject) => {
    //         apiPostMethod(`${GET_ALL_USER_POST}?page=${page_size}&limit=${page_limit}`, data, token).then(res => {
    //             resolve(res)
    //         }).catch(err => {
    //             reject(err? err:'')
    //         })
    //     })
    // }
    return new Promise((resolve, reject) => {
        apiPostMethod(`${GET_ALL_USER_POST}?page=${page_size}&limit=${page_limit}`, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err ? err : '')
        })
    })
};

export const listComments = (postId, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${VIEW_COMMENTS}/${postId}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const fileUpload = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(MULTIPLE_FILE_UPLOAD, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const toggleLikeApi = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(TOGGLE_lIKE, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const addComment = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(ADD_COMMENTS, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// export const getCommentsById=(id)=>{
//     return new Promise((resolve, reject) => {
//         let headers = {};
//         apiGetMethod(`${VIEW_COMMENTS_BY_POST}?id=${id}`, headers).then(res => {
//             resolve(res)
//         }).catch(err => {
//             reject(err)
//         })
//     })
// }

export const getDashBoardNews = (url) => {
    return new Promise((resolve, reject) => {
        let headers = {};
        apiGetMethod(url, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export const deleteCommentApi = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(DELETE_COMMENTS, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export const deleteUserPost = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(DELETE_USER_POST, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateUserPost = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPutMethod(UPDATE_DASHBOARD_POST, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export const viewPostLikedUserList = (postId, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${VIEW_LIKE_USER_LIST}/${postId}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getLibertorListByCity = (city, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_LIBERATOR_LIST_BY_CITY}/${city}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getResourceListByCity = (city, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_RESOURCE_LIST_BY_CITY}/${city}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const getCommunityData = (value, headers) => {
    return new Promise((resolve, reject) => {
        apiGetMethod(`${GET_COMMUNITY_DATA}?communityId=${value}`, headers).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
};

export const shareSocialMediaToken = (data, token) => {
    return new Promise((resolve, reject) => {
        apiPostMethod(SHARE_SOCIAL, data, token).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export default userPostSlice.reducer;
