
export const unescapeHtml = (str) => {
    return str
        .replace(/&amp;/g, "&")
        .replace(/&quot;/g, '"')
        .replace(/&#x27;/g, "'")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&#x2F;/g, "/")
        .replace(/&#x5C;/g, "\\")
        .replace(/&#96;/g, "`");
}
export const formatDate = (text) => {
   const date = new Date(text)
    // let d = date.getDate();
    // let m = date.getMonth() + 1; //Month from 0 to 11
    // let y = date.getFullYear();
    // return `${y}-${m<=9 ? '0' + m : m}-${d <= 9 ? '0' + d : d}`;
return date.toDateString();
}
