import React from 'react';
import {
    Modal,
    Button,
    Form
} from 'antd';
import parse from 'html-react-parser';
import './uservisionbio.scss';

export function UserVisionBioPopUpModal(props) {
    let {
        title,
        description,
        values
    } = props;
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };
    return (
        <React.Fragment>
                <Modal
                    title={title}
                    centered
                    visible={props.visible}
                    onOk={(event) => props.onOk(event)}
                    onCancel={() => props.onCancel(false)}
                    okButtonProps={{ title: "Submit" }}
                    footer={null}>
                    <div className="user-vision-bio-modal">
                        <Form
                            {...layout}
                            onFinish={(event) => props.onOk(event)}>
                            <div className="row mt-2"><div className="col-12 vision-modal-content">{values?parse(values):''}</div></div>
                            <div className="row mt-4"><div className="col-12 text-center"><Button type="primary" htmlType="submit">Ok</Button></div></div>
                        </Form>
                    </div>
                </Modal>
        </React.Fragment>
    )
}