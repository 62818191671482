import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Carousel, Avatar, Grid } from 'antd';
import React from 'react';
import './SponsorCarousel.scss';
const { useBreakpoint } = Grid;

// import '../mentormodule/mentorregister/MentorsCarousel.scss'

function SponsorCarousel({ sponsors }) {
  const screens = useBreakpoint();
  console.log(screens);
  return (
    <div className='sponsorCarousel my-5 px-2'>
      <h1
        className='text-center font-weight-bold  mb-5'
        style={{ color: '#00a9ab' }}
      >
        As Seen in the Media
      </h1>

      <Carousel
        slidesToShow={screens.md ? 4 : 3}
        autoplay
        // arrows
        draggable
        pauseOnFocus
        accessibility
        dots={false}
        centerMode
        // lazyLoad='ondemand'
        nextArrow={<CaretRightOutlined />}
        prevArrow={<CaretLeftOutlined />}
        className='mt-5'
      >
        {sponsors.map((sponsor, idx) => (
          <center key={idx}>
            <a target='_blank' href={sponsor.link}>
              <Avatar src={sponsor.image} size={screens.md ? 200 : 70} />
              {/* <h6 className='mt-4'>{sponsor.name}</h6> */}
            </a>
          </center>
        ))}
      </Carousel>
    </div>
  );
}

export default React.memo(SponsorCarousel);
