import React from 'react';
import { Modal, Avatar, Image } from 'antd';
import './socialShareModal.css';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  // EmailShareButton,
  // EmailShareIcon,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  // ViberShareButton,
  // VKShareButton,
  // WhatsappShareButton,
  // WorkplaceShareButton
} from 'react-share';
import ElAvatar from '../../../assets/images/EL-Avatar.png';

export function SocialShareModal(props) {

  let { title, description, currentEntry } = props;
  //  let webBaseUrl = process.env.REACT_SITE_URL ? process.env.REACT_SITE_URL : 'https://equilibertysoftuvo.softuvo.xyz/';
  //let locationUrl = window.location.href.replace('/', '');
  // const shareUrl = process.env.REACT_SITE_URL ? process.env.REACT_SITE_URL : 'https://equilibertysoftuvo.softuvo.xyz/';
  // const shareUrl=webBaseUrl + ''+locationUrl;
  const shareUrl = window.location.href;
  function beforeShare(event) {

    // return new Promise((resolve,reject)=>{
    //     debugger

    // })
  }

  function onShareWindowClose(event) {

  }

  function openShareDialogOnClick(event) {

  }

  return (
    <Modal
      className='social-media-share-modal'
      title={title}
      centered
      visible={props.visible}
      //   onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      footer={null}
    >
      <div className='Demo__container'>
        <div
          className='Demo__some-network'
          style={{ cursor: 'pointer' }}
          title='Share to Equiliberty Community'
          onClick={() => props.onOk(currentEntry)}
        >
          <Avatar src={ElAvatar} />
        </div>
        <div className='Demo__some-network'>
          <FacebookShareButton
            url={shareUrl}
            quote={description}
            className='Demo__some-network__share-button'
            beforeOnClick={(event) => beforeShare(event)}
            onShareWindowClose={(event) => onShareWindowClose(event)}
            openShareDialogOnClick={(event) => openShareDialogOnClick(event)}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
        <div className='Demo__some-network'>
          <TwitterShareButton
            url={shareUrl}
            title={description}
            className='Demo__some-network__share-button'
            beforeOnClick={(event) => {
              beforeShare(event);
            }}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
        <div className='Demo__some-network'>
          <LinkedinShareButton
            url={shareUrl}
            summary={description}
            className='Demo__some-network__share-button'
            beforeOnClick={(event) => {
              beforeShare(event);
            }}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </div>
    </Modal>
  );
}
