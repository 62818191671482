import React, { useState } from 'react';
import { AutoComplete, Input, Avatar } from 'antd';
import { apiGetMethod } from '../../../api/rest';
import { useHistory } from 'react-router-dom';
import './UsersSearch.scss';

function UsersSearch() {
  const [options, setOptions] = useState([]);
  const history = useHistory();

  const handleSearch = async (value) => {
    const users = await apiGetMethod(`/users/search?q=${value}`);
    const usersOptions = users.map((user) => ({
      label: (
        <>
          <Avatar src={user.userPhoto} />
          <span className='ml-2'>
            {user.firstName} {user.lastName}
          </span>
        </>
      ),
      value: user.id,
    }));
    setOptions(usersOptions);
  };

  const onSelect = (val) => {
    history.push('/user/profile', {
      postId: val,
      userType: 'other',
    });
  };
  return (
    <AutoComplete
      style={{
        width: '100%',
      }}
      className='usersSearch'
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
    >
      <Input.Search
        placeholder='Search Community'
        enterButton
        style={{ padding: 0 }}
      />
    </AutoComplete>
  );
}

export default UsersSearch;
