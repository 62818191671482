import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Input, Row, Layout, message } from "antd";
import Container from "../../widgets/container";
import style from './resetpassword.module.sass'
import './reset-password.scss'
import { history } from "../../../redux/store";
import { setLoading as running, resetPassword, checkResetPasswordToken } from "../../../redux/slices/authSlice";
import { header } from '../../../api/rest';
import { CONSTANTS ,REGEX_CONSTANTS} from '../../shared/constants'
var {
  ERROR_MESSAGE,
  PASSWORD_VALIDATION_MESSAGE_BCK
} = CONSTANTS;
var {PASSWORD_VALIDATION_REGEX_BCK}=REGEX_CONSTANTS;

const ResetPassword = () => {

  React.useEffect(() => {
    document.title = "Reset Password";
  })

  const dispatch = useDispatch();
  const session = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [urlToken, setUrlToken] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    let url = window.location.pathname;
    if (url.split('/').length === 4) {
      let token = url.split('/')[3];
      setUrlToken(token);
      CheckPasswordToken();
    }
  }, [urlToken]);

  useEffect(() => {
    CheckPasswordToken();
  }, [])


  function CheckPasswordToken() {
    if (urlToken) {
      let data = {
        password_verified_token: urlToken
      }
      checkResetPasswordToken(data, header).then(res => {
      }).catch(err => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        if (err && err.message) {
          // setErrorMessage(err.message)
        }
      })
    } else {
    }
  }


  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    dispatch(running(true));
    const body = {
      // emailAddress: values.email,
      password: values.password,
      email_verify_token: urlToken
    };
    dispatch(resetPassword(body, urlToken));
  };

  useEffect(() => {
    if (session.data.name && session.data.name === "Error") {
    } else {
      // if email verification message recieved then it will redirect to home
      if (loading && !session.loading && session.data.message) {
        // message.success(session.data.message);
        history.push('/');
      }
    }
    setLoading(session.loading);
  }, [loading, session]);

  const onFinishFailed = errorInfo => {
  };
  return (
    <Layout.Content className={style.block}>
      <Row className={`header-divider`} />
      <Container className={`auth-modal min-height-60vh ${style.reg_container}`}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="reset-password-form"
        >
          <h3>Reset Password</h3>
          <Form.Item
            label={"Password"}
            name="password"
            rules={[
              {
                required: true,
                // message: 'Please input your password!',
                pattern:PASSWORD_VALIDATION_REGEX_BCK,
                validator: (_, value) => PASSWORD_VALIDATION_REGEX_BCK.test(value)  ? Promise.resolve() : Promise.reject(PASSWORD_VALIDATION_MESSAGE_BCK),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={"Confirm Password"}
            name="password_confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Row justify={'center'}>
            <Button type="primary" htmlType="submit" className={style.submit_btn} loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Container>
    </Layout.Content>
  )
}

export default ResetPassword;