import React from 'react';
import {
    Modal,
    Button,
    Form,
    Input,
    Row
} from 'antd';
import {
    REGEX_CONSTANTS
    // ,POP_UP_MODAL_HEADER_CONSTANTS
} from '../constants';

var {
    ACCEPT_NUMBER
} = REGEX_CONSTANTS;
// var {
//     EDIT_LIBERAL
// } = POP_UP_MODAL_HEADER_CONSTANTS;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export function AddEditLocalLiberator(props) {
    let { title, loading } = props;
    const [form] = Form.useForm();
    form.setFieldsValue({
        ...props.currentEntry
    })

    return (
        <Modal
            title={title}
            centered
            visible={props.visible}
            onOk={(event) => props.onOk(event)}
            onCancel={() => props.onCancel(false)}
            okButtonProps={{ title: "Submit" }}
            footer={null}>
            <Form
                {...layout}
                form={form}
                onFinish={(event) => props.onOk(event)}>
                <Form.Item
                    label={"Contact Name"}
                    name="contactName"
                    rules={[{
                        required: true,
                        message: 'Please input your Contact Name!',
                    }]}>
                    <Input placeholder="Contact Name" />
                </Form.Item>
                <Form.Item
                    label={"Address"}
                    name="address"
                    rules={[{
                        required: true,
                        message: 'Please input your Address!',
                    }]}>
                    <Input placeholder="Address" />
                </Form.Item>
                <Form.Item
                    label={"Email"}
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        }, {
                            required: true,
                            message: 'Please input your Email',
                        }]}>
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                    label={"Phone Number"}
                    name="phoneNumber"
                    rules={[{
                        required: true,
                        pattern: ACCEPT_NUMBER,
                        validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid Phone Number'),
                    }]}>
                    <Input placeholder="Phone Number" maxLength="10" />
                </Form.Item>
                <Form.Item
                    label={"City"}
                    name="city"
                    rules={[{
                        required: true,
                        message: 'Please input your City!',

                    }]}>
                    <Input placeholder="City" />
                </Form.Item>
                <Form.Item
                    label={"State"}
                    name="state"
                    rules={[{
                        required: true,
                        message: 'Please input your State!',

                    }]}>
                    <Input placeholder="State" />
                </Form.Item>

                <Form.Item
                    label={"Zip Code"}
                    name="zip"
                    rules={[{
                        required: true,
                        pattern: ACCEPT_NUMBER,
                        validator: (_, value) => ACCEPT_NUMBER.test(value) ? Promise.resolve() : Promise.reject('Please Enter Valid Zipcode'),
                    }]}>
                    <Input placeholder={`Zip Code`} maxLength="6" />
                </Form.Item>
                <Row justify={'center'}>
                    <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                </Row>
            </Form>
        </Modal>
    )
}
