import React from 'react';
import { Modal, Button, Form, Input, Row, Select } from 'antd';
import {
  REGEX_CONSTANTS,
  // POP_UP_MODAL_HEADER_CONSTANTS,
  CONSTANTS,
} from '../constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { unescapeHtml } from '../../../utils/helpers';

var { ACCEPT_NUMBER } = REGEX_CONSTANTS;
// var {
//     EDIT_LIBERAL
// } = POP_UP_MODAL_HEADER_CONSTANTS;
var { NEWS_TYPE, NEWS_STATUS } = CONSTANTS;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Option } = Select;

export function AddEditNews(props) {
  let { title, loading } = props;
  const [form] = Form.useForm();
  form.setFieldsValue({
    ...props.currentEntry,
  });
  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      okButtonProps={{ title: 'Submit' }}
      footer={null}
    >
      <Form {...layout} form={form} onFinish={(event) => props.onOk(event)}>
        <Form.Item
          label={'Title'}
          name='title'
          rules={[
            {
              required: true,
              message: 'Please input your News Title!',
            },
          ]}
        >
          <Input placeholder='News Title' />
        </Form.Item>
        <Form.Item
          label={'Type'}
          name='type'
          rules={[
            {
              required: true,
              message: 'Please input your News type!',
            },
          ]}
        >
          <Select placeholder='Choose category' allowClear>
            {NEWS_TYPE.length
              ? NEWS_TYPE.map((c) => (
                  <Option value={c.id} key={c.id}>
                    {c.text}
                  </Option>
                ))
              : ''}
          </Select>
          {/* <Input placeholder="News type" /> */}
        </Form.Item>
        <Form.Item
          label={'News Text'}
          name='text'
          rules={[
            {
              required: true,
              message: 'Please input your News text!',
            },
          ]}
        >
          <CKEditor
            editor={ClassicEditor}
            data={unescapeHtml(form.getFieldValue('text'))}
            onChange={(event, editor) => {
              const data = editor.getData();
              form.setFieldsValue({ text: data });
            }}
            required
          />
        </Form.Item>
        {/* <Form.Item
            label={'News Text'}
            name='text'
            rules={[
              {
                required: true,
                message: 'Please input your News text!',
              },
            ]}
          >
            <Input placeholder='News text' />
          </Form.Item> */}
        <Form.Item
          label={'News Status'}
          name='newsStatus'
          rules={[
            {
              required: true,
              message: 'Please input your News Status!',
            },
          ]}
        >
          <Select
            placeholder='Choose news status'
            style={{
              border: '4px solid #e4e4e4',
              background: '#fff',
              height: '45px',
              boxShadow: 'none',
            }}
            allowClear
          >
            {NEWS_STATUS.length
              ? NEWS_STATUS.map((c) => (
                  <Select.Option value={c.id} key={c.id}>
                    {c.text}
                  </Select.Option>
                ))
              : ''}
          </Select>
          {/* <Input placeholder="News Status" /> */}
        </Form.Item>
        <Form.Item
          label={'City'}
          name='city'
          rules={[
            {
              required: true,
              message: 'Please input your City!',
            },
          ]}
        >
          <Input placeholder='City' />
        </Form.Item>
        <Form.Item
          label={'Zip Code'}
          name='zip'
          help='Use zip code 00000 for showing news in all communities'
          rules={[
            {
              required: true,
              pattern: ACCEPT_NUMBER,
              validator: (_, value) =>
                ACCEPT_NUMBER.test(value)
                  ? Promise.resolve()
                  : Promise.reject('Please Enter Valid Zipcode'),
            },
          ]}
        >
          <Input placeholder={`Zip Code`} maxLength='6' />
        </Form.Item>
        <Row justify={'center'}>
          <Button type='primary' htmlType='submit' loading={loading}>
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
