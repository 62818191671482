import React from 'react';
import { Modal, Button, Form, Input, Row } from 'antd';
import {
  REGEX_CONSTANTS,
  // ,POP_UP_MODAL_HEADER_CONSTANTS
} from '../constants';
import { useSelector } from 'react-redux';

var { ACCEPT_NUMBER, VALID_URL_REGEX } = REGEX_CONSTANTS;
// var { EDIT_LIBERAL } = POP_UP_MODAL_HEADER_CONSTANTS;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export function AddEditUserResource(props) {
  const user = useSelector((state) => state?.user?.user);
  let { title, loading } = props;
  const [form] = Form.useForm();
  form.setFieldsValue({
    ...props.currentEntry,
  });

  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      okButtonProps={{ title: 'Submit' }}
      footer={null}
    >
      <Form
        {...layout}
        form={form}
        onFinish={(event) =>
          props.onOk({
            ...event,
            referenceId: props.currentEntry.referenceId
              ? props.currentEntry.referenceId
              : props.currentEntry._id,
          })
        }
      >
        <Form.Item
          label={user.role === 'sponsor' ? 'Name' : 'Business Name'}
          name='name'
          rules={[
            {
              required: true,
              message: 'Please input your Resource Name!',
            },
          ]}
        >
          <Input
            placeholder={user.role === 'sponsor' ? 'Name' : 'Business Name'}
          />
        </Form.Item>

        {user.role === 'sponsor' && (
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>
        )}

        <Form.Item
          label={'Phone Number'}
          name='phone'
          rules={[
            {
              required: false,
              pattern: ACCEPT_NUMBER,
              validator: (_, value) =>
                ACCEPT_NUMBER.test(value)
                  ? Promise.resolve()
                  : Promise.reject('Please Enter Valid Phone Number'),
            },
          ]}
        >
          <Input placeholder='Phone Number' maxLength='10' />
        </Form.Item>
        {user.role !== 'sponsor' && (
          <Form.Item
            label={'Website'}
            name='website'
            rules={[
              {
                required: false,
                pattern: VALID_URL_REGEX,
                validator: (_, value) =>
                  VALID_URL_REGEX.test(value)
                    ? Promise.resolve()
                    : Promise.reject('Please Enter Valid Website'),
              },
            ]}
          >
            <Input placeholder='Website' />
          </Form.Item>
        )}
        {user.role !== 'sponsor' && (
          <Form.Item
            label={'Address'}
            name='address'
            rules={[
              {
                required: true,
                message: 'Please input your Address!',
              },
            ]}
          >
            <Input placeholder='Address' />
          </Form.Item>
        )}
        <Row justify={'center'}>
          <Button type='primary' htmlType='submit' loading={loading}>
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
