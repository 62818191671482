import React, { useEffect, useState } from 'react';
// REACT REDUX
import { useDispatch, useSelector } from 'react-redux';
// REACT ROUTER
import { Link, NavLink, useHistory } from 'react-router-dom';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
// ANT DESIGN
import {
  Layout,
  message,
  Spin,
  Popover,
  Button
  // ,Row,  Avatar, Card,Button , Input,Upload,
} from 'antd';
// Moment
import moment from 'moment';
// Light Box
import Lightbox from 'react-image-lightbox';
import {
  CloseOutlined,
  DeleteOutlined,
  DashOutlined,
  EditOutlined,
} from '@ant-design/icons';
// import {
//   QqCircleFilled,
//   TwitterCircleFilled,
//   FacebookFilled,
//   FormOutlined,
//   CameraOutlined,
//   VideoCameraOutlined,
//   FileTextOutlined,
//   ProfileOutlined
// } from '@ant-design/icons'
// import style from './dashboard.module.sass';
import { history } from '../../../redux/store';
// import MyAncor from "../../widgets/global/ancor";
// import PostItem from "./postItem";
// import dashBanner from '../../../assets/images/dash_banner.jpeg';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import 'react-google-places-autocomplete/dist/index.min.css';

// IMAGES
// import MessageIcon from '../../../assets/images/icon-message.png';
// import UserImg from '../../../assets/images/user-img.png';
// import CommentImg from '../../../assets/images/comment-img.png';
// import Image from '../../../assets/images/image.jpg';
import NewsIcon2 from '../../../assets/icons/marketing.png';
import NewsIcon3 from '../../../assets/images/news-3.png';
import TockenIcon from '../../../assets/images/token-icon.png';
import EditProfile from '../../../assets/icons/hovers/pencil-181818.png';
import EditProfileHover from '../../../assets/icons/hovers/pencil-18A4AD.png';
import ManageProfile from '../../../assets/icons/hovers/smile-181818.png';
import ManageProfileHover from '../../../assets/icons/hovers/smile-18A4AD.png';
import ImgCommunity from '../../../assets/icons/hovers/pin-181818.png';
import ImgCommunityHover from '../../../assets/icons/hovers/pin-18A4AD.png';
import Wallet from '../../../assets/icons/EQ_Icons_Wallet.png';
import Journy from '../../../assets/icons/EQ_Icons_Journey.png';
import LearningIcon from '../../../assets/icons/EQ_Icons_Books.png';
import ImgIcon from '../../../assets/images/hovers/img-181818.png';
import ImgIconHover from '../../../assets/images/hovers/img-18a4ad.png';
import DocumentIcon from '../../../assets/images/hovers/document-181818.png';
import DocumentIconHover from '../../../assets/images/hovers/document-18A4AD.png';
import LocationIcon from '../../../assets/images/hovers/location-181818.png';
import LocationIconHover from '../../../assets/images/hovers/location-icon.png';
import CommentIcon from '../../../assets/images/comment-icon.png';
import HeartIcon from '../../../assets/icons/EQ_Icons_Likes.png';
import EmptyheartIcon from '../../../assets/icons/EQ_Icons_Like.png';
import ShareIcon from '../../../assets/icons/EQ_Icons_Share.png';
import CommentsIcon from '../../../assets/icons/EQ_Icons_Comment.png';
import NeedAvatar from '../../../assets/images/Yates_HeadshotTiny.png';
import NewsIcon1 from '../../../assets/icons/bulb.png';
import LocationPin from '../../../assets/icons/EQ_Icons_Locations.png';
import logo from '../../../assets/images/equiliberty-logo.png';
// CONSTANTS
import {
  CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
  POP_UP_MODAL_CONSTANTS,
  API_END_POINTS_CONSTANT,
} from '../../shared/constants';
// API ACTIONS
import { apiGetMethod, header } from '../../../api/rest';
import { getUserProfile } from '../../../redux/slices/userProfileSlice';
import { setLoading as running } from '../../../redux/slices/authSlice';
import { getLedgerList } from '../../../redux/slices/walletSlice';
import { getContactList } from '../../../redux/slices/contactsSlice';
import { getReminderList } from '../../../redux/slices/reminderSlice';
import {
  getAllUserPost,
  listComments,
  createUserPost,
  fileUpload,
  toggleLikeApi,
  addComment,
  getDashBoardNews,
  deleteCommentApi,
  deleteUserPost,
  updateUserPost,
  viewPostLikedUserList,
  getLibertorListByCity,
  getResourceListByCity,
  getCommunityData,
  shareSocialMediaToken,
} from '../../../redux/slices/dashboardSlice';
import { getSurveys } from '../../../redux/slices/surveySlice';
// SOCKET
import { getLiberatorChatNotification } from '../../service/socket';
// SUBHEADER
import SubHeader from '../../widgets/subheader';
// LOADERS
import { SkeletonDashBoardPostLoader, Loader } from '../../shared/Loader';
// POPUPMODALS
import PopUpModal from '../../shared/popupmodal';
import SurveyPopup from '../../shared/SurveyPopup';
// DASHBOARD CSS
import './dashboard-new.scss';
import './dashboard.sass';
// Sound play Component
import { TokenEarnSound } from '../../shared/soundeffect';
import parse from 'html-react-parser';
import { unescapeHtml } from '../../../utils/helpers';
import LinkPreviewCard from '../../shared/LinkPreviewCard';

var { GET_NEWS } = API_END_POINTS_CONSTANT;
var { SOCIAL_SHARE, CONFIRMATION_HEADER, UPDATE_POST_HEADER } =
  POP_UP_MODAL_HEADER_CONSTANTS;
var { SOCIAL_SHARE_MODAL, CONFIRMATION_POPUP_MODAL, UPDATE_POPUP_MODAL } =
  POP_UP_MODAL_CONSTANTS;

var {
  DASHBOARD_PLACEHOLDER,
  TOKEN_EXPIRED_MESSAGE,
  ERROR_IMAGE_URL,
  ERROR_MESSAGE,
  FILE_LENGTH_LIMIT,
  POST_REMOVE_MESSAGE,
  COMMENT_REMOVE_MESSAGE,
} = CONSTANTS;

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.user);

  // REACT HOOKS
  const [userData, setUserData] = useState(session.data);
  // const [showTextBox, setShowTextBox] = useState(false);
  const [postInput, setPostInput] = useState('');
  const storageData = localStorage.getItem('userData');
  const [isLoading, showIsLoading] = useState(true);
  const { id, city, zipcode, badgeToken, role, communityId, email } =
    storageData ? JSON.parse(storageData).payload : '';
  const { token } = storageData ? JSON.parse(storageData) : '';
  const [allPosts, setAllPosts] = useState([]);
  const [toggleCommentBox, setToggleCommentBox] = useState('');
  const [mapBoxStatus, setMapBoxStatus] = useState(false);
  const [state, setState] = React.useState({
    lat: '28.612821703621936',
    lon: '77.23326859801553',
    geo: {},
    locationEnable: true,
  });
  const [postDocumentObj, setPostDocumentObj] = useState({
    postImage: '',
    // postMap: '',
    postDocument: '',
  });
  const [
    showLoader,
    // , setShowLoader
  ] = useState({
    postImage: false,
    postMap: false,
    postDocument: false,
  });
  const [
    commentBoxObj,
    // , setCommentBoxObj
  ] = useState({
    postId: '',
    userId: '',
    comment: '',
  });
  // Default status is blank when file choose it will selected when file is uploaded it is set to done
  const [postDocumentObjStatus, setPostDocumentObjStatus] = useState({
    postImage: '',
    postMap: '',
    postDocument: '',
  });
  const [isSingleFileSelected, setIsSingleFileSelected] = useState(false);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [comment, setComment] = useState('');
  const [currentPostDescription, setCurrentPostDescription] = useState('');
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [checkInAddress, setCheckInAddress] = useState('');
  const [news, setNews] = useState([]);
  const [lastCommentViewedPostId, setLastCommentViewedPostId] = useState();
  // Light Box
  const [imageViewingPost, setImageViewingPost] = useState({});
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [activeCommentId, setActiveCommentId] = useState('');
  const [activePostId, setActivePostId] = useState('');
  const [updatedLocationBox, setUpdatedLocationBox] = useState(false);
  const [updatePostDocumentObj, setUpdatePostDocumentObj] = useState({
    postImage: '',
    postDocument: '',
  });
  // Default status is blank when file choose it will selected when file is uploaded it is set to done
  const [updatePostDocumentObjStatus, setUpdatePostDocumentObjStatus] =
    useState({
      postImage: '',
      postDocument: '',
    });
  const [isSingleUpdatePostFileSelected, setIsSingleUpdatePostFileSelected] =
    useState(false);
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedPostLocation, setUpdatedPostLocation] = useState('');
  const [activePostDocument, setActivePostDocument] = useState('');
  const [activePostImage, setActivePostImage] = useState('');
  const isSingleFileChoosed =
    typeof postDocumentObj.postImage === 'object' &&
    typeof postDocumentObj.postDocument === 'object'
      ? false
      : typeof postDocumentObj.postImage === 'object'
      ? true
      : typeof postDocumentObj.postDocument === 'object'
      ? true
      : false;
  const isSingleUpdatedFileChoosed =
    typeof updatePostDocumentObj.postImage === 'object' &&
    typeof updatePostDocumentObj.postDocument === 'object'
      ? false
      : typeof updatePostDocumentObj.postImage === 'object'
      ? true
      : typeof updatePostDocumentObj.postDocument === 'object'
      ? true
      : false;
  const [ledgerEntries, setLedgerEntries] = useState([]);
  const [userContact, setUserContact] = useState([]);
  const [paginationObj, setPaginationObj] = useState({
    page_size: 1,
    page_limit: 10,
  });
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [totalPage, setTotalPage] = useState('');
  const [totalPost, setTotalPost] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const invitationToken = new URLSearchParams(props.location.search).get(
    'token'
  );
  const [likedUserList, setLikedUserList] = useState([]);
  const [currentReminder, setCurrentReminder] = useState([]);
  const [currentLiberator, setCurrentLiberator] = useState([]);
  const [currentResource, setCurrentResource] = useState([]);
  const [communityDetail, setCommunityDetail] = useState({
    communityPhoto: '',
    communityVideo: '',
    city: '',
    id: '',
    description: '',
    name: '',
  });
  const [playSound, setPlaySound] = useState(false);

  const dashBoardRef = React.createRef('');
  const dashBoardEntryRef = React.createRef('');
  const isLocalLiberal = session.data ? session.data.isLocalLiberal : '';
  useEffect(() => {
    const storageData = localStorage.getItem('userData');
    if (role === 'admin') {
      history.push('/admin/dashboard');
    } else {
      if (storageData) {
        getUserProfileInfo();
        getContactsListFromApi();
        getReminderListApi();
        if (zipcode) {
          getDashBoardNewContent();
          getLiberatorList();
          getResourceList();
        }
        document.title = 'Dashboard';
      } else {
        history.push('/');
        message.error(TOKEN_EXPIRED_MESSAGE);
      }

      dispatch(getSurveys());
    }
    setUserData(session.data);
  }, [userData]);

  useEffect(() => {
    if (session.data?.communityId) {
      getCommunityDetails();
    }
  }, [session.data]);

  // Important Code used in future
  // useEffect(()=>{
  //   if(session.data.isLocalLiberal){
  //     getLiberatorChatNotification((err, data) => {
  //       //
  //       if (err) return;
  //
  //     });
  //   }
  // },[session.data])

  function getUserProfileInfo() {
    let data = JSON.parse(storageData);
    dispatch(running(true));
    let user_id = {
      id: data.payload.id,
      type: 'normal',
    };
    dispatch(getUserProfile(user_id));
  }

  function getContactsListFromApi() {
    header.Authorization = token;
    getContactList(id, header)
      .then((res) => {
        if (res.data.length) {
          let arr = [];
          for (let i = 0; i < res.data.length; i++) {
            if (i > 1) {
              break;
            } else {
              arr.push(res.data[i]);
            }
          }
          setUserContact(arr);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err?.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  useEffect(() => {
    const storageData = localStorage.getItem('userData');
    if (role === 'admin') {
      history.push('/admin/dashboard');
    } else {
      if (storageData) {
        // Calling all post api
        getPostApi();
        getInvitationInfo();
      }
    }
  }, []);

  useEffect(() => {
    let data = localStorage.getItem('userData');
    if (data) {
      getLedgerEntriesFromApi();
    } else {
      history.push('/');
    }
  }, []);

  const getInvitationInfo = () => {
    if (!invitationToken) return;
    apiGetMethod(`/sponsors/invites/${invitationToken}/accept`)
      .then((res) => {
        message.success(res.message, 10);
      })
      .catch((err) => message.error(err.data.message, 10));
  };

  // get ledger List APi
  function getLedgerEntriesFromApi() {
    header.Authorization = token;
    getLedgerList(id, header)
      .then((res) => {
        if (res.ledgesData) {
          let arr = [];
          for (let i = 0; i < res.ledgesData.length; i++) {
            if (i > 1) {
              break;
            } else {
              arr.push(res.ledgesData[i]);
            }
          }
          setLedgerEntries(arr);
        }
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  function getCommunityDetails() {
    header.Authorization = token;
    let data = session.data && session.data.communityId;
    getCommunityData(data, header)
      .then((res) => {
        if (res.data) {
          setCommunityDetail((prevCommunityDetail) => {
            return {
              ...prevCommunityDetail,
              communityPhoto: res.data.communityPhoto,
              communityVideo: res.data.communityVideo,
              city: res.data.city,
              id: res.data.id,
              description: res.data.description,
              name: res.data.name,
            };
          });
        }
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  // Get Dashboard News
  function getDashBoardNewContent() {
    // let url=`${GET_NEWS}/${city}/${zipcode}`;
    let url = `${GET_NEWS}/${zipcode}`;
    getDashBoardNews(url)
      .then((res) => {
        if (res.newsList) {
          setNews(res.newsList);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function getReminderListApi() {
    let d = new Date();
    d.setUTCHours(0, 0, 0, 0);
    let modifyDate = d.setDate(d.getDate());
    let dateWOZero = new Date(modifyDate).toISOString();
    let data = {
      userId: id,
      days: 1,
      currentDate: new Date().toISOString(),
    };
    header.Authorization = token;
    getReminderList(data, header)
      .then((res) => {
        if (res.userTodayReminder) {
          setCurrentReminder(res.userTodayReminder);
        } else {
          setCurrentReminder([]);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function getLiberatorList() {
    header.Authorization = token;
    getLibertorListByCity(zipcode, header)
      .then((res) => {
        if (res.data) {
          setCurrentLiberator(res.data);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function getResourceList() {
    header.Authorization = token;
    getResourceListByCity(zipcode, header)
      .then((res) => {
        if (res.data) {
          setCurrentResource(res.data);
        }
        setShowPageLoader(false);
      })
      .catch((err) => {
        message.error(err?.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  // Submit post data
  function submitPost(event, type) {
    header.Authorization = token;
    setShowPageLoader(true);
    if (type === 'create') {
      event.preventDefault();
      if (
        postDocumentObj.postDocument === '' &&
        postDocumentObj.postImage === ''
      ) {
        callCreatePost();
      } else {
        for (const property in postDocumentObj) {
          // if(postDocumentObj[property] || po)
          if (postDocumentObj[property]) {
            // if (typeof (postDocumentObj.postImage) === "object" && typeof (postDocumentObj.postDocument) === "object") {
            //   // setIsdataLoad3(true)
            // } else if (typeof (postDocumentObj.postImage) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else if (typeof (postDocumentObj.postDocument) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else {
            // }
            uploadDocument(header, postDocumentObj[property], property, type);
          } else {
          }
        }
      }
    } else {
      if (
        updatePostDocumentObj.postDocument === '' &&
        updatePostDocumentObj.postImage === ''
      ) {
        CallUpdatePostApi();
      } else {
        for (const property in updatePostDocumentObj) {
          // if(postDocumentObj[property] || po)
          if (updatePostDocumentObj[property]) {
            // if (typeof (postDocumentObj.postImage) === "object" && typeof (postDocumentObj.postDocument) === "object") {
            //   // setIsdataLoad3(true)
            // } else if (typeof (postDocumentObj.postImage) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else if (typeof (postDocumentObj.postDocument) === "object") {
            //   uploadDocument(header, postDocumentObj[property], property);
            // } else {
            // }
            uploadDocument(
              header,
              updatePostDocumentObj[property],
              property,
              type
            );
          } else {
          }
        }
      }
    }
  }

  function uploadDocument(token, property, name, type) {
    var formData = new FormData();
    for (const File of property) {
      formData.append('files', File);
    }
    commonFileUpload(formData, token, name, type);
  }

  function commonFileUpload(formData, header, name, type) {
    fileUpload(formData, header)
      .then((res) => {
        let arr = [];
        for (let i = 0; i < res.image.length; i++) {
          arr.push(res.image[i].Location);
        }
        if (type === 'create') {
          if (name === 'postImage') {
            setPostDocumentObj((prevPostDocumentObj) => {
              // Object.assign would also work
              return { ...prevPostDocumentObj, [name]: arr };
            });
            // setIsdataLoad(true)
            setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
              // Object.assign would also work
              return { ...prevPostDocumentObjStatus, [name]: 'done' };
            });
          }
          if (name === 'postDocument') {
            setPostDocumentObj((prevPostDocumentObj) => {
              // Object.assign would also work
              return { ...prevPostDocumentObj, [name]: arr };
            });
            // setIsdataLoad1(true)
            setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
              // Object.assign would also work
              return { ...prevPostDocumentObjStatus, [name]: 'done' };
            });
          }
          if (isSingleFileChoosed) {
            setIsSingleFileSelected(isSingleFileChoosed);
          }
        } else {
          if (name === 'postImage') {
            setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
              // Object.assign would also work
              return { ...prevUpdatePostDocumentObj, [name]: arr };
            });
            setUpdatePostDocumentObjStatus(
              (prevUpdatePostDocumentObjStatus) => {
                // Object.assign would also work
                return { ...prevUpdatePostDocumentObjStatus, [name]: 'done' };
              }
            );

            // setPostDocumentObj(prevPostDocumentObj => {
            //   // Object.assign would also work
            //   return { ...prevPostDocumentObj, [name]: arr };
            // });
            // // setIsdataLoad(true)
            // setPostDocumentObjStatus(prevPostDocumentObjStatus => {
            //   // Object.assign would also work
            //   return { ...prevPostDocumentObjStatus, [name]: 'done' };
            // });
          }
          if (name === 'postDocument') {
            setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
              // Object.assign would also work
              return { ...prevUpdatePostDocumentObj, [name]: arr };
            });
            setUpdatePostDocumentObjStatus(
              (prevUpdatePostDocumentObjStatus) => {
                // Object.assign would also work
                return { ...prevUpdatePostDocumentObjStatus, [name]: 'done' };
              }
            );
            // setPostDocumentObj(prevPostDocumentObj => {
            //   // Object.assign would also work
            //   return { ...prevPostDocumentObj, [name]: arr };
            // });
            // // setIsdataLoad1(true)
            // setPostDocumentObjStatus(prevPostDocumentObjStatus => {
            //   // Object.assign would also work
            //   return { ...prevPostDocumentObjStatus, [name]: 'done' };
            // });
          }
          if (isSingleUpdatedFileChoosed) {
            setIsSingleUpdatePostFileSelected(isSingleUpdatedFileChoosed);
          }
        }
      })
      .catch((err) => {
        setShowPageLoader(false);
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  useEffect(() => {
    let { postImage, postDocument } = postDocumentObjStatus;
    if (postDocument === 'done' && postImage === 'done') {
      callCreatePost();
    }
  }, [postDocumentObjStatus]);

  useEffect(() => {
    if (isSingleFileSelected) {
      callCreatePost();
    }
  }, [isSingleFileSelected]);

  function callCreatePost() {
    let data = {};
    data.postImage = postDocumentObj.postImage;
    data.postMap = checkInAddress;
    data.postDocument = postDocumentObj.postDocument;
    data.description = postInput;
    data.userId = id;
    data.city = city;
    data.zip = zipcode;
    dispatch(running(true));
    // dispatch(createUserPost(data, header))
    setPlaySound(true);

    setTimeout(() => {
      createUserPostApi(data);
    }, 2000);
  }

  function createUserPostApi(data) {
    header.Authorization = token;
    createUserPost(data, header)
      .then((res) => {
        // showing success message
        message.success(res.message);
        setPostDocumentObj((prevPostDocumentObj) => {
          // Object.assign would also work
          return {
            ...prevPostDocumentObj,
            postImage: '',
            // postMap: '',
            postDocument: '',
          };
        });
        setCheckInAddress('');
        setState(state);
        setMapBoxStatus(false);
        setIsSingleFileSelected(false);
        setShowPageLoader(false);
        setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
          // Object.assign would also work
          return {
            ...prevPostDocumentObjStatus,
            postImage: '',
            postMap: '',
            postDocument: '',
          };
        });
        // calling get post api
        getPostApi();
        getUserProfileInfo();
        setPlaySound(false);
      })
      .catch((err) => {
        message.error(
          err && err.data.message ? err.data.message : ERROR_MESSAGE
        );
        setShowPageLoader(false);
        setPlaySound(false);
      });
  }

  // Submit comment data
  function submitComment(event) {
    event.preventDefault();
    setShowPageLoader(true);
    let data = {};
    header.Authorization = token;
    data.userId = id;
    data.postId = toggleCommentBox;
    data.comment = comment;
    dispatch(running(true));
    // dispatch(createUserPost(data, header))
    addComment(data, header)
      .then((res) => {
        // showing success message
        message.success(res.message);
        setToggleCommentBox('');
        setComment('');
        setState(state);
        getPostApi();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function handleCreatePostInput(event) {
    let { value } = event.target;
    setPostInput(value);
  }

  function redirectToWallet() {
    history.push('/wallet');
  }

  // fetch all post users post
  function getPostApi(append = false) {
    const data = {
      userId: id,
      zip: zipcode
    };
    let { page_size, page_limit } = paginationObj;
     setLoadMoreLoading(true)
    getAllUserPost(data, append ? page_size : 1, page_limit, header)
      .then((res) => {
        if (res.postList?.length) {
          if (append) {
            let data = allPosts.concat(res.postList);
            setAllPosts(data);
          } else {
            setAllPosts(res.postList);
          }
          let totalPage = Math.ceil(res.totalCount / page_limit);
          setTotalPage(totalPage);
          setTotalPost(res.totalCount);
          setHasMore(true);
          setIsBottom(false);
        } else {
          setAllPosts(res.postList);
        }
        showIsLoading(false);
        setPostInput('');
        setIsBottom(false);
        setShowPageLoader(false);
         setLoadMoreLoading(false)
        setPaginationObj((prevPaginationObj) => {
            return { ...prevPaginationObj, page_size: page_size + 1 };
          });
      })
      .catch((err) => {
        showIsLoading(false);
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
         setLoadMoreLoading(false)
      });
    // dispatch(running(true));
    // dispatch(getAllUserPost());
    // showIsLoading(false)
    // setPostInput('');
  }

  // fetch all comments of a particular post
  function getAllComments(postId) {
    header.Authorization = token;
    listComments(postId, header)
      .then((res) => {
        if (res.data && res.data) {
          setLastCommentViewedPostId(postId);
          if (lastCommentViewedPostId) {
            const index = allPosts.findIndex(
              (post) => post._id === lastCommentViewedPostId
            );
            allPosts[index].comments = [];
          }
          const index = allPosts.findIndex((post) => post._id === postId);
          allPosts[index].comments = res.data;
          setAllPosts([]);
          setAllPosts(allPosts);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function collapseAllComments() {
    getPostApi();
    // if (lastCommentViewedPostId) {
    //   const index = allPosts.findIndex(post => post._id === lastCommentViewedPostId);
    //   // allPosts[index].comments = [];

    //   delete allPosts[index].comments;
    //   debugger
    //   setAllPosts([]);
    //   setAllPosts(allPosts);
    // }
    // const index = allPosts.findIndex(post => post._id === lastCommentViewedPostId);
    // // allPosts[index].comments = res.data;
    // setAllPosts([]);
    // setAllPosts(allPosts);
  }

  // oepn light box
  function openLightBox(post, imageIndex) {
    setImageViewingPost(post);
    setImageIndex(imageIndex);
    setIsLightBoxOpen(true);
  }

  // file load api is common api for uploading file and images
  function fileUploadApi(event) {
    let { files, name } = event.target;
    if (files.length > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
    } else {
      if (postDocumentObj[name] === '') {
        setPostDocumentObj((prevPostDocumentObj) => {
          // Object.assign would also work
          return { ...prevPostDocumentObj, [name]: files };
        });
        setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
          // Object.assign would also work
          return { ...prevPostDocumentObjStatus, [name]: 'selected' };
        });
      } else {
        let data = addFileListObj(name, files, postDocumentObj, 'create');
        if (data) {
          setPostDocumentObj((prevPostDocumentObj) => {
            // Object.assign would also work
            return { ...prevPostDocumentObj, [name]: data };
          });
          setPostDocumentObjStatus((prevPostDocumentObjStatus) => {
            // Object.assign would also work
            return { ...prevPostDocumentObjStatus, [name]: 'selected' };
          });
        }
      }
      // setShowLoader(prevShowLoader => {
      //   // Object.assign would also work
      //   return { ...prevShowLoader, [name]: true };
      // });
    }
  }
  // for same file choose
  function onInputClick(event) {
    event.target.value = '';
  }

  // handling add more file list object
  function addFileListObj(name, file, documentVariable, type) {
    let activeArr = Array.prototype.slice.call(documentVariable[name]),
      fileArr = Array.prototype.slice.call(file);
    let newArray = activeArr.concat(fileArr);
    let activePostImageLength = activePostImage ? activePostImage.length : 0;
    let activePostDocumentLength = activePostDocument
      ? activePostDocument.length
      : 0;
    let activeVariableLength =
      name === 'postImage' ? activePostImageLength : activePostDocumentLength;
    let fileLength =
      type === 'create'
        ? newArray.length
        : activeVariableLength + newArray.length;
    if (fileLength > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
      return false;
    } else {
      newArray.forEach(function (file, key) {
        newArray[key] = file;
      });
      return newArray;
    }
  }

  // calling toggle like api
  function callingToggleLikeApi(postId, userId, Status) {
    let data = {};
    setShowPageLoader(true);
    data.postId = postId;
    data.userId = userId;
    data.status = Status;
    let toggleStatus = Status === 1 ? true : false,
      timeOutDuration = Status === 1 ? 1500 : 300;
    if (toggleStatus) {
      setPlaySound(true);
    }
    setTimeout(() => {
      toggleLikeApiFunction(data);
    }, timeOutDuration);
  }

  function toggleLikeApiFunction(data) {
    header.Authorization = token;

    toggleLikeApi(data, header)
      .then((res) => {
        message.success(res.message);
        const index = allPosts.findIndex((post) => post._id === data.postId);
        allPosts[index].liked = data.status;
        if (data.status) {
          allPosts[index].totalLikes += 1;
        } else {
          allPosts[index].totalLikes -= 1;
        }
        setAllPosts([]);
        setAllPosts(allPosts);
        setShowPageLoader(false);
        setPlaySound(false);
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
        setPlaySound(false);
      });
  }

  // Toggle comment box
  function toggleCommentBoxfunc(id) {
    setToggleCommentBox(id);
  }

  // Toggle map box
  function toggleMapBox(status) {
    setMapBoxStatus(status);
  }
  // show popup modal
  function showPopupModal(currentPostDescription) {
    setCurrentPostDescription(currentPostDescription);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: SOCIAL_SHARE_MODAL,
        modalHeader: SOCIAL_SHARE,
      };
    });
  }

  // close poup modal
  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
    setActiveCommentId('');
    setActivePostId('');
    setUpdatedLocationBox(false);
  }

  // save value of user comment
  function handleCommentInput(event) {
    let { value } = event.target;
    // setCommentBoxObj(prevcommentBoxObj => {
    //   return {
    //     ...prevcommentBoxObj,
    //     [name]:value
    //   }
    // })
    setComment(value);
  }

  function handleCheckInInput(event) {
    let { label } = event;
    setCheckInAddress(label);
    // setPostDocumentObj(prevPostDocumentObj => {
    //   // Object.assign would also work
    //   return { ...prevPostDocumentObj, postMap: label };
    // });
  }

  function setChoosedFiles(event) {
    let { files, name } = event.target,
      obj = {},
      arr = [];
    obj.name = name;
    obj.files = files;
    arr.push(obj);
  }

  function removeSelectedFile(event, name, id) {
    let activeArr = Array.prototype.slice.call(postDocumentObj[name]);
    activeArr.splice(id, 1);
    activeArr.forEach(function (file, key) {
      activeArr[key] = file;
    });
    let data = activeArr.length ? activeArr : '';
    // delete postDocumentObj[name][id]/
    setPostDocumentObj((prevPostDocumentObj) => {
      return { ...prevPostDocumentObj, [name]: data };
    });
  }
  // redirect to user profileo
  function redirectToUserProfile(postId, userType) {
    history.push('/user/profile', {
      // pathname: ,
      state: {
        postId: postId,
        userType: userType,
      },
    });
    // history.push('/user/profile');
  }
  // Showing confimation poupmodal
  function ToggleConfirmationPopup(postId, id, description, type, modalHeader) {
    setActiveCommentId(id);
    setActivePostId(postId);
    setCurrentPostDescription(description);
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: modalHeader,
      };
    });

    if (type === 'UPDATE_POPUP_MODAL') {
      let data = allPosts.filter((x) => {
        if (x.id === postId) {
          return x;
        }
      })[0];
      setUpdatedDescription(data.description);
      setUpdatedPostLocation(data.postMap);
      let postDocument = data.postDocument
        ? data.postDocument.filter(function (el) {
            return el != '';
          })
        : [];
      let postImage = data.postImage
        ? data.postImage.filter(function (el) {
            return el != '';
          })
        : [];
      setActivePostDocument(postDocument);
      setActivePostImage(postImage);
      setCheckInAddress(data.postMap);
    } else {
    }
  }

  // when user on submit button in delete comment popup modal then api function is called
  function callDeleteCommentApi() {
    // event.preventDefault();
    functionDeleteCommentApi();
  }

  // Calling delete Comment api
  function functionDeleteCommentApi() {
    let data = {
      commentId: activeCommentId,
    };
    header.Authorization = token;
    deleteCommentApi(data, header)
      .then((res) => {
        message.success(res.message);
        // getAllComments(activePostId);
        getPostApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  // when user on submit button in delete comment popup modal then api function is called
  function callDeletePostApi() {
    // event.preventDefault();
    functionDeleteUserPostApi();
  }
  // Calling delete user post api
  function functionDeleteUserPostApi() {
    let data = {
      postId: activePostId,
    };
    header.Authorization = token;
    deleteUserPost(data, header)
      .then((res) => {
        message.success(res.message);
        // getAllComments(activePostId);
        getPostApi();
        closePopupModal();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
        setShowPageLoader(false);
      });
  }

  function onSubmitPopUpModal(event) {
    event.preventDefault();
    if (currentPostDescription === COMMENT_REMOVE_MESSAGE) {
      setShowPageLoader(true);
      callDeleteCommentApi();
    }
    if (currentPostDescription === POST_REMOVE_MESSAGE) {
      setShowPageLoader(true);
      callDeletePostApi();
    }
    if (popUpModal.type === UPDATE_POPUP_MODAL) {
      submitPost(event, 'update');
    }
    // currentPostDescription === COMMENT_REMOVE_MESSAGE ? callDeleteCommentApi : callDeletePostApi
  }

  function handlePopUpModalInput(event) {
    let { value } = event.target;
    setUpdatedDescription(value);
  }

  function handlePopUpModalFileInput(event) {
    let { files, name } = event.target,
      arrayLength =
        name === 'postImage'
          ? activePostImage.length
          : activePostDocument.length,
      totalCount = arrayLength + files.length;
    if (totalCount > 5) {
      message.error(FILE_LENGTH_LIMIT, 10);
    } else {
      if (updatePostDocumentObj[name] === '') {
        setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
          // Object.assign would also work
          return { ...prevUpdatePostDocumentObj, [name]: files };
        });
        setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
          // Object.assign would also work
          return { ...prevUpdatePostDocumentObjStatus, [name]: 'selected' };
        });
      } else {
        let data = addFileListObj(name, files, updatePostDocumentObj, 'update');
        if (data) {
          setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObj, [name]: data };
          });
          setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
            // Object.assign would also work
            return { ...prevUpdatePostDocumentObjStatus, [name]: 'selected' };
          });
        }
      }
    }
  }

  function handlePopUpModalLocationInpt(event) {
    let { label } = event;
    setUpdatedPostLocation(label);
  }

  function CallUpdatePostApi() {
    let data = {};
    header.Authorization = token;
    data.id = activePostId;
    data.postImage = updatePostDocumentObj.postImage
      ? finalUploadedImageAndDocument('postImage')
      : activePostImage; //    updatePostDocumentObj.postImage;
    data.postMap = updatedLocationBox ? updatedPostLocation : checkInAddress;
    data.postDocument = updatePostDocumentObj.postDocument
      ? finalUploadedImageAndDocument('postDocument')
      : activePostDocument; // updatePostDocumentObj.postDocument;
    data.description = updatedDescription;
    data.userId = id;
    dispatch(running(true));
    updateUserPost(data, header)
      .then((res) => {
        message.success(res.message);
        // getAllComments(activePostId);
        setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
          // Object.assign would also work
          return {
            ...prevUpdatePostDocumentObj,
            postImage: '',
            postDocument: '',
          };
        });
        setCheckInAddress('');
        setState(state);
        setMapBoxStatus(false);
        setIsSingleFileSelected(false);
        setShowPageLoader(false);
        setUpdatePostDocumentObjStatus((prevUpdatePostDocumentObjStatus) => {
          // Object.assign would also work
          return {
            ...prevUpdatePostDocumentObjStatus,
            postImage: '',
            postDocument: '',
          };
        });
        setPopUpModal((prevPopUpModal) => {
          return {
            ...prevPopUpModal,
            visible: false,
            type: '',
            modalHeader: '',
          };
        });
        setUpdatedLocationBox(false);
        getPostApi();
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  function removeSelectedImageOrDoc(event, index, name) {
    let { id } = event.target;
    if (name === 'activePostDocument') {
      let arr = activePostDocument.filter((value, id) => id !== index);
      setActivePostDocument(arr);
    } else {
      let arr = activePostImage.filter((value, id) => id !== index);
      setActivePostImage(arr);
    }
  }

  function toggleLocationBox() {
    setUpdatedLocationBox(true);
  }

  function removeUpdatedModalSelectedFile(event, name, id) {
    let activeArr = Array.prototype.slice.call(updatePostDocumentObj[name]);
    activeArr.splice(id, 1);
    activeArr.forEach(function (file, key) {
      activeArr[key] = file;
    });
    let data = activeArr.length ? activeArr : '';
    setUpdatePostDocumentObj((prevUpdatePostDocumentObj) => {
      // Object.assign would also work
      return { ...prevUpdatePostDocumentObj, [name]: data };
    });
  }

  function finalUploadedImageAndDocument(type) {
    let arr;
    if (type === 'postDocument') {
      if (activePostDocument.length) {
        arr = updatePostDocumentObj.postDocument.concat(activePostDocument);
        return arr;
      } else {
        return updatePostDocumentObj.postDocument;
      }
    } else {
      if (activePostImage.length) {
        arr = updatePostDocumentObj.postImage.concat(activePostImage);
        return arr;
      } else {
        return updatePostDocumentObj.postImage;
      }
    }
  }

  useEffect(() => {
    let { postImage, postDocument } = updatePostDocumentObjStatus;
    if (postDocument === 'done' && postImage === 'done') {
      CallUpdatePostApi();
    }
  }, [updatePostDocumentObjStatus]);

  useEffect(() => {
    if (isSingleUpdatePostFileSelected) {
      CallUpdatePostApi();
    }
  }, [isSingleUpdatePostFileSelected]);

  function handleScroll() {
    if (dashBoardRef.current) {
      //
      let { scrollHeight, clientHeight, scrollTop } = dashBoardRef.current;
      //
      //
      //
      //
      //
      if (scrollHeight - scrollTop === clientHeight) {
        let pageSize = paginationObj.page_size,
          data = pageSize + 1;
        if (data <= totalPage) {
          setPaginationObj((prevPaginationObj) => {
            return { ...prevPaginationObj, page_size: data };
          });
          // setIsBottom(true)
        } else {
        }
      }
    }
  }

  useEffect(() => {
    if (isBottom) {
      getPostApi();
    }
  }, [isBottom]);

  function getLikedUserListApi(postId) {
    header.Authorization = token;
    viewPostLikedUserList(postId, header)
      .then((res) => {
        if (res.data) {
          setLikedUserList(res.data);
        } else {
          setLikedUserList([]);
        }
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  const handleVisibleChange = (visible) => {
    getLikedUserListApi(visible);
  };

  function socialMediaShareAPI() {
    header.Authorization = token;
    let data = {
      id: id,
    };
    shareSocialMediaToken(data, header)
      .then((res) => {
        debugger;
      })
      .catch((err) => {
        message.error(err && err.message ? err.message : ERROR_MESSAGE);
      });
  }

  // useEffect(() => {
  //   debugger
  //   if(paginationObj.page_size){
  //     getPostApi();
  //   }
  // }, paginationObj)

  let { firstName, userPhoto, lastName } = session.data;
  let dashBoardPostSectionVariables = {
      toggleCommentBox,
      postDocumentObj,
      showLoader,
      postInput,
      isLoading,
      allPosts,
      state,
      mapBoxStatus,
      commentBoxObj,
      checkInAddress,
      id,
      totalPage,
      paginationObj,
      totalPost,
      likedUserList,
      communityDetail,
      storageData,
      zipcode,
      loadMoreLoading
    },
    dashBoardPostSectionFunction = {
      handleCreatePostInput: handleCreatePostInput,
      submitPost: submitPost,
      toggleCommentBoxfunc: toggleCommentBoxfunc,
      fileUploadApi: fileUploadApi,
      callingToggleLikeApi: callingToggleLikeApi,
      toggleMapBox: toggleMapBox,
      handleCommentInput: handleCommentInput,
      submitComment: submitComment,
      showPopupModal: showPopupModal,
      handleCheckInInput: handleCheckInInput,
      setChoosedFiles: setChoosedFiles,
      removeSelectedFile: removeSelectedFile,
      getAllComments: getAllComments,
      openLightBox: openLightBox,
      redirectToUserProfile: redirectToUserProfile,
      ToggleConfirmationPopup: ToggleConfirmationPopup,
      onInputClick: onInputClick,
      handleScroll: handleScroll,
      collapseAllComments: collapseAllComments,
      getLikedUserListApi: getLikedUserListApi,
      handleVisibleChange: handleVisibleChange,
      getPostApi
    },
    dashBoardNewsAndUpdateSectionVariable = {
      news,
      userContact,
      currentLiberator,
      firstName,
      lastName,
      id,
      isLocalLiberal,
    },
    updateProfileCustomProps = {
      handlePopUpModalLocationInpt: handlePopUpModalLocationInpt,
      handlePopUpModalFileInput: handlePopUpModalFileInput,
      handlePopUpModalInput: handlePopUpModalInput,
      removeSelectedImageOrDoc: removeSelectedImageOrDoc,
      toggleLocationBox: toggleLocationBox,
      removeUpdatedModalSelectedFile: removeUpdatedModalSelectedFile,
      onInputClick: onInputClick,
    },
    updatProfileCustomVariable = {
      updatedDescription,
      updatedPostLocation,
      activePostDocument,
      activePostImage,
      updatePostDocumentObj,
      updatedLocationBox,
      showPageLoader,
    };

  return (
    <Layout.Content>
      {playSound ? <TokenEarnSound /> : ''}

      {isLightBoxOpen && (
        <Lightbox
          mainSrc={imageViewingPost['postImage'][imageIndex]}
          nextSrc={
            imageViewingPost['postImage'][
              (imageIndex + 1) % imageViewingPost['postImage'].length
            ]
          }
          prevSrc={
            imageViewingPost['postImage'][
              (imageIndex + imageViewingPost['postImage'].length - 1) %
                imageViewingPost['postImage'].length
            ]
          }
          onCloseRequest={() => setIsLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex(
              (imageIndex + imageViewingPost['postImage'].length - 1) %
                imageViewingPost['postImage'].length
            )
          }
          onMoveNextRequest={() =>
            setImageIndex(
              (imageIndex + 1) % imageViewingPost['postImage'].length
            )
          }
        />
      )}
     <SubHeader pathName='Community Feed' />
      <section className='main-section'>
        {popUpModal.visible ? (
          <PopUpModal
            title={popUpModal.modalHeader}
            visible={popUpModal.visible}
            onCancel={closePopupModal}
            onOk={onSubmitPopUpModal}
            type={popUpModal.type}
            description={currentPostDescription}
            {...updateProfileCustomProps}
            {...updatProfileCustomVariable}
          />
        ) : (
          ''
        )}
        {showPageLoader ? <Loader /> : ''}
        <div className='container'>
          <div className='row'>
            {/* <DashBoardYourProfileSection
              firstName={firstName}
              role={role}
              currentReminder={currentReminder}
              badgeToken={badgeToken}
              userPhoto={userPhoto}
              ledgerEntries={ledgerEntries}
              id={id}
              redirectToWallet={redirectToWallet}
              redirectToUserProfile={redirectToUserProfile}
              userData={session}
            /> */}
            <DashBoardPostSection
              {...dashBoardPostSectionVariables}
              {...dashBoardPostSectionFunction}
              dashBoardRef={dashBoardRef}
              dashBoardEntryRef={dashBoardEntryRef}
            />
            {/* <DashBoardNewsAndUpdateSection
              {...dashBoardNewsAndUpdateSectionVariable}
              role={role}
            /> */}
          </div>
        </div>
      </section>
    </Layout.Content>
  );
};


function DashBoardPostSection(props) {
  let {
      postInput,
      isLoading,
      allPosts,
      showLoader,
      mapBoxStatus,
      toggleCommentBox,
      postDocumentObj,
      checkInAddress,
      totalPage,
      totalPost,
      paginationObj,
      communityDetail,
      likedUserList,
      storageData,
      zipcode,
      loadMoreLoading
    } = props,
    { postImage, postDocument } = showLoader;
  return (
    <div className='col-md-12 col-lg-12'>
      <div
        className='community-section'
        ref={props.dashBoardRef}
        onScroll={() => {
          props.handleScroll();
        }}
      >
        <div className='row mb-4 none'>
          <div className='col-2'>
            <Link to='/community-detail'>
              <img
                src={
                  communityDetail.communityPhoto
                    ? communityDetail.communityPhoto
                    : logo
                }
                alt={communityDetail.communityPhoto}
                className='cat-img'
              />
            </Link>
          </div>
          <div className='col-9 ml-2'>
            <h3 className='cat-head'>
              {communityDetail.id
                ? communityDetail.name
                : 'No Community Selected'}
            </h3>
            {communityDetail.id ? (
              <p className='line-clamp-css'>{communityDetail.description}</p>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className='comment'>
          <h2 className='font-weight-bold'>
            What's happening in {communityDetail.name}...
          </h2>
          <form onSubmit={(event) => props.submitPost(event, 'create')}>
            <div className='form-group'>
              <textarea
                className='form-control'
                value={postInput}
                onChange={props.handleCreatePostInput}
                placeholder={
                  zipcode
                    ? `Share an update with ${communityDetail.name}`
                    : 'Field is disable beacause no zipcode available'
                }
                required
                disabled={zipcode ? false : true}
              />
              <div className='row'>
                <div className='col-md-6 col-7'>
                  <ul className='comment_icone d-flex align-items-center flex-wrap'>
                    <li className='h-i-c'>
                      <Spin spinning={postImage}>
                        <a
                          href='/'
                          className='upload-files-icons disabled d-flex'
                        >
                          <span className='h-i-c'>
                            <img
                              className='h-o-h'
                              src={ImgIcon}
                              alt='ImgIcon'
                            />
                            <img
                              className='d-o-h'
                              src={ImgIconHover}
                              alt='ImgIcon'
                            />
                          </span>
                        </a>
                        <input
                          type='file'
                          name='postImage'
                          accept='image/*'
                          className='disabled'
                          onChange={(event) => {
                            props.fileUploadApi(event);
                          }}
                          onClick={(event) => {
                            props.onInputClick(event);
                          }}
                          multiple
                          disabled={zipcode ? false : true}
                        />
                      </Spin>
                    </li>
                    <li className='h-i-c'>
                      <Spin spinning={postDocument}>
                        <a href='/' className='disabled d-flex'>
                          <span className='h-i-c'>
                            <img
                              className='h-o-h'
                              src={DocumentIcon}
                              alt='DocumentIcon'
                            />
                            <img
                              className='d-o-h'
                              src={DocumentIconHover}
                              alt='DocumentIcon'
                            />
                          </span>
                        </a>
                        <input
                          type='file'
                          name='postDocument'
                          accept='.doc, .docx,.pdf'
                          className='disabled'
                          onChange={(event) => {
                            props.fileUploadApi(event);
                          }}
                          onClick={(event) => {
                            props.onInputClick(event);
                          }}
                          multiple
                          disabled={zipcode ? false : true}
                        />
                      </Spin>
                    </li>
                    <li className='h-i-c' disabled={zipcode ? false : true}>
                      <span className='h-i-c'>
                        <img
                          onClick={() => props.toggleMapBox(!mapBoxStatus)}
                          src={LocationIcon}
                          alt='LocationIcon'
                          className='disabled location-icon h-o-h'
                        />
                        <img
                          onClick={() => props.toggleMapBox(!mapBoxStatus)}
                          src={LocationIconHover}
                          alt='LocationIcon'
                          className='disabled location-icon d-o-h'
                        />
                      </span>
                    </li>
                  </ul>
                  {mapBoxStatus ? (
                    <div style={{ width: '350px', marginBottom: '10px' }}>
                      <GooglePlacesAutocomplete
                        // apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                        apiKey='AIzaSyBFTEG2DxMcjMh8FUt3lRdXyQ85wJrJj0c'
                        // onClick={(event) => handleAddess(event)}
                        selectProps={{
                          // value,
                          checkInAddress,
                          onChange: (event) => props.handleCheckInInput(event),
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {/* IMPORTANT CODE USED IN FUTURE WRITTEN BY MAYANK */}
                  {/* <div className="uploadfile-name">
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                    <p>jkghkjg.jpg <span className="cross-icon"><CloseOutlined /></span></p>
                  </div> */}
                </div>
                <div className='col-md-6 col-5 d-flex align-items-center justify-content-end'>
                  <input
                    type='submit'
                    className='form-control post-comment'
                    value='POST'
                    disabled={zipcode ? false : true}
                  ></input>
                </div>
                <div className='col-md-12 post-documents'>
                  {postDocumentObj.postImage === '' ? (
                    ''
                  ) : postDocumentObj.postImage.length ? (
                    <div className='col-md-6 uploadfile-name'>
                      {Object.entries(postDocumentObj.postImage).map(
                        ([key]) => {
                          let fileName =
                            postDocumentObj.postImage[key] &&
                            postDocumentObj.postImage[key].name &&
                            postDocumentObj.postImage[key].name.length > 10
                              ? postDocumentObj.postImage[key].name.substring(
                                  0,
                                  10
                                ) +
                                '... ' +
                                postDocumentObj.postImage[key].name.substr(
                                  postDocumentObj.postImage[key].name.length - 5
                                )
                              : postDocumentObj.postImage[key].name;
                          return (
                            <p key={`${fileName}-${key}-postImage`}>
                              {fileName}
                              <span
                                className='cross-icon'
                                onClick={(event) =>
                                  props.removeSelectedFile(
                                    event,
                                    'postImage',
                                    key
                                  )
                                }
                              >
                                <CloseOutlined />
                              </span>
                            </p>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {postDocumentObj.postDocument === '' ? (
                    ''
                  ) : postDocumentObj.postDocument.length ? (
                    <div className='col-md-6 uploadfile-name'>
                      {Object.entries(postDocumentObj.postDocument).map(
                        ([key]) => {
                          let fileName =
                            postDocumentObj.postDocument[key] &&
                            postDocumentObj.postDocument[key].name &&
                            postDocumentObj.postDocument[key].name.length > 10
                              ? postDocumentObj.postDocument[
                                  key
                                ].name.substring(0, 10) +
                                '... ' +
                                postDocumentObj.postDocument[key].name.substr(
                                  postDocumentObj.postDocument[key].name
                                    .length - 5
                                )
                              : postDocumentObj.postDocument[key].name;
                          return (
                            <p key={`${fileName}-${key}-postDocument`}>
                              {fileName}
                              <span
                                className='cross-icon'
                                onClick={(event) =>
                                  props.removeSelectedFile(
                                    event,
                                    'postDocument',
                                    key
                                  )
                                }
                              >
                                <CloseOutlined />
                              </span>
                            </p>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        {isLoading ? (
          <SkeletonDashBoardPostLoader
            rows={4}
            width={['100%', '100%', '100%', '100%']}
          />
        ) : allPosts?.length ? (
          <div className='d-inline-block' ref={props.dashBoardEntryRef}>
            {allPosts.map((value, index) => {
              if (value.userId) {
                let { lastName, firstName, userPhoto } = value.userId;
                let userFullName = firstName
                  ? firstName
                  : ` ${lastName}`
                  ? lastName
                  : '';
                let postId = value.id;
                let userId = value.userId.id;
                let likeStatus = value.liked ? 0 : 1;

                const urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gm;
                const urls = value.description?.match(urlRegex)
                const postDescription = value.description?.replace(urlRegex, '')
                let createdAt = moment().isSame(value.createdAt, 'day')
                  ? moment(value.createdAt).format('hh:mm a').toString()
                  : moment(value.createdAt)
                      .format('MMM DD, YYYY hh:mm a')
                      .toString();
                // value.userStatus === "active"
                return value.id ? (
                  <div
                    className='user-comment mb-4'
                    key={`${userFullName}-${index}`}
                  >
                    <div className='row1 post_list'>
                      <div className='profile_image col-md-30 col-30'>
                        <Link
                          to={{
                            pathname: '/user-profile',
                            state: {
                              postId: value.userId.id,
                              userType:
                                value.userId.id === props.id ? 'me' : 'other',
                            },
                          }}
                          // onClick={() => props.redirectToUserProfile(value.userId.id,'other')}
                        >
                          <img
                            className='post-user-img'
                            src={value.title === 'New user joined'
                                ? NewsIcon2
                                : userPhoto}
                            alt={`${
                              userPhoto ? userPhoto : `ERROR_IMAGE_URL`
                            }-${index}`}
                          />
                        </Link>
                      </div>
                      <div className='post_detaile col-md-90 col-90'>
                        <div className='comment-content'>
                          <p>
                            <span className='comment-user-name'>
                              {value.type === 'COMMUNITY'
                                ? value.communityId.name
                                : userFullName}{' '}
                              <br />{' '}
                              <small className='opacity-05'>{createdAt}</small>
                            </span>
                            <span className='post-time'>
                              {userId === props.id ? (
                                <div className='row text-right'>
                                  <div className='col-12 col-md-12'>
                                    <div className='delete-update-popup'>
                                      <Popover
                                        placement='top'
                                        content={
                                          <div className='update-btns px-3 py-2'>
                                            <p
                                              className='comment-delete-action my-1'
                                              onClick={() => {
                                                props.ToggleConfirmationPopup(
                                                  postId,
                                                  userId,
                                                  POST_REMOVE_MESSAGE,
                                                  CONFIRMATION_POPUP_MODAL,
                                                  CONFIRMATION_HEADER
                                                );
                                              }}
                                            >
                                              <DeleteOutlined />{' '}
                                              <span>Delete</span>
                                            </p>
                                            <p
                                              className='comment-update-action my-1'
                                              onClick={() => {
                                                props.ToggleConfirmationPopup(
                                                  postId,
                                                  userId,
                                                  '',
                                                  UPDATE_POPUP_MODAL,
                                                  UPDATE_POST_HEADER
                                                );
                                              }}
                                            >
                                              <EditOutlined />
                                              <span>Update</span>{' '}
                                            </p>
                                          </div>
                                        }
                                      >
                                        {' '}
                                        <span className='model_open'>...</span>
                                        {/* <DashOutlined /> */}
                                      </Popover>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </span>
                          </p>
                          {urls?.length && (<LinkPreviewCard url={urls[0]} />)}
                          <div> {parse(unescapeHtml(postDescription))}</div>
                          {value.postMap ? (
                            <p className='location-adderss opacity-05'>
                              {' '}
                              <span>
                                {' '}
                                <img src={LocationPin} alt='LocationPin' />
                                {value.postMap}
                              </span>
                            </p>
                          ) : (
                            ''
                          )}
                          {value.postImage.length ? (
                            <div className='row mb-2'>
                              {value.postImage.map((data, imageIndex) => {
                                return (
                                  <React.Fragment key={`${data}-${imageIndex}`}>
                                    {data ? (
                                      <div
                                        className={
                                          imageIndex === 0
                                            ? 'col-md-12 col-12 hover-opacity-70'
                                            : 'col-md-4 col-6'
                                        }
                                        onClick={() =>
                                          props.openLightBox(value, imageIndex)
                                        }
                                      >
                                        <img
                                          src={data ? data : ERROR_IMAGE_URL}
                                          alt={
                                            data
                                              ? `value.postImage-${imageIndex}`
                                              : 'ERROR_IMAGE_URL'
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='col-md-12 col-12'>
                          {/* <div className="comment-content">
                        <p><span className="comment-user-name">{userFullName}</span> <br /> {value.description}</p>
                        {value.postImage ? <img src={value.postImage} alt="dashboardimg" /> : ''}
                      </div> */}
                          <div className='row'>
                            <div className='col-md-6 col-12 px-0'>
                              <div className='left-tags'>
                                <ul className='d-flex'>
                                  <li>
                                    <Popover
                                      trigger='hover'
                                      onVisibleChange={() =>
                                        props.handleVisibleChange(postId)
                                      }
                                      content={
                                        likedUserList.length ? (
                                          likedUserList.map((data, id) => {
                                            let {
                                                firstName,
                                                lastName,
                                                userPhoto,
                                              } = data.userId,
                                              fullName =
                                                firstName + ' ' + lastName,
                                              userID = storageData
                                                ? JSON.parse(storageData)
                                                    .payload.id
                                                : '',
                                              userType =
                                                data.userId.id === userID
                                                  ? 'me'
                                                  : 'other';
                                            return (
                                              <Link
                                                to={{
                                                  pathname: '/user/profile',
                                                  state: {
                                                    postId: data.userId.id,
                                                    userType: userType,
                                                  },
                                                }}
                                              >
                                                <div
                                                  className='row align-items-center'
                                                  key={`${data.id}-${id}`}
                                                >
                                                  <div className='col-md-4 col-4'>
                                                    <img
                                                      src={
                                                        userPhoto
                                                          ? userPhoto
                                                          : ERROR_IMAGE_URL
                                                      }
                                                      alt={value.id}
                                                      className='popover-img'
                                                    />
                                                  </div>
                                                  <div className='col-md-8 col-8'>
                                                    {fullName}
                                                  </div>
                                                </div>
                                              </Link>
                                            );
                                          })
                                        ) : (
                                          <div className='text-center'>
                                            {' '}
                                            No User List Available
                                          </div>
                                        )
                                      }
                                    >
                                      <button
                                        title={value.liked ? 'Unlike' : 'Like'}
                                        className='disabled'
                                        onClick={() =>
                                          props.callingToggleLikeApi(
                                            postId,
                                            props.id,
                                            likeStatus
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            value.liked
                                              ? HeartIcon
                                              : EmptyheartIcon
                                          }
                                          alt={`${index}-${
                                            value.liked
                                              ? 'HeartIcon'
                                              : 'EmptyheartIcon'
                                          }`}
                                        />
                                      </button>
                                    </Popover>
                                  </li>
                                  {/* {
                                value.liked
                                  ?
                                  <li><button title={value.liked ?"Like":"Unlike"}    className="disabled" onClick={() => props.callingToggleLikeApi(postId, userId, likeStatus)}><img src={value.liked ? HeartIcon :EmptyheartIcon} alt={`${index}-${value.liked ?'HeartIcon':'EmptyheartIcon'}`} /></button></li>
                                  :
                                  <li><button title="Like" className="disabled" onClick={() => props.callingToggleLikeApi(postId, userId, 1)}><img src={EmptyheartIcon} alt={`${index}-EmptyheartIcon`} /></button></li>
                              } */}
                                  <li>
                                    <button
                                      className='disabled'
                                      id={value.id}
                                      onClick={() =>
                                        props.toggleCommentBoxfunc(value.id)
                                      }
                                    >
                                      <img
                                        src={CommentsIcon}
                                        alt={`${index}-CommentsIcon`}
                                      />
                                    </button>{' '}
                                  </li>
                                  <li>
                                    <button className='disabled'>
                                      <img
                                        src={ShareIcon}
                                        alt={`${index}-ShareIcon`}
                                        style={{ maxWidth: '25px' }}
                                        onClick={() =>
                                          props.showPopupModal(
                                            value.description
                                          )
                                        }
                                      />
                                    </button>
                                  </li>
                                </ul>
                                <div className='row'>
                                  {value.totalLikes || value.totalComments ? (
                                    <div className='col-12'>
                                      <span className='h6 font-weight-bold mr-2'>
                                        {value.totalLikes
                                          ? value.totalLikes == 1
                                            ? value.totalLikes + ' like'
                                            : value.totalLikes + ' likes'
                                          : ''}
                                      </span>
                                      <span className='h6 font-weight-bold mr-2'>
                                        {value.totalComments
                                          ? value.totalComments == 1
                                            ? value.totalComments + ' comment'
                                            : value.totalComments + ' comments'
                                          : ''}
                                      </span>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-12'>
                              <div className='right-tags'>
                                {value.postDocument &&
                                value.postDocument.length ? (
                                  value.postDocument.map((value, id) => {
                                    if (value) {
                                      return (
                                        <a
                                          href={value}
                                          index={id}
                                          target='_blank'
                                          rel='noreferrer noopener'
                                          key={`${value}-${id}`}
                                        >
                                          {/* <FileDoneOutlined twoToneColor="#18a4ad" /> */}
                                          <svg
                                            viewBox='64 64 896 896'
                                            focusable='false'
                                            className=''
                                            data-icon='file-done'
                                            width='25px'
                                            height='25px'
                                            fill='#18a4ad'
                                            aria-hidden='true'
                                          >
                                            <path d='M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 00-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z'></path>
                                          </svg>
                                        </a>
                                      );
                                    } else {
                                      return <span key={`${value}-${id}`} />;
                                    }
                                  })
                                ) : (
                                  <span />
                                )}
                              </div>
                            </div>
                          </div>
                          {toggleCommentBox === value.id ? (
                            <form
                              className='row my-3'
                              onSubmit={props.submitComment}
                            >
                              <div className='user-post-content w-100'>
                                <div className='form-group'>
                                  <textarea
                                    className='form-control'
                                    onChange={(event) =>
                                      props.handleCommentInput(event)
                                    }
                                    placeholder='Write Comment'
                                    value={props.comment}
                                    name='comment'
                                    required
                                  />
                                  <div className='text-right'>
                                    <input
                                      className='post-comment-btn'
                                      type='Submit'
                                      value='Comment'
                                    />
                                    <button
                                      className='default cancel-btn'
                                      onClick={() =>
                                        props.toggleCommentBoxfunc('')
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          ) : (
                            ''
                          )}
                        </div>
                        {(value.comments && value.comments.length) ||
                        (value.lastComment && value.lastComment.length) ? (
                          <div className='show-comment'>
                            <h3 className='my-3'>Recent Comments</h3>
                            {value.comments && value.comments.length ? (
                              <a
                                className='view-all-comment'
                                onClick={() => props.collapseAllComments()}
                              >
                                Hide all comments
                              </a>
                            ) : (
                              ''
                            )}
                            {value.comments && value.comments.length ? (
                              value.comments.map((comment, commentIndex) => {
                                let { lastName, firstName, userPhoto } =
                                  comment.userId ? comment.userId : '';
                                userFullName = firstName
                                  ? firstName + ' ' + lastName
                                  : 'Equiliberty User';
                                let activeUserID = props.id;
                                let activeUserCommentId = comment.userId
                                  ? comment.userId.id
                                  : '';
                                return (
                                  <div
                                    className='row my-2 flex-nowrap'
                                    key={commentIndex}
                                  >
                                    <div className='col-12 d-flex flex-nowrap'>
                                      <div>
                                        <div className='comment-img'>
                                          <img
                                            src={
                                              userPhoto
                                                ? userPhoto
                                                : ERROR_IMAGE_URL
                                            }
                                            alt={`${
                                              userPhoto
                                                ? `userPhoto-${commentIndex}`
                                                : `ERROR_IMAGE_URL-${commentIndex}`
                                            }`}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={`${
                                          activeUserID === activeUserCommentId
                                            ? ''
                                            : ''
                                        }`}
                                        className='pl-3'
                                      >
                                        <div className='comment-user-name'>
                                          <h4>{userFullName}</h4>
                                        </div>
                                        <div className='user-comments-detail'>
                                          <p>{comment.comment}</p>
                                        </div>
                                      </div>
                                      {activeUserID === activeUserCommentId ? (
                                        <div className='ml-auto pl-3'>
                                          <div className='comment-img delete-comment-icon'>
                                            <DeleteOutlined
                                              onClick={() => {
                                                props.ToggleConfirmationPopup(
                                                  postId,
                                                  comment.id,
                                                  COMMENT_REMOVE_MESSAGE,
                                                  CONFIRMATION_POPUP_MODAL,
                                                  CONFIRMATION_HEADER
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div>
                                {value.totalComments > 1 && (
                                  // <Button type="link" onClick={() => props.getAllComments(value._id)}>View all <b>{value.totalComments}</b> comments</Button>

                                  <a
                                    className='view-all-comment'
                                    onClick={() =>
                                      props.getAllComments(value._id)
                                    }
                                  >
                                    View all <b>{value.totalComments}</b>{' '}
                                    comments
                                  </a>
                                )}
                                {value.lastComment && value.lastComment.length
                                  ? value.lastComment.map(
                                      (comment, commentIndex) => {
                                        if (!comment.userId) {
                                          return (
                                            <div className='row my-2 flex-nowrap'>
                                              <div className='col-12 d-flex flex-nowrap'>
                                                <div className='pl-3'>
                                                  <div className='comment-user-name'>
                                                    <h4 className='text-danger'>
                                                      Comment Deleted
                                                    </h4>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (comment.userId) {
                                          let {
                                            lastName,
                                            firstName,
                                            userPhoto,
                                          } = comment.userId;

                                          userFullName =
                                            firstName + ' ' + lastName;
                                          let activeUserID = props.id;
                                          let activeUserCommentId =
                                            comment.userId
                                              ? comment.userId.id
                                              : '';
                                          return (
                                            <div
                                              className='row my-2 flex-nowrap'
                                              key={commentIndex}
                                            >
                                              <div className='col-12 d-flex flex-nowrap'>
                                                <div>
                                                  <div className='comment-img'>
                                                    <img
                                                      src={
                                                        userPhoto
                                                          ? userPhoto
                                                          : ERROR_IMAGE_URL
                                                      }
                                                      alt={`${commentIndex}`}
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  className={`${
                                                    activeUserID ===
                                                    activeUserCommentId
                                                      ? ''
                                                      : ''
                                                  }`}
                                                  className='pl-3'
                                                >
                                                  <div className='comment-user-name'>
                                                    <h4>{userFullName}</h4>
                                                  </div>
                                                  <div className='user-comments-detail'>
                                                    <p>{comment.comment}</p>
                                                  </div>
                                                </div>
                                                {activeUserID ===
                                                activeUserCommentId ? (
                                                  <div className='ml-auto pl-3'>
                                                    <div className='comment-img delete-comment-icon'>
                                                      <DeleteOutlined
                                                        onClick={() => {
                                                          props.ToggleConfirmationPopup(
                                                            postId,
                                                            comment.id,
                                                            COMMENT_REMOVE_MESSAGE,
                                                            CONFIRMATION_POPUP_MODAL,
                                                            CONFIRMATION_HEADER
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                      // INDEX END
                                    )
                                  : ''}
                              </div>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className='user-comment'
                    key={`${value.userStatus}-${index}`}
                  >
                    <div className='row'>
                      <div className='col-md-3 col-3'>
                        <img
                          className='comment-icon'
                          src={CommentIcon}
                          alt='CommentIcon'
                        />
                      </div>
                      <div className='col-md-9 col-9'>
                        <div className='comment-content'>
                          <p>
                            <span className='comment-user-name'>
                              There's a new barber shop in your community !{' '}
                              <br /> Check it out here...
                            </span>
                          </p>
                        </div>
                        <div className='row'>
                          <div className='col-md-6 col-6'>
                            <div className='left-tags'>
                              <span />
                              <span />
                              <span />
                            </div>
                          </div>
                          <div className='col-md-6 col-6'>
                            <div className='right-tags'>
                              <span />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return false;
              }
            })}
              <div className="d-flex justify-content-center">
             {allPosts.length < totalPost && <Button loading={loadMoreLoading} disabled={loadMoreLoading} onClick={() => props.getPostApi(true)}>Load more posts</Button>}
             </div>
          </div>
        ) : (
          <div className='row mt-4'>
            <div className='col-12 text-center'>
              <div className='profile-boost-heading'>
                <h3>Boost your profile with interesting posts.</h3>
              </div>
            </div>
          </div>
        )}

        {/* <div className="user-comment">
                  <div className="row">
                    <div className="col-md-3 col-3">
                      <img src={CommentImg} alt="CommentImg_1" />
                    </div>
                    <div className="col-md-9 col-9">
                      <div className="comment-content">
                        <p><span className="comment-user-name">Malcom Jones</span> <br /> </p>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <div className="left-tags">
                            <span/>
                            <span/>
                            <span/>
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="right-tags">
                            <span/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

        {/* <div className="user-comment">
                  <div className="row">
                    <div className="col-md-3 col-3">
                      <img src={CommentImg} alt="CommentImg" />
                    </div>
                    <div className="col-md-9 col-9">
                      <div className="comment-content">
                        <p><span className="comment-user-name">Malcom Jones</span> <br /> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <div className="left-tags">
                            <span/>
                            <span/>
                            <span/>
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="right-tags">
                            <span/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
      </div>
    </div>
  );
}

function DashBoardNewsAndUpdateSection(props) {
  let {
    news,
    userContact,
    currentLiberator,
    role,
    firstName,
    lastName,
    id,
    isLocalLiberal,
  } = props;
  let fullName = firstName + ' ' + lastName;

  return (
    <React.Fragment>
      <div className='col-md-12 col-lg-3'>
        {role === 'mentor' ? (
          ''
        ) : (
          <AnimatedOnScroll
            animationIn='fadeInRight'
            animationOut='fadeOutRight'
            animationInDuration={1000}
            animationOutDuration={1500}
            isVisible={true}
          >
            <div className='need-help'>
              <div className='profile'>
                <div className='help-img'>
                  <img src={NeedAvatar} alt='NeedAvatar' />
                </div>
                <div className='need-content'>
                  <h4>Need Some Help?</h4>
                  {currentLiberator.length ? (
                    <p>
                      Contact your local Liberator:{' '}
                      {currentLiberator.map((value, index) => {
                        return (
                          <React.Fragment key={`${value.id}-${index}`}>
                            <br />
                            <Link
                              to={{
                                pathname: '/liberatorchat',
                                state: {
                                  data: {
                                    senderId: id,
                                    recieverId: value.userId,
                                    senderName: value.contactName,
                                    type: 'text',
                                    fullName: fullName,
                                    userPhoto: '',
                                  },
                                },
                              }}
                              disabled={isLocalLiberal}
                            >
                              {value.contactName}
                              {/* Message */}
                            </Link>
                          </React.Fragment>
                        );
                      })}
                    </p>
                  ) : (
                    <p>No local Liberator Available</p>
                  )}
                </div>
              </div>
              <div className='profile_footer'>
                <span>Other Resources:</span>
                <ul>
                  <li>
                    <Link to='/user/contact'>Thirdward Local Resources</Link>
                  </li>
                  <li>
                    <Link to='/enquiry-form'>Community Help Desk</Link>
                  </li>
                </ul>
              </div>
            </div>
          </AnimatedOnScroll>
        )}
        <AnimatedOnScroll
          animationIn='fadeInRight'
          animationOut='fadeOutRight'
          animationInDuration={1500}
          animationOutDuration={1000}
          isVisible={true}
        >
          <div className='news-feeds'>
            <h3 className='pt-2 font-weight-bold'>News & Updates</h3>
            <div className='w-100 unoi-notification-container'>
              {news.length ? (
                news.map((value, id) => {
                  let { type } = value;
                  let icon =
                    type === 'normal'
                      ? NewsIcon1
                      : type === 'announcement'
                      ? NewsIcon2
                      : type === 'meeting'
                      ? NewsIcon3
                      : NewsIcon1;
                  let showRedText =
                    value.newsStatus === 'latest' ? true : false;
                  return (
                    <div className='news_box' key={value.id}>
                      <p
                        className={
                          showRedText
                            ? 'text-danger font-weight-bold'
                            : 'font-weight-bold text-333f48'
                        }
                      >
                        Update <span className='ml-2'></span>
                      </p>
                      <div className='news-icon'>
                        <div className='text-center'>
                          <img
                            src={icon}
                            alt={type}
                            style={{ width: '100%', maxWidth: '60px' }}
                          />
                        </div>
                      </div>

                      <div className='news-content'>
                        {/* <p className={`${showRedText ? 'text-danger' : ''} date`}>{moment(value.updatedAt).format("MMM DD, YYYY").toString()}</p> */}
                        <p className=''>{parse(unescapeHtml(value.text))}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='row'>
                  <div className='col-12 text-center'>
                    <p>No new updates</p>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="row">
            <div className="col-md-3 col-3">
              <div className="news-icon">
                <p className="text-danger">Update</p>
                <img src={NewsIcon1} alt="NewsIcon1" />
              </div>
            </div>
            <div className="col-md-9 col-9">
              <div className="news-content">
                <p className="date text-danger">11/02/2020</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-3">
              <div className="news-icon">
                <p className="">Update</p>
                <img src={NewsIcon2} alt="NewsIcon2" />
              </div>
            </div>
            <div className="col-md-9 col-9">
              <div className="news-content">
                <p className="date">11/02/2020</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-3">
              <div className="news-icon">
                <p className="">Update</p>
                <img src={NewsIcon3} alt="NewsIcon3" />
              </div>
            </div>
            <div className="col-md-9 col-9">
              <div className="news-content">
                <p className="date">11/02/2020</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-3">
              <div className="news-icon">
                <p className="">Update</p>
                <img src={NewsIcon2} alt="NewsIcon2" />
              </div>
            </div>
            <div className="col-md-9 col-9">
              <div className="news-content">
                <p className="date">11/02/2020</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </div>
            </div>
          </div> */}
          </div>
        </AnimatedOnScroll>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
