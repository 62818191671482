import React from "react";
import { Modal } from "antd";
import "./followerList.scss";
// Constants
import { POP_UP_MODAL_HEADER_CONSTANTS, CONSTANTS } from "../constants";
import { Link } from "react-router-dom";

var { ERROR_IMAGE_URL } = CONSTANTS;
var { FOLLOWING_LIST } = POP_UP_MODAL_HEADER_CONSTANTS;

export function FollowerFollowingListModal(props) {
  function callSaveId(id, unfollowStatus) {
    props.toggelConfirmModal(unfollowStatus);
    props.saveUnfollowUserId(id);
  }
  let { title, followerList, followingList } = props;
  let arrayList = title === FOLLOWING_LIST ? followingList : followerList;
  let noDataStatus = title === FOLLOWING_LIST ? "Following" : "Follower";
  let userStatus = title === FOLLOWING_LIST ? "Unfollow" : "Follow";
  const storageData = localStorage.getItem("userData");
  const userId = storageData ? JSON.parse(storageData).payload.id : "";
  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      footer={null}
      // okButtonProps={{ title: "Submit" }}
      // footer={[
      //     <Button key="back" onClick={() => props.onCancel(false)} >
      //         Cancel
      //     </Button>,
      //     <Button key="submit" type="primary" onClick={(event) => props.onOk(event)}>
      //         Submit
      //     </Button>,
      // ]}
    >
      <div>
        {arrayList.length ? (
          arrayList.map((value, index) => {
            let { firstName, lastName, userPhoto, id } =
              title === FOLLOWING_LIST ? value.userId : value.followedById;
            let fullName = firstName + " " + lastName;
            let unfollowStatus = `Are you Sure to unfollow ${fullName}.it will be removed from following list`;
            let userFollowStatus =
              title === FOLLOWING_LIST
                ? false
                : value.followedById.id === userId
                ? "Followed"
                : "Follow";
            return (
              <div className="followers-list">
                <div
                  className={`${
                    arrayList.length ? "" : "text-center"
                  } row mb-2`}
                  key={`${value.id}-${index}`}
                >
                  <div className="col-md-2 col-2">
                    <Link
                      to={{
                        pathname: "/user/profile",
                        state: {
                          postId: id,
                          userType: "other",
                        },
                      }}
                      onClick={() => window.history.go(0)}
                    >
                      <div className="list-img">
                        <img
                          className="rounded img-thumbnail"
                          src={userPhoto ? userPhoto : ERROR_IMAGE_URL}
                          alt={value.userId}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6 col-6">
                    <Link
                      to={{
                        pathname: "/user/profile",
                        state: {
                          postId: id,
                          userType: "other",
                        },
                      }}
                      onClick={() => window.history.go(0)}
                      style={{color: "inherit"}}
                    >
                      <div className="list-name">
                        <p>{fullName}</p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4 col-4">
                    <div className="list-btn">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          title === FOLLOWING_LIST
                            ? callSaveId(id, unfollowStatus)
                            : props.callFollowAPI(title, id)
                        }
                      >
                        {userFollowStatus ? userFollowStatus : userStatus}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={`${arrayList.length ? "" : "text-center"} row`}>
            <div className="col-12 text-center">No {noDataStatus} Found</div>
          </div>
        )}
      </div>
    </Modal>
  );
}
