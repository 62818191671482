import React, { useState, useEffect } from 'react';
import {
  Layout,
  Row,
  Button,
  // Tabs,
  message,
  Drawer,
  Menu,
  Avatar,
  Popover,
  Dropdown,
} from 'antd';
import MyAncor from '../global/ancor';
import logo from '../../../assets/images/logo.png';
import style from './header.module.sass';
import { useSelector, useDispatch } from 'react-redux';
// import DefaultPhoto from '../../../assets/images/userprofiledefault.png';
// import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
// import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import {
  uploadUserPhoto,
  logout,
  logoutApi,
  saveUserPendingCommunity,
  selectUserData,
} from '../../../redux/slices/authSlice';
import { header } from '../../../api/rest';
import {
  setUserData,
  setLoading as running,
  loginApi,
  googleLoginApi,
  facebookLoginApi,
  searchCommunityByZipcode,
  whoami,
} from '../../../redux/slices/authSlice';
import { getTokenCount } from '../../../redux/slices/walletSlice';
import { userUpdateProfile } from '../../../redux/slices/userProfileSlice';
import { history } from '../../../redux/store';
// CONSTANTS
import { CONSTANTS } from '../../shared/constants';
// CONSTANTS
import {
  POP_UP_MODAL_CONSTANTS,
  POP_UP_MODAL_HEADER_CONSTANTS,
} from '../../shared/constants';
// POPUPMODALS
import PopUpModal from '../../shared/popupmodal';
// SOUND PLAY COMPONENT
import { TokenEarnSound } from '../../shared/soundeffect';
// GLOBAL CSS
import GlobalCSS from '../../../assets/globalNew.scss';
// CSS
import './header.scss';

const { Header } = Layout;
// const { TabPane } = Tabs;
const {
  ERROR_IMAGE_URL,
  LOG_OUT_MESSAGE,
  ERROR_MESSAGE,
  NO_COMMUNITY_MESSAGE,
} = CONSTANTS;
const { LOGIN_MODAL, ZIP_CODE_POP_UP_MODAL, MESSAGE_POP_UP_MODAL } =
  POP_UP_MODAL_CONSTANTS;
const {
  ZIPCODE_MODAL_HEADING,
  // ERROR_HEADER,
  THANK_FOR_INTREST,
} = POP_UP_MODAL_HEADER_CONSTANTS;

const SiteHeader = (props) => {
  const dispatch = useDispatch();
  const storageData = localStorage.getItem('userData');
  const payload =
    storageData && JSON.parse(storageData)?.payload
      ? JSON.parse(storageData)
      : null;
  const role = storageData ? JSON.parse(storageData).payload.role : '';
  const session = useSelector((state) => state.user);
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(session.loading);
  const [visible, setVisiblity] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [popUpModal, setPopUpModal] = useState({
    visible: false,
    type: '',
    modalHeader: '',
  });
  const [loader, setLoader] = useState(false);
  const currentPath = useSelector((state) => state.router.location.pathname);
  const [socialMediaResponse, setSocialMediaResponse] = useState({});
  const [description, setDescription] = useState('');
  // const home = currentPath === '/';
  const pathArray = currentPath.split('/');
  const reg = pathArray[1] === 'register';
  const showMentor = role ? (role === 'mentor' ? true : false) : false;
  const bdetails = reg && pathArray[2] === 'business-details';
  const currentPathName = props.children.props.history.location.pathname;
  const [playSound, setPlaySound] = useState(false);
  // const visible=false,imageUrl=null;

  // FOR SOCIAL MEDIA LOGIN DO NOT REMOVE BOTTOM COMMENT USED IN FUTURE
  // loading && !session.loading && session.data && session.data.existUser && session.data.existUser.id
  useEffect(() => {
    if (
      loading &&
      !session.loading &&
      session &&
      session.data &&
      session.data.payload &&
      session.data.payload.id
    ) {
      setVisiblity(false);
      history.push('/dashboard');
    }
    setSocialLoading(session.loading);
  }, [socialLoading, session, setVisiblity, loading]);
  useEffect(() => {
    dispatch(whoami());
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      user?.zipcode &&
      !user?.communityId &&
      user?.role === 'user' &&
      !window.location.href.includes('register') &&
      !window.location.href.includes('user/profile/update')
    ) {
      message.info('Please Choose Community');
      history.push('/register/choose-community');
    }
  }, [user]);
  function redirectToAccountPage() {
    setShowMenu(false);
    props.children.props.history.push('/user/profile', {
      postId: payload.payload.id,
      userType: 'me',
    });
  }

  const menu =
    storageData === null ? (
      storageData ? (
        <Menu>
          <Menu.Item key='0'>
            <MyAncor
              type='link'
              onClick={() => {
                setShowMenu(false);
                history.push('/contact');
              }}
            >
              Contact
            </MyAncor>
          </Menu.Item>
          <Menu.Item key='1'>
            <MyAncor
              type='link'
              onClick={() => {
                setShowMenu(false);
                history.push('/dashboard');
              }}
            >
              Dashboard
            </MyAncor>
          </Menu.Item>
        </Menu>
      ) : (
        <Menu>
          <Menu.Item key='0'>
            <MyAncor
              type='link'
              onClick={() => {
                setShowMenu(false);
                history.push('/contact');
              }}
            >
              Contact
            </MyAncor>
          </Menu.Item>
          <Menu.Item key='1'>
            <MyAncor
              type='link'
              onClick={() => {
                setShowMenu(false);
                history.push('/about');
              }}
            >
              About us
            </MyAncor>
          </Menu.Item>
          <Menu.Item key='2'>
            <MyAncor
              type='link'
              onClick={() => {
                setShowMenu(false);
                history.push('/mentor/register');
              }}
            >
              Become a Mentor
            </MyAncor>
          </Menu.Item>
          <Menu.Item key='3'>
            <MyAncor
              type='link'
              onClick={() => {
                showPopupModal(LOGIN_MODAL, '');
                // setVisiblity(true);
                // setShowMenu(false);
              }}
            >
              Sign In / Join Today!
            </MyAncor>
          </Menu.Item>
        </Menu>
      )
    ) : (
      <div className={style.menuWrapper}>
        <Avatar
          className={style.userPhoto}
          src={
            session.data.userPhoto ? session.data.userPhoto : ERROR_IMAGE_URL
          }
          size={100}
          onClick={() => redirectToAccountPage()}
        />
        <Menu className={style.menuContent}>
          {role === 'sponsor' && (
            <>
              <Menu.Item key='0'>
                <MyAncor
                  type='link'
                  onClick={() => {
                    setShowMenu(false);
                    history.push('/sponsor-dashboard');
                  }}
                >
                  Dashboard
                </MyAncor>
              </Menu.Item>
            </>
          )}
          {role === 'mentor' && (
            <React.Fragment>
              <Menu.Item key='0'>
                <MyAncor
                  type='link'
                  onClick={() => {
                    setShowMenu(false);
                    history.push('/mentor/dashboard');
                  }}
                >
                  Dashboard
                </MyAncor>
              </Menu.Item>
              <Menu.Item key='1'>
                <MyAncor
                  type='link'
                  onClick={() => {
                    setShowMenu(false);
                    history.push('/reminder');
                  }}
                >
                  Schedule
                </MyAncor>
              </Menu.Item>
            </React.Fragment>
          )}

          {role === 'user' && (
            <React.Fragment>
              <Menu.Item key='0'>
                <MyAncor
                  type='link'
                  onClick={() => {
                    setShowMenu(false);
                    history.push('/dashboard');
                  }}
                >
                  Dashboard
                </MyAncor>
              </Menu.Item>
              <Menu.Item key='1'>
                <MyAncor
                  type='link'
                  onClick={() => {
                    setShowMenu(false);
                    history.push('/user/journey');
                  }}
                >
                  Journey
                </MyAncor>
              </Menu.Item>
              <Menu.Item key='2'>
                <MyAncor
                  type='link'
                  onClick={() => {
                    setShowMenu(false);
                    history.push('/learning');
                  }}
                >
                  Learning
                </MyAncor>
              </Menu.Item>
              <Menu.Item key='3'>
                <MyAncor
                  type='link'
                  onClick={() => {
                    setShowMenu(false);
                    history.push('/reminder');
                  }}
                >
                  Schedule
                </MyAncor>
              </Menu.Item>
            </React.Fragment>
          )}
          {/* <Menu.Item key="3"> */}
          {/* <MyAncor type="link" onClick={() => { setShowMenu(false); history.push('/customerCenter') }}>
              Customer Center
            </MyAncor> */}
          {/* <MyAncor type="link" onClick={() => { setShowMenu(false); history.push('/customerCenter') }}>
              Community
            </MyAncor> */}
          {/* </Menu.Item> */}
          <Menu.Item key='4'>
            <MyAncor type='link' onClick={() => redirectToAccountPage()}>
              profile
            </MyAncor>
          </Menu.Item>
          <Menu.Item key='5'>
            <MyAncor
              type='link'
              onClick={() => {
                setShowMenu(false);
                handleLogout();
              }}
            >
              Logout
            </MyAncor>
          </Menu.Item>
        </Menu>
      </div>
    );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done' && info.file.response.success) {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoading(false);
      });
      dispatch(
        uploadUserPhoto(
          `${process.env.REACT_APP_BASE_URL}/uploads/${info.file.response.userPhoto}`
        )
      );
      message.success(
        `${info.file.response.userPhoto} file uploaded successfully`
      );
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const showDrawer = () => {
    setShowMenu(true);
  };

  const onCloseDrawer = () => {
    setShowMenu(false);
  };

  const handlePopoverChange = (visible) => {
    setShowPopover(visible);
  };

  const handleLogout = () => {
    header.Authorization = payload.token;
    let data = {
      userId: payload.payload.id,
    };
    logoutApi(data, header)
      .then((res) => {
        dispatch(logout());
        setShowPopover(false);
        history.push('/');
      })
      .catch((err) => {
        dispatch(logout());
        setShowPopover(false);
        history.push('/');
      });
    success();
  };
  const success = () => {
    message.success(LOG_OUT_MESSAGE, 5);
  };

  const responseGoogle = async (response) => {
    let data = { tokenId: response.tokenId };
    googleLoginApi(data, header)
      .then((response) => {
        if (response && response) {
          if (
            response.message ===
            'we are working hard to bring Equiliberty to your community!!'
          ) {
            setDescription(response.message);
            showPopupModal(MESSAGE_POP_UP_MODAL, THANK_FOR_INTREST);
            return false;
          }
          message.success(response.message);
          localStorage.setItem(
            'x-headers',
            `${response.payload.email}my supersceretpasswordisnone`
          );
          setSocialMediaResponse(response);
          if (response.oldUser && response.payload.communityId) {
            localStorage.setItem('userData', JSON.stringify(response));
            dispatch(setUserData(response.payload));
            history.push('/dashboard');
            closePopupModal();
            // setLoader(false);
          } else {
            getWallettokenCountApi(
              response.payload.id,
              response.token,
              'socialMedia',
              response
            );
          }
        }
      })
      .catch((err) => {});
  };

  const responseFacebook = async (response) => {
    if (response.accessToken) {
      let data = { accessToken: response.accessToken, userId: response.userID };
      facebookLoginApi(data, header)
        .then((response) => {
          if (response) {
            if (
              response.message ===
              'we are working hard to bring Equiliberty to your community!!'
            ) {
              setDescription(response.message);
              showPopupModal(MESSAGE_POP_UP_MODAL, THANK_FOR_INTREST);
              return false;
            }
            message.success(response.message);
            localStorage.setItem(
              'x-headers',
              `${response.payload.email}my supersceretpasswordisnone`
            );
            setSocialMediaResponse(response);
            if (response.oldUser && response.payload.communityId) {
              localStorage.setItem('userData', JSON.stringify(response));
              dispatch(setUserData(response.payload));
              history.push('/dashboard');
              closePopupModal();
              // setLoader(false);
            } else {
              getWallettokenCountApi(
                response.payload.id,
                response.token,
                'socialMedia',
                response
              );
            }
          }
        })
        .catch((err) => {});
    } else {
    }
  };

  const responseGoogleError = async (error) => {};

  const hideModal = () => {
    setVisiblity(false);
  };

  const content = (
    <React.Fragment>
      <div>
        {/* <a href="/user/profile/update">Edit Profile</a> */}
        <MyAncor
          type='link'
          onClick={() => {
            setShowMenu(false);
            history.push('/user/profile/update');
          }}
        >
          Edit Profile
        </MyAncor>
      </div>
      <div>
        {/* <a onClick={handleLogout} >Logout</a> */}
        <Button type='link' onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </React.Fragment>
  );

  function redirectToSignup(key) {
    if (key === 'signup') {
      // setVisiblity(false)
      closePopupModal();
      history.push('/register');
    } else {
      return null;
    }
  }

  // show popup modal
  function showPopupModal(type, header) {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: true,
        type: type,
        modalHeader: header,
      };
    });
  }

  function closePopupModal() {
    setPopUpModal((prevPopUpModal) => {
      return {
        ...prevPopUpModal,
        visible: false,
        type: '',
        modalHeader: '',
      };
    });
  }

  function onSubmitPopUpModal(event, data) {
    // event.preventDefault();
    if (popUpModal.type === LOGIN_MODAL) {
      const body = {
        email: event.email,
        password: event.password,
      };
      submitLoginData(body);
      setLoader(true);
    }
    if (popUpModal.type === ZIP_CODE_POP_UP_MODAL) {
      checkZipCodeValid(event);
    }
    if (popUpModal.modalHeader === THANK_FOR_INTREST) {
      closePopupModal();
      history.push('/');
    }
  }

  function submitLoginData(data) {
    loginApi(data, header)
      .then((res) => {
        if (
          res.message ===
          'we are working hard to bring Equiliberty to your community!!'
        ) {
          setDescription(res.message);
          showPopupModal(MESSAGE_POP_UP_MODAL, THANK_FOR_INTREST);
          return false;
        }
        localStorage.setItem('userData', JSON.stringify(res));
        localStorage.setItem('x-headers', data.password);
        message.success(res.message);
        dispatch(setUserData(res.payload));
        setLoader(false);
        setPlaySound(true);
        setTimeout(() => {
          if (res.payload.role === 'mentor') {
            closePopupModal();
            history.push('/mentor/dashboard');
          } else if (res.payload.role === 'admin') {
            closePopupModal();
            history.push('/admin/dashboard');
          } else {
            getWallettokenCountApi(res.payload.id, res.token, 'normal', res);
          }
        }, 2000);
      })
      .catch((err) => {
        if (err.message === 'Please verify your email then try to login') {
          closePopupModal();
          history.push('/auth/verify', { data: data.email });

          return;
        }
        message.error(err ? err.message : ERROR_MESSAGE);
        dispatch(running(false));
        setLoader(false);
      });
  }

  function getWallettokenCountApi(id, token, type, resp) {
    header.Authorization = token;
    getTokenCount(id, header)
      .then((res) => {
        if (res.ledgesData) {
          let count = 0;
          for (let i = 0; i < res.ledgesData.length; i++) {
            count = count + res.ledgesData[i].tokenCount;
          }
          updateLocalStorage('badgeToken', count);
        }
        closePopupModal();
        if (type === 'socialMedia') {
          showPopupModal(ZIP_CODE_POP_UP_MODAL, ZIPCODE_MODAL_HEADING);
        } else {
          history.push('/dashboard');
        }
        setPlaySound(false);
        dispatch(running(false));
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
        setPlaySound(false);
      });
  }

  function updateLocalStorage(inputName, value) {
    let data = localStorage.getItem('userData');
    if (data) {
      var persons = JSON.parse(data);
      persons.payload[inputName] = value;
      localStorage.setItem('userData', JSON.stringify(persons));
    }
  }
  // Check zipcode is valid or not if valid update zipcode with user profile
  function checkZipCodeValid(data) {
    searchCommunityByZipcode(data, header)
      .then((res) => {
        closePopupModal();
        if (res.data.length) {
          localStorage.setItem('userData', JSON.stringify(socialMediaResponse));
          dispatch(setUserData(socialMediaResponse.payload));
          setLoader(false);
          userUpdateProfileAPI(data);
          redirectToProfileUpdatePage();
        } else {
          showPopupModal(MESSAGE_POP_UP_MODAL, THANK_FOR_INTREST);
          setDescription(NO_COMMUNITY_MESSAGE);
          saveUserPendingCommunityAPI(data);
        }
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  // save pending community data to data base
  function saveUserPendingCommunityAPI(value) {
    let data = {
      firstName: socialMediaResponse.payload.firstName,
      lastName: socialMediaResponse.payload.lastName
        ? socialMediaResponse.payload.lastName
        : '',
      email: socialMediaResponse.payload.email,
      zipcode: value.zipCode,
    };
    saveUserPendingCommunity(data, header)
      .then((res) => {})
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }
  // Call update user profile api to save zipcode which user entered during zipcode modal
  function userUpdateProfileAPI(value) {
    header.Authorization = socialMediaResponse.token;
    let data = {
      zipcode: value.zipCode,
      id: socialMediaResponse.payload.id,
    };
    userUpdateProfile(data, header)
      .then((res) => {
        updateLocalStorage('zipcode', value.zipCode);
      })
      .catch((err) => {
        message.error(err ? err.message : ERROR_MESSAGE);
      });
  }

  // redirect to profile page
  function redirectToProfileUpdatePage() {
    history.push('/user/profile/update', {
      data: 'register',
    });
  }

  const onFinish = (values) => {
    // dispatch(running(true));
    const body = {
      email: values.email,
      password: values.password,
    };
    // dispatch(login(body));
    submitLoginData(body);
  };

  const onFinishFailed = (errorInfo) => {};

  let { firstName, lastName, userPhoto } = payload ? payload.payload : '',
    photo = session.data
      ? session.data.userPhoto
      : 'https://equiliberty-storage.s3.us-east-2.amazonaws.com/profilepick.png';
  let loginModalFunctionProps = {
      hideModal: hideModal,
      setVisiblity: setVisiblity,
      redirectToSignup: redirectToSignup,
      responseFacebook: responseFacebook,
      responseGoogle: responseGoogle,
      onFinish: onFinish,
      onFinishFailed: onFinishFailed,
      responseGoogleError: responseGoogleError,
    },
    loginModalVariableProps = {
      style,
      loader,
    };

  return (
    <Header className={`${style.site_header} custom-header-class`}>
      {playSound ? <TokenEarnSound /> : ''}
      <Row
        className={bdetails ? `upload-photo-enabled` : ''}
        align={`middle`}
        justify={`space-between`}
      >
        <MyAncor href={payload ? '/dashboard' : '/'}>
          <img src={logo} alt={`Equiliberty`} className={style.logo} />
        </MyAncor>
        {/* {bdetails && <Upload
          name="file"
          listType="picture-card"
          className={`avatar-uploader`}
          showUploadList={false}
          action={`${process.env.REACT_APP_BASE_URL}/user/upload-image`}
          onChange={handleChange}
        >
        </Upload>} */}
        {storageData === null ? (
          <Row className={style.headerButtonWrapper}>
            {/* <MyAncor type='link' className={style.signUp_btn} href='/contact'>
              Contact
            </MyAncor>
            <MyAncor type='link' className={style.signUp_btn} href='/about'>
              About us
            </MyAncor> */}
            {(
              session?.data
                ? session?.data?.payload && session?.payload?.data?.id
                : false
            ) ? (
              <MyAncor
                type='link'
                className={style.signUp_btn}
                href='/dashboard'
              >
                Dashboard
              </MyAncor>
            ) : (
              <div className='login-box'>
                <ul>
                  {/* <li>
                    <MyAncor
                      className={'bg-white'}
                      type='link'
                      href='/mentor/register'
                    >
                      Become a Mentor
                    </MyAncor>
                  </li> */}
                  <li className='mr-4'>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item>
                            <MyAncor
                              type='link'
                              style={{
                                fontSize: 16,
                                fontWeight: '600',
                                padding: '0.75em 1em',
                              }}
                              href='/register'
                            >
                              Build a Community
                            </MyAncor>
                          </Menu.Item>
                          <Menu.Item>
                            <MyAncor
                              type='link'
                              style={{
                                fontSize: 16,
                                fontWeight: '600',
                                padding: '0.75em 1em',
                              }}
                              href='/mentor/register'
                            >
                              Become a Mentor
                            </MyAncor>
                          </Menu.Item>
                        </Menu>
                      }
                      placement='bottomCenter'
                      arrow
                    >
                      <a
                        href='#'
                        onClick={(e) => e.preventDefault()}
                        className='sign-up'
                      >
                        Join
                      </a>
                    </Dropdown>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => showPopupModal(LOGIN_MODAL, '')}
                      className='sign-up'
                    >
                      Sign In
                    </a>
                  </li>
                  {/* <li><a href="#" onClick={() => showPopupModal(LOGIN_MODAL, '')} className="sign-up">Sign Up</a></li> */}
                </ul>
              </div>
            )}
          </Row>
        ) : (
          <Row className={style.headerButtonWrapper} align={'middle'}>
            {role === 'sponsor' && (
              <>
                <MyAncor href='/sponsor-dashboard' className={style.login_btn}>
                  Dashboard
                </MyAncor>
                <MyAncor href='/community-feed' className={style.login_btn}>
                  Community Feed
                </MyAncor>
                <MyAncor
                  type='link'
                  onClick={() => redirectToAccountPage()}
                  className={style.login_btn}
                >
                  Profile
                </MyAncor>
              </>
            )}
            {role === 'mentor' && (
              <React.Fragment>
                <MyAncor href='/mentor/dashboard' className={style.login_btn}>
                  Dashboard
                </MyAncor>
                <MyAncor href='/wallet' className={style.login_btn}>
                  Wallet
                </MyAncor>
                <MyAncor
                  href='/mentor/profile'
                  onClick={() =>
                    history.push({
                      pathname: '/mentor/profile',
                      state: {
                        postId: payload.id,
                        userType: 'me',
                      },
                    })
                  }
                  className={style.login_btn}
                >
                  Profile
                </MyAncor>
                <MyAncor href='/mentor/schedule' className={style.login_btn}>
                  Schedule
                </MyAncor>
                <MyAncor
                  href='/mentor/availability'
                  className={style.login_btn}
                >
                  Availability
                </MyAncor>
              </React.Fragment>
            )}
            {role === 'user' && (
              <React.Fragment>
                <MyAncor href='/dashboard' className={style.login_btn}>
                  Dashboard
                </MyAncor>
                <MyAncor href='/wallet' className={style.login_btn}>
                  Wallet
                </MyAncor>
                <MyAncor href='/user/journey' className={style.login_btn}>
                  Journey
                </MyAncor>
                <MyAncor href='/reminder' className={style.login_btn}>
                  Schedule
                </MyAncor>
                <MyAncor href='/learning' className={style.login_btn}>
                  Learning
                </MyAncor>
                {/* <MyAncor href="/" className={style.login_btn}>Community</MyAncor> */}
                {currentPathName === '/user-profile' ? (
                  <a
                    href='/user/profile'
                    onClick={() => redirectToAccountPage()}
                    className={style.login_btn}
                  >
                    Profile
                  </a>
                ) : (
                  <MyAncor
                    type='link'
                    onClick={() => redirectToAccountPage()}
                    className={style.login_btn}
                  >
                    Profile
                  </MyAncor>
                )}
              </React.Fragment>
            )}
            <Popover
              content={content}
              title={`${firstName ? firstName : firstName} ${
                lastName ? lastName : ''
              }`}
              trigger='click'
              visible={showPopover}
              onVisibleChange={handlePopoverChange}
            >
              <Avatar
                className={style.userPhoto}
                src={userPhoto ? userPhoto : photo}
                size={40}
              ></Avatar>
            </Popover>
          </Row>
        )}
        <Button className={style.menuButton} onClick={showDrawer}>
          <MenuOutlined className={style.menuIcon} />
        </Button>
      </Row>
      <Drawer
        title=''
        closable={false}
        onClose={onCloseDrawer}
        visible={showMenu}
      >
        {menu}
      </Drawer>
      {popUpModal.visible ? (
        <PopUpModal
          title={popUpModal.modalHeader}
          visible={popUpModal.visible}
          onCancel={closePopupModal}
          onOk={onSubmitPopUpModal}
          type={popUpModal.type}
          description={description}
          {...loginModalFunctionProps}
          {...loginModalVariableProps}
        />
      ) : (
        ''
      )}
    </Header>
  );
};

export default SiteHeader;
