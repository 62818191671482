import React from 'react';
import { Modal, Button, Form, Input,Row } from 'antd';
// CONSTANT

import { REGEX_CONSTANTS, CONSTANTS } from '../constants';

var { PASSWORD_VALIDATION_REGEX_BCK } = REGEX_CONSTANTS
var { PASSWORD_VALIDATION_MESSAGE_BCK } = CONSTANTS;

export function AdminApproveMentorModal(props) {
  let { title, loading } = props;
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  return (
    <Modal
      title={title}
      centered
      visible={props.visible}
      onOk={(event) => props.onOk(event)}
      onCancel={() => props.onCancel(false)}
      okButtonProps={{ title: 'Submit' }}
      footer={null}
    >
      <Form {...layout} onFinish={(event) => props.onOk(event)}>
        <Form.Item
          label={'Password'}
          name='password'
          rules={[
            {
              required: true,
              // message: 'Please input your password!',
              pattern: PASSWORD_VALIDATION_REGEX_BCK,
              validator: (_, value) =>
                PASSWORD_VALIDATION_REGEX_BCK.test(value)
                  ? Promise.resolve()
                  : Promise.reject(PASSWORD_VALIDATION_MESSAGE_BCK),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={'Confirm Password'}
          name='password_confirm'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'The two passwords that you entered do not match!'
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Row justify={'center'}>
          <Button type='primary' htmlType='submit' loading={loading}>
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
