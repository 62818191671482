/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { history } from '../../../redux/store';
import logo from '../../../assets/images/equiliberty-logo.png';
import TockenIcon from '../../../assets/images/token-icon.png';
import Partner1 from '../../../assets/images/Chase_Bank.png';
import Partner2 from '../../../assets/images/Ser_Jobs_Logo.png';
import Partner3 from '../../../assets/images/SCORE_Logo.png';
import MeetingPic from '../../../assets/images/townhall.png';
import { CONSTANTS } from '../../shared/constants';
import { header } from '../../../api/rest';
import {
  getAllUserPost,
  getCommunityData,
} from '../../../redux/slices/dashboardSlice';
import SubHeader from '../../widgets/subheader';
import './communitydetail.scss';
import { Link } from 'react-router-dom';
import DefaultBannerImage from '../../../assets/images/image_2021_03_11T11_53_14_686Z.png';
import parse from 'html-react-parser';
import { unescapeHtml } from '../../../utils/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import LinkPreviewCard from '../../shared/LinkPreviewCard';

const { ERROR_MESSAGE } = CONSTANTS;

const videoPlayerStyles = {
  objectFit: 'cover',
  width: '100%',
  height: 330,
};

const communityProfilePhoto = {
  objectFit: 'cover',
  width: '100%',
};

const CommunityBusiness = (props) => {
  const { name, founder, industry } = props;
  return (
    <div className='community-business' style={{ color: '#323F47' }}>
      <h5 className='mb-1' style={{ color: 'inherit' }}>
        {name}
      </h5>
      <p>
        Founded By: {founder} <br />
        Industry: {industry}
      </p>
    </div>
  );
};

function CommunityDetail() {
  // const dispatch = useDispatch();
  const session = useSelector((state) => state.user);
  const [userData, setUserData] = useState(session.data);
  const storageData = localStorage.getItem('userData');
  const { token, payload } = storageData ? JSON.parse(storageData) : '';
  const { role, communityId } = payload;
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
    hasMore: true,
    totalPosts: 10,
  });
  const [communityDetail, setCommunityDetail] = useState({
    communityPhoto: '',
    communityVideo: '',
    city: '',
    id: '',
    description: '',
    name: '',
    partners: [],
    business: [],
    posts: [],
  });

  useEffect(() => {
    if (role === 'admin') {
      history.push('/admin/dashboard');
    }
    if (storageData) {
      document.title = 'Community Detail';
    } else {
      history.push('/');
    }
    setUserData(session.data);
  }, [userData]);

  const getPosts = async () => {
    if (loading) {
      return;
    }
    console.log('get posts');
    setLoading(true);
    const res = await getAllUserPost(
      { communityId: session.data.communityId },
      pagination.page,
      pagination.limit
    );
    setPosts([...posts, ...res.postList]);
    setPagination({
      ...pagination,
      totalPosts: res.totalCount,
      page: pagination.page + 1,
    });
    setLoading(false);
  };
  useEffect(() => {
    if (communityId) {
      getCommunityDetails();
      getPosts();
    } else {
      message.error('No Community Selected,  Please Choose your community');
      history.push('/user/profile/update');
    }
  }, []);

  const getCommunityDetails = useCallback(async () => {
    try {
      let community = await getCommunityData(communityId);
      setCommunityDetail(community.data);
    } catch (err) {
      message.error(err ? err.message : ERROR_MESSAGE);
    }
  }, [session.data.communityId]);

  React.useEffect(() => {
    document.title = 'Community detail';
  });

  return (
    <React.Fragment>
      <SubHeader
        showProfile
        history
        pathName={`WELCOME TO ${communityDetail.name}`}
      />
      <div className='container'>
        <div className='box-outer'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='profile-detail'>
                <div className='profile-img' style={{ marginTop: 0 }}>
                  <img
                    src={communityDetail.communityPhoto || logo}
                    alt={communityDetail.name}
                    style={communityProfilePhoto}
                  />
                </div>
                <h1 className='mb-0' style={{ fontSize: 22 }}>
                  {communityDetail.name}
                </h1>
                <div
                  className='p-3'
                  style={{ background: '#F8F8F8', color: '#323F47' }}
                >
                  <Link to='/wallet'>
                    <p>
                      <img src={TockenIcon} width={40} alt='TockenIcon' />
                      <span
                        style={{
                          verticalAlign: 'sub',
                          fontSize: 20,
                          color: '#323F47',
                        }}
                        className='ml-2'
                      >
                        {communityDetail.tokens}
                      </span>
                    </p>
                  </Link>{' '}
                  {/* <h6 style={{ color: '#323F47' }}>State: Texas</h6> */}
                  <h6 style={{ color: '#323F47' }}>
                    Total Members: {communityDetail.totalMembers}
                  </h6>
                  <h4
                    style={{ color: '#323F47', fontWeight: 600 }}
                    className='mt-4'
                  >
                    {' '}
                    Partners
                  </h4>
                  <div
                    className='parnter-logo-container d-flex align-items-center justify-content-center'
                    style={{ gap: 20, flexWrap: 'wrap' }}
                  >
                    {communityDetail.partners.map((partner) => (
                      <div
                        className='partner-logo px-2'
                        style={{ width: 100 }}
                        key={partner.id}
                      >
                        <img src={partner.logo} style={{ maxWidth: '100%' }} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='community-video-wrapper'>
                <div
                  className='profile-detail-content'
                  style={{ marginBottom: 20 }}
                >
                  <div className='detail-img'>
                    {/* <img className='profile-detail-img' src={DefaultBannerImage}
                        alt={DefaultBannerImage} style={videoPlayerStyles} /> */}
                    <img
                      className='profile-detail-img'
                      src={
                        communityDetail.communityVideo
                          ? communityDetail.communityVideo
                          : DefaultBannerImage
                      }
                      alt={
                        communityDetail.communityVideo
                          ? communityDetail.communityVideo
                          : DefaultBannerImage
                      }
                      style={videoPlayerStyles}
                    />
                  </div>
                </div>
                {/* <video
                  src={
                    communityDetail.communityVideo ||
                    "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
                  }
                  autoplay="autoplay"
                  loop="true"
                  preload
                  muted
                  style={videoPlayerStyles}
                /> */}

                {/* <div className="video-content mt-3">
                  <h4>Description</h4>
                  <p>{communityDetail.description}</p>
                </div> */}
              </div>
              <div className='row'>
                <div className='col-md-7'>
                  <h3
                    style={{ color: '#3CA3AD' }}
                    className='font-weight-bold text-center mt-4'
                  >
                    LATEST FROM COMMUNITY
                  </h3>
                  <div
                    style={{ height: 400, overflow: 'auto', padding: '0 16px' }}
                    id='scrollableDiv'
                  >
                    <InfiniteScroll
                      dataLength={posts.length} //This is important field to render the next data
                      next={getPosts}
                      hasMore={true}
                      loader={loading && <h4>Loading...</h4>}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      scrollableTarget='scrollableDiv'
                    >
                      {posts.map((post) => {
                        const urlRegex =
                          /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gm;
                        const urls = post.description.match(urlRegex);
                        const postDescription = post.description.replace(
                          urlRegex,
                          ''
                        );
                        return (
                          <div key={post.id}>
                            <b>{post.title}</b> <br />
                            {urls?.length && <LinkPreviewCard url={urls[0]} />}
                            <p> {parse(unescapeHtml(postDescription))}</p>
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='w-100 p-2' style={{ background: '#F8F8F8' }}>
                    <h4 style={{ color: '#323F47' }} className='mb-4'>
                      Community Businesses
                    </h4>
                    {communityDetail.business.map((business) => (
                      <CommunityBusiness
                        name={business.name}
                        founder={business.foundedBy}
                        industry={business.industry}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CommunityDetail;
