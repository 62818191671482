import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Select,
  Checkbox,
  Button,
  Form,
  message,
} from 'antd';
import { header } from '../../../api/rest';
import { updateMentorAvailability } from '../../../redux/slices/userProfileSlice';
import { getUserProfile } from '../../../redux/slices/userProfileSlice';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../../redux/store';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

const getTime = (interval = 15) => {
  var times = []; // time array
  var tt = 0; // start time
  var ap = ['AM', 'PM']; // AM-PM

  //loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] =
      ('0' + (hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + interval;
  }
  return times;
};

export default function MentorAvailability() {
  const [form] = Form.useForm();
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState({
    startTime: '09:00AM',
    endTime: '05:00PM',
    days: [],
  });
  const dispatch = useDispatch();
  const storageInfo = localStorage.getItem('userData');
  const user = JSON.parse(storageInfo);
  const token = user?.token || '';
  const loginUserData = useSelector((state) => state.user);

  useEffect(() => {
    let user_id = {
      id: user.payload.id,
      type: 'normal',
    };
    dispatch(getUserProfile(user_id));
  }, []);

  useEffect(() => {

    if (loginUserData.data.availability) {
      setDays(loginUserData.data.availability.days);
      form.setFieldsValue(loginUserData.data.availability);
    }
  }, [loginUserData.data]);

  const timeForm = async (values) => {

    setLoading(true);
    header.Authorization = token;
    values.days = days;

    setAvailability(values);

    try {
      let res = await updateMentorAvailability(values, header);
      message.success(res.message);
      history.push('/dashboard');
    } catch (error) {
      message.error(error.data.message);
    }
    setLoading(false);
  };

  return (
    <Row className='justify-content-center'>
      <Form
        layout='vertical'
        form={form}
        initialValues={availability}
        onFinish={timeForm}
      >
        <div
          className='d-flex flex-column'
          style={{
            border: '2px solid #b6b3b3',
            borderRadius: '7px',
            width: '100%',
            paddingLeft: '13px',
            paddingRight: '13px',
          }}
        >
          <Col span={23} style={{ paddingTop: '14px' }}>
            <Title level={5} style={{ paddingLeft: '16px' }}>
              Set Your availability
            </Title>
            <Text style={{ paddingLeft: '16px', fontSize: '12px' }}>
              Let us know when you're typically avaialable to accept
              appointments
            </Text>
          </Col>
          <Col span={14} style={{ paddingTop: '55px' }}>
            <Title level={5} style={{ paddingLeft: '14px' }}>
              Available Hours
            </Title>
            <div className='d-flex' style={{ paddingLeft: '14px' }}>
              <Col span={19} style={{ marginRight: '23px' }}>
                <Form.Item
                  name='startTime'
                  rules={[
                    {
                      required: true,
                      message: 'Invaild Time',
                    },
                  ]}
                >
                  <Select>
                    {getTime().map((time) => (
                      <Option value={time} key={time}>
                        {time.slice(0, 5)} {time.slice(5, 7)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={19}>
                <Form.Item
                  name='endTime'
                  rules={[
                    {
                      required: true,
                      message: 'Invaild Time',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startTime = moment(
                          getFieldValue('startTime'),
                          'hh:mma'
                        );
                        const endTime = moment(value, 'hh:mma');
                        if (startTime.isBefore(endTime)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            'Choose an end time later than the start time.'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Select
                    style={{
                      boder: '2px solid #b6b3b3',
                      borderRadius: '8px',
                    }}
                  >
                    {getTime().map((time) => (
                      <Option value={time} key={time}>
                        {time.slice(0, 5)} {time.slice(5, 7)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </div>
          </Col>
          <Form.Item name='days'>
            <Col span={24} style={{ paddingTop: '30px' }}>
              <Title level={5} style={{ paddingLeft: '14px' }}>
                Available Days
              </Title>

              <Checkbox.Group onChange={(days) => setDays(days)} value={days}>
                <div className='d-flex' style={{ paddingLeft: '14px' }}>

                  <div
                    className='d-flex flex-column'
                    style={{ paddingRight: '23px' }}
                  >
                    <Checkbox value='sunday'>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          position: 'relative',
                          left: '-14px',
                        }}
                      >
                        Sunday
                      </span>
                    </Checkbox>
                  </div>
                  <div
                    className='d-flex flex-column'
                    style={{ paddingRight: '23px' }}
                  >
                    <Checkbox value='monday'>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          position: 'relative',
                          left: '-14px',
                        }}
                      >
                        Monday
                      </span>
                    </Checkbox>
                  </div>
                  <div
                    className='d-flex flex-column'
                    style={{ paddingRight: '23px' }}
                  >
                    <Checkbox value='tuesday'>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          position: 'relative',
                          left: '-14px',
                        }}
                      >
                        Tuesday
                      </span>
                    </Checkbox>
                  </div>
                  <div
                    className='d-flex flex-column'
                    style={{ paddingRight: '23px' }}
                  >
                    <Checkbox value='wednesday'>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          position: 'relative',
                          left: '-14px',
                        }}
                      >
                        Wednesday
                      </span>
                    </Checkbox>
                  </div>
                  <div
                    className='d-flex flex-column'
                    style={{ paddingRight: '23px' }}
                  >
                    <Checkbox value='thursday'>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          position: 'relative',
                          left: '-14px',
                        }}
                      >
                        Thursday
                      </span>
                    </Checkbox>
                  </div>
                  <div
                    className='d-flex flex-column'
                    style={{ paddingRight: '23px' }}
                  >
                    <Checkbox value='friday'>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          position: 'relative',
                          left: '-14px',
                        }}
                      >
                        Friday
                      </span>
                    </Checkbox>
                  </div>
                  <div className='d-flex flex-column'>
                    <Checkbox value='saturday'>
                      <br />
                      <span
                        style={{
                          fontSize: '11px',
                          position: 'relative',
                          left: '-14px',
                        }}
                      >
                        Saturday
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </Checkbox.Group>
            </Col>
          </Form.Item>
        </div>
        <Col
          span={14}
          style={{
            display: 'flex',
            paddingTop: '14px',
            justifyContent: 'flex-end',
          }}
        >
          <Form.Item>
            <Button
              htmlType='submit'
              size='large'
              type='primary'
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Row>
  );
}
