import React, { useState,useCallback } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { unescapeHtml } from '../../../utils/helpers';

export default function AddUpdateCommunityPost({ visible, onOk, onCancel }) {
  const title = 'Add Community Post';
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
  const handleSubmit = (e) => {

      e.type = 'COMMUNITY';
      onOk(e)
  }

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  return (
    <Modal
      title={title}
      centered
      visible={visible}
      onOk={(event) => onOk(event)}
      onCancel={() => onCancel(false)}
      okButtonProps={{ title: 'Submit' }}
      className='modal-update-community-post'
      footer={false}
    >
      <Form onFinish={handleSubmit} form={form} {...layout}>
        <Form.Item
          label={'Title'}
          name='title'
          rules={[
            {
              required: true,
              message: 'Please input post title',
            },
          ]}
        >
         <Input />
        </Form.Item>
        <Form.Item label={'Description'} name='description'>
          <CKEditor
            editor={ClassicEditor}
            data={unescapeHtml(description)}
            onChange={(event, editor) => {
              const data = editor.getData();
              form.setFieldsValue({description: data})
              setDescription(data);
            }}
            required
          />
        </Form.Item>
        <Button type='primary' htmlType='submit' >
          Submit
        </Button>
      </Form>
    </Modal>
  );
}

