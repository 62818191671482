import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Progress } from 'antd';
import {
  getSurveys,
  getAllSurveys,
  setActiveSurvey,
  setShowPopup,
} from '../../../redux/slices/surveySlice';

const SurveysList = () => {
  const dispatch = useDispatch();
  const surveys = useSelector(getAllSurveys);

  useEffect(() => {
    dispatch(getSurveys());
  }, []);
  return (
    <div className='surveys' style={{ backgroundColor: '#f8f8f8' }}>
      <h4
        style={{
          background: '#6a00b8',
          color: '#fff',
          padding: '12px 20px',
          fontWeight: 600,
        }}
      >
        Surveys
      </h4>
      <div className='w-100'>
        <div className='col-md-12 text-left'>
          {!surveys.length && (
            <h5 class='py-4 text-center'>All Surveys Completed</h5>
          )}
          <List
            itemLayout='horizontal'
            dataSource={surveys}
            size='small'
            renderItem={(item) => (
              <>
                <Progress
                  percent={Math.round(
                    (item.totalAnsweredQuestions * 100) / item.totalQuestions
                  )}
                  size='small'
                  strokeWidth={5}
                  style={{ padding: '0 16px' }}
                />
                <List.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setActiveSurvey(item.id));
                    dispatch(setShowPopup(true));
                  }}
                >
                  <List.Item.Meta
                    title={item.name}
                    description={item.description}
                  />
                </List.Item>
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SurveysList;
